<h4 mat-dialog-title>אישור מחיקת פריט</h4>
<mat-dialog-content class="mat-typography">
  <mat-dialog-content class="mat-typography">
    <!-- <h5>
       האם אתה בטוח שברצונך למחוק פריט:
     </h5>-->

    <mat-form-field class="edit-item mediumNumericField">
      <mat-label>קוד פריט </mat-label>
      <input autocomplete="off" matInput [(ngModel)]="selectedItem.IT_CODE" placeholder="קוד פריט" #input readonly>
    </mat-form-field>


    <mat-form-field class="edit-item longTextField">
      <mat-label>תאור פריט </mat-label>
      <input autocomplete="off" matInput [(ngModel)]="selectedItem.IT_DESC" placeholder="תאור פריט" #input readonly>
    </mat-form-field>


    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="false" mat-raised-button color="basic">ביטול</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial mat-raised-button color="primary">מחק</button>
    </mat-dialog-actions>

  </mat-dialog-content>
</mat-dialog-content>






