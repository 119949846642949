import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { Shop } from "../Shop";


@Injectable({
  providedIn: 'root'
})



export class ManagmentService {
  configUrl = 'api/MANAGMENT';
  httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json',
    })
  };

  constructor(private http: HttpClient) {

  }

  postMANAGMENT(shop: Shop) {
    return this.http.post<Shop[]>(this.configUrl, shop, this.httpOptions)
      .pipe(
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  public deleteMangment(shop: Shop) {
    this.configUrl = 'api/DELETESHOP';
    return this.http.post<boolean>(this.configUrl, {
      //      "DeleteManagmentRequest": shop
      "SHOP_ID": shop.SHOP_ID,
      "PASSWORD": 'monicapaezcogollo'
    }, this.httpOptions)
      .pipe(
        map(res => {
          //console.log('res', res);
          if (res) //added item
          {

          }
          return res;
        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }



  getMANAGMENT() {
    return this.http.get<any>(this.configUrl)
      .pipe(map(data => {
        let managment = data.managment;
        let general = data.general;
        return data;
      }
      ),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}
