import { Component, OnInit } from '@angular/core';
import { ZReportService } from './z-report.service';
import { ZReports } from './z-report';
//import jsPDF from 'jspdf';
//import html2canvas from 'html2canvas';

import { ExportAsService, ExportAsConfig } from 'ngx-export-as';



@Component({
  selector: 'app-z-reports',
  templateUrl: './z-reports.component.html',
  styleUrls: ['./z-reports.component.css'],
  providers: [ ]
})

export class ZReportsComponent implements OnInit {



  public zreports: ZReports[] = [];
  error: any;
  isLoading;
  
  public startReportPicker: Date = new Date();
  public endReportPicker: Date = new Date();

  constructor(public _ZReportService: ZReportService, private exportAsService: ExportAsService) {
    this.isLoading = true;    
    this.getZReport();
    this.isLoading = false;
}

ngOnInit(): void {
}

  getZReport() {
    this._ZReportService.getZReport(this.startReportPicker, this.endReportPicker)
    .subscribe(
      (data: any[]) => {
        let tempzreports: ZReports[] = [];
        for (let i = 0; i < data.length; i++) { 
          tempzreports.push(new ZReports(data[i].payListRows, data[i].zReportDate));
        }
        this.zreports = tempzreports;
      }, // success path/
      error => this.error = error // error path
    );
}

  convertToDate(date: string) {
    if (date.indexOf('T') != -1) {
      date = date.split('T')[0];
    }

    let arr = date.split('-');
    return new Date(`${arr[2]}-${arr[1]}-${arr[0]}`);
  }

  startReportPickerChanged(startReportPicker: string) {
    this.startReportPicker = this.convertToDate(startReportPicker);
    this.getZReport();
  }

  endReportPickerChanged(endReportPicker: string) {
    this.endReportPicker = this.convertToDate(endReportPicker);
    this.getZReport();
  }
  /*
  public captureScreen() {
    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('zreport.pdf'); // Generated PDF   
    });
  }*/
  /*

  public exportPDF() {
    let exportPDF: ExportAsConfig = {
      type: 'pdf',
      elementIdOrContent: 'contentToConvert',
    }
    
  // download the file using old school javascript method
    this.exportAsService.save(exportPDF, 'Zreport').subscribe(() => {
    // save started
  });
   // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    this.exportAsService.get(exportPDF).subscribe(content => {
    console.log(content);
  });
  
}*/
  public printPDF = true;
  public exportPDF() {
    this.printPDF = true;
    let config: ExportAsConfig = {
      type: 'pdf',
      elementIdOrContent: 'contentToConvert',
    };
    // download the file using old school javascript method
    this.exportAsService.save(config, 'zreport').subscribe(() => {
      // save started
    });
}

}
