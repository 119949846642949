import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'invoicing-report-single',
  templateUrl: './invoicing-report-single.component.html',
  styleUrls: ['./invoicing-report-single.component.css']
})
export class InvoicingReportSingleComponent implements OnInit {

  @Input('invoice') invoice: any = null;
  public displayedColumns: string[] = ['IT_DESC', 'IT_CODE', 'IT_QTY', 'IT_PRICE','IT_DISC','IT_TOTAL'];
  public dataSource = new MatTableDataSource([]);

  constructor() {

  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.invoice.invoiceAccItemsRecords);
  }

}
