import { Component, OnInit, ViewChild, OnDestroy, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable, of } from 'rxjs';
import { catchError, retry, tap, map } from 'rxjs/operators';
import { Shop } from '../Shop';
import { ItemSup } from '../item-sup/item-sup';
import { SpecialSalesRow } from '../items-managment/specialSalesRow';
import { ItemsManagmentService } from '../items-managment/items-managment.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { specialSalesBarcodeRow } from '../items-managment/specialSalesBarcodeRow';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Item } from '../items-managment/Item';
import { supplierRow } from '../items-managment/supplierRow';
import { itSortRow } from '../items-managment/itSortRow';



@Injectable({
  providedIn: 'root'
})
export class SpecialSalesManagmentService {
  configUrl = 'api/USERS_SHOPS_SPECIALSALESBARCODE';
  httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json',
    })
  }

  public specialSalesBarcodeRows: specialSalesBarcodeRow[][] = [];
  public itemsRows: Item[][] = [];
  public lastUpdated: Date;
  public specialSalesBarcodeRowsFilterd: specialSalesBarcodeRow[] = [];
  public itSortRows: itSortRow[][] = [];
  public supplierRows: supplierRow[][] = [];
  //  public colorRows: colorRow[][] = [];
  //  public midaRows: midaRow[][] = [];
  public selectedItem: Item = null;
  public specialSalesRow: SpecialSalesRow;


  public shopIndex = 0;
  public filterValue = "";
  public selectedspecialSalesBarcode: specialSalesBarcodeRow;
  error: any;
  private _data: any = null;



  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private http: HttpClient) {

  }

  rcode = new MatTableDataSource<specialSalesBarcodeRow>(this.specialSalesBarcodeRowsFilterd);

  ngOnInit(): void {
    //    this.getBarcodes(null);
    //  this.dataSourceBarcode.paginator = this.paginator;
  }

  public setItems(data: any): void {
    this._data = data;
  }

  public getspecialsale(selectedShop: Shop) {
//  public getspecialsale(Shop: Shop) {
    let shopIds: number = 0;
    if (selectedShop != null)
      shopIds = selectedShop.SHOP_ID;
//    if (selectedShops && selectedShops.length > 0)
//      shopIds = selectedShops.map(x => x.SHOP_ID);


    this.configUrl = 'api/USERS_SHOPS_SPECIALSALES';
    const url = shopIds ? this.configUrl + "?ShopIndex=" + shopIds : this.configUrl;
//    if (selectedShops && selectedShops.length > 0)
//    shopIds = selectedShops.map(x => x.SHOP_ID);
    return this.http.get<Observable<any>>(url, this.httpOptions)
      .pipe(
        map(data => {
          let shops = data["specialsale_data"].map(x => x.shop);
          let specialSalesRows = data["specialsale_data"].map(x => x.specialSalesRows);
          let specialSalesBarcodeRows = data["specialsale_data"].map(x => x.specialSalesBarcodeRows);
          let itemsRows = data["specialsale_data"].map(x => x.ItemsRows);
          let supplierRows = data["specialsale_data"].map(x => x.supplierRows);
          let itSortRows = data["specialsale_data"].map(x => x.itSortRows);

          this.setItems(
            {
              shops: shops,
              specialSalesRows: specialSalesRows,
              specialSalesBarcodeRows: specialSalesBarcodeRows,
              itemsRows: itemsRows,
              supplierRows: supplierRows,
              itSortRows: itSortRows,
              skip: true
            }
          ); 
          return {
            shops: shops,
            specialSalesRows: specialSalesRows,
            specialSalesBarcodeRows: specialSalesBarcodeRows,
            itemsRows: itemsRows,
            supplierRows: supplierRows,
            itSortRows: itSortRows,
            skip: true
          }
 
        }
      ),
      retry(1), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
      );





  /*
  
  
  
      return this.http.post<specialSalesRow[]>(this.configUrl,
        {
          "shopIds": shopIds,
        }, this.httpOptions)
        .pipe(
          retry(3), // retry a failed request up to 3 times
          catchError(this.handleError) // then handle the error
      );
  */


  }

  
  public getspecialsaleBarcodes(selectedShops: Shop[]) {
    let shopIds: number[] = [];
    this.configUrl = 'api/USERS_SHOPS_SPECIALSALESBARCODE';
    if (selectedShops && selectedShops.length > 0)
      shopIds = selectedShops.map(x => x.SHOP_ID);
    return this.http.post<specialSalesBarcodeRow[]>(this.configUrl,
      {
        "shopIds": shopIds,
      }, this.httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );

  }

  public getSpecialSalesCompare(code: string) {

    this.configUrl = 'api/USERS_SHOPS_SPECIALSALESCompare';
    const url = code ? this.configUrl + "?CODE=" + code : this.configUrl;

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        map(data => {
          return data;
        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

}

