<app-nav-menu></app-nav-menu>
<div class="container">
  <router-outlet></router-outlet>
  <!--
    <app-loading></app-loading>
    <router-outlet></router-outlet>
     -->
</div>


<!-- <app-nav-menu-new></app-nav-menu-new> -->
