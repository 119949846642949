import { Component } from '@angular/core';
import { AuthorizeService } from '../../api-authorization/authorize.service';
import { Observable } from 'rxjs/internal/Observable';
import { NavMenuService } from './nav-menu.service';
import { AutoLogoutService } from '../../api-authorization/logout/auto-logout.service';
import { interval } from 'rxjs/internal/observable/interval';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;
  public isAuthenticated: Observable<boolean>;
  public userName: Observable<string>;
  public roles: string[];
  public isAdmin = false;
  public isStore = false;
  
  error: any;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
//    this.isExpanded = !this.isExpanded;
    let bool = this.isExpanded;
    this.isExpanded = bool === false ? true : false;
  }

  constructor(private authorizeService: AuthorizeService, private _NavMenuService: NavMenuService, private _AutoLogoutService: AutoLogoutService) {
    this.startLogoutTimer();
  }
  subscription: Subscription;

  startLogoutTimer(): void {
    const source = interval(1000);
    this.subscription = source.subscribe(val => {
      let t = this._AutoLogoutService.logOutCheck();
      if (t) {
        window.location.href = '/authentication/logout';
        this.subscription.unsubscribe();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public menuVisible = false;
  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }

  getRoles() {
    this._NavMenuService.getRoles()
      .subscribe({
        next: (data: string[]) => {
          this.roles = data;
          this.isAdmin = this.roles.indexOf('ADMIN') > -1;
          this.isStore = this.isAdmin || this.roles.indexOf('USER_STORE') > -1;
        },
        error: (error) => { 
          this.error = error;
          console.log("🚀 ~ file: nav-menu.component.ts:69 ~ NavMenuComponent ~ getRoles ~ error:", error)
        },
        complete: () => {}
      });
  }

  ngAfterViewInit() {
    this.getRoles();
  }

  ngOnInit() {
    this.isAuthenticated = this.authorizeService.isAuthenticated();


  }

  public showReportsMenu = false;
  public showTableMenu = false;

  public hideReportsMenuClick(): void {
    this.showReportsMenu = false;
  }

  showTableMenuClick(): void {
    this.showTableMenu = !this.showTableMenu
  }

  deleteShop(element) {

  }
  showReportsMenuClick(): void
  {
    this.showReportsMenu = !this.showReportsMenu
  }
  
  
}
