import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Shop } from '../Shop';
import { ItemsManagmentService } from '../items-managment/items-managment.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { colorRow } from '../items-managment/colorRow';
import { interval } from 'rxjs/internal/observable/interval';
import { Subscription } from 'rxjs';

@Component({
  selector: 'color-managment',
  templateUrl: './color-managment.component.html',
  styleUrls: ['./color-managment.component.css']
})

export class ColorManagmentComponent implements OnInit, OnDestroy {

  public shops: Shop[] = [];
  public colorRows: colorRow[][] = [];
  public lastUpdated: Date;
  public colorRowsFilterd: colorRow[] = [];
  public selectedShop: Shop = null;


  public shopIndex = 0;
  public filterValue = "";
  public selectedColor: colorRow;
  error: any;

  displayedColumns: string[] =
    [
      'CODE',
      'DESC1',
    ];

  @ViewChild(MatPaginator) paginator: MatPaginator;



  public newColor(): void {
    this.selectedColor = {
      SHOP_ID: this.selectedShop.SHOP_ID,
      TABLEID: 0,
      CODE: null,
      DESC1: null,
    }
  }

  constructor(public _ItemsManagmentService: ItemsManagmentService) {
  }

  ngOnInit(): void {
    this.getItems(null);
    this.dataSource.paginator = this.paginator;
  }

  public dataSource = new MatTableDataSource<colorRow>(this.colorRowsFilterd);

  filterItems() {
    this.colorRowsFilterd = this.colorRows[this.shopIndex].filter(x => x);
    this.colorRowsFilterd.sort(function (a, b) {
      return a.CODE - b.CODE
    })

    this.dataSource = new MatTableDataSource<colorRow>(this.colorRowsFilterd);
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
  }

  getItems(lastUpdated: Date) {
    this._ItemsManagmentService.getItems(lastUpdated)
      .subscribe(
        (data: any) => {
          this.shops = data.shops;
          this.colorRows = data.colorRows;
          this.selectedShop = this.shops[0];
          this.colorRowsFilterd = [];
          this.dataSource = new MatTableDataSource<colorRow>(this.colorRowsFilterd)
          this.dataSource.paginator = this.paginator;
          this.startDelayedFilter();
          this.newColor();
          return;
        }, // success path/
        error => this.error = error // error path
      );
  }

  subscription: Subscription;

  ngOnDestroy() {
    //this.subscription.unsubscribe();
  }


  startDelayedFilter(): void {
    const source = interval(1000);
    this.subscription = source.subscribe(val => {
      if (this.colorRowsFilterd.length === 0) {
        this.filterItems()
        this.subscription.unsubscribe();
      }
      return 1;
    }
    );
  }

  selectionChangeShop(selectedshop: Shop) {
    this.selectedShop = selectedshop;
    this.shopIndex = this.shops.findIndex(x => x.SHOP_ID === this.selectedShop.SHOP_ID)
    this.filterItems();
  }

  public selectRow(colorRow: colorRow) {
    this.selectedColor = colorRow;
  }


  save() {
    this.selectedColor.SHOP_ID = this.selectedShop.SHOP_ID;

    this.selectedColor.CODE = this.selectedColor.CODE ? parseInt(this.selectedColor.CODE.toString()) : null;

    this._ItemsManagmentService.postselectedColor(this.selectedColor, this.shopIndex)
      .subscribe(
        (data: number) => {
          if (data) //added item
            this.selectedColor.TABLEID = data;
           // this.colorRows[this.shopIndex].push(this.selectedColor);
          
          this.newColor();
          this.filterItems();
        }, // success path/
        error => this.error = error // error path
      );
  }

}
