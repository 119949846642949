import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Shop } from '../Shop';
import { ItemsManagmentService } from '../items-managment/items-managment.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { interval } from 'rxjs/internal/observable/interval';
import { Subscription } from 'rxjs';
import { emvSerialRow } from '../items-managment/emvSerialRow';

@Component({
  selector: 'emvserial-managment',
  templateUrl: './emvserial-managment.component.html',
  styleUrls: ['./emvserial-managment.component.css']
})
export class EmvserialManagmentComponent implements OnInit, OnDestroy {

  public shops: Shop[] = [];
  public emvserialRows: emvSerialRow[][] = [];
  public lastUpdated: Date;
  public emvserialRowsFilterd: emvSerialRow[] = [];
  public selectedShop: Shop = null;
  public ispayingstring: string = null;

  public shopIndex = 0;
  public filterValue = "";
  public selectedEmvSerialRow: emvSerialRow;
  error: any;

  displayedColumns: string[] =
    [
      'SHOPNAME',
      'ID',
      'SERIAL',
      'FIRSTDATE',
      'LASTDATE',
      'ADDRESS',
      'STATION',
      'VERNBR',
      'ISPAYING',
    ];

  @ViewChild(MatPaginator) paginator: MatPaginator;



  public newEmvSerial(): void {
    this.ispayingstring = null;
    this.selectedEmvSerialRow = {
      SHOP_ID: this.selectedShop.SHOP_ID,
      TABLEID: 0,
      FIRSTDATE: null,
      LASTDATE: null,
      ISPAYING: null,
      SHOPNAME: null,
      STATION: null,
      VERNBR: null,
      ADDRESS: null,
      SERIAL: null,
      ID: null,
    }
  }

  constructor(public _ItemsManagmentService: ItemsManagmentService) {
  }

  ngOnInit(): void {
    this.getItems(null);
    this.dataSource.paginator = this.paginator;
  }

  public dataSource = new MatTableDataSource<emvSerialRow>(this.emvserialRowsFilterd);

  filterItems() {
    this.emvserialRowsFilterd = this.emvserialRows[0].filter(x => x);
    //this.emvserialRowsFilterd.sort(function (a, b) {
   //   return a.CODE - b.CODE
    //})

    this.dataSource = new MatTableDataSource<emvSerialRow>(this.emvserialRowsFilterd);
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
  }

  getItems(lastUpdated: Date) {
    this._ItemsManagmentService.getItems(lastUpdated)
      .subscribe(
        (data: any) => {
          this.shops = data.shops;
          this.emvserialRows = data.emvSerialRows;
          this.selectedShop = this.shops[0];
          this.emvserialRowsFilterd = [];
          this.dataSource = new MatTableDataSource<emvSerialRow>(this.emvserialRowsFilterd)
          this.dataSource.paginator = this.paginator;
          this.startDelayedFilter();
          this.newEmvSerial();
          return;
        }, // success path/
        error => this.error = error // error path
      );
  }

  subscription: Subscription;

  ngOnDestroy() {
    //this.subscription.unsubscribe();
  }


  startDelayedFilter(): void {
    const source = interval(1000);
    this.subscription = source.subscribe(val => {
      if (this.emvserialRowsFilterd.length === 0) {
        this.filterItems()
        this.subscription.unsubscribe();
      }
      return 1;
    }
    );
  }

  selectionChangeShop(selectedshop: Shop) {
    this.selectedShop = selectedshop;
    this.shopIndex = this.shops.findIndex(x => x.SHOP_ID === this.selectedShop.SHOP_ID)
    this.filterItems();
  }

  public selectRow(envserialRow: emvSerialRow) {
    this.selectedEmvSerialRow = envserialRow;
    this.ispayingstring = this.selectedEmvSerialRow.ISPAYING == true ? "true" : this.selectedEmvSerialRow.ISPAYING == false ? "false" : null;

    //console.log('this.selectedEmvSerialRow', this.selectedEmvSerialRow);

    //this.selectedEmvSerialRow.ISPAYING = this.ispayingstring == "true" ? true : this.ispayingstring == "false" ? false : null;
    //console.log('this.ispayingstring', this.ispayingstring);
  }


  save() {
    this.selectedEmvSerialRow.SHOP_ID = this.selectedShop.SHOP_ID;
    this.selectedEmvSerialRow.STATION = this.selectedEmvSerialRow.STATION ? parseInt(this.selectedEmvSerialRow.STATION.toString()) : null;
    this.selectedEmvSerialRow.ISPAYING = this.ispayingstring == "true" ? true : this.ispayingstring == "false" ? false : null
   // console.log("this.ispayingstring", this.ispayingstring)
    //console.log("this.selectedEmvSerialRow.ISPAYING", this.selectedEmvSerialRow.ISPAYING)
    
    this._ItemsManagmentService.postselectedEmvSerialRows(this.selectedEmvSerialRow, this.shopIndex)
      .subscribe(
        (data: number) => {
          if (data) //added item
          //  this.selectedEmvSerialRow.TABLEID = data;
          // this.emvserialRows[this.shopIndex].push(this.selectedEmvSerialRow);

          this.newEmvSerial();
          this.filterItems();
        }, // success path/
        error => this.error = error // error path
      );
  }

  public changeFIRSTDATE(event: Date): void {
    this.selectedEmvSerialRow.FIRSTDATE = new Date(event.setHours(5, 0, 0, 0));
  }

  public changeLASTDATE(event: Date): void {
    this.selectedEmvSerialRow.LASTDATE = new Date(event.setHours(5, 0, 0, 0));
  }


}





