import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { EnvItem, FullEnvItem } from '../env-item';

@Component({
  selector: 'inventory-item-list',
  templateUrl: './inventory-item-list.component.html',
  styleUrls: ['./inventory-item-list.component.css']
})
export class InventoryItemListComponent implements OnInit {
  @Input('selectedEntfullRows') selectedEntfullRows: FullEnvItem[] = [];
  @Output() selectedEntfullRow = new EventEmitter<FullEnvItem>();

  public dataSource = new MatTableDataSource([]);

  constructor() { }
  public displayedColumns: string[] =
    [
      'IT_CODE',
      'IT_DESC',
      'IT_QTY',
      'BONUS',
      'IT_TOTAL',
      'IT_COST',
      'IT_PRICE',
      'IT_DISC',


/*      'ITSORT',
      'SUPPLIER',
      'NUMBER',
      'IT_LINE',

            'IT_TOTAL_CUR',
                  'IT_PRICE_CUR',

      'VAT',
      'CASHIER',
      'IT_CHPRICE',
      'PRICE2',
      'PRICE3',
      'ICOM',*/
    ];
  ngOnInit(): void {
    //console.log('this.selectedEntfullRows', this.selectedEntfullRows)
   // this.dataSource = new MatTableDataSource([{}, {}]);
  }


  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.selectedEntfullRows);
    if (this.selectedEntfullRows.length>0)
    this.selectRow(this.selectedEntfullRows[this.selectedEntfullRows.length - 1])
  }
  public selectRow(selectedEntfullRow: FullEnvItem) {
    this.selectedEntfullRow.emit(selectedEntfullRow)
  }

}


