import { Component } from '@angular/core';
import { SalesReportByGroup } from './sales-report-by-group';
// import { MatTableDataSource } from '@angular/material/table';
import { SalesReportByGroupService } from './sales-report-by-group.service';
import { Shop } from '../../Shop';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { DxDataGridComponent } from 'devextreme-angular';
import { locale, loadMessages } from "devextreme/localization";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { saveAs } from 'file-saver';




@Component({
  selector: 'sales-report-by-group',
  templateUrl: './sales-report-by-group.component.html',
  styleUrls: ['./sales-report-by-group.component.css']
})
export class SalesReportByGroupComponent {

  public startReportPicker: Date = new Date();
  public endReportPicker: Date = new Date();

  public salesReportByGroup: SalesReportByGroup[] = [];
  public selectedShops: Shop[];
  dataSourse1: DataSource;
  rtlEnabled = true;
  isLoading = false;
  error: any;

//  displayedColumns: string[] = ['CODE', 'Name', 'Tot_Qty', 'Tot_Total', 'Tot_WitOutVat', 'Total_Percentage', 'Qty_Percentage', 'Tot_Cost', 'Profit', 'DProfit', 'AProfit'];
//  dataSource = new MatTableDataSource(this.salesReportByGroup);

  public catorgiesBy: string = '1';
 


  constructor(public _salesReportByGroupService: SalesReportByGroupService, private exportAsService: ExportAsService) {

  }

  ngOnInit() {
   
  }

  shopsChanged(shops: Shop[]) {
    this.selectedShops = shops;
    this.geSalesReport();
  }

  convertToDate(date: string) {
    if (date.indexOf('T') != -1) {
      date = date.split('T')[0];
    }

    let arr = date.split('-');
    return new Date(`${arr[2]}-${arr[1]}-${arr[0]}`);
  }


  startReportPickerChanged(startReportPicker: string) {
    this.startReportPicker = this.convertToDate(startReportPicker);
    this.geSalesReport();
  }

  endReportPickerChanged(endReportPicker: string) {
    this.endReportPicker = this.convertToDate(endReportPicker);
    this.geSalesReport();
  }

  fillDatabase(SalesReportByGroup: any) {
    this.dataSourse1 = new DataSource({
      store: SalesReportByGroup,

      select: [
        "CODE",
        "Name",
        "Tot_Qty",
        "Tot_Total",
        "Tot_WitOutVat",
        "Tot_Cost",
        "Profit",
        "DProfit",
        "AProfit",
        "Discount",
        "Qty_Percentage",
        "Total_Percentage",
      ]
    });

  }


  geSalesReport() {
    this.isLoading = true;
    this._salesReportByGroupService.getSalesReportByGroup(this.startReportPicker, this.endReportPicker, this.selectedShops, this.catorgiesBy)
      .subscribe(
        (data: any) => {
          this.salesReportByGroup = data;
          this.fillDatabase(this.salesReportByGroup);
          this.isLoading = false;
//          this.dataSource = new MatTableDataSource(this.salesReportByGroup);
        }, // success path/
        error => { this.error = error; this.isLoading = false; } // error path
      );
  }

  exportGrids(e) {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('CountriesPopulation');
    e.numberFormat = "$#,##0.00";
    e.horizontalAlignment = "right";

    exportDataGrid({
      component: e.component,
      worksheet,
      topLeftCell: { row: 4, column: 1 },
    }).then((cellRange) => {
      // header
      const headerRow = worksheet.getRow(2);
      headerRow.height = 30;
      worksheet.mergeCells(2, 1, 2, 8);
      let ReDate: Date = new Date();
      var d = '0' + (new Date().getMonth() + 1).toString();
      var d1 = '0' + (this.startReportPicker.getMonth() + 1).toString();
      var d2 = '0' + (this.endReportPicker.getMonth() + 1).toString();

      let date_String: string;
      if (this.catorgiesBy == '1') date_String = "דוח מכירות פריטים מרוכז לפי קבוצה";
      if (this.catorgiesBy == '2') date_String = "דוח מכירות פריטים מרוכז לפי ספק ";
      date_String = date_String + "  מתאריך " + (this.startReportPicker.getDate() < 10 ? '0' : '') + this.startReportPicker.getDate() + "/" + d1 + "/" + this.startReportPicker.getFullYear() + "  עד תאריך  " + (this.endReportPicker.getDate() < 10 ? '0' : '') + this.endReportPicker.getDate() + "/" + d2 + "/" + this.endReportPicker.getFullYear() + "                                                      ";
      //      date_String = date_String + " תאריך הדפסה:  " + (ReDate.getDate() < 10 ? '0' : '') + ReDate.getDate() + "/" + d + "/" + ReDate.getFullYear() + " " + ReDate.getHours() + ":" + (ReDate.getMinutes() < 10 ? '0' : '') + ReDate.getMinutes();


      headerRow.getCell(1).value = date_String;
      headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 15 };
      headerRow.getCell(1).alignment = { horizontal: 'center' };


      // footer
      //const footerRowIndex = cellRange.to.row + 2;
      //const footerRow = worksheet.getRow(footerRowIndex);
      //worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

      //footerRow.getCell(1).value = 'www.wikipedia.org';
      //footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true };
      //footerRow.getCell(1).alignment = { horizontal: 'right' };
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'SaleReportDetail.xlsx');
      });
    });
    e.cancel = true;
  }


  onExporting(e) {
    //    alert(e.format);
    if (e.format == 'excel') {
      this.exportGrids(e);
    }
    else {
      const doc = new jsPDF('l', 'pt', 'a4');
      doc.addFont("../assets/David.ttf", "David", "normal");
      doc.addFont("../assets/davidbd.ttf", "David", "bold");

      doc.setFont("David", "normal");
      doc.setFontSize(10);

      let ReDate: Date = new Date();
      var d = '0' + (new Date().getMonth() + 1).toString();
      var d1 = '0' + (this.startReportPicker.getMonth() + 1).toString();
      var d2 = '0' + (this.endReportPicker.getMonth() + 1).toString();

      let date_String: string;
      if (this.catorgiesBy == '1') date_String = "דוח מכירות פריטים מרוכז לפי קבוצה";
      if (this.catorgiesBy == '2') date_String = "דוח מכירות פריטים מרוכז לפי ספק ";
      date_String = date_String + "  מתאריך " + (this.startReportPicker.getDate() < 10 ? '0' : '') + this.startReportPicker.getDate() + "/" + d1 + "/" + this.startReportPicker.getFullYear() + "  עד תאריך  " + (this.endReportPicker.getDate() < 10 ? '0' : '') + this.endReportPicker.getDate() + "/" + d2 + "/" + this.endReportPicker.getFullYear() + "                                                      ";
      date_String = date_String + " תאריך הדפסה:  " + (ReDate.getDate() < 10 ? '0' : '') + ReDate.getDate() + "/" + d + "/" + ReDate.getFullYear() + " " + ReDate.getHours() + ":" + (ReDate.getMinutes() < 10 ? '0' : '') + ReDate.getMinutes();
      let reverseString: string;
      for (let char of date_String) {
        reverseString = char + reverseString;
      }

      reverseString = reverseString.slice(0, date_String.length);

      //    doc.text(new Date().toJSON().slice(0, 15).replace(/-/g, '/') + 'ךיראת : ', 10, 20);
      //    doc.text(date_String + , 50, 20);
      doc.text(reverseString, 50, 20);

      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: e.component,
        indent: 0,
        customizeCell({ gridCell, pdfCell }) {

          if (gridCell.rowType === 'header') {
            pdfCell.backgroundColor = '#D8FEFF';
            pdfCell.font.style = 'bold';
            pdfCell.textColor = '#010101';
            pdfCell.font.size = 8;

          } else if (gridCell.rowType === 'group') {
            pdfCell.backgroundColor = '#BEDFE6';
          } else if (gridCell.rowType === 'totalFooter') {
            pdfCell.font.style = 'italic';
          }
        },

      }).then(() => {
        //      const header = date_String;
        //      const pageWidth = doc.internal.pageSize.getWidth();
        //      doc.setFontSize(15);
        //      const headerWidth = doc.getTextDimensions(header).w;
        //      doc.text(header, (pageWidth - headerWidth) / 2, 20);

        doc.save(date_String);
      });
    }
  }

  phoneNumberFormat(value) {
    const USNumber = value.match(/(\d{3})(\d{3})(\d{4})/);

    return `(${USNumber[1]}) ${USNumber[2]}-${USNumber[3]}`;
  }

  onCellPrepared(e) {

    if (e.rowType === 'header') {
      e.cellElement.style.backgroundColor = "lightgray";
      e.cellElement.style.color = 'black';
      e.cellElement.style.fontweight = "bold";

    }
    else
      if (e.rowType === 'data') {
        if (e.rowIndex % 2 === 0) {
          // Even row index
          e.cellElement.style.height = "0.35rem";
          e.cellElement.style.backgroundColor = '#c0f0f0'; // Set the background color for even rows
          e.rowElement.css({ height: 10 });
        } else {
          // Odd row index
          e.cellElement.style.height = "0.5rem";
          e.cellElement.style.backgroundColor = '#ffffff'; // Set the background color for odd rows
          e.rowElement.css({ height: 10 });
        }
      }
  }


  catorgiesByChange(selectedCategory: string) {
    this.catorgiesBy = selectedCategory;
    this.geSalesReport();
  }

  public printPDF = true;
  public exportxlsx() {
    this.printPDF = true;
    let config: ExportAsConfig = {
      type: 'xlsx',
      elementIdOrContent: 'contentToxlsx',
    };
    // download the file using old school javascript method
    this.exportAsService.save(config, 'sales report').subscribe(() => {
      // save started
    });
  }

}
