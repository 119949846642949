import { Data } from "@angular/router";

export class Ent_Item {
  TABLEID: number;
  SHOP_ID: number;
  NUMBER: number;
  IT_LINE: number;
  IT_CODE: string;
  IT_DESC: string;
  IT_QTY: number;
  BONUS: number;
  IT_COST: number;
  IT_PRICE: number;
  IT_PRICE_CUR: number;
  IT_DISC: number;
  IT_TOTAL: number;
  IT_TOTAL_CUR: number;
  VAT: string;
  CASHIER: number;
  IT_CHPRICE: string;
  PRICE2: number;
  PRICE3: number;
  ICOM: string;
}

export class Ent_Head  {
  TABLEID: number;
  SHOP_ID: number;
  //      public DateTime ? HEAD_EDATE { get; set; }
  NUMBER: number;
  EDATE: Date;
  SUPNUM: number;
  REF2: number;
  CASHIER: number;
  SALEPERSON: number;
  TOTAL_B: number;
  ENT_DATE: Date;
  TOTAL_F: number;
  STATUS: string;
  VAT: number;
  VAT_PERC: number;
  DISCT_P: number;
  DISCT_T: number;
  PDATE: Date;
  SUPORDER: number;
  TOTAL_B_CUR: number;
  DISCT_T_CUR: number;
  TOTAL_F_CUR: number;
  PIKADON: number;
  ICOM: string;
  
}


