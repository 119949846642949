<div class="flex-row filter-row-container">
  
  <div class="form-floating customized-input flex-item">
    <input type="text" class="form-control" id="floatingInput"
           name="filterShops"
           placeholder="חיפוש" autocomplete="off"
           (keyup)="applyFilter($event)" #input>
    <label for="floatingInput" class="label-color">חיפוש</label>
  </div>
  

<!--SHOP ID: {{selectedShop?.SHOP_ID}}-->


<div class="flex-row filter-row-container">

  <div class="form-floating customized-input flex-item" id="PARENT_SHOP">
    <input type="text" class="form-control" autocomplete="off" placeholder="PARENT SHOP" [(ngModel)]="selectedShop.PARENT_SHOP_ID" #input>
    <label for="PARENT SHOP" class="label-color">PARENT SHOP</label>
  </div>

  <div class="form-floating customized-input flex-item" id="ITEMS_FROM_SHOP">
    <input type="text" class="form-control" autocomplete="off" placeholder="ITEMS FROM SHOP" [(ngModel)]="selectedShop.ITEMS_FROM_SHOP_ID" #input>
    <label for="ITEMS FROM SHOP" class="label-color">ITEMS FROM SHOP</label>
  </div>

  <div class="form-floating customized-input flex-item" id="SHOP_NAME">
    <input type="text" class="form-control" autocomplete="off" placeholder="SHOP NAME" [(ngModel)]="selectedShop.SHOP_NAME" #input>
    <label for="SHOP NAME" class="label-color">SHOP NAME</label>
  </div>


  <div class="form-floating customized-input flex-item" id="SHORT_NAME">
    <input type="text" class="form-control" autocomplete="off" placeholder="SHORT NAME" [(ngModel)]="selectedShop.SHORT_NAME" #input>
    <label for="SHORT NAME" class="label-color">SHORT NAME</label>
  </div>


  <div class="form-floating customized-input flex-item" id="SPECIAL_SALES_FROM">
    <input type="text" class="form-control" autocomplete="off" placeholder="SPECIAL_SALES_FROM" [(ngModel)]="selectedShop.SPECIAL_SALES_FROM_SHOP_ID" #input>
    <label for="SPECIAL_SALES_FROM" class="label-color">SPECIAL_SALES_FROM</label>
  </div>

  <div class="form-floating customized-input flex-item" id="BACKUP_DAYS">
    <input type="text" class="form-control" autocomplete="off" placeholder="BACKUP DAYS" [(ngModel)]="selectedShop.BACKUP_DAYS" #input>
    <label for="BACKUP DAYS" class="label-color">BACKUP DAYS</label>
  </div>

  <div class="form-floating customized-input flex-item" id="TOKEN">
    <input type="text" class="form-control" autocomplete="off" placeholder="TOKEN" [(ngModel)]="selectedShop.TOKEN" #input>
    <label for="TOKEN" class="label-color">TOKEN</label>
  </div>

  <div class="form-floating customized-input flex-item" id="TOKEN">
    <input type="text" class="form-control" autocomplete="off" placeholder="SITE" [(ngModel)]="selectedShop.Site" #input>
    <label for="Site" class="label-color">Site</label>
  </div>

  <div *ngIf="HasSite" class="form-floating customized-input flex-item" id="WORDTOKEN">
    <input type="text" class="form-control" autocomplete="off" placeholder="ConsumerKey" [(ngModel)]="selectedShop.ConsumerKey" #input>
    <label for="ConsumerKey" class="label-color">ConsumerKey</label>
  </div>

  <div *ngIf="HasSite" class="form-floating customized-input flex-item" id="WORDTOKEN">
    <input type="text" class="form-control" autocomplete="off" placeholder="ConsumerSecret" [(ngModel)]="selectedShop.ConsumerSecret" #input>
    <label for="ConsumerSecret" class="label-color">ConsumerSecret</label>
  </div>


  <!--
  <mat-form-field class="edit-shop">
    <mat-label>SHOP TYPE</mat-label>
    <input matInput [(ngModel)]="selectedShop.SHOP_TYPE" placeholder="SHOP TYPE" #input>
  </mat-form-field>-->
  <!--
  <telegtam-single-selection (telegramChatChanged)="telegramChat1Changed($event)" [selectedTelegramId]="selectedShop.TelegramChat1"></telegtam-single-selection>
  <telegtam-single-selection (telegramChatChanged)="telegramChat2Changed($event)" [selectedTelegramId]="selectedShop.TelegramChat2"></telegtam-single-selection>
  <telegtam-single-selection (telegramChatChanged)="telegramChat3Changed($event)" [selectedTelegramId]="selectedShop.TelegramChat3"></telegtam-single-selection>
   -->

  <div>
    <button mat-raised-button color="basic" (click)="newShop()">New Shop</button>
    <button mat-raised-button color="primary" (click)="generateTOKEN()">TOKEN</button>
    <button mat-raised-button color="primary" (click)="save()">Save</button>
    <button mat-raised-button color="primary" (click)="deleteShop()">Delete </button>
  </div>
  <div class="form-floating customized-input flex-item" id="BACKUP_DAYS">
    <input type="text" class="form-control" autocomplete="off" placeholder="מעמ %" [(ngModel)]="general.VAT_PERCENT" #input>
    <label for="מעמ %" class="label-color">מעמ %</label>
  </div>

  <div>
    <input class="form-control" id="VatDate"
           name="dp"
           type="date"
           [value]="general.VAT_LAST_DATE | date:'yyyy-MM-dd'"
           (change)="changeVatDate()" />
  </div>


  <div class="form-floating customized-input flex-item" id="BACKUP_DAYS">
    <input type="text" class="form-control" autocomplete="off" placeholder="מעמ %" [(ngModel)]="general.VAT_OLD_PERCENT" #input>
    <label for="מעמ ישן" class="label-color">מעמ ישן</label>
  </div>
  <button *ngIf="isLoading" style="margin-top:0.5rem;margin-right:1.0rem" class="btn btn-primary" type="button" disabled>
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span class="sr-only">  מבטל ...</span>
  </button>


  <div class="bordered-div" *ngIf="ifdelete">

    <div mat-dialog-content style="text-align: center">
      <h1 mat-dialog-title style="text-align:center">ביטול חנות</h1>
      <p style="margin-top:-1rem">האם אתה בטוח שברצונך לבטל?</p>
      <button mat-button style="margin-top: -0.5rem;background-color:yellow; margin-right: 10%" (click)="onNoClick()">לא</button>
      <button mat-button style="margin-top: -0.5rem;background-color:yellow" (click)="onYesClick()">כן</button>

      <!--   </div> -->
    </div>
  </div>




</div>

<table mat-table  matSort matSortActive="SHOP_ID"   [dataSource]="dataSource" class="mat-elevation-z8">


  <ng-container matColumnDef="SHOP_ID">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> SHOP No. </th>
    <td mat-cell *matCellDef="let element"> {{element.SHOP_ID}} </td>
  </ng-container>

  <ng-container matColumnDef="PARENT_SHOP_ID">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> PARENT SHOP ID </th>
    <td mat-cell *matCellDef="let element"> {{element.PARENT_SHOP_ID}} </td>
  </ng-container>

  <ng-container matColumnDef="SHOP_NAME">
    <th mat-header-cell *matHeaderCellDef> SHOP NAME </th>
    <td mat-cell *matCellDef="let element"> {{element.SHOP_NAME}} </td>
  </ng-container>

  <ng-container matColumnDef="SHORT_NAME">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> SHORT NAME </th>
    <td mat-cell *matCellDef="let element"> {{element.SHORT_NAME}} </td>
  </ng-container>

  <ng-container matColumnDef="ITEMS_FROM_SHOP_ID">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> ITEMS FROM SHOP ID </th>
    <td mat-cell *matCellDef="let element"> {{element.ITEMS_FROM_SHOP_ID}} </td>
  </ng-container>

  <ng-container matColumnDef="SPECIAL_SALES_FROM_SHOP_ID">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> SPECIAL SALES FROM SHOP ID </th>
    <td mat-cell *matCellDef="let element"> {{element.SPECIAL_SALES_FROM_SHOP_ID}} </td>
  </ng-container>

  <ng-container matColumnDef="BACKUP_DAYS">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>BACKUP DAYS</th>
    <td mat-cell *matCellDef="let element"> {{element.BACKUP_DAYS}} </td>
  </ng-container>

  <ng-container matColumnDef="UPDDATE">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>UPDDATE</th>
    <td mat-cell *matCellDef="let element"> {{ element.UPDDATE | date:'HH:mm dd/MM/yyyy ' }} </td>
  </ng-container>



  <!--
  <ng-container matColumnDef="SHOP_TYPE">
    <th mat-header-cell *matHeaderCellDef> SHOP TYPE </th>
    <td mat-cell *matCellDef="let element"> {{element.SHOP_TYPE}} </td>
  </ng-container>-->

  <ng-container matColumnDef="TOKEN">
    <th mat-header-cell *matHeaderCellDef>TOKEN</th>
    <td mat-cell *matCellDef="let element"> {{element.TOKEN}} </td>
  </ng-container>
  <!--
          <ng-container matColumnDef="TelegramChat1">
            <th mat-header-cell *matHeaderCellDef>Telegram1</th>
            gr
            m
            <td mat-cell *matCellDef="let element">
              <button mat-raised-button color="basic" (click)="testTelegram(element.TelegramChat1)">{{element.TelegramChat1 | telegram:telegramChats}}</button>
            </td>
          </ng-container>
          <ng-container matColumnDef="TelegramChat2">
            <th mat-header-cell *matHeaderCellDef>Telegram2</th>
            <td mat-cell *matCellDef="let element">
              <button mat-raised-button color="basic" (click)="testTelegram(element.TelegramChat2)">{{element.TelegramChat2 | telegram:telegramChats}}</button>
            </td>
          </ng-container>
          <ng-container matColumnDef="TelegramChat3">
            <th mat-header-cell *matHeaderCellDef>Telegram3</th>
            <td mat-cell *matCellDef="let element">
              <button mat-raised-button color="basic" (click)="testTelegram(element.TelegramChat3)">{{element.TelegramChat3 | telegram:telegramChats}}</button>
            </td>
          </ng-container>

   -->


  <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
  <tr mat-row *matRowDef="let idx1=index; let even=even; let row1; columns: displayedColumns;"
      [style.background-color]="rowClicked == idx1 ? 'yellow' : (even ? 'white' : 'lightgray')"
      (click)="changeTableRowColor(idx1,row1)">
    (click)="selectRow(row)">
  </tr>


  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">{{input.value}}"</td>
  </tr>
</table>
