import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Shop } from '../../Shop';
import { Customerlist } from './customerlist';
import { CustomerlistService } from './customer-list.service';
import { MatSort, Sort } from '@angular/material/sort';
import { PageEvent, MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})


export class CustomerlistComponent implements AfterViewInit, OnInit  {
  public Customerlist: Customerlist[] = [];
  public selectedShops: Shop[];
  public catorgiesBy = 1;
  public pageIndex: number = 0;

  error: any;
   
  @ViewChild(MatSort) CustomerSort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['SHORT_NAME', 'CLNUM', 'CLNAME', 'ADDRESS', 'CITY', 'HPHONE', 'SPHONE', 'LAST_UPDATE'];
  dataSource = new MatTableDataSource(this.Customerlist);


  ngAfterViewInit() {
    this.dataSource.sort = this.CustomerSort;
    const sortState: Sort = { active: 'CLNAME', direction: 'asc' };
    this.CustomerSort.active = sortState.active;
    this.CustomerSort.direction = sortState.direction;
    this.CustomerSort.sortChange.emit(sortState);

  }
  constructor(public _CustomerlistService: CustomerlistService) { 

  }
  ngOnInit(): void {
  }

  length = 100;
  pageSize = 30;
  pageSizeOptions: number[] = [30];


  // MatPaginator Output
  pageEvent: PageEvent;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  public togglePageSize() {
    this.paginator.pageSize = this.paginator.pageSize === 5 ? 10 : 5;
    this.dataSource.paginator = this.paginator;
    this.pageSize = this.paginator.pageSize;
  }



  shopsChanged(shops: Shop[]) {
    this.selectedShops = shops;
    this.getCustomerList();
    this.sortCustomer();
  }

  
  sortCustomer() {
    this.dataSource.sort = this.CustomerSort;
    const sortState: Sort = { active: 'CLNUM', direction: 'asc' };
    this.CustomerSort.active = sortState.active;
    this.CustomerSort.direction = sortState.direction;
    this.CustomerSort.sortChange.emit(sortState);
  }
  

  getCustomerList(): void {
    this._CustomerlistService.getCustomerList (this.selectedShops)
      .subscribe(
        (data: Customerlist[]) => {
          this.dataSource = new MatTableDataSource(data);
          this.sortCustomer();
        }, // success path/
        error => this.error = error // error path
      );
  }



  catorgiesByChange(selectedCategory: number) {
    this.catorgiesBy = selectedCategory;
    this.getCustomerList();
  }




}


