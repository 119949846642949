import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ItemsManagmentService } from '../../items-managment/items-managment.service';
import { Shop } from '../../Shop';
import { Transaction } from './transaction';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transactions-report',
  templateUrl: './transactions-report.component.html',
  styleUrls: ['./transactions-report.component.css']
})
export class TransactionsReportComponent implements OnInit {

  public startReportPicker: Date = new Date();
  public endReportPicker: Date = new Date();
  public selectedShops: Shop[];
  public itCode:string = null;
  public itDesc:string = null;

  public displayedColumns: string[] = ['SHORT_NAME','DOC_TYPE', 'AC_NBR', 'DOC_NUMBER', 'DOC_TIME', 'NAME', 'QTY', 'PRICE','DISCOUNT','TOTAL','accumulate'];
  public dataSource = new MatTableDataSource([]);
  public haveItem = false;
  error: any;

  public selectedItem = null;
  constructor(public _ItemsManagmentService: ItemsManagmentService,private exportAsService: ExportAsService,private router: Router) {
    this.selectedItem = this._ItemsManagmentService.getCurrentItemRow();
    if (this.selectedItem)
    {
      this.haveItem = true;
      this.itCode = this.selectedItem["IT_CODE"];
      this.itDesc = this.selectedItem["IT_DESC"];
      this._ItemsManagmentService.setCurrentItemRow(null);
    }

  }

  public itemsManagment():void{
    this._ItemsManagmentService.setCurrentItemRow(this.selectedItem);
    this.router.navigate(['items-managment', {  }]);
  }

  shopsChanged(shops: Shop[]) {
    this.selectedShops = shops;
    this.getTransactionsReport();
  }

  startReportPickerChanged(startReportPicker: Date) {
    this.startReportPicker = startReportPicker;
    this.getTransactionsReport();
  }

  endReportPickerChanged(endReportPicker: Date) {
    this.endReportPicker = endReportPicker;
    this.getTransactionsReport();
  }

  applyFilter(event: Event) {

    this.itCode = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.itCode.trim().toLowerCase();
    this.getTransactionsReport();



//    this.itDesc = 
  }


  ngOnInit(): void {
    for (let i = 0; i < 20; i++) { }; // without this loop it does not work
  }

  ngOnChanges() {

  }

  getTransactionsReport(): void{

    this._ItemsManagmentService.
    getTransactionsReport(
      (this.itCode + ''),
      this.startReportPicker,
      this.endReportPicker,this.selectedShops)
      .subscribe(
        (data: Transaction[]) => {
          this.itDesc = data[0].IT_DESC;
          this.dataSource = new MatTableDataSource(data);
        }, // success path/
        error => this.error = error // error path
      );
  }

  public printPDF = true;
  public exportPDF() {
    this.printPDF = true;
    let config: ExportAsConfig = {
      type: 'pdf',
      elementIdOrContent: 'contentToConvert',
    };
    // download the file using old school javascript method
    this.exportAsService.save(config, 'transactionsreport').subscribe(() => {
      // save started
    });
  }

  }



