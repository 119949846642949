<script src="https://cdnjs.cloudflare.com/ajax/libs/angular-i18n/1.8.3/angular-locale_he-il.min.js" integrity="sha512-3HTIO2Y+JH2WW3R9qszo5FtdNPF9zr62QIGlBlkQZZs9iumeddEmB2xN7dOe8IoXKRRX9Ejx3AksgiYsVaMCcw==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
<mat-dialog-content id="content1">

  <p class="h4 dialog-header">הקמה ו / או עדכון מבצע</p>

  <div class="row">
    <div id="SCode" class="form-floating customized-input flex-item mb-3 col-6">
      <input type="text" autocomplete="off" class="form-control" id="formControlInput1" placeholder="קוד מבצע" [(ngModel)]="selectedspecialSales.CODE" #input>
      <label for="formControlInput1" class="form-label">קוד מבצע</label>
    </div>

    <div id="SName" class="form-floating customized-input flex-item mb-3 col-6">
      <input type="text" autocomplete="off" class="form-control" id="formControlInput2" placeholder="שם מבצע" [(ngModel)]="selectedspecialSales.NAME" #input>
      <label for="formControlInput2" class="form-label">שם מבצע</label>
    </div>
  </div>

  <div class="row">
    <div id="SQty"class="form-floating customized-input flex-item mb-3 col-6">
      <input type="text" autocomplete="off" class="form-control" id="formControlInput3" placeholder="כמות" [(ngModel)]="selectedspecialSales.QTY" #input>
      <label for="formControlInput3" class="form-label">כמות</label>
    </div>

    <div id="SQty" class="form-floating customized-input flex-item mb-3 col-6">
      <input type="text" autocomplete="off" class="form-control" id="formControlInput4" placeholder="מחיר" [(ngModel)]="selectedspecialSales.PRICE" #input>
      <label for="formControlInput4" class="form-label">מחיר</label>
    </div>
  </div>

  <div class="row">
    <div id="SQty" class="form-floating customized-input flex-item mb-3 col-6">
      <input type="text" autocomplete="off" class="form-control" id="formControlInput5" placeholder="כמות 2" [(ngModel)]="selectedspecialSales.QTY2" #input>
      <label for="formControlInput5" class="form-label">כמות 2</label>
    </div>

    <div id="SQty" class="form-floating customized-input flex-item mb-3 col-6">
      <input type="text" autocomplete="off" class="form-control" id="formControlInput6" placeholder="מחיר 2" [(ngModel)]="selectedspecialSales.PRICE2" #input>
      <label for="formControlInput6" class="form-label">מחיר 2</label>
    </div>
  </div>

  <div class="row">
    <div id="SQty" class="form-floating customized-input flex-item mb-3 col-6">
      <input type="text" autocomplete="off" class="form-control" id="formControlInput7" placeholder="כמות 3" [(ngModel)]="selectedspecialSales.QTY3" #input>
      <label for="formControlInput7" class="form-label">כמות 3</label>
    </div>

    <div id="SQty" class="form-floating customized-input flex-item mb-3 col-6">
      <input type="text" autocomplete="off" class="form-control" id="formControlInput8" placeholder="מחיר 3" [(ngModel)]="selectedspecialSales.PRICE3" #input>
      <label for="formControlInput8" class="form-label">מחיר 3</label>
    </div>
  </div>

  <div class="row">
    <div id="SQty" class="form-floating customized-input flex-item mb-3 col-6">
      <input type="text" autocomplete="off" class="form-control" id="formControlInput9" placeholder="כמות 4" [(ngModel)]="selectedspecialSales.QTY4" #input>
      <label for="formControlInput9" class="form-label">כמות 4</label>
    </div>

    <div id="SQty" class="form-floating customized-input flex-item mb-3 col-6">
      <input type="text" autocomplete="off" class="form-control" id="formControlInput10" placeholder="מחיר 4" [(ngModel)]="selectedspecialSales.PRICE4" #input>
      <label for="formControlInput10" class="form-label">מחיר 4</label>
    </div>
  </div>

  <div class="row">
    <div id="SQty" class="form-floating customized-input flex-item mb-3 col-6">
      <input type="text" autocomplete="off" class="form-control" id="formControlInput11" placeholder="כמות 5" [(ngModel)]="selectedspecialSales.QTY5" #input>
      <label for="formControlInput11" class="form-label">כמות 5</label>
    </div>

    <div id="SQty" class="form-floating customized-input flex-item mb-3 col-6">
      <input type="text" autocomplete="off" class="form-control" id="formControlInput12" placeholder="מחיר 5" [(ngModel)]="selectedspecialSales.PRICE5" #input>
      <label for="formControlInput12" class="form-label">מחיר 5</label>
    </div>
  </div>

  <div class="row">
    <div id="SQty" class="form-floating customized-input flex-item mb-3 col-6">
      <input type="text" autocomplete="off" class="form-control" id="formControlInput13" placeholder="כמות 6" [(ngModel)]="selectedspecialSales.QTY6" #input>
      <label for="formControlInput13" class="form-label">כמות 6</label>
    </div>

    <div id="SQty" class="form-floating customized-input flex-item mb-3 col-6">
      <input type="text" autocomplete="off" class="form-control" id="formControlInput14" placeholder="מחיר 6" [(ngModel)]="selectedspecialSales.PRICE6" #input>
      <label for="formControlInput14" class="form-label">מחיר 6</label>
    </div>
  </div>

  <div class="row">
    <div id="SQty" class="form-floating customized-input flex-item mb-3 col-6">
      <input type="text" autocomplete="off" class="form-control" id="formControlInput15" placeholder="כמות 7" [(ngModel)]="selectedspecialSales.QTY7" #input>
      <label for="formControlInput15" class="form-label">כמות 7</label>
    </div>

    <div id="SQty" class="form-floating customized-input flex-item mb-3 col-6">
      <input type="text" autocomplete="off" class="form-control" id="formControlInput16" placeholder="מחיר 7" [(ngModel)]="selectedspecialSales.PRICE7" #input>
      <label for="formControlInput16" class="form-label">מחיר 7</label>
    </div>
  </div>

  <div class="row">
    <div id="SQty" class="form-floating customized-input flex-item mb-3 col-12">
      <input type="text" autocomplete="off" placeholder=" מתחיל מ-סכום" class="form-control" id="formControlInput17" [(ngModel)]="selectedspecialSales.AMOUNT" #input>
      <label for="formControlInput17" class="form-label"> מתחיל מ-סכום</label>
    </div>
  </div>

  <div  id="SShort" class="row">
    <div class="form-floating customized-input flex-item mb-3 col-12">
      <input type="text" autocomplete="off" placeholder="המשך מבצע=1" class="form-control" id="formControlInput18" [(ngModel)]="selectedspecialSales.IFCONTINUE" #input maxlength="1">
      <label for="formControlInput18" class="form-label">המשך מבצע=1</label>
    </div>

  </div>

  <div class="row">
    <div id="SQty" class="form-floating customized-input flex-item mb-3 col-12">
      <input type="text" autocomplete="off" placeholder="כמות מקסימום" class="form-control" id="formControlInput19" [(ngModel)]="selectedspecialSales.MAXQTY" #input maxlength="1">
      <label for="formControlInput19" class="form-label">כמות מקסימום</label>
      </div>
    </div>

    <div id="DatePic" class="row">
      <div class="col-6">
        <form class="row row-cols-sm-auto">
          <div class="col-12">
            <div class="input-group">
              <input class="form-control"
                     placeholder="dd/mm/yyyy"
                     locale="he" 
                     name="d"
                     #c2="ngModel"
                     [(ngModel)]="selectedspecialSales.ENDDATESTR"
                     [firstDayOfWeek]="0"
                     ngbDatepicker
                     #d="ngbDatepicker" />
              <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <br />
    <div id="SRem" class="col-6">
      <div class="form-floating customized-input flex-item mb-3">
        <input type="text" autocomplete="off" placeholder="הודעת מבצע" class="form-control" id="formControlInput20" [(ngModel)]="selectedspecialSales.REMARKS" #input maxlength="80">
        <label for="formControlInput20" class="form-label">הודעת מבצע</label>
      </div>
    </div>

    <div style="text-align: left;">
      <button class="btn btn-primary flex-item customized-button" [mat-dialog-close]="false">ביטול</button>
      <button class="btn btn-primary flex-item customized-button" [mat-dialog-close]="selectedspecialSales">שמור</button>
    </div>
    <br />
</mat-dialog-content>

