import { Pipe, PipeTransform } from '@angular/core';
import { itSortRow } from '../../items-managment/itSortRow';

@Pipe({
  name: 'itSort',
  pure: false

})
export class ItSortPipe implements PipeTransform {

  transform(value: number, sortRows: itSortRow[]): unknown {
    try {
      //return value + " - " + sortRows.find(x => x.CODE === value).DESCR;
      return sortRows.find(x => x.CODE === value).DESCR;

   }
    catch{
      return value;
    }
  }

}
