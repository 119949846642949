<ng-container>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="DT">
      <th mat-header-cell *matHeaderCellDef>תאריך</th>
      <td mat-cell *matCellDef="let element"> {{element.DT | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="IT_DISC">
      <th mat-header-cell *matHeaderCellDef>הנחה</th>
      <td mat-cell *matCellDef="let element"> {{element.IT_DISC | number:'1.2'}} </td>
    </ng-container>

    <ng-container matColumnDef="DISCT_P">
      <th mat-header-cell *matHeaderCellDef>אחוז הנחה</th>
      <td mat-cell *matCellDef="let element"> {{element.DISCT_P | number:'1.2'}} </td>
    </ng-container>

    <ng-container matColumnDef="COST">
      <th mat-header-cell *matHeaderCellDef>עלות </th>
      <td mat-cell *matCellDef="let element"> {{element.COST | number:'1.2'}} </td>
    </ng-container>

    <ng-container matColumnDef="QTY">
      <th mat-header-cell *matHeaderCellDef>כמות</th>
      <td mat-cell *matCellDef="let element"> {{element.QTY | number:'1.2'}} </td>
    </ng-container>

    <ng-container matColumnDef="BONUS">
      <th mat-header-cell *matHeaderCellDef>בונוס</th>
      <td mat-cell *matCellDef="let element"> {{element.BONUS | number:'1.2'}} </td>
    </ng-container>

    <ng-container matColumnDef="SUPPLIER">
      <th mat-header-cell *matHeaderCellDef>ספק</th>
      <td mat-cell *matCellDef="let element"> {{element.SUPPLIER | supplier:supplierRows}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">{{input.value}}"</td>
    </tr>
  </table>

</ng-container>
