import { Pipe, PipeTransform } from '@angular/core';
import { supplierRow } from '../../items-managment/supplierRow';

@Pipe({
  name: 'supplier',
  pure: false
})
export class SupplierPipe implements PipeTransform {

  transform(value: number, supplierRows: supplierRow[]): unknown {
    try {
      //return value + " - " + supplierRows.find(x => x.CLNUM === value).CLNAME;
      return supplierRows.find(x => x.CLNUM === value).CLNAME;


    }
    catch{
      return value;
    }
  }
}
