<mat-sidenav-container fullscreen>
    <mat-sidenav #sidenav>
        <mat-nav-list>
            <a mat-list-item *ngIf="isAdmin" [routerLink]="['/managment']">
                <mat-icon>library_books</mat-icon>
                <span>ניהול</span>
            </a>
            <a mat-list-item *ngIf="isAdmin" [routerLink]="['/UsersShops']" [routerLinkActive]="is-active">
                <mat-icon>library_books</mat-icon>
                <span>שיוך חנויות</span>
            </a>
    
            
        </mat-nav-list>

    </mat-sidenav>

    <mat-toolbar color="primary">
        <button mat-icon-button (click)="sidenav.open()">
            <mat-icon>menu</mat-icon>
        </button>
    </mat-toolbar>

    <router-outlet></router-outlet>
</mat-sidenav-container>