<!--[(ngModel)]="selectedShop"-->




<div class="flex-row filter-row-container">

  <div id="SHOP_NAME" class="form-floating customized-select flex-item">
    <select class="form-select" [(ngModel)]="selectedShop" (ngModelChange)="selectionChangeShop($event)" required>
      <option *ngFor="let shop of shops" [ngValue]="shop">{{ shop.SHORT_NAME }}</option>
    </select>
    <label class="label-color">בחר חנות</label>
  </div>

  <div class="form-floating customized-select flex-item">
    <select class="form-select" [(ngModel)]="selectedCategory" (ngModelChange)="selectionChangeCategory($event)" required>
      <option [ngValue]="selectAllCategories">{{ selectAllCategories.DESCR }}</option>
      <option *ngFor="let itSortRow of itSortRows[shopIndex]" [ngValue]="itSortRow">{{ itSortRow.DESCR }}</option>
    </select>
    <label class="label-color">בחר קבוצה</label>
  </div>

  <div class="form-floating customized-select flex-item">
    <select class="form-select" [(ngModel)]="selectedSupplier" (ngModelChange)="selectionChangeSupplier($event)" required>
      <option [ngValue]="selectAllSuppliers">{{ selectAllSuppliers.CLNAME }}</option>
      <option *ngFor="let supplierRow of supplierRows[shopIndex]" [ngValue]="supplierRow">{{ supplierRow.CLNAME }}</option>
    </select>
    <label class="label-color">בחר ספק</label>
  </div>

  <div class="form-floating customized-input flex-item">
    <input type="text" class="form-control" id="SEARCHF" autocomplete="off" placeholder="חיפוש" [(ngModel)]="filterText" #input (focusout)="SearchFocusOutEvent($event)" (keyup)="SerchhandleKeyboardEvent($event)">
    <label class="label-color">חיפוש</label>
  </div>

  <button class="btn btn-primary flex-item customized-button" (click)="newItem()">פריט חדש</button>
  <button *ngIf="isColumnsMobile!=true" class="btn btn-primary flex-item customized-button" (click)="save()">שמור</button>
  <button class="btn btn-primary flex-item customized-button" (click)="openDialog(selectedItem)" *ngIf="selectedItem.TABLEID">תמחיר</button>
  <button class="btn btn-primary flex-item customized-button" (click)="updateItem(selectedItem)" *ngIf="selectedItem.TABLEID && isColumnsMobile">ערוך פריט</button>
  <button class="btn btn-primary flex-item customized-button" (click)="confirmDelete(selectedItem)" *ngIf="selectedItem.TABLEID">מחק פריט</button>
  <button class="btn btn-primary flex-item customized-button" (click)="updateItCodeWindow(selectedItem)" *ngIf="selectedItem.TABLEID">שינוי קוד פריט</button>
  <button *ngIf="rowClicked >=0" class="btn btn-primary flex-item customized-button" (click)="openDialogCompare()">השוואת פריטים</button>
  <button class="btn btn-primary flex-item customized-button" (click)="togglePageSize()" *ngIf="pageSize == 10">הקטן טבלה</button>
  <button class="btn btn-primary flex-item customized-button" (click)="togglePageSize()" *ngIf="pageSize == 5">הגדל טבלה</button>


  <!--  <br *ngIf="isColumnsMobile">-->
  <button class="btn btn-primary flex-item customized-button" (click)="transactionsReport()" *ngIf="selectedItem.TABLEID">דו"ח תנועות פריט</button>

  <button *ngIf="isLoading" style="margin-top:1.5rem;margin-right:1.0rem" class="btn btn-primary" type="button" disabled>
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span class="sr-only">  מטעין ...</span>
  </button>

</div>

<div *ngIf="isColumnsMobile!=true" class="flex-row">

  <div class="form-floating customized-input flex-item">
    <input type="text" class="form-control" id="IT_CODE" autocomplete="off" placeholder="קוד פריט" [(ngModel)]="selectedItem.IT_CODE" #input (focusout)="IT_CODEonFocusOutEvent($event)" (keyup)="IT_CODEhandleKeyboardEvent($event)">
    <label for="IT_CODE" class="label-color">קוד פריט</label>
  </div>

  <div id="IT_DESC_Div" class="form-floating customized-input flex-item">
    <input type="text" class="form-control" id="IT_DESC" autocomplete="off" placeholder="תיאור פריט" [(ngModel)]="selectedItem.IT_DESC" #input maxlength="40" (keyup)="IT_DESChandleKeyboardEvent($event)" />
    <label for="IT_DESC" class="label-color">תאור פריט (F2-שכפול תאור קודם)</label>
  </div>

  <div class="form-floating customized-input flex-item">
    <input type="text" class="form-control" autocomplete="off" placeholder="ברקוד" [(ngModel)]="selectedItem.BARCODE" #input>
    <label class="label-color">ברקוד</label>
  </div>

  <div id="CODE_Div" class="form-floating customized-input flex-item">
    <input type="text" class="form-control" autocomplete="off" placeholder="קבוצה" [(ngModel)]="selectedItem.ITSORT" #input (keyup)="changeInputITSORT()">
    <label class="label-color">קבוצה</label>
  </div>

  <div class="form-floating customized-select flex-item">
    <select class="form-select" [(ngModel)]="selectedITSORT" (ngModelChange)="selectionChangeselectedITSORT()" required>
      <option *ngFor="let itSortRow of itSortRows[shopIndex]" [ngValue]="itSortRow">{{ itSortRow.DESCR }}</option>
    </select>
    <label class="label-color">בחר קבוצה</label>
  </div>

  <div id="SUP_Div" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="ספק" autocomplete="off" (keyup)="changeInputSUPPLIER()" [(ngModel)]="selectedItem.SUPPLIER" #input>
    <label class="label-color">מס' ספק</label>
  </div>

  <div class="form-floating customized-select flex-item">
    <select class="form-select" [(ngModel)]="selectedSUPPLIER" (ngModelChange)="selectionChangeselectedSUPPLIER()" required>
      <option *ngFor="let supplierRow of supplierRows[shopIndex]" [ngValue]="supplierRow">{{ supplierRow.CLNAME }}</option>
    </select>
    <label class="label-color">בחר ספק</label>
  </div>

  <div class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="קוד ספק" autocomplete="off" [(ngModel)]="selectedItem.IT_S_CODE" #input>
    <label class="label-color">קוד ספק</label>
  </div>

  <div id="PRICE_Div" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" style="padding-right:0" class="form-control" placeholder="הנחת ספק" autocomplete="off" [(ngModel)]="selectedItem.COSTPERC" #input>
    <label style="padding-right:0" class="label-color">הנחת ספק</label>
  </div>

  <div id="PRICE_Div" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="עלות תקן" autocomplete="off" [(ngModel)]="selectedItem.STANDARTCOST" #input>
    <label class="label-color">עלות תקן</label>
  </div>

  <div id="PRICE_Div" class="form-floating customized-input flex-item">
    <input type="text" class="form-control" placeholder="ע. אחרונה" autocomplete="off" [(ngModel)]="this.selectedItem.LASTCOST" #input disabled>
    <label class="label-color">ע. אחרונה</label>
  </div>


  <div id="PRICE_Div" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="מחיר 1" autocomplete="off" [(ngModel)]="this.selectedItem.PRICE1" #input>
    <label class="label-color">מחיר 1 </label>
  </div>

  <div id="PRICE_Div" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="מחיר 2" autocomplete="off" [(ngModel)]="this.selectedItem.PRICE2" #input>
    <label class="label-color">מחיר 2</label>
  </div>

  <div id="PRICE_Div" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="מחיר 3" autocomplete="off" [(ngModel)]="this.selectedItem.PRICE3" #input>
    <label class="label-color">מחיר 3 </label>
  </div>

  <div id="PRICE_Div" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="מחיר 4" autocomplete="off" [(ngModel)]="this.selectedItem.PRICE4" #input>
    <label class="label-color">מחיר 4 </label>
  </div>

  <div id="PRICE_Div" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="מחיר 5" autocomplete="off" [(ngModel)]="this.selectedItem.PRICE5" #input>
    <label class="label-color">מחיר 5 </label>
  </div>

  <div id="PRICE_Div" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="מחיר 6" autocomplete="off" [(ngModel)]="this.selectedItem.PRICE6" #input>
    <label class="label-color">מחיר 6 </label>
  </div>

  <div id="PRICE_Div" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="מחיר 7" autocomplete="off" [(ngModel)]="this.selectedItem.PRICE7" #input>
    <label class="label-color">מחיר 7 </label>
  </div>

  <div id="PRICE_Div" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="מחיר 8" autocomplete="off" [(ngModel)]="this.selectedItem.PRICE8" #input>
    <label class="label-color">מחיר 8 </label>
  </div>

  <div id="PRICE_Div" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="מחיר 9 " autocomplete="off" [(ngModel)]="this.selectedItem.PRICE9" #input>
    <label class="label-color">מחיר 9 </label>
  </div>








  <!--

   <div class="input-group flex-item customized-button">
     <button class="btn btn-outline-primary customized-button dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{{ currentPriceItem && currentPriceItem.name }}</button>
     <ul class="dropdown-menu">
         <li *ngFor="let price of prices; let i = index;">
           <a class="dropdown-item customized-dropdown-item" (click)="setCurrentPriceItem(i)">{{ price && price.name }}</a>
         </li>
         <li *ngIf="prices.length < 9">
           <a class="dropdown-item customized-dropdown-item" (click)="addPriceItem()">הוספת מחיר</a>
         </li>
     </ul>
     <input type="text" class="form-control" [(ngModel)]="currentPriceItem.value" value="{{ currentPriceItem && currentPriceItem.value }}" (ngModelChange)="setSelectedItemPriceToCurrentPrice($event)"/>
     <span class="input-group-text customized-input-group-text">₪</span>
  -->

  <div id="SHORT_Div" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="חתך" autocomplete="off" [(ngModel)]="selectedItem.CUT" #input>
    <label class="label-color">חתך</label>
  </div>

  <div id="PRICE_Div" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="כ. מינימום" autocomplete="off" [(ngModel)]="selectedItem.MINIQTY" #input>
    <label class="label-color">כ. מינימום</label>
  </div>

  <div id="PRICE_Div" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="כ. מקסימום" autocomplete="off" [(ngModel)]="selectedItem.MAXQTY" #input>
    <label class="label-color">כ. מקסימום</label>
  </div>

  <div id="REMARK_Div" class="form-floating customized-input flex-item">
    <input type="text" class="form-control" placeholder="הערת מכירה" autocomplete="off" [(ngModel)]="selectedItem.SALEREMARK" #input>
    <label class="label-color">הערת מכירה</label>
  </div>

  <div id="OTHERS" class="form-floating customized-select flex-item">
    <select class="form-select" [(ngModel)]="selectedItem.OTHERS" required>
      <option [value]></option>
      <option [value]>NetPrinter1</option>
      <option [value]>NetPrinter2</option>
      <option [value]>Printer1</option>
      <option [value]>Printer2</option>
      <option [value]>הוספת מנוי</option>
      <option [value]>מדבקת מכבסה</option>
      <option [value]>פיקדון</option>
      <option [value]>הקפאת מכירה</option>
      <option [value]>הקפאת קניה</option>
      <option [value]>ברקוד</option>
      <option [value]>מנוי</option>
      <option [value]>קוד מבצע</option>
      <option [value]>פיצה</option>
      <option [value]>מספרי סיריאל</option>
      <option [value]>הדפסת שובר</option>
      <option [value]>הודעת אלכהול</option>
      <option [value]>פאקט</option>
      <option [value]>בולט שקיל</option>
      <option [value]>מדבקת בירה</option>
      <option [value]>ללא תוספת</option>
      <option [value]>הודעת סיגריות</option>
      <option [value]>בולט לא שקיל</option>
      <option [value]>מעדניה שונות</option>
      <option [value]>חישוב שקית</option>
      <option [value]>תוספת אשראי באחוזים</option>
      <option [value]>מעדניה מחיר</option>
      <option [value]>מדבקה פג תוקף</option>
      <option [value]>טרה</option>
      <option [value]>טרת פריט</option>
      <option [value]>עץ מוצר לתעודות</option>
    </select>
    <label style="font-weight:bold;" class="label-color"> שונות</label>
  </div>

  <div id="SHORT_Div" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="צבע" autocomplete="off" [(ngModel)]="selectedItem.COLOR" #input (keyup)="changeInputCOLOR()">
    <label class="label-color">צבע</label>
  </div>

  <div class="form-floating customized-select flex-item">
    <select class="form-select" [(ngModel)]="selectedCOLOR" (ngModelChange)="selectionChangeselectedCOLOR($event.value)" required>
      <option *ngFor="let colorRow of colorRows[shopIndex]" [ngValue]="colorRow">{{ colorRow.DESC1 }}</option>
    </select>
    <label class="label-color">בחר צבע</label>
  </div>

  <div id="SHORT_Div" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="מידה" autocomplete="off" [(ngModel)]="selectedItem.MIDA" #input (keyup)="changeInputMIDA()">
    <label class="label-color">מידה</label>
  </div>

  <div class="form-floating customized-select flex-item">
    <select class="form-select" [(ngModel)]="selectedMIDA" (ngModelChange)="selectionChangeselectedMIDA()" required>
      <option *ngFor="let midaRow of midaRows[shopIndex]" [ngValue]="midaRow">{{ midaRow.DESC1 }}</option>
    </select>
    <label class="label-color">בחר מידה</label>
  </div>

  <div id="PERCENT_Div" class="form-floating customized-input flex-item">
    <input type="text" class="form-control" placeholder="כ. ליחידה" autocomplete="off" [(ngModel)]="selectedItem.QTYPERUNIT" #input>
    <label class="label-color">כ.יחידה</label>
  </div>

  <div id="PERCENT_Div" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="_" autocomplete="off" [(ngModel)]="selectedItem.DISCOUNT" #input>
    <label class="label-color">% הנחה</label>
  </div>



  <div class="flex-row">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="flexCheckDefaultSale" [(ngModel)]="selectedItem.IT_SALE_BOOL" />
      <label class="form-check-label" for="flexCheckDefaultSale">מבצע</label>
    </div>

    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="flexCheckDefaultCHPrice" [(ngModel)]="selectedItem.IT_CHPRICE_BOOL" />
      <label class="form-check-label" for="flexCheckDefaultCHPrice">שינוי מחיר</label>
    </div>

    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="flexCheckDefaultScale" [(ngModel)]="selectedItem.SCALE_BOOL" />
      <label class="form-check-label" for="flexCheckDefaultScale">משקל</label>
    </div>

    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="flexCheckDefaultNOVAT" [(ngModel)]="selectedItem.NOVAT_BOOL" />
      <label class="form-check-label" for="flexCheckDefaultNOVAT">ללא מע"מ</label>
    </div>

    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="flexCheckDefaultDiscount" [(ngModel)]="selectedItem.IF_DISCOUNT_BOOL" />
      <label class="form-check-label" for="flexCheckDefaultDiscount">בר הנחה</label>
    </div>

    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="flexCheckDefaultDiscount" [(ngModel)]="selectedItem.FATHER" />
      <label class="form-check-label" for="flexCheckDefaultFather">מוצג באתר </label>
    </div>


    <div *ngIf="selectedItem.SALEREMARK1" id="SALEREMARK1" class="form-floating customized-input flex-item">
      <input style="background-color:yellow" type="text" class="form-control" placeholder="משתתף במבצע" autocomplete="off" [(ngModel)]="selectedItem.SALEREMARK1" #input disabled>
      <label class="label-color"> משתתף במבצע</label>
    </div>



  </div>

  <div *ngIf="pageSize===5">
    <app-item-sup [item]="selectedItem" [shop]="selectedShop" [supplierRows]="supplierRows[shopIndex]"></app-item-sup>
  </div>

</div>







<table mat-table matSort  [dataSource]="dataSource" class="mat-elevation-z8 clearBoth">
  <ng-container matColumnDef="IT_CODE">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="IT_CODE" class="small"> קוד פריט </th>
    <td mat-cell *matCellDef="let element"> {{element.IT_CODE}} </td>
  </ng-container>
  <ng-container matColumnDef="IT_DESC">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="IT_DESC"> תאור פריט </th>
    <td mat-cell *matCellDef="let element"> {{element.IT_DESC}} </td>
  </ng-container>

  <ng-container matColumnDef="BARCODE">
    <th mat-header-cell *matHeaderCellDef> ברקוד </th>
    <td mat-cell *matCellDef="let element"> {{element.BARCODE}} </td>
  </ng-container>

  <ng-container matColumnDef="ITSORT">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="ITSORT">מיון/ קבוצה</th>
    <td mat-cell *matCellDef="let element">
      <span class="hideCellular">{{ element.ITSORT | itSort:itSortRows[shopIndex] }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="CUT">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="CUT"> חתך </th>
    <td mat-cell *matCellDef="let element"> {{element.CUT}} </td>
  </ng-container>
  <ng-container matColumnDef="PRICE1">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="PRICE1" class="small"> מחיר 1 </th>
    <td mat-cell *matCellDef="let element"> {{element.PRICE1 | number:'1.2'}} </td>
  </ng-container>
  <ng-container matColumnDef="PRICE2">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="PRICE2" class="small"> מחיר 2 </th>
    <td mat-cell *matCellDef="let element"> {{element.PRICE2 | number:'1.2'}} </td>
  </ng-container>
  <ng-container matColumnDef="DISCOUNT">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="DISCOUNT"> אחוז הנחה </th>
    <td mat-cell *matCellDef="let element"> {{element.DISCOUNT}} </td>
  </ng-container>
  <ng-container matColumnDef="STANDARTCOST">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="STANDARTCOST"> עלות תקן </th>
    <td mat-cell *matCellDef="let element"> {{element.STANDARTCOST}} </td>
  </ng-container>
  <ng-container matColumnDef="MINIQTY">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="MINIQTY"> כמות מינימום </th>
    <td mat-cell *matCellDef="let element"> {{element.MINIQTY}} </td>
  </ng-container>
  <ng-container matColumnDef="MAXQTY">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="MAXQTY"> כמות מקסימום </th>
    <td mat-cell *matCellDef="let element"> {{element.MAXQTY}} </td>
  </ng-container>

  <div class="inputDropDown">
    <ng-container matColumnDef="SUPPLIER">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="SUPPLIER"> מספר ספק </th>
      <td mat-cell *matCellDef="let element">

        <span class="hideCellular"> {{element.SUPPLIER | supplier:supplierRows[shopIndex]}}</span>
      </td>
    </ng-container>
  </div>


  <ng-container matColumnDef="IT_SALE">
    <th mat-header-cell *matHeaderCellDef> מבצע כ/ל </th>
    <td mat-cell *matCellDef="let element"> {{element.IT_SALE}} </td>
  </ng-container>
  <!-- <ng-container matColumnDef="IT_SALE_BOOL">
    <th mat-header-cell *matHeaderCellDef> מבצע כ/ל </th>
    <td mat-cell *matCellDef="let element"> {{element.IT_SALE_BOOL}} </td>



  </ng-container> -->




  <ng-container matColumnDef="IT_CHPRICE">
    <th mat-header-cell *matHeaderCellDef> שינוי </th>
    <td mat-cell *matCellDef="let element"> {{element.IT_CHPRICE}} </td>
  </ng-container>
  <ng-container matColumnDef="SCALE">
    <th mat-header-cell *matHeaderCellDef> משקל כ/ל </th>
    <td mat-cell *matCellDef="let element"> {{element.SCALE}} </td>
  </ng-container>
  
  <ng-container matColumnDef="OTHERS">
    <th mat-header-cell *matHeaderCellDef> dropdown list </th>
    <td mat-cell *matCellDef="let element"> {{element.OTHERS}} </td>
  </ng-container>
  <ng-container matColumnDef="NOVAT">
    <th mat-header-cell *matHeaderCellDef>ללא מע"מ כ/ל</th>
    <td mat-cell *matCellDef="let element"> {{element.NOVAT}} </td>
  </ng-container>

  <ng-container matColumnDef="IF_DISCOUNT">
    <th mat-header-cell *matHeaderCellDef> בר הנחה כ/ל </th>
    <td mat-cell *matCellDef="let element"> {{element.IF_DISCOUNT}} </td>
  </ng-container>
  <ng-container matColumnDef="SALEREMARK">
    <th mat-header-cell *matHeaderCellDef> הערת מכירה </th>
    <td mat-cell *matCellDef="let element"> {{element.SALEREMARK}} </td>
  </ng-container>
  <ng-container matColumnDef="SOLDDATE">
    <th mat-header-cell *matHeaderCellDef> ת.מכירה אחרון </th>
    <td mat-cell *matCellDef="let element"> {{element.SOLDDATE | date:'dd/MM/yyyy'}} </td>
  </ng-container>
  <ng-container matColumnDef="UPDDATE">
    <th mat-header-cell *matHeaderCellDef> ת.עדכון אחרון </th>
    <td mat-cell *matCellDef="let element"> {{element.UPDDATE | date:'dd/MM/yyyy'}} </td>
  </ng-container>
  <ng-container matColumnDef="SALEREMARK1">
    <th mat-header-cell *matHeaderCellDef> משתתף במבצע </th>
    <td mat-cell *matCellDef="let element"> {{element.SALEREMARK1}} </td>
  </ng-container>


  <!--(click)="selectRow(row)"-->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let idx=index; let even=even; let row; columns: displayedColumns;"
      [style.background-color]="rowClicked == idx ? 'yellow' : (even ? 'white' : 'lightgray')"
      (click)="selectRow(idx, row)"
      [matRowKeyboardSelection]="this.selection"
      [rowModel]="row" [matTable]="demomatdatatable" [row]="demmatrow"
      [ngClass]="{selected: selection.isSelected(row)}"></tr>

</table>

<!--<mat-paginator [length]="itemsRowsFilterd.length" [pageSize]="pageSize" (page)="onPaginateChange($event)"></mat-paginator>-->
