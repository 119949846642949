import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Shop } from '../../Shop';
import { PerHourSales } from './per-hour-sales';
import { PerHourSalesService } from './per-hour-sales.service';
import { DxChartModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';



@Component({
  selector: 'per-hour-sales',
  templateUrl: './per-hour-sales.component.html',
  styleUrls: ['./per-hour-sales.component.css']
})

export class PerHourSalesComponent implements OnInit {

  public startReportPicker: Date = new Date();
  public endReportPicker: Date = new Date();

  public perHourSales: PerHourSales[] = [];
  public selectedShops: Shop[];
  isLoading = false;

  public hour: number;
  public qty: number;
  public total: number;
  public totalPerDay: number;
  public Difference_In_Days = 1;


  error: any;

  displayedColumns: string[] = ['hour', 'qty', 'qtyPerDay', 'total', 'totalPerDay'];
  dataSource = new MatTableDataSource(this.perHourSales);

  public catorgiesBy = 1;
  isFirst: boolean = true;

  public yaxisMax: number;

  constructor(public _perHourSalesService: PerHourSalesService) {

  }
  ngOnInit(): void {

  }
    
  shopsChanged(shops: Shop[]) {
    this.selectedShops = shops;
    if (this.isFirst) {
      this.isFirst = false;
    }
    else
    this.geSalesReport();
  }

  convertToDate(date: string) {
    if (date.indexOf('T') != -1) {
      date = date.split('T')[0];
    }

    let arr = date.split('-');
    return new Date(`${arr[2]}-${arr[1]}-${arr[0]}`);
  }

  startReportPickerChanged(startReportPicker: string) {
    this.startReportPicker = this.convertToDate(startReportPicker);

    let Difference_In_Time = this.endReportPicker.getTime() - this.startReportPicker.getTime();
    this.Difference_In_Days = Math.floor((Difference_In_Time / (1000 * 3600 * 24))) + 1;
//    this.geSalesReport();
  }

  endReportPickerChanged(endReportPicker: string) {
    this.endReportPicker = this.convertToDate(endReportPicker);

    let Difference_In_Time = this.endReportPicker.getTime() - this.startReportPicker.getTime();
    this.Difference_In_Days = Math.floor((Difference_In_Time / (1000 * 3600 * 24))) + 1;
//    this.geSalesReport();
  }

  geSalesReport() {
    this.isLoading = true;
    this._perHourSalesService.getPerHourSales(this.startReportPicker, this.endReportPicker, this.selectedShops)
      .subscribe(
        (data: PerHourSales[]) => {
          this.perHourSales = data;
          this.yaxisMax = (Math.floor(Math.max(...this.perHourSales.map(x => x.totalPerDay)) / 500) + 1) * 500
          this.dataSource = new MatTableDataSource(this.perHourSales);
          this.isLoading = false;
        }, // success path/

        error => { this.isLoading = false; this.error = error }// error path
      );
  }


  catorgiesByChange(selectedCategory: number) {
    this.catorgiesBy = selectedCategory;
    this.geSalesReport();
  }

 


}
