import {Component, EventEmitter, Output, ChangeDetectorRef,  ChangeDetectionStrategy, AfterViewInit} from '@angular/core';
import { formatDate } from '@angular/common';
import { DatePipe } from '@angular/common';
import { NgbDate, NgbDateStruct, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'date-range-selection',
  templateUrl: './date-range-selection.component.html',
  styleUrls: ['./date-range-selection.component.css'],
})
export class DateRangeSelectionComponent {
  public maxDate: Date = new Date();
  public startReportPicker: Date = new Date();
  public endReportPicker: Date = new Date();
  sDate: string;
 
  @Output() startReportPickerChanged = new EventEmitter<string>();
  @Output() endReportPickerChanged = new EventEmitter<string>();

  constructor() { }

  convertToDate(date: string) {
    if (date.indexOf('T') != -1) {
      date = date.split('T')[0];
    }

    let arr = date.split('-');
    return new Date(`${arr[2]}-${arr[1]}-${arr[0]}`);
  }

 
  public changeReportStartDate(event: string ): void {
    if (event != null)
    {
      this.startReportPicker = this.convertToDate(event);
      this.startReportPickerChanged.emit(event);

    }
  }

  public changeReportEndDate(event: string): void {
    if (event != null) {
      
      this.endReportPickerChanged.emit(event);
    }
  }

  

}
