import { Shop } from "../Shop";
import { itSortRow } from "./itSortRow";
import { supplierRow } from "./supplierRow";

export class FilterItems {

  constructor(public selectedCategory: itSortRow, public selectedshop: Shop, public filterText:string,public pageIndex:number,public selectedSupplier:supplierRow) {

  }
}
