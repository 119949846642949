import { Component, OnInit, Inject, Input } from '@angular/core';
import { SpecialSales } from './specialSales';
import { SpecialSalesRow } from '../items-managment/specialSalesRow';
import { specialSalesBarcodeRow } from '../items-managment/specialSalesBarcodeRow';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbCalendar, NgbDate, NgbDateStructAdapter } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapterService } from '../services/custom-adapter.service';
import { SpecialSalesManagmentService } from '../specialSales-managment/specialSales-managment.service';
import ISpecialSale from 'src/interfaces/ISpecialSale';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'specialSalesCompare',
  templateUrl: './specialSalesCompare.component.html',
  styleUrls: ['./specialSalesCompare.component.css'],
})

export class specialSalesCompareComponent implements OnInit {

  public selectedspecialSales: SpecialSalesRow = new SpecialSalesRow();
  public specialSalesRows: SpecialSales[] = [];
  public specialSalesBarcodeRows: specialSalesBarcodeRow[][] = [];
//  public empList: Array<{ name: SpecialSalesRow[], empoloyeeID: specialSalesBarcodeRow[] }> = [];
  public dataSource = new MatTableDataSource<SpecialSales>();
  public dataSourceBarcode = new MatTableDataSource<specialSalesBarcodeRow>();

  error: any;

  initialDisplayedColumns: string[] =
    [
      'SHOPNAME',
      'CODE',
      'NAME',
      'QTY',
      'PRICE',
      'AMOUNT',
      'IFCONTINUE',
      'MAXQTY',
      'ENDDATE',
      'REMARKS',
      'QTY2',
      'PRICE2',
      'QTY3',
      'PRICE3',
      'QTY4',
      'PRICE4',
      'QTY5',
      'PRICE5',
      'QTY6',
      'PRICE6',
      'QTY7',
      'PRICE7',
      'UPDDATE',
    ];


  displayedColumns: string[] =
    [
      'SHOPNAME',
      'CODE',
      'NAME',
      'QTY',
      'PRICE',
      'AMOUNT',
      'IFCONTINUE',
      'MAXQTY',
      'ENDDATE',
      'REMARKS',
      'QTY2',
      'PRICE2',
      'QTY3',
      'PRICE3',
      'QTY4',
      'PRICE4',
      'QTY5',
      'PRICE5',
      'QTY6',
      'PRICE6',
      'QTY7',
      'PRICE7',
      'UPDDATE',
    ];

  displayedColumnForMobilesPhones: string[] =
    [
      'SHOPNAME',
      'CODE',
      'NAME',
      'QTY',
      'PRICE',
      'AMOUNT',
      'MAXQTY',
    ];

  displayedColumnForDesk1024: string[] =
    [
      'SHOPNAME',
      'CODE',
      'NAME',
      'QTY',
      'PRICE',
      'AMOUNT',
      'MAXQTY',
      'ENDDATE',
      'REMARKS',
    ];

 
  displayedColumnsBarcode: string[] =
    [
      'ITEMCODE',
      'IT_DESC',
      'PRICE',
      //      'removeitem'
    ];





  constructor(@Inject(MAT_DIALOG_DATA) public data: ISpecialSale, public _SpecialSalesManagmenService: SpecialSalesManagmentService) {
    this.selectedspecialSales = data.specialSalesRow;
  }



  ngOnInit(): void {
    this.getItems();
  }

  getItems() {
    this._SpecialSalesManagmenService.getSpecialSalesCompare(this.selectedspecialSales.CODE)
  .subscribe(
    (data: any) => {

      let specialSalesRows = data.map(x => x.specialSalesRows);
//        let specialSalesRows = data.specialSalesRows;
//      this.empList.push({ name: data.specialSalesRows, empoloyeeID: data.specialSalesBarcodeRows });
//      for (let i = 0; i < data.length; i++) {
        //        alert(data[i].specialSalesRows.length);
//        this.specialSalesRows.push(data[i].specialSalesRows);
//        this.empList.push({ name: data[i].specialSalesRows, empoloyeeID: data[i].specialSalesBarcodeRows });
//        let specialSalesRows = data[i].specialSalesRows;
//        this.specialSalesBarcodeRows[i] = data[i].specialSalesBarcodeRows;
//      }
//      alert(this.empList[0].name.length);
      this.dataSource = new MatTableDataSource<SpecialSales>(specialSalesRows[0]);

      return;
    }, // success path/
    error => this.error = error // error path
  );
  }


}
