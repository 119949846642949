import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class SpecialSalesRow {
  constructor(code: string = null) { this.CODE = code; }
  SHOP_ID: number;
  TABLEID: number;
  CODE: string;
  AMOUNT: number;
  IFCONTINUE: string;
  // TODO: MUST CHECK FOR REGRESSION
  ENDDATE: Date;
  ENDDATESTR: string;
  MAXQTY: number;
  PRICE: number;
  PRICE2: number;
  PRICE3: number;
  PRICE4: number;
  PRICE5: number;
  PRICE6: number;
  PRICE7: number;
  NAME: string;
  REMARKS: string;
  QTY: number;
  QTY2:number;
  QTY3: number;
  QTY4: number;
  QTY5: number;
  QTY6: number;
  QTY7: number;
  UPDDATE: Date;
  
}
