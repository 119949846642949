export class ZReport {
  public shopName: string;
  public creditCard: number;
  public hakafa: number;
  public cash: number;
  public checks: number;
  public sum: number;
  public netPayment: number;
}

export class ZReports {

  constructor(public zreports: ZReport[], public date: Date) {
    for (let i = 0; i < zreports.length; i++) {
      zreports[i].shopName = zreports[i].shopName === "totalShopName" ? 'סה"כ' : zreports[i].shopName
   }
  }
}
