import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Shop } from '../../Shop';


@Injectable({
  providedIn: 'root'
})


export class InvoicingReportService {
  configUrl = 'api/INVOICINGREPORT';
  httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json',
    })
  };

  constructor(private http: HttpClient) {

  }

  private addLeadingZero(numnerAsString: number): string {
    console.log('numnerAsString', numnerAsString);
    return ((numnerAsString.toString().length === 1) ? "0" + numnerAsString.toString() : numnerAsString.toString());

  }

  getInvoicing(reportFrom: Date, reportTo: Date, selectedShop: number) {
    let shopIds: number[] = [];

    console.log("reportFrom", reportFrom)
    console.log("1reportTo", reportTo)
    let reportFromString: string = reportFrom.getFullYear() + "-" + this.addLeadingZero((reportFrom.getMonth() + 1)) + "-" + this.addLeadingZero(reportFrom.getDate()) + 'T' + this.addLeadingZero(reportFrom.getHours()) + ':' + this.addLeadingZero(reportFrom.getMinutes()) + ':' + this.addLeadingZero(reportFrom.getSeconds());
    let reporToString: string = reportTo.getFullYear() + "-" + this.addLeadingZero((reportTo.getMonth() + 1)) + "-" + this.addLeadingZero(reportTo.getDate()) + 'T' + this.addLeadingZero(reportTo.getHours()) + ':' + this.addLeadingZero(reportTo.getMinutes()) + ':' + this.addLeadingZero(reportTo.getSeconds());
    console.log("reportFromString", reportFromString)
    console.log("reporToString", reporToString)


    return this.http.post<any[]>(this.configUrl,
      {
        "reportStart": reportFromString,
        "reportEnd": reporToString,
        "shopId": parseInt(selectedShop.toString()),
      }, this.httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}

