<mat-dialog-content class="mat-typography">
  <mat-dialog-content class="mat-typography">
    <div>
      <mat-form-field appearance="fill" class="filterFields">
        <mat-label>בחר חנות</mat-label>
        <mat-select [(ngModel)]="selectedShop" (selectionChange)="selectionChangeShop($event.value)">
          <mat-option *ngFor="let shop of shops" [value]="shop">{{ shop.SHOP_NAME }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="filterFields">
        <mat-label>חיפוש</mat-label>
        <input autocomplete="off" matInput (keyup)="applyFilter($event)" placeholder="חיפוש" #input>
      </mat-form-field>

      <div class="inputDropDown">

        <button mat-raised-button color="primary" (click)="openDialog()">הוספת פריט</button>
        <button mat-raised-button color="primary" (click)="save()">שמור</button>
        <button mat-button mat-dialog-close mat-raised-button color="basic">סגור</button>
      </div>


    </div>

    <div style="clear:both" *ngIf="selectedspecialSalesBarcode">
      <mat-form-field class="edit-item mediumNumericField">
        <mat-label>קוד פריט </mat-label>
        <input autocomplete="off" matInput [ngModel]="selectedspecialSalesBarcode.ITEMCODE" placeholder="קוד פריט" #input readonly="readonly">
      </mat-form-field>
      
      <mat-form-field class="edit-item longTextField">
        <mat-label>שם פריט </mat-label>
        <input autocomplete="off" matInput [ngModel]="selectedspecialSalesBarcode.ITEMCODE | itemName:itemsRows[shopIndex]" placeholder="שם פריט" #input readonly="readonly">
      </mat-form-field>

      <mat-form-field class="edit-item mediumNumericField">
        <mat-label>מחיר </mat-label>
        <input autocomplete="off" matInput [(ngModel)]="selectedspecialSalesBarcode.PRICE" placeholder="מחיר" #input>
      </mat-form-field>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 clearBoth">
      <ng-container matColumnDef="ITEMCODE">
        <th mat-header-cell *matHeaderCellDef class="small"> קוד פריט  </th>
        <td mat-cell *matCellDef="let element"> {{element.ITEMCODE}} </td>
      </ng-container>
      <ng-container matColumnDef="IT_DESC">
        <th mat-header-cell *matHeaderCellDef> שם פריט </th>
        <td mat-cell *matCellDef="let element"> {{element.ITEMCODE | itemName:itemsRows[shopIndex]}} </td>
      </ng-container>
      <ng-container matColumnDef="PRICE">
        <th mat-header-cell *matHeaderCellDef> מחיר </th>
        <td mat-cell *matCellDef="let element"> {{element.PRICE}} </td>
      </ng-container>

      <ng-container matColumnDef="removeitem">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
          <button mat-raised-button color="primary" (click)="removeItem(element)">הסר פריט</button>
        </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectRow(row)"></tr>
    </table>

    <mat-paginator [length]="specialSalesBarcodeRowsFilterd.length"
                   [pageSize]="10">
    </mat-paginator>

</mat-dialog-content>
</mat-dialog-content>
