<div *ngIf="isAuthenticated | async">
  <div mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon title="{{ userName | async }}" id="user-managment">
        {{ userNameFirstLetter | async }}
    </mat-icon>
  </div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item [routerLink]='["/authentication/profile"]' title="ניהול פרופיל">
        <mat-icon>settings</mat-icon>
        <span>פרופיל</span>
    </button>
    <button mat-menu-item [routerLink]='["/authentication/logout"]' [state]='{ local: true }' title="התנתקות">
        <mat-icon>power_settings_new</mat-icon>
        <span>התנתקות</span>
    </button>
  </mat-menu>
</div>


<!-- <ul class="navbar-nav" *ngIf="isAuthenticated | async">

  <li class="nav-item">
    <a class="nav-link text-white" [routerLink]='["/authentication/profile"]' title="Manage"> {{ userName | async }}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link text-white" [routerLink]='["/authentication/logout"]' [state]='{ local: true }' title="Logout">התנתקות</a>
  </li>
</ul> -->
<ul class="navbar-nav" *ngIf="!(isAuthenticated | async)">
  <li class="nav-item">
        <a class="nav-link nav-link text-white" [routerLink]='["/authentication/register"]'>Register</a>
    </li>
    <li class="nav-item">
        <a class="nav-link text-white" [routerLink]='["/authentication/login"]'>Login</a>
    </li>
</ul>
