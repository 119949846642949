import { NgModule } from '@angular/core';
import { Component, EventEmitter, Output, AfterViewInit } from '@angular/core';
// import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgbAlertModule, NgbDatepickerModule, NgbDateStruct, NgbDateStructAdapter } from '@ng-bootstrap/ng-bootstrap';
import { formatDate, DatePipe,Time } from '@angular/common';



@Component({
  selector: 'time-range-selection',
  templateUrl: './time-range-selection.component.html',
  styleUrls: ['./time-range-selection.component.css'],
//  imports: [NgbDatepickerModule, NgbAlertModule],
})


export class TimeRangeSelectionComponent implements AfterViewInit {
  public maxDate: Date = new Date();
  public reportPicker: Date = new Date();
  model: NgbDateStruct;  

  @Output() reportPickerChanged = new EventEmitter<ReportDateRange>()


  //  public fromHour = new Date().getHours() - 1;
  public fHour : string = "00:01";
  public tHour: string = "23:59";
  theDate1: Date;
  theDate2: Date;
  public fromHour = 7;
  public fromMinute = 0;
  public toHour = 23;
  public toMinute = 59;
  public hours = Array.apply(null, { length: 24 }).map(Number.call, Number);
  public minutes = Array.apply(null, { length: 60 }).map(Number.call, Number);

  constructor(  ) { }

  ngAfterViewInit(): void {
//    let formattedDt = formatDate(new Date(), 'dd/MM/yyyy', 'en_UK')
//    this.theDate = formattedDt; // new Date().toJSON().slice(0, 10).replace(/-/g, '/');
//    this.theDate1 = new Date();
    this.timeChange();
   }


  convertToDate(date: string) {
    if (date.indexOf('T') != -1) {
      date = date.split('T')[0];
    }

    let arr = date.split('-');
    return new Date(`${arr[2]}-${arr[1]}-${arr[0]}`);
  }
    
  public changeReportStartDate(event: string): void {
    if (event != null) {
      this.reportPicker = this.convertToDate(event);
      this.timeChange();
    }
  }
  public changeReportDate() {
//    this.reportPicker = this.theDate1;
    this.timeChange();
  }

  setTimeToDate(date: Date, time: string): void {
    const [hours, minutes] = time.split(':').map(Number);
    date.setHours(hours, minutes, null, null); // Set hours, minutes, seconds, and milliseconds
  }

  public timeChange() {

    let reportDateRange = new ReportDateRange();
    this.theDate1 = new Date(this.reportPicker);
    this.theDate2 = new Date(this.reportPicker); 

    this.setTimeToDate(this.theDate1, this.fHour);
    this.setTimeToDate(this.theDate2, this.tHour);
    reportDateRange.reportStart = this.theDate1;
    reportDateRange.reportEnd = this.theDate2;


    this.reportPickerChanged.emit(reportDateRange);
  }

}

export class ReportDateRange{
   public reportStart: Date;
   public reportEnd: Date;
  constructor() {}
}
