import { Component } from '@angular/core';
import { UsersShopsService } from './users-shops.service';

@Component({
  selector: 'app-users-shops',
  templateUrl: './users-shops.component.html',
  styleUrls: ['./users-shops.component.css']
})

export class UsersShopsComponent {

  error: any;

  public shops: Shop[] = [];
  public conections: any = [];
  public users: any = [];
  public usersFilterd: any = [];
  public shopsFilterd: Shop[] = [];

  
  public filterUsers = "";
  public filterShops = "";

  public connections: any = [];
  public userShops: Shop[] = [];
  public usersShops: any;
  public availableShops: Shop[] = [];

  public selectedUser: any = null;

  public selectedShops: Shop[];

  constructor(public _UsersShopsService: UsersShopsService) {
    this.getUsersShops();
  }

  public onUserFilterChange() {
    this.filterUsers = this.filterUsers.trim().toUpperCase();
    if (this.filterUsers.length>0)
      this.usersFilterd = this.users.filter(x => x.NormalizedEmail.indexOf(this.filterUsers) > -1)
    else
      this.usersFilterd = this.users.filter(x => x)

    if (this.usersFilterd.length === 1)
      this.selectUser(this.usersFilterd[0]);
  }

  public onShopFilterChange() {
    this.filterShops = this.filterShops.trim();
    if (this.filterShops.length > 0)
      this.shopsFilterd = this.shops.filter(x => x.SHOP_NAME.indexOf(this.filterShops) > -1)
    else
      this.shopsFilterd = this.shops.filter(x => x)
  }

  public clearAllShops(): void {
    this.selectedShops = [];
    this.onShopsChange(this.selectedShops);
  }

  public selectAllShops(): void {
    this.selectedShops = this.shops.filter(x => x);
    this.onShopsChange(this.selectedShops);
  }

  public onShopsChange(selectedShops: Shop[]) {
    this._UsersShopsService.posttUsersShops({ "userId": this.selectedUser.Id, "shopIds": selectedShops.map(x => x.SHOP_ID)})
      .subscribe(
        (data: any) => {
          this.connections = data.CONNECTIONS;
          this.selectUser(this.selectedUser);
        }, // success path/
        error => this.error = error // error path
      );
  }

  selectUser(user: any) {
    this.selectedUser = user;
    let usersShopsIds = this.connections.filter(x => x.ApplicationUser.Id === this.selectedUser.Id).map(x => x.SHOP).map(x => x.SHOP_ID)
    this.selectedShops = this.shops.filter(x => usersShopsIds.includes(x.SHOP_ID));
    this.onShopFilterChange();
  }

  getUsersShops() {
    this._UsersShopsService.getUsersShops()
      .subscribe(
        (data: any) => this.gotData(data), // success path/
        error => this.error = error // error path
      );
  }

  gotData(data: any) {
    this.shops = data.SHOPS.sort((a, b) => (a.SHOP_ID < b.SHOP_ID) ? 1 : -1);
    if (this.users.length === 0)
      this.users = data.USERS.sort((a, b) => (a.id < b.id) ? 1 : -1);
    this.connections = data.CONNECTIONS;
    this.onUserFilterChange()

  }
}

export class Shop {
  SHOP_ID: number;
  PARENT_SHOP_ID: number;
  SHOP_NAME: string;
  SHOP_TYPE: number;
  TOKEN: string;
  ITEMS_FROM_SHOP_ID: number;
  BACKUP_DAYS: number;
}
