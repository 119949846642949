import { Component, OnInit, Inject, Input } from '@angular/core';
import { SpecialSalesRow } from '../items-managment/specialSalesRow';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbCalendar, NgbDate, NgbDateStructAdapter } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapterService } from '../services/custom-adapter.service';
import ISpecialSale from 'src/interfaces/ISpecialSale';

@Component({
  selector: 'specialSalesHeader',
  templateUrl: './specialSalesHeader.component.html',
  styleUrls: ['./specialSalesHeader.component.css'],
})

export class specialSalesHeaderComponent implements OnInit {

  public selectedspecialSales: SpecialSalesRow = new SpecialSalesRow();

  constructor(private ngbCalendar: NgbCalendar, private dateAdapter: CustomAdapterService, @Inject(MAT_DIALOG_DATA) public data: ISpecialSale) {
    data.specialSalesRow.ENDDATESTR = this.convertDateToModel(data.specialSalesRow.ENDDATE);
    this.selectedspecialSales = data.specialSalesRow;
  }

  ngOnInit(): void {}

  get today() {
		return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
	}

  convertDateToModel(enddate: Date) {
    if (enddate == null) return null;
    let newDate = new Date(enddate);
    return `${newDate.getDate()}-${newDate.getMonth() + 1}-${newDate.getFullYear()}`;
  }
}
