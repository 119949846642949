<div class="flex-row filter-row-container" style="margin-top:0.65rem">

  <my-shops-single-selection style="float:right;width:10rem" (shopChanged)="shopChanged($event)"></my-shops-single-selection>
  <time-range-selection (reportPickerChanged)="dateChanged($event)"></time-range-selection>
  <button mat-button style="margin-right: 0.5rem; margin-top:0rem" (click)=getReport() cdkFocusInitial mat-raised-button color="primary">בצע</button>

  <button *ngIf="isLoading" style="margin-right:0.5rem;" class="btn btn-primary" type="button" disabled>
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span class="sr-only"> מטעין... </span>
  </button>


    <div style="margin-top:1rem; width: 50%;">
      <ng-container *ngFor="let invoice of invoices">
        <div style="border:solid;">
          <invoice-header [invoice]="invoice"></invoice-header>
          <invoicing-report-single [invoice]="invoice"></invoicing-report-single>
          <pay-list [report]="invoice"> </pay-list>
        </div>
      </ng-container>
    </div>




