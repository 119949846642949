import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AutoLogoutService {

  private _lastUpdate: Date = new Date();
  private logOutTimeInSeconds = 60*120;
  constructor() { }

  public actionCommited(): void{
    this._lastUpdate = new Date();
  }

  public logOutCheck(): boolean {
    return ((new Date().getTime() - this._lastUpdate.getTime()) / 1000) > this.logOutTimeInSeconds;
  }

}
