import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { SalesReportByGroup } from './sales-report-by-group';
import { Shop } from '../../users-shops/users-shops.component';

@Injectable({
  providedIn: 'root'
})
export class SalesReportByGroupService {
  configUrl = 'api/SalesReportByGroup';

  httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json',

    })
  };


  constructor(private http: HttpClient) { }

  private addLeadingZero(numnerAsString: number): string {
    console.log('numnerAsString', numnerAsString);
    return ((numnerAsString.toString().length === 1) ? "0" + numnerAsString.toString() : numnerAsString.toString());

  }

  getSalesReportByGroup(reportFrom: Date, reportTo: Date, selectedShops: Shop[], catorgiesBy: string) {
    let shopIds: number[] = [];

    if (selectedShops && selectedShops.length > 0)
      shopIds = selectedShops.map(x => x.SHOP_ID);

    let reportFromString: string = reportFrom.getFullYear() + "-" + this.addLeadingZero((reportFrom.getMonth() + 1)) + "-" + this.addLeadingZero(reportFrom.getDate()) + 'T00:00:00';
    let reporToString: string = reportTo.getFullYear() + "-" + this.addLeadingZero((reportTo.getMonth() + 1)) + "-" + this.addLeadingZero(reportTo.getDate()) + 'T00:00:00';
  

    return this.http.post<any>(this.configUrl,
      {
        "reportStart": reportFromString,
        "reportEnd": reporToString,
        "shopIds": shopIds,
        "catorgiesBy": catorgiesBy,
      }, this.httpOptions)
      .pipe(
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

}
