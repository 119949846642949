import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { ManagmentService } from './managment.service';
import { Managment } from './managment';
import { General } from './General';
import { v4 as uuidv4 } from 'uuid';
import { Shop } from '../Shop';
import { TelegramChat } from '../reports/telegtam-single-selection/telegram-chat';
import { TelegramChatsService } from '../reports/telegtam-single-selection/telegram-chat.service';

@Component({
  selector: 'app-managment',
  templateUrl: './managment.component.html',
  styleUrls: ['./managment.component.css']
})


export class ManagmentComponent implements OnInit {
  public shops: Shop[] = [];
  public selectedShop: Shop;
  public managment: Managment;
  public general: General;
  rowClicked: number;
  ifdelete: boolean;
  isLoading = false;
  HasSite = false;

  error: any;

  //displayedColumns: string[] = ['SHOP_ID', 'PARENT_SHOP_ID', 'SHOP_NAME', 'SHORT_NAME', 'ITEMS_FROM_SHOP_ID', 'SPECIAL_SALES_FROM_SHOP_ID', 'BACKUP_DAYS', 'TOKEN', 'TelegramChat1', 'TelegramChat2', 'TelegramChat3'];
  displayedColumns: string[] = ['SHOP_ID', 'PARENT_SHOP_ID', 'SHOP_NAME', 'SHORT_NAME', 'ITEMS_FROM_SHOP_ID', 'SPECIAL_SALES_FROM_SHOP_ID', 'BACKUP_DAYS', 'TOKEN','UPDDATE'];
  dataSource = new MatTableDataSource(this.shops);

  @ViewChild(MatSort) sort: MatSort; 

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  sortItems() {
    this.dataSource.sort = this.sort;
    const sortState: Sort = { active: 'SHOP_ID', direction: 'asc' };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  //headers: string[];
  constructor(public _ManagmentService: ManagmentService, public _TelegramChatsService: TelegramChatsService) {
    this.newShop()
    this.getManagment();
//    this.sortItems();
  }

  ngOnInit(): void { }
  save() {
    this.selectedShop.SHOP_ID = parseInt(this.selectedShop.SHOP_ID.toString());
    if (isNaN(this.selectedShop.PARENT_SHOP_ID)) {
      this.selectedShop.PARENT_SHOP_ID = 0;
    }
    this.selectedShop.PARENT_SHOP_ID = parseInt(this.selectedShop.PARENT_SHOP_ID.toString());
    this.selectedShop.SHOP_TYPE = 1;//parseInt(this.selectedShop.SHOP_TYPE.toString())
    if (isNaN(this.selectedShop.ITEMS_FROM_SHOP_ID)) {
      this.selectedShop.ITEMS_FROM_SHOP_ID = 0;
    }
    this.selectedShop.ITEMS_FROM_SHOP_ID = parseInt(this.selectedShop.ITEMS_FROM_SHOP_ID.toString());
    this.selectedShop.SPECIAL_SALES_FROM_SHOP_ID = parseInt(this.selectedShop.SPECIAL_SALES_FROM_SHOP_ID.toString());

    if (isNaN(this.selectedShop.BACKUP_DAYS)) {
      this.selectedShop.BACKUP_DAYS = 0;
    }
    this.selectedShop.BACKUP_DAYS = parseInt(this.selectedShop.BACKUP_DAYS.toString());

    this._ManagmentService.postMANAGMENT(this.selectedShop)
      .subscribe(
        (data: Shop[]) => {
          this.dataSource = new MatTableDataSource(data);
          this.shops = data;
          this.newShop();
        }, // success path
        error => this.error = error // error path
      );
  }


  deleteShop() {
    this.ifdelete = true;
  }
  onNoClick(): void {
    this.ifdelete = false;
  }

  onYesClick(): void {
    this.ifdelete = false;
    this.isLoading = true;

    this._ManagmentService.deleteMangment(this.selectedShop)
      .subscribe(
        (data: any) => {
       //   this.getManagment();

          this.isLoading = false;
          window.location.reload();
        }, // success path/
        error => { this.error = error, this.isLoading = false } // error path
      );   

  }


  changeTableRowColor(idx: any, row) {
    this.shops = row;

    this.rowClicked = this.rowClicked === idx ? -1 : idx;
    this.selectRow(row);
  }

  generateTOKEN() {
    this.selectedShop.TOKEN = uuidv4().substring(0, 30);
  }

  changeVatDate() {

  }
  getManagment() {
      this._ManagmentService.getMANAGMENT()
        .subscribe(
          //(data: Shop[]) => {
          (data: any) => {
            this.managment = data.managment;
            this.general = data.general;
            this.dataSource = new MatTableDataSource(data.managment);
            this.shops = data.managment;
            console.log(this.shops);
          }, // success path/
          error => this.error = error // error path
        );
  }

  public selectRow(shop: Shop) {
    this.selectedShop = shop;
    this.HasSite = false;
    if (this.selectedShop.Site.length > 0) this.HasSite=true;
//    this.selectedShop.TelegramChat1 = this.selectedShop.TelegramChat1 == null ? 0 : this.selectedShop.TelegramChat1;
//    this.selectedShop.TelegramChat2 = this.selectedShop.TelegramChat2 == null ? 0 : this.selectedShop.TelegramChat2;
//    this.selectedShop.TelegramChat3 = this.selectedShop.TelegramChat3 == null ? 0 : this.selectedShop.TelegramChat3;

  }

  public newShop() {
    this.selectedShop = new Shop();
    this.selectedShop.SHOP_ID = 0;
    this.selectedShop.PARENT_SHOP_ID = 0;
    this.selectedShop.ITEMS_FROM_SHOP_ID = 0;
    this.selectedShop.SPECIAL_SALES_FROM_SHOP_ID = 0;
    this.selectedShop.BACKUP_DAYS = 0;
    this.selectedShop.SHOP_TYPE = 0;
    this.selectedShop.SHOP_NAME = "";
    this.selectedShop.SHORT_NAME = "";
    this.selectedShop.TelegramChat1 = 0;
    this.selectedShop.TelegramChat2 = 0;
    this.selectedShop.TelegramChat3 = 0;

    this.generateTOKEN();
  }

  public telegramChat1Changed(chat: TelegramChat) {
    this.selectedShop.TelegramChat1 = chat.id;
  }

  public telegramChat2Changed(chat: TelegramChat) {
    this.selectedShop.TelegramChat2 = chat.id;
  }

  public telegramChat3Changed(chat: TelegramChat) {
    this.selectedShop.TelegramChat3 = chat.id;
  }

  public testTelegram(chatId:number):void {
    this._TelegramChatsService.SendTelegramValidationMsg(chatId)
      .subscribe(
        (data: any[]) => {
        }, // success path
        error => this.error = error // error path
      );
  }
}
