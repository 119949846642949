import { Component, OnInit, Input,  } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SalesReportByGroup } from '../sales-report-by-group/sales-report-by-group';

@Component({
  selector: 'sales-report-datailed-by-group',
  templateUrl: './sales-report-datailed-by-group.component.html',
  styleUrls: ['./sales-report-datailed-by-group.component.css']
})
export class SalesReportDatailedByGroupComponent implements OnInit {
  @Input('SalesReportByGroup') salesReportByGroup: any = null;

 // @Input('SalesReportByGroup') salesReportByGroup: SalesReportByGroup[] = [];
  // 'Total_Percentage', 'Qty_Percentage',
  displayedColumns: string[] = ['CODE', 'Name', 'PRICE1', 'Tot_Qty', 'Tot_Total','discount', 'Tot_WitOutVat', 'Tot_Cost', 'Profit', 'DProfit', 'AProfit'];
  public dataSource = new MatTableDataSource([]);
  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges() {
  //  console.log('ngOnChanges salesReportByGroup', this.salesReportByGroup)
  //  console.log('ngOnChanges salesReportByGroup[0]', this.salesReportByGroup[0])

    this.dataSource = new MatTableDataSource(this.salesReportByGroup);
  }

}




