<div class="flex-row filter-row-container">

    <div class="form-floating customized-select flex-item">
      <select class="form-select" [(ngModel)]="selectedCategory" (ngModelChange)="selectionChangeCategory($event)" required>
        <option [ngValue]="selectAllCategories">{{ selectAllCategories.DESCR }}</option>
        <option *ngFor="let itSortRow of data.itSortRows" [ngValue]="itSortRow">{{ itSortRow.DESCR }}</option>
      </select>
      <label class="label-color">בחר קבוצה</label>
    </div>

    <div class="form-floating customized-select flex-item">
      <select class="form-select" [(ngModel)]="selectedSupplier" (ngModelChange)="selectionChangeselectedSUPPLIER($event)" required>
        <option [ngValue]="selectAllSuppliers">{{ selectAllSuppliers.CLNAME }}</option>
        <option *ngFor="let supplierRow of data.supplierRows" [ngValue]="supplierRow">{{ supplierRow.CLNAME }}</option>
      </select>
      <label class="label-color">בחר ספק</label>
    </div>

    <div class="form-floating customized-input flex-item">
      <input type="text" class="form-control" id="SEARCHF" autocomplete="off" placeholder="חיפוש" [(ngModel)]="filterValue" #input (focusout)="SearchFocusOutEvent($event)" (keyup)="SerchhandleKeyboardEvent($event)">
    </div>

  <mat-dialog-actions>
    <button mat-button mat-dialog-close mat-raised-button color="primary">סגור</button>
  </mat-dialog-actions>
</div>



<table mat-table [dataSource]="dataSource" matSort matSortActive="IT_CODE" class="mat-elevation-z8 clearBoth">
  <ng-container matColumnDef="IT_CODE">
    <th mat-header-cell *matHeaderCellDef  mat-sort-header  class="small"> קוד פריט </th>
    <td mat-cell *matCellDef="let element"> {{element.IT_CODE}} </td>
  </ng-container>
  <ng-container matColumnDef="IT_DESC">
    <th mat-header-cell *matHeaderCellDef mat-sort-header  class="large"> תאור פריט </th>
    <td mat-cell *matCellDef="let element"> {{element.IT_DESC}} </td>
  </ng-container>
  <ng-container matColumnDef="ITSORT">
    <th mat-header-cell *matHeaderCellDef class="large"> מיון/ קבוצה </th>
    <td mat-cell *matCellDef="let element">

      <span class="hideCellular">{{element.ITSORT | itSort:data.itSortRows}}</span>
    </td>
  </ng-container>
  <div class="inputDropDown">
    <ng-container matColumnDef="SUPPLIER">
      <th mat-header-cell *matHeaderCellDef class="large"> מספר ספק </th>
      <td mat-cell *matCellDef="let element">

        <span class="hideCellular"> {{element.SUPPLIER | supplier:data.supplierRows}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="PRICE1">
      <th mat-header-cell *matHeaderCellDef class="large"> מחיר 1 </th>
      <td mat-cell *matCellDef="let element"> {{element.PRICE1  | number:'1.2'}} </td>
    </ng-container>
    <ng-container matColumnDef="BARCODE">
      <th mat-header-cell *matHeaderCellDef class="large">ברקוד </th>
      <td mat-cell *matCellDef="let element"> {{element.BARCODE}} </td>
    </ng-container>


  </div>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns; let idx=index; let even=even;"
      [style.background-color]="rowClicked == idx ? 'yellow' : (even ? 'white' : 'lightgray')"
      (click)="selectRow(idx, row)"
      [matRowKeyboardSelection]="this.selection"
      [ngClass]="{selected: selection.isSelected(row)}"></tr>


  <!--[rowModel]="row" [matTable]="demomatdatatable" [row]="demmatrow"-->



</table>

  <mat-paginator [length]="itemsRowsFilterd.length"
                 [pageSize]="20">
  </mat-paginator>
