<div class="flex-row filter-row-container" id="entryHeader">
  <my-shops-single-selection id="my-shops-single-selection" (shopChanged)="shopChanged($event)"></my-shops-single-selection>


  <div id="DOC_Number" class="form-floating customized-input flex-item">
    <input type="text" class="form-control" [value]="selectedNumber" id="floatingInput" disabled />
    <label for="floatingInput" class="label-color"> מספר תעודה</label>
  </div>

  <div id="SUP_Number" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="ספק" autocomplete="off" [value]="selectedSupplier.CLNUM" (keyup)="SUPPLIERhandleKeyboardEvent($event)" #input>
    <label class="label-color">מס' ספק</label>
  </div>

  <div id="SUP_Name" class="form-floating customized-select flex-item">
    <select class="form-select" [(ngModel)]="selectedSupplier" (ngModelChange)="selectionChangeselectedSUPPLIER($event)" required>
      <option *ngFor="let supplierRow of supplierRows" [ngValue]="supplierRow">{{ supplierRow.CLNAME }}</option>
    </select>
    <label class="label-color">בחר ספק</label>
  </div>

  <div id="SUP_Div" class="form-floating customized-input flex-item">
    <input type="text" inputmode="numeric" class="form-control" placeholder="מספר תעודת ספק" id="REF2" autocomplete="off" [(ngModel)]="selectedEnt_Head.REF2" (keyup)="REF2handleKeyboardEvent($event)">
    <label class="label-color">מספר תעודת ספק</label>
  </div>

  <div class="form-floating customized-input flex-item">
    <input class="form-control"
           id="Reg_Date"
           name="dp"
           type="date"
           [value]="RegDate | date:'yyyy-MM-dd'"
           (change)="changeRegDate($event)" />
    <label class="label-color">תאריך רישום </label>
  </div>

  <div class="form-floating customized-input flex-item">
    <input class="form-control"
           id="Pay_Date"
           name="dp"
           type="date"
           [value]="PayDate | date:'yyyy-MM-dd'"
           (change)="changeFDate($event)" />
    <label class="label-color">תאריך פרעון </label>
  </div>

</div>



<div class="tableItem">
  <div id="HeaderButtons">
    <button mat-raised-button color="primary" (click)="openDialogEntItem(true)">הוספת פריט</button>
    <button mat-raised-button color="primary" (click)="removeEntItem(selectedEnt_ItemRow)" *ngIf="rowClicked >= 0">מחק</button>
    <button mat-raised-button color="primary" (click)="openDialogEntItem(false)" *ngIf="rowClicked >= 0">עדכון שורה</button>
    <button mat-raised-button color="primary" (click)="openDialogEntItem(true)">שמירה</button>
    <button mat-raised-button color="primary" (click)="print()"><span>הדפסה</span></button>


    <dx-report-viewer id="reportViewer"
       [reportUrl]="'Entry'">
    </dx-report-viewer>


    <!--
     <div>
       <dx-report-viewer [reportUrl]="reportUrl" height="800px" cssClass='myViewer'>
         <dxrv-request-options [invokeAction]="invokeAction" [host]="hostUrl"></dxrv-request-options>
         <dxrv-tabpanel-settings width="500" position="Left"></dxrv-tabpanel-settings>
         <dxrv-export-settings [useSameTab]="false" [useAsynchronousExport]="false"></dxrv-export-settings>
         <dxrv-search-settings [useAsyncSearch]="false"></dxrv-search-settings>
         <dxrv-callbacks (CustomizeMenuActions)="CustomizeMenuActions($event)">
         </dxrv-callbacks>
       </dx-report-viewer>
     </div>
   </div>
  -->


    <table id="table1" mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="IT_LINE">
        <th mat-header-cell *matHeaderCellDef>  שורה   </th>
        <td mat-cell *matCellDef="let element"> {{element.IT_LINE}} </td>
      </ng-container>

      <ng-container matColumnDef="IT_CODE">
        <th mat-header-cell *matHeaderCellDef>  קוד פריט  </th>
        <td mat-cell *matCellDef="let element"> {{element.IT_CODE}} </td>
        <!-- [class.selected]="element.selected" (click)="element.selected= !element.selected" -->
      </ng-container>

      <ng-container matColumnDef="IT_DESC">
        <th mat-header-cell *matHeaderCellDef> תאור פריט   </th>
        <td mat-cell *matCellDef="let element"> {{element.IT_DESC}} </td>
      </ng-container>

      <ng-container matColumnDef="IT_QTY" class="QTY">
        <th mat-header-cell *matHeaderCellDef> כמות </th>
        <td mat-cell *matCellDef="let element"> {{element.IT_QTY}} </td>
      </ng-container>

      <ng-container matColumnDef="BONUS" class="BONUS">
        <th mat-header-cell *matHeaderCellDef> בונוס </th>
        <td mat-cell *matCellDef="let element"> {{element.BONUS}} </td>
      </ng-container>


      <ng-container matColumnDef="IT_COST" class="PRICE">
        <th mat-header-cell *matHeaderCellDef> עלות </th>
        <td mat-cell *matCellDef="let element"> {{element.IT_COST | number:'1.2'}} </td>
      </ng-container>

      <ng-container matColumnDef="IT_PRICE" class="PRICE">
        <th mat-header-cell *matHeaderCellDef> מ. מכירה </th>
        <td mat-cell *matCellDef="let element"> {{element.IT_PRICE | number:'1.2'}} </td>
      </ng-container>

      <ng-container matColumnDef="IT_DISC" class="PRICE">
        <th mat-header-cell *matHeaderCellDef> %הנחה  </th>
        <td mat-cell *matCellDef="let element"> {{element.IT_DISC | number:'1.2'}} </td>
      </ng-container>

      <ng-container matColumnDef="IT_TOTAL" class="PRICE">
        <th mat-header-cell *matHeaderCellDef> סה"כ שורה  </th>
        <td mat-cell *matCellDef="let element"> {{element.IT_TOTAL | number:'1.2'}} </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <
      <tr mat-row *matRowDef="let idx=index; let even=even; let row; columns: displayedColumns;"
          (click)="changeTableRowColor(idx,row)" [style.background-color]="rowClicked == idx ? 'yellow' : (even ? 'white' : 'lightgray')">
      </tr>

    </table>


    <div align="left" class="flex-row filter-row-container" id="entryfotter">
      <label style="margin-left: 0.5rem; margin-right: 0.5rem" class="Fotter_label">בניים</label>
      <div id="Total_B" class="">
        <input align="left" type="text" class="form-control" [value]="Total_B | number:'1.2'" id="floatingInput" disabled />
      </div>

      <label style="margin-left: 0.5rem; margin-right: 0.5rem" class="Fotter_label">%הנחה</label>
      <div id="Disc_P" class="">
        <input align="left" type="text" class="form-control" [value]="Disc_P | number:'1.2'" id="floatingInput" disabled />
      </div>

      <label style="margin-left: 0.5rem; margin-right: 0.5rem" class="Fotter_label">הנחה</label>
      <div id="Disc_T" class="">
        <input align="left" type="text" class="form-control" [value]="Disc_T | number:'1.2'" id="floatingInput" disabled />
      </div>

      <label style="margin-left: 0.5rem; margin-right: 0.5rem" class="Fotter_label">%מע&quot;מ</label>
      <div id="VatPerc" class="">
        <input align="left" type="text" class="form-control" [value]="Vat_Perc | number:'1.2'" id="floatingInput" disabled />
      </div>
      <label style="margin-left: 0.5rem; margin-right: 0.5rem" class="Fotter_label"> מע&quot;מ</label>
      <div id="Vat" class="">
        <input align="left" type="text" class="form-control" [value]="Vat | number:'1.2'" id="floatingInput" disabled />
      </div>

      <label style="margin-left: 0.5rem; margin-right: 0.5rem" class="Fotter_label"> פקדון</label>
      <div id="Pikadon" class="">
        <input align="left" type="text" class="form-control" [value]="Pikadon | number:'1.2'" id="floatingInput" disabled />
      </div>

      <label style="margin-left: 0.5rem; margin-right: 0.5rem " class="Fotter_label"> סה&quot;כ תעודה</label>
      <div id="Total_F" class="">
        <input align="left" type="text" class="form-control" [value]="Total_F | number:'1.2'" id="floatingInput" disabled />
      </div>

    </div>
  </div>




