export class Shop {
    SHOP_ID: number;
    PARENT_SHOP_ID: number;
    SHOP_NAME: string;
    SHORT_NAME: string;
    SHOP_TYPE: number;
    TOKEN: string;
    ITEMS_FROM_SHOP_ID: number;
    SPECIAL_SALES_FROM_SHOP_ID: number;
    BACKUP_DAYS: number;
    TelegramChat1: number;
    TelegramChat2: number;
    TelegramChat3: number;
    Site: string;
    ConsumerKey: string;
    ConsumerSecret: string;
}
