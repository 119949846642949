
<div class="flex-row filter-row-container">
  <date-range-selection (startReportPickerChanged)="startReportPickerChanged($event)" (endReportPickerChanged)="endReportPickerChanged($event)"></date-range-selection>
  <div style="margin-right: 1rem; ">
    <label style="font-weight:bold;" class="label-color">מיון לפי</label>

    <div class="form-floating customized-select flex-item">
      <select style="height:2.6rem" class="form-select" id="floatingSelect" #code1 (change)="catorgiesByChange(code1.value)">
        <option [value]='1'>קבוצה</option>
        <option [value]='2'>ספק</option>
      </select>
    </div>
  </div>

  <div style="height:2rem">
    <my-shops-selection (shopsChanged)="shopsChanged($event)"></my-shops-selection>
  </div>

  <button *ngIf="isLoading" style="margin-top:1.5rem;margin-right:1.0rem" class="btn btn-primary" type="button" disabled>
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span class="sr-only">  מטעין ...</span>
  </button>

</div>

<div style="height:0.21rem; margin-bottom:-5.0rem"></div>
<dx-data-grid id="gridContainer"
              [dataSource]="dataSourse1"
              [rowAlternationEnabled]="false"
              keyExpr="CODE"
              [rtlEnabled]="rtlEnabled"
              [repaintChangesOnly]="true"
              (onCellPrepared)="onCellPrepared($event)"
              [columnAutoWidth]="true"
              [wordWrapEnabled]="true"
              (onExporting)="onExporting($event)"
              height="100%"
              [showBorders]="true">

  <div id="exportContainer">
    <dx-button text="Export multiple grids"
               icon="xlsxfile"
               (onClick)="exportGrids($event)"></dx-button>
  </div>

  <dxo-selection mode="single"></dxo-selection>
  <dx-button (onClick)="onExporting($event)">
  </dx-button>



  <dxi-column dataField="CODE" [width]="80" caption="קוד"></dxi-column>
  <dxi-column dataField="Name" [width]="300" caption="תאור "></dxi-column>
  <dxi-column dataField="Tot_Qty" [width]="110" caption=" כמות" [format]="{ type: 'fixedPoint', precision: 2 }"></dxi-column>
  <dxi-column dataField="Discount" [width]="80" caption=" סה&quot;כ הנחה " [format]="{ type: 'fixedPoint', precision: 2 }"></dxi-column>
  <dxi-column dataField="Tot_Total" [width]="130" caption=" סה&quot;כ מכירה " [format]="{ type: 'fixedPoint', precision: 2 }"></dxi-column>
  <dxi-column dataField="Tot_WitOutVat" [width]="130" caption=" ללא מע&quot;מ " [format]="{ type: 'fixedPoint', precision: 2 }"></dxi-column>
  <dxi-column dataField="Total_Percentage" [width]="80" caption=" % מכספים" [format]="{ type: 'fixedPoint', precision: 2 }"></dxi-column>
  <dxi-column dataField="Qty_Percentage" [width]="80" caption=" % מכמויות" [format]="{ type: 'fixedPoint', precision: 2 }"></dxi-column>
  <dxi-column dataField="Tot_Cost" [width]="130" caption="סה&quot;כ עלות" [format]="{ type: 'fixedPoint', precision: 2 }"></dxi-column>
  <dxi-column dataField="Profit" [width]="130" caption=" רווח " [format]="{ type: 'fixedPoint', precision: 2 }"></dxi-column>
  <dxi-column dataField="DProfit" [width]="64" caption=" %רווח מלמטה " [format]="{ type: 'fixedPoint', precision: 2 }"></dxi-column>
  <dxi-column dataField="AProfit" [width]="68" caption=" %רווח מלמעלה " [format]="{ type: 'fixedPoint', precision: 2 }"></dxi-column>

  <dxo-summary>

    <dxi-total-item column="Tot_Qty"
                    summaryType="sum"
                    [valueFormat]="{type:'fixedPoint',precision:2}"
                    displayFormat="{0}"
                    [showInGroupFooter]="true">
    </dxi-total-item>

    <dxi-total-item column="Discount"
                    summaryType="sum"
                    [showInGroupFooter]="true"
                    [valueFormat]="{type:'fixedPoint',precision:2}"
                    displayFormat="{0}"
                    [alignByColumn]="true">
    </dxi-total-item>

    <dxi-total-item column="Tot_Total"
                    summaryType="sum"
                    [valueFormat]="{type:'fixedPoint',precision:2}"
                    displayFormat="{0}"
                    [showInGroupFooter]="true">
    </dxi-total-item>

    <dxi-total-item column="Tot_WitOutVat"
                    summaryType="sum"
                    [valueFormat]="{type:'fixedPoint',precision:2}"
                    displayFormat="{0}"
                    [showInGroupFooter]="true">
    </dxi-total-item>
    <dxi-total-item column="Tot_Cost"
                    summaryType="sum"
                    [valueFormat]="{type:'fixedPoint',precision:2}"
                    [showInGroupFooter]="true"
                    displayFormat="{0}"
                    [alignByColumn]="true">
    </dxi-total-item>
    <dxi-total-item column="Profit"
                    summaryType="sum"
                    [showInGroupFooter]="true"
                    [valueFormat]="{type:'fixedPoint',precision:2}"
                    displayFormat="{0}"
                    [alignByColumn]="true">
    </dxi-total-item>

    <dxi-total-item column="DProfit"
                    summaryType="avg"
                    [showInGroupFooter]="true"
                    displayFormat="{0}"
                    [valueFormat]="{type:'fixedPoint',precision:2}"
                    [alignByColumn]="true">

    </dxi-total-item>

    <dxi-total-item column="AProfit"
                    summaryType="avg"
                    [showInGroupFooter]="true"
                    [valueFormat]="{type:'fixedPoint',precision:2}"
                    displayFormat="{0}"
                    [alignByColumn]="true">

    </dxi-total-item>

  </dxo-summary>

  <dxi-sort-by-group-summary-info summaryItem="count"></dxi-sort-by-group-summary-info>

  <dxo-toolbar>

  </dxo-toolbar>

  <dxo-export [enabled]="true"
              [formats]="['pdf','excel']"
              [allowExportSelectedData]="true"></dxo-export>

</dx-data-grid>

<!--

<ng-container *ngIf="salesReportByGroup && salesReportByGroup.length>0">
  <br />
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" id="contentToxlsx">
    <ng-container matColumnDef="CODE">
      <th mat-header-cell *matHeaderCellDef>קוד</th>
      <td mat-cell *matCellDef="let element"> {{element.CODE}} </td>
    </ng-container>

    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef>תאור</th>
      <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
    </ng-container>

    <ng-container matColumnDef="Tot_Qty">
      <th mat-header-cell *matHeaderCellDef>כמות</th>
      <td mat-cell *matCellDef="let element"> {{element.Tot_Qty | number:'1.0-2'}} </td>
    </ng-container>


    <ng-container matColumnDef="Tot_Total">
      <th mat-header-cell *matHeaderCellDef>סה"כ מכירה </th>
      <td mat-cell *matCellDef="let element"> {{element.Tot_Total | number:'1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="Tot_WitOutVat">
      <th mat-header-cell *matHeaderCellDef>סה"כ מכירה ללא מע"מ</th>
      <td mat-cell *matCellDef="let element"> {{element.Tot_WitOutVat | number:'1.2-2'}} </td>
    </ng-container>



    <ng-container matColumnDef="Total_Percentage">
      <th mat-header-cell *matHeaderCellDef>% מכספים</th>
      <td mat-cell *matCellDef="let element"> {{element.Total_Percentage | number:'1.2-2'}}<ng-container *ngIf="element.Total_Percentage">%</ng-container> </td>
    </ng-container>

    <ng-container matColumnDef="Qty_Percentage">
      <th mat-header-cell *matHeaderCellDef>% מכמויות</th>
      <td mat-cell *matCellDef="let element"> {{element.Qty_Percentage | number:'1.2-2'}}<ng-container *ngIf="element.Qty_Percentage">%</ng-container> </td>
    </ng-container>


    <ng-container matColumnDef="Tot_Cost">
      <th mat-header-cell *matHeaderCellDef>עלות</th>
      <td mat-cell *matCellDef="let element"> {{element.Tot_Cost | number:'1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="Profit">
      <th mat-header-cell *matHeaderCellDef>רווח</th>
      <td mat-cell *matCellDef="let element"> {{element.Profit | number:'1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="DProfit">
      <th mat-header-cell *matHeaderCellDef>רווח מלמטה</th>
      <td mat-cell *matCellDef="let element"> {{element.DProfit | number:'1.2-2'}}% </td>
    </ng-container>

    <ng-container matColumnDef="AProfit">
      <th mat-header-cell *matHeaderCellDef>רווח מלמעלה</th>
      <td mat-cell *matCellDef="let element"> {{element.AProfit | number:'1.2-2'}}% </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


   
<tr class="mat-row" *matNoDataRow>
  <td class="mat-cell" colspan="4">{{input && input.value}}"</td>
</tr>
  </table>

</ng-container>
 -->
