import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ItemSup } from './item-sup';
import { ItemsManagmentService } from '../items-managment/items-managment.service';
import { Item } from '../items-managment/Item';
import { Shop } from '../Shop';

@Component({
  selector: 'app-item-sup',
  templateUrl: './item-sup.component.html',
  styleUrls: ['./item-sup.component.css']
})
export class ItemSupComponent implements OnInit {

  @Input('item') item: Item = null;
  @Input('shop') shop: Shop = null;
  @Input('supplierRows') supplierRows: any[] = null;

  public displayedColumns: string[] = ['DT', 'IT_DISC', 'DISCT_P', 'COST', 'QTY', 'BONUS', 'SUPPLIER'];
  public dataSource = new MatTableDataSource([]);
/*
  TABLEID: number;
  SHOP_ID: number;
  IT_CODE: string; //15
  DT
  IT_DISC: number;
  DISCT_P: number;
  COST: number;
  QTY: number;
  BONUS: number;
  SUPPLIER: number;
  DELETE_INDEX: number;
  */
  error: any;

  constructor(public _ItemsManagmentService: ItemsManagmentService) {

  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    console.log(this.supplierRows)
    this._ItemsManagmentService.getItemsSupList(this.item, this.shop)
      .subscribe(
        (data: ItemSup[]) => {
          this.dataSource = new MatTableDataSource(data);
        }, // success path/
        error => this.error = error // error path
      );
  }

    
  }



