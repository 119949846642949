<h4 mat-dialog-title>עדכון מחירים זריז</h4>
<mat-dialog-content class="mat-typography">
  <br />
  <mat-label style="font-weight:bold; color: black; width: 7.850rem !important; display: inline-block; font-size: 1rem; position: relative; transform: translateY(-50%);">קוד פריט</mat-label>

  <mat-form-field style="width: 10rem;" subscriptSizing="dynamic" class="edit-item mediumNumericField">
    <input style="position:absolute; background-color: yellow; font-size: 1.1rem; width:10rem; height: fit-content; margin-top: -0.7rem " autocomplete="off" matInput [(ngModel)]="selectedItem.IT_CODE" placeholder="קוד פריט" #input readonly>
  </mat-form-field>

  <br />

  <mat-label style=" font-weight: bold; color: black; width: 7.850rem !important; display: inline-block; font-size: 1rem; position: relative; transform: translateY(-50%);">תאור פריט </mat-label>
  <mat-form-field style="width:25rem;" subscriptSizing="dynamic" class="edit-item longTextField">
    <input style="position:absolute; background-color: yellow; font-size: 1.1rem; width:25rem; height:fit-content; margin-top: -0.7rem " autocomplete="off" matInput [(ngModel)]="selectedItem.IT_DESC" placeholder="תאור פריט" #input readonly>
  </mat-form-field>
  <br />

  <mat-label style=" font-weight: bold; color: black; width: 7.850rem !important; display: inline-block; font-size: 1rem; position: relative; transform: translateY(-50%)">מחיר 1 </mat-label>
  <mat-form-field style="width:5rem;" subscriptSizing="dynamic" class="edit-item smallNumericField">
    <input style="position:absolute; background-color: yellow; font-size: 1.1rem; width:5rem; height: fit-content; margin-right: -1rem; margin-top: -0.7rem " autocomplete="off" matInput [(ngModel)]="selectedItem.PRICE1" placeholder="מחיר 1" #input (keyup)="priceChange($event.target.value)">
  </mat-form-field>
  <br />

  <mat-label style=" font-weight: bold; color: black; width: 7.850rem !important; display: inline-block; font-size: 1rem; position: relative; transform: translateY(-50%)">עלות </mat-label>
  <mat-form-field style="width:5rem;" subscriptSizing="dynamic" class="edit-item smallNumericField">
    <input style="position:absolute; background-color: yellow; font-size: 1.1rem; width:10rem; height: fit-content; margin-right: -1rem; margin-top: -0.7rem " autocomplete="off" matInput [(ngModel)]="costWithoutVat" placeholder="עלות" #input (keyup)="costWithoutVatChange($event.target.value)">
  </mat-form-field>
  <br />

  <mat-label style=" font-weight: bold; color: black; width: 7.850rem !important; display: inline-block; font-size: 1rem; position: relative; transform: translateY(-50%)">עלות כוללת מע"מ </mat-label>
  <mat-form-field style="width:5rem;" subscriptSizing="dynamic" class="edit-item smallNumericField">
    <input style="position:absolute; background-color: yellow; font-size: 1.1rem; width:10rem; height: fit-content; margin-right: -1rem; margin-top: -0.7rem " autocomplete="off" matInput [(ngModel)]="costWithVat" placeholder="עלות כולל מעמ" #input (keyup)="costWithVatChange($event.target.value)">
  </mat-form-field>
  <br />

  <mat-label style=" font-weight: bold; color: black; width: 7.850rem !important; display: inline-block; font-size: 1rem; position: relative; transform: translateY(-50%)">%הנחת ספק </mat-label>
  <mat-form-field style="width:5rem; " subscriptSizing="dynamic" class="edit-item smallNumericField">
    <input style="position:absolute; background-color: yellow; font-size: 1.1rem; width:10rem; height: fit-content; margin-right: -1rem; margin-top: -0.7rem " autocomplete="off" matInput [(ngModel)]="selectedItem.COSTPERC" placeholder="הנחת ספק" #input (keyup)="COSTPERCChange($event.target.value)">
  </mat-form-field>
  <br />

  <mat-label style=" font-weight: bold; color: black; width: 7.850rem !important; display: inline-block; font-size: 1rem; position: relative; transform: translateY(-50%)">%רווח מלמטה </mat-label>
  <mat-form-field style="width:5rem;" subscriptSizing="dynamic" class="edit-item smallNumericField">
    <input style="position:absolute; background-color: yellow; font-size: 1.1rem; width:10rem; height: fit-content; margin-right: -1rem; margin-top: -0.7rem " autocomplete="off" matInput [(ngModel)]="buttomProfit" placeholder="" #input (keyup)="buttomProfitChange($event.target.value)">
  </mat-form-field>
  <br />

  <mat-label style=" font-weight: bold; color: black; width: 7.850rem !important; display: inline-block; font-size: 1rem; position: relative; transform: translateY(-50%)">%רווח מלמעלה</mat-label>
  <mat-form-field style="width:5rem;" subscriptSizing="dynamic" class="edit-item smallNumericField">
    <input style="position:absolute; background-color: yellow; font-size: 1.1rem; width:10rem; height: fit-content; margin-right: -1rem; margin-top: -0.7rem " autocomplete="off" matInput [(ngModel)]="topProfit" placeholder="" #input (keyup)="topProfitChange($event.target.value)">
  </mat-form-field>
  <br />

  <mat-label style=" font-weight: bold; color: black; width: 7.850rem !important; display: inline-block; font-size: 1rem; position: relative; transform: translateY(-50%)">ללא מע"מ</mat-label>
  <mat-form-field style="width:3rem;" subscriptSizing="dynamic" appearance="fill" class="edit-item yesNoDropdown">
    <mat-select style="background-color: yellow; font-size: 1.1rem; width:3rem; height: fit-content; padding-right:-1rem " [(ngModel)]="selectedItem.NOVAT" (selectionChange)="NOVATChange()">
      <mat-option value="T">כן</mat-option>
      <mat-option value="F">לא</mat-option>
    </mat-select>
  </mat-form-field>
  <br />
  <br />
  <mat-dialog-actions style="float:left">
    <button mat-button mat-dialog-close mat-raised-button color="basic">ביטול</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial mat-raised-button color="primary">שמור</button>
  </mat-dialog-actions>
</mat-dialog-content>






