<div class="flex-row filter-row-container">
  <div class="form-floating customized-select flex-item" id="ShopSelect">
    <select class="form-select" id="floatingSelect" #code (change)="selectionChangeShop(code.value)">
      <option *ngFor="let shop of shops" [value]="shop.SHOP_ID">{{ shop.SHOP_NAME }}</option>
    </select>
    <label for="floatingSelect" class="label-color">בחר חנות</label>
  </div>

  <div class="form-floating customized-input flex-item">
    <input type="text" class="form-control" id="floatingInput"
           name="filterShops"
           placeholder="חיפוש" autocomplete="off"
    (keyup)="applyFilter($event)" #input>
    <label for="floatingInput" class="label-color">חיפוש</label>
  </div>

  <div class="inputDropDown">

    <button mat-raised-button color="primary" (click)="newColor()">צבע חדש</button>
    <button mat-raised-button color="primary" (click)="save()">שמור</button>
  </div>
</div>


<div class="flex-row filter-row-container">

  <div class="form-floating customized-input flex-item">
    <input type="text" class="form-control" id="IT_CODE" autocomplete="off" placeholder="קוד צבע" [(ngModel)]="selectedColor.CODE" #input>
    <label for="IT_CODE" class="label-color">קוד צבע</label>
  </div>

  <div class="form-floating customized-input flex-item" id="DESC1">
    <input type="text" class="form-control" id="IT_CODE" autocomplete="off" placeholder="תאור צבע " [(ngModel)]="selectedColor.DESC1" #input>
    <label for="IT_CODE" class="label-color">תאור צבע </label>
  </div>

</div>


<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 clearBoth">
  <ng-container matColumnDef="CODE">
    <th mat-header-cell *matHeaderCellDef class="small"> קוד צבע  </th>
    <td mat-cell *matCellDef="let element"> {{element.CODE}} </td>
  </ng-container>
  <ng-container matColumnDef="DESC1">
    <th mat-header-cell *matHeaderCellDef> תאור צבע </th>
    <td mat-cell *matCellDef="let element"> {{element.DESC1}} </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectRow(row)"></tr>
</table>

<mat-paginator [length]="colorRowsFilterd.length"
               [pageSize]="10">
</mat-paginator>
