import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MyShopsService } from '../my-shops.service';
import { Shop } from '../../Shop';

@Component({
  selector: 'my-shops-single-selection',
  templateUrl: './my-shops-single-selection.component.html',
  styleUrls: ['./my-shops-single-selection.component.css']
})
export class MyShopsSingleSelectionComponent implements OnInit {

  @Output() shopChanged = new EventEmitter<Shop>();

  public shops: Shop[] = [];
  public selectedShop: Shop;

  error: any;

  constructor(public _myShopsService: MyShopsService) { }

  ngOnInit(): void {
    this.getShops();
  }

  getShops() {
    this._myShopsService.getMyShops()
      .subscribe(
        (data: Shop[]) => {
          this.shops = data;
          this.onShopsChange(this.shops[0].SHOP_ID);
        }, // success path/
        error => this.error = error // error path
      );
  }
  
  public onShopsChange(code: number) {
    this.selectedShop = this.shops.find(({ SHOP_ID }) => SHOP_ID == code);
    this.shopChanged.emit(this.selectedShop);
  }

}
