import { Component, OnInit, ViewChild, OnDestroy, ViewEncapsulation, HostListener } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Shop } from '../Shop';
import { ItemsManagmentService } from '../items-managment/items-managment.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { interval } from 'rxjs/internal/observable/interval';
import { Subscription } from 'rxjs';
import { SpecialSalesRow } from '../items-managment/specialSalesRow';
import { MatDialog } from '@angular/material/dialog';
import { specialSalesBarcodeManagmentComponent } from '../specialSalesBarcode-managment/specialSalesBarcode-managment.component';
import { Item } from '../items-managment/Item';
import { SpecialSalesManagmentService } from './specialSales-managment.service';
import { specialSalesBarcodeRow } from '../items-managment/specialSalesBarcodeRow';
import { specialSalesHeaderComponent } from '../specialSalesHeader/specialSalesHeader.component';
import { specialSalesCompareComponent } from '../specialSalesCompare/specialSalesCompare.component';
import { ItemSearchComponent } from '../inventory/item-search/item-search.component';
import { supplierRow } from '../items-managment/supplierRow';
import { itSortRow } from '../items-managment/itSortRow';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { CustomAdapterService } from '../services/custom-adapter.service';
import { UPDATE } from '@ngrx/store';
import { exit } from 'process';

@Component({
  selector: 'specialSales-managment',
  templateUrl: './specialSales-managment.component.html',
  styleUrls: ['./specialSales-managment.component.css'],
  host: { '(window:resize)': 'onResize($event)' },
//  encapsulation: ViewEncapsulation.Emulated,
})


export class SpecialSalesManagmentComponent implements OnInit, OnDestroy {

  public shops: Shop[] = [];
  public specialSalesRows: SpecialSalesRow[][] = [];
  public specialSalesBarcodeRows: specialSalesBarcodeRow[][] = [];
  public itemRows: Item[][] = [];
  public itSortRows: itSortRow[][] = [];
  public supplierRows: supplierRow[][] = [];

  public lastUpdated: Date;
  public specialSalesRowsFilterd: SpecialSalesRow[] = [];
  public specialSalesBarcodeRowsFilterd: specialSalesBarcodeRow[] = [];
  public selectedShop: Shop = null;
  public specialSalesRow: SpecialSalesRow;


  public shopIndex: number = 0;
  public filterValue = "";
  public selectedSpecialSales: SpecialSalesRow;
  public selectedSpecialSalesBarcode: specialSalesBarcodeRow;
  public selectedSpecialSalesBarcode1: specialSalesBarcodeRow;
  SpecialSalesBarcodeRow: specialSalesBarcodeRow;
  public selectedItem: Item;
  public getScreenWidth: any;
  public getScreenHeight: any;


  error: any;
  rowClicked: number;
  rowBarcodeClicked: number;
  selectedIdx: number = 0;
  isColumnsMobile: boolean;
  lwidth: string;
  lheight: string;
  ltop: string;
  isLoading = false;
  lastSpecialSalesCode: string = null;
  initialDisplayedColumns: string[] =
    [
      'CODE',
      'NAME',
      'QTY',
      'PRICE',
      'AMOUNT',
      'IFCONTINUE',
      'MAXQTY',
      'ENDDATE',
      'REMARKS',
      'QTY2',
      'PRICE2',
      'QTY3',
      'PRICE3',
      'QTY4',
      'PRICE4',
      'QTY5',
      'PRICE5',
      'QTY6',
      'PRICE6',
      'QTY7',
      'PRICE7',
      'UPDDATE',
    ];

  
  displayedColumns: string[] =
    [
      'CODE',
      'NAME',
      'QTY',
      'PRICE',
      'AMOUNT',
      'IFCONTINUE',
      'MAXQTY',
      'ENDDATE',
      'REMARKS',
      'QTY2',
      'PRICE2',
      'QTY3',
      'PRICE3',
      'QTY4',
      'PRICE4',
      'QTY5',
      'PRICE5',
      'QTY6',
      'PRICE6',
      'QTY7',
      'PRICE7',
      'UPDDATE',
    ];

  displayedColumnForMobilesPhones: string[] =
    [
      'CODE',
      'NAME',
      'QTY',
      'PRICE',
    ];

  displayedColumnForDesk1024:string[] =
    [
      'CODE',
      'NAME',
      'QTY',
      'PRICE',
      'AMOUNT',
      'MAXQTY',
      'ENDDATE',
      'UPDDATE',
      'REMARKS',
    ];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumnsBarcode: string[] =
    [
      'ITEMCODE',
      'IT_DESC',
      'PRICE',
//      'removeitem'
    ];


  /*
  public onResize(event) {
    event.target.innerWidth; // window width
    if (event.target.innerWidth <= 1024 && !this.isColumnsMobile) {

      this.displayedColumns = this.displayedColumnForMobilesPhones;
      this.isColumnsMobile = true;

    } else if (event.target.innerWidth >= 600 && this.isColumnsMobile) {

      this.displayedColumns = this.initialDisplayedColumns;
      this.isColumnsMobile = false;

    }

  }

*/

  
  public newspecialSalesBarcode(): void {
    this.selectedSpecialSalesBarcode = {
      SHOP_ID: this.selectedShop.SHOP_ID,
      TABLEID: 0,
      CODE: null,
      ITEMCODE: null,
      IT_DESC: null,
      PRICE: null,
//      UPDDATE:null,
    }

    //this.openDialog();
  }



  public newSpecialSales(): void {
    this.selectedSpecialSales = {
      SHOP_ID: this.selectedShop.SHOP_ID,
      TABLEID: 0,
      CODE: null,
      AMOUNT: null,
      IFCONTINUE : null,
      ENDDATE : null,
      ENDDATESTR: null,
      MAXQTY: null,
      PRICE: null,
      PRICE2: null,
      PRICE3: null,
      PRICE4: null,
      PRICE5: null,
      PRICE6: null,
      PRICE7: null,
      NAME: null,
      REMARKS: null,
      QTY: null,
      QTY2: null, 
      QTY3: null, 
      QTY4: null, 
      QTY5: null, 
      QTY6: null, 
      QTY7: null,
      UPDDATE:null,

    }
  }

  constructor( private _ItemsManagmentService: ItemsManagmentService, private dateAdapter: CustomAdapterService, public dialog: MatDialog, public _SpecialSalesManagmenService: SpecialSalesManagmentService) {}

  ngOnInit(): void {
//daniel    this.getItems(null, true);
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth <= 400 && !this.isColumnsMobile) {

      this.displayedColumns = this.displayedColumnForMobilesPhones;
      this.isColumnsMobile = true;

    } 
    else
    {
      if (this.getScreenWidth <= 1024 && !this.isColumnsMobile) 
      {
        this.displayedColumns = this.displayedColumnForDesk1024;
        this.isColumnsMobile = true;
      }
      else
      {
        if (this.getScreenWidth <= 1555 && !this.isColumnsMobile) 
        {
          this.displayedColumns = this.displayedColumnForDesk1024; 
          this.isColumnsMobile = false;
        }
        if (this.getScreenWidth > 1555 && !this.isColumnsMobile) {
          this.displayedColumns = this.initialDisplayedColumns;
          this.isColumnsMobile = false;
        }

      }
    }

    this.getItems(this.selectedShop);
    
//daniel    this.dataSource.paginator = this.paginator;
  }

  public dataSource = new MatTableDataSource<SpecialSalesRow>(this.specialSalesRowsFilterd);
  public dataSourceBarcode = new MatTableDataSource<specialSalesBarcodeRow>(this.specialSalesBarcodeRowsFilterd);

  filterItems() {
    this.specialSalesRowsFilterd = [];
    this.shopIndex = this.shops.findIndex(x => x.SHOP_ID === this.selectedShop.SHOP_ID);
//    this.specialSalesRowsFilterd = this.specialSalesRows[this.shopIndex].filter(x => x);
    this.specialSalesRowsFilterd = this.specialSalesRows[0];
    this.specialSalesRowsFilterd.sort(function (a, b) {
      if (a.CODE < b.CODE) { return -1; }
      if (a.CODE > b.CODE) { return 1; }
      return 0;
    })

    this.lastSpecialSalesCode = this.specialSalesRowsFilterd[ this.specialSalesRowsFilterd.length - 1 ]?.CODE;
    this.dataSource = new MatTableDataSource<SpecialSalesRow>(this.specialSalesRowsFilterd);
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
//    this.filterValueBarcode = (event.target as HTMLInputElement).value;
//    this.dataSourceBarcode.filter = this.filterValueBarcode.trim().toLowerCase();
  }

  filterBarcode() {
    this.specialSalesBarcodeRowsFilterd = [];
    this.shopIndex = this.shops.findIndex(x => x.SHOP_ID === this.selectedShop.SHOP_ID);
    if (this.specialSalesRow != null) {
      this.specialSalesBarcodeRowsFilterd = this.specialSalesBarcodeRows[0].filter(x => x.CODE == this.specialSalesRow.CODE);
      //    this.specialSalesBarcodeRowsFilterd = this.specialSalesBarcodeRows[this.shopIndex].filter(x => x.CODE == this.selectedSpecialSales.CODE);
      this.specialSalesBarcodeRowsFilterd.sort(function (a, b) {
        if (a.CODE < b.CODE) { return -1; }
        if (a.CODE > b.CODE) { return 1; }
        return 0;
      })
      //
      this.dataSourceBarcode = new MatTableDataSource<specialSalesBarcodeRow>(this.specialSalesBarcodeRowsFilterd);
      this.dataSource.filter = this.filterValue.trim().toLowerCase();
      this.dataSource.paginator = this.paginator;
    }
  }

  getItems(Shop: Shop) {
    this.isLoading = true;
    this._SpecialSalesManagmenService.getspecialsale(Shop)
      .subscribe(
        (data: any) => {
          this.itSortRows = data.itSortRows;
          this.supplierRows = data.supplierRows;
          this.specialSalesRows = data.specialSalesRows;
          this.specialSalesBarcodeRows = data.specialSalesBarcodeRows;
          this.itemRows = data.itemsRows[0];
          if (this.shops.length == 0) this.shops = data.shops[0]; // for the first time
          if (Shop == null) this.selectedShop = this.shops[0];
          else              this.selectedShop = Shop;

          this.specialSalesRow = null;
          this.rowClicked = -1;
          this.filterItems();
          this.isLoading = false;
//          this.rowClicked = -1;
//          this.changeTableRowColor(this.selectedIdx, this.specialSalesRow[this.selectedIdx]);
          return;
        }, // success path/
        error => { this.isLoading = false; this.error = error } // error path
    );
  }

  Barcodesubscription: Subscription;

  startBarcodeDelayedFilter(): void {
    const source = interval(1000);
    this.Barcodesubscription = source.subscribe(val => {
      if (this.specialSalesBarcodeRowsFilterd.length === 0) {
        this.filterBarcode()
        this.Barcodesubscription.unsubscribe();
      }
      return 1;
    }
    );
  }
  changeTableRowColor(idx: any, row) {

    this.selectedSpecialSales = row;
    this.rowClicked = this.rowClicked === idx ? -1 : idx;

    //    SpecialSalesManagmentService.getBarcodes(null);
    this.rowBarcodeClicked = -1;
    this.selectedIdx = idx;
    this.specialSalesRow = row;
    this.filterBarcode();
  }

  changeBarcodeTableRowColor(idx1, row1) {

    if (this.rowBarcodeClicked === idx1) this.rowBarcodeClicked = -1;
    else this.rowBarcodeClicked = idx1;
    this.selectedSpecialSalesBarcode = row1;
  }

  subscription: Subscription;

  ngOnDestroy() {
    //this.subscription.unsubscribe();
  }

 

  openDialogHeader(isNew: boolean) {

    if (isNew) {
      this.specialSalesRow = new SpecialSalesRow(this.lastSpecialSalesCode && (+this.lastSpecialSalesCode + 1).toString());
      this.selectedSpecialSales = this.specialSalesRow;
      this.rowClicked = -1;
    }

    const dialogRef = this.dialog.open(
      specialSalesHeaderComponent,
      {
        data: {
          specialSalesRow: this.specialSalesRow
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.save(result);
      }
    });
  }

  openDialogCompare() {
    this.lwidth = '95%';

    this.lheight = '50%';
    this.ltop = '6.5rem';


    const dialogRef = this.dialog.open(
      specialSalesCompareComponent,
      {
        disableClose: true,
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: this.lheight,
        width: this.lwidth,        
        data: {
          specialSalesRow: this.specialSalesRow
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
//      if (result) {
//        this.save(result);
//      }
    });
  }

  startDelayedFilter(): void {
    const source = interval(1000);
    this.subscription = source.subscribe(val => {
      if (this.specialSalesRowsFilterd.length === 0) {
        this.filterItems()
        this.subscription.unsubscribe();
      }
      return 1;
    }
    );
  }

  selectionChangeShop(selectedshop: Shop) {
    //this.selectedSpecialSales.SHOP_ID = this.selectedShop.SHOP_ID;
    this.selectedShop = selectedshop;
    this.shopIndex = this.shops.findIndex(x => x.SHOP_ID === this.selectedShop.SHOP_ID);
    //    this.shopIndex = this.shops.findIndex(x => x.SHOP_ID === this.selectedSpecialSales.SHOP_ID);
    this.getItems(selectedshop);
    

//    this.filterItems();
//    this.filterBarcode();
  }

  public selectRow(specialSalesRow: SpecialSalesRow) {
    this.selectedSpecialSales = specialSalesRow;
  }
  
  convertToDate(date: string) {
    if (date.indexOf('T') != -1) {
      date = date.split('T')[0];
    }

    let arr = date.split('-');
    return new Date(`${arr[2]}-${arr[1]}-${arr[0]}`);
  }

  save(row: SpecialSalesRow) {
    // TODO: Cleanup the mess

    row.SHOP_ID = this.selectedSpecialSales.SHOP_ID;
    this.selectedSpecialSales = row;
    // this.selectedSpecialSales.SHOP_ID = this.selectedShop.SHOP_ID;
    this.selectedSpecialSales.SHOP_ID = this.selectedSpecialSales.SHOP_ID;
    this.selectedSpecialSales.PRICE = this.selectedSpecialSales.PRICE ? parseFloat(this.selectedSpecialSales.PRICE.toString()) : null;
    this.selectedSpecialSales.PRICE2 = this.selectedSpecialSales.PRICE2 ? parseFloat(this.selectedSpecialSales.PRICE2.toString()) : null;
    this.selectedSpecialSales.PRICE3 = this.selectedSpecialSales.PRICE3 ? parseFloat(this.selectedSpecialSales.PRICE3.toString()) : null;
    this.selectedSpecialSales.PRICE4 = this.selectedSpecialSales.PRICE4 ? parseFloat(this.selectedSpecialSales.PRICE4.toString()) : null;
    this.selectedSpecialSales.PRICE5 = this.selectedSpecialSales.PRICE5 ? parseFloat(this.selectedSpecialSales.PRICE5.toString()) : null;
    this.selectedSpecialSales.PRICE6 = this.selectedSpecialSales.PRICE6 ? parseFloat(this.selectedSpecialSales.PRICE6.toString()) : null;
    this.selectedSpecialSales.PRICE7 = this.selectedSpecialSales.PRICE7 ? parseFloat(this.selectedSpecialSales.PRICE7.toString()) : null;
    this.selectedSpecialSales.AMOUNT = this.selectedSpecialSales.AMOUNT ? parseFloat(this.selectedSpecialSales.AMOUNT.toString()) : null;
    this.selectedSpecialSales.QTY = this.selectedSpecialSales.QTY ? parseInt(this.selectedSpecialSales.QTY.toString()) : null;
    this.selectedSpecialSales.QTY2 = this.selectedSpecialSales.QTY2 ? parseInt(this.selectedSpecialSales.QTY2.toString()) : null;
    this.selectedSpecialSales.QTY3 = this.selectedSpecialSales.QTY3 ? parseInt(this.selectedSpecialSales.QTY3.toString()) : null;
    this.selectedSpecialSales.QTY4 = this.selectedSpecialSales.QTY4 ? parseInt(this.selectedSpecialSales.QTY4.toString()) : null;
    this.selectedSpecialSales.QTY5 = this.selectedSpecialSales.QTY5 ? parseInt(this.selectedSpecialSales.QTY5.toString()) : null;
    this.selectedSpecialSales.QTY6 = this.selectedSpecialSales.QTY6 ? parseInt(this.selectedSpecialSales.QTY6.toString()) : null;
    this.selectedSpecialSales.QTY7 = this.selectedSpecialSales.QTY7 ? parseInt(this.selectedSpecialSales.QTY7.toString()) : null;
    this.selectedSpecialSales.MAXQTY = this.selectedSpecialSales.MAXQTY ? parseInt(this.selectedSpecialSales.MAXQTY.toString()) : null;

    this.selectedSpecialSales.ENDDATE = this.selectedSpecialSales.ENDDATESTR ? this.convertToDate(this.selectedSpecialSales.ENDDATESTR) : null;
    this.selectedSpecialSales.UPDDATE = new Date(); 

    this._ItemsManagmentService.postselectedSpecialSales(this.selectedSpecialSales, this.shopIndex)
      .subscribe({
        next: (data: number) => {},
        error: (error) => {
          this.error = error;
          this._ItemsManagmentService.openSnackBar('תקלה בשמירת מבצע');
        },
        complete: () => {
          this._ItemsManagmentService.openSnackBar('מבצע נשמר בהצלחה').afterDismissed().subscribe(() => {
            location.reload();
          });
        }
      });
  }

  public removeItemBarcode(specialSalesBarcodeRow: specialSalesBarcodeRow) {
   
    this.specialSalesBarcodeRowsFilterd = this.specialSalesBarcodeRowsFilterd.filter(x => x.ITEMCODE != specialSalesBarcodeRow.ITEMCODE);
//    this.shopIndex = this.shops.findIndex(x => x.SHOP_ID === this.selectedShop.SHOP_ID);

    this._ItemsManagmentService.postselectedSpecialSalesBarcode(specialSalesBarcodeRow.CODE, this.specialSalesBarcodeRowsFilterd, this.selectedShop.SHOP_ID, this.specialSalesRow)
      .subscribe(
        (data: any) => {
/*        this.specialSalesBarcodeRowsFilterd.forEach((element, index) => {
            if (element == specialSalesBarcodeRow) this.specialSalesBarcodeRowsFilterd.splice(index, 1);
          });
*/
          this.specialSalesBarcodeRows[0].forEach((element, index) => {
            if (element == specialSalesBarcodeRow) this.specialSalesBarcodeRows[0].splice(index, 1);
          });



//          this.specialSalesBarcodeRows[this.shopIndex]

          this.filterBarcode();
          this.dataSourceBarcode = new MatTableDataSource<specialSalesBarcodeRow>(this.specialSalesBarcodeRowsFilterd); 
          return;
        }, // success path/
        error => this.error = error // error path
    );

//    this.getItems(false);
  }



  deleteSpecialSales(selectedItem: SpecialSalesRow) {
    this._ItemsManagmentService.deleteSpecialSales(selectedItem)
      .subscribe(
        (data: number) => {
          if (data) //deleted
            location.reload();
        }, // success path/
        error => this.error = error // error path
      );
  }

  IT_CODEonFocusOutEvent(event: any) {
    this.selectedSpecialSales.CODE = event.target.value;
    let selectedSpecialSales = this.specialSalesRowsFilterd.filter(x => x.CODE == event.target.value);
    if (selectedSpecialSales.length === 1) {
      this.selectRow(selectedSpecialSales[0]);
      return;
    }

    let selectedItem = this.itemRows[this.shopIndex].filter(x => x.IT_CODE == event.target.value);
    this.newSpecialSales();
    this.selectedSpecialSales.CODE = event.target.value;
    if (selectedItem.length === 1)
      this.selectedSpecialSales.NAME = selectedItem[0].IT_DESC;
  }

  IT_CODEhandleKeyboardEvent(event) {
    this.selectedSpecialSales.CODE = event.target.value;
    if (event.keyCode === 13) {
      this.IT_CODEonFocusOutEvent(event);
    }
  }




  openDialogBarcode() {
    this.newspecialSalesBarcode();

    if (this.shopIndex === -1)
      this.shopIndex = this.shops.findIndex(x => x.SHOP_ID === this.selectedShop.ITEMS_FROM_SHOP_ID);

    const dialogRef = this.dialog.open(ItemSearchComponent
      , {
        disableClose: true,
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: this.lheight,
        width: this.lwidth,
        data: {
          itSortRows: this.itSortRows[this.shopIndex],
          supplierRows: this.supplierRows[this.shopIndex],
          selectedShop: this.selectedShop,
          itemsRows: this.itemRows,
          dialog: this.dialog,
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedSpecialSalesBarcode.ITEMCODE = result.IT_CODE;
        this.selectedSpecialSalesBarcode.IT_DESC = result.IT_DESC;
        this.selectedSpecialSalesBarcode.PRICE = result.PRICE1;
        this.selectedSpecialSalesBarcode.CODE = this.selectedSpecialSales.CODE;
        this.selectedSpecialSalesBarcode.SHOP_ID = this.selectedSpecialSales.SHOP_ID;

        this.saveBarcode(true);
      }
      else {
        /*this.selectedItem.PRICE1 = backupItem.PRICE1;
        this.selectedItem.COSTPERC = backupItem.COSTPERC;
        this.selectedItem.NOVAT = backupItem.NOVAT;
        this.selectedItem.STANDARTCOST = backupItem.STANDARTCOST;*/
      }
    });
  }

  saveBarcode(newItem = false) {

    let check = 1;
    for (let i = 0; i < this.specialSalesBarcodeRowsFilterd.length; i++) {
      this.specialSalesBarcodeRowsFilterd[i].PRICE = this.specialSalesBarcodeRowsFilterd[i].PRICE ? parseFloat(this.specialSalesBarcodeRowsFilterd[i].PRICE.toString()) : null;
      if (this.specialSalesBarcodeRowsFilterd[i].SHOP_ID == this.selectedSpecialSalesBarcode.SHOP_ID && this.specialSalesBarcodeRowsFilterd[i].ITEMCODE == this.selectedSpecialSalesBarcode.ITEMCODE) {
        alert('הפריט כבר קיים במבצע זה');
        check = 0;
        break;
      }
    }

    if (check == 1) {
      const isItemExsits = this.specialSalesBarcodeRows[0].findIndex(x => x.ITEMCODE === this.selectedSpecialSalesBarcode.ITEMCODE) > -1;

      if (newItem && !isItemExsits) {
        this.specialSalesBarcodeRowsFilterd.push(this.selectedSpecialSalesBarcode);
        this.dataSourceBarcode = new MatTableDataSource<specialSalesBarcodeRow>(this.specialSalesBarcodeRowsFilterd);
      }
      else {
        let index = this.specialSalesBarcodeRows[0].findIndex(x => x.ITEMCODE === this.selectedSpecialSalesBarcode.ITEMCODE);
        this.selectedSpecialSalesBarcode1 = this.specialSalesBarcodeRows[0][index];
        let index1 = this.specialSalesRows[0].findIndex(x => x.CODE === this.selectedSpecialSalesBarcode1.CODE);
        if (this.selectedSpecialSalesBarcode.CODE != this.specialSalesRows[0][index1].CODE) {
          alert("פריט כבר נמצא במבצע אחר" + " " + this.selectedSpecialSalesBarcode1.CODE + " " + this.specialSalesRows[0][index1].NAME);
          check = 0;
        }
      }

      if (check == 1)
        this._ItemsManagmentService.postselectedSpecialSalesBarcode(this.specialSalesRow.CODE, this.specialSalesBarcodeRowsFilterd, this.specialSalesRow.SHOP_ID, this.specialSalesRow)
        .subscribe(
          //        (data: number) => {
          (data: any) => {
            //          if (data) //added item
            //console.log("data1",data)          return;
            //window.location.reload()

            //          this.newspecialSalesBarcode();
            for (let i = 0; i < data.length; i++) {
              //            alert(data[i].ITEMCODE + " " + this.selectedSpecialSalesBarcode.ITEMCODE);
              if (data[i].ITEMCODE == this.selectedSpecialSalesBarcode.ITEMCODE)
                this.specialSalesBarcodeRows[0].push(this.selectedSpecialSalesBarcode);

            }


            //          this.specialSalesBarcodeRows.push(data);
            this.filterBarcode();


            this.dataSourceBarcode = new MatTableDataSource<specialSalesBarcodeRow>(this.specialSalesBarcodeRowsFilterd);

            //          this.filterItems();
            return;
          }, // success path/
          error => this.error = error // error path
        );
    }
  }
}


