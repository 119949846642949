import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Item } from '../../items-managment/Item';

import { MatTableDataSource } from '@angular/material/table';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs/internal/Subscription';
import { interval, Observable, of } from 'rxjs';
import { itSortRow } from '../../items-managment/itSortRow';
import { supplierRow } from '../../items-managment/supplierRow';
import { Ent_Head, Ent_Item } from '../ent-item';
import { ImageLike, Rectangle } from 'tesseract.js';
import Tesseract from 'tesseract.js';

//import { pdf2image } from 'pdf2image';
//import { createWorker } from "tesseract.js";

//import  TesseractWorker from 'tesseract.js';
//import { Tesseract } from 'tesseract.js';
import { ImageCroppedEvent } from "ngx-image-cropper";
//import * as pdfjsLib from 'pdfjs-dist';
//import { NgxTesseractService } from 'ngx-tesseract';
// import { createWorker } from 'tesseract.js';
// declare var Tesseract;


@Component({
  selector: 'EntryOcr',
  templateUrl: './EntryOcr.component.html',
  styleUrls: ['./EntryOcr.component.css']
})
export class EntryOcrComponent {

//  worker: Tesseract.Worker = createWorker();
//  createWorker  = Tesseract;
//  worker = Tesseract.createWorker();
  isReady: boolean;
  imageChangedEvent: any;
  base64Image: any;
  ocrResult: any;
  croppedImage: any = "";
  isScanning: boolean;
  extractedText: string = '';

  constructor() {
//    this.initialize();
  }
  async initialize(): Promise<void> {
//    await (await this.worker).load();
//    await (await this.worker).loadLanguage("heb");
//    await (await this.worker).initialize("heb");
    this.isReady = true;
  }


 



  async handleFileInput(event: any) {
    const file = event.target.files[0];

    const worker = await Tesseract.createWorker({
      logger: m => console.log(m)});

//    const worker = Tesseract.createWorker();

    (async () => {
      await worker.load();
      await worker.loadLanguage('heb'); // Load English language
      await worker.initialize('heb');

      const { data: { text } } = await worker.recognize(file);
//      const { data: { text, pdf } } = await worker.recognize(file);
      this.extractedText = text;
//      alert(text);

      await worker.terminate();
    })();


  }



  handleFileInput1(event): void {
    //  console.log(event);

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      this.imageChangedEvent = event;

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
        this.base64Image = event.target.result;
//        event.target.result = null;
      };
//      Tesseract.recognize('c:/BANK/IMG_0085.png').then(function (result) {
//        alert(result);
//      });
    }
    
  }

  scanOCR() {
    this.isScanning = true;
    this.imageChangedEvent = null;
//    this.doOCR(this.croppedImage);
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent): void {
    console.log(event);
    //this.doOCR(event.base64);
    this.croppedImage = event.base64;
    this.base64Image = event.base64;
  }

 

  async doOCR1(base64Image: string) {
    this.ocrResult = "Scanning";
    console.log(`Started: ${new Date()}`);
    if (this.isReady) {
//      const data = await (await this.worker).recognize(base64Image);
//      console.log(data);
//      this.ocrResult = data.data.text;
      alert(this.ocrResult);
    }
    // await this.worker.terminate();
    console.log(`Stopped: ${new Date()}`);
    this.isScanning = false;
  }

  transform(): string {
    return this.base64Image;
  }

  test() {
//    Tesseract.recognize(base64Image).then(function (result) {
//      alert(result);
//    });
  }
}

 


