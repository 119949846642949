export class SaveItem {
  TABLEID: number;
  SHOP_ID: number;
  IT_CODE: string;
  IT_DESC: string;
  BARCODE: string;
  ITSORT: number;
  CUT: number;
  PRICE1: number;
  PRICE2: number;
  PRICE3: number;
  PRICE4: number;
  PRICE5: number;
  PRICE6: number;
  PRICE7: number;
  PRICE8: number;
  PRICE9: number;
  DISCOUNT: number;
  STANDARTCOST: number;
  LASTCOST: number;
  MINIQTY: number;
  MAXQTY: number;
  SUPPLIER: number;
  MIDA: number;
  COLOR: number;
  IT_SALE: string;
  IT_CHPRICE: string;
  SCALE: string;
  NOVAT: string;
  IF_DISCOUNT: string;
  SALEREMARK: string;
  IT_S_CODE: string;
  UPDDATE: Date;
  FATHER: string;
}

