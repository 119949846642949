import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Shop } from '../Shop';
import { ItemsManagmentService } from '../items-managment/items-managment.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { midaRow } from '../items-managment/midaRow';
import { interval } from 'rxjs/internal/observable/interval';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mida-managment',
  templateUrl: './mida-managment.component.html',
  styleUrls: ['./mida-managment.component.css']
})
export class MidaManagmentComponent implements OnInit, OnDestroy {

  public shops: Shop[] = [];
  public midaRows: midaRow[][] = [];
  public lastUpdated: Date;
  public midaRowsFilterd: midaRow[] = [];
  public selectedShop: Shop = null;


  public shopIndex = 0;
  public filterValue = "";
  public selectedMida: midaRow;
  error: any;

  displayedColumns: string[] =
    [
      'CODE',
      'DESC1',
    ];

  @ViewChild(MatPaginator) paginator: MatPaginator;



  public newMida(): void {
    this.selectedMida = {
      SHOP_ID: this.selectedShop.SHOP_ID,
      TABLEID: 0,
      CODE: null,
      DESC1: null,
    }
  }

  constructor(public _ItemsManagmentService: ItemsManagmentService) {
  }

  ngOnInit(): void {
    this.getItems(null);
    this.dataSource.paginator = this.paginator;
  }

  public dataSource = new MatTableDataSource<midaRow>(this.midaRowsFilterd);

  filterItems() {
    this.midaRowsFilterd = this.midaRows[this.shopIndex].filter(x => x);
    this.midaRowsFilterd.sort(function (a, b) {
      return a.CODE - b.CODE
    })


    this.dataSource = new MatTableDataSource<midaRow>(this.midaRowsFilterd);
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
  }

  getItems(lastUpdated: Date) {
    this._ItemsManagmentService.getItems(lastUpdated)
      .subscribe(
        (data: any) => {
          this.shops = data.shops;
          this.midaRows = data.midaRows;
          this.selectedShop = this.shops[0];
          this.filterItems()
          this.dataSource = new MatTableDataSource<midaRow>(this.midaRowsFilterd)
          this.dataSource.paginator = this.paginator;
          this.newMida();
          return;
        }, // success path/
        error => this.error = error // error path
      );
  }

  subscription: Subscription;

  ngOnDestroy() {
    //this.subscription.unsubscribe();
  }


  startDelayedFilter(): void {
    const source = interval(1000);
    this.subscription = source.subscribe(val => {
      if (this.midaRowsFilterd.length === 0) {
        this.filterItems()
        this.subscription.unsubscribe();
      }
      return 1;
    }
    );
  }

  selectionChangeShop(selectedshop: Shop) {
    this.selectedShop = selectedshop;
    this.shopIndex = this.shops.findIndex(x => x.SHOP_ID === this.selectedShop.SHOP_ID)
    this.filterItems();
  }

  public selectRow(midaRow: midaRow) {
    this.selectedMida = midaRow;
  }


  save() {
    this.selectedMida.CODE = this.selectedMida.CODE ? parseInt(this.selectedMida.CODE.toString()) : null;

    this._ItemsManagmentService.postselectedMida(this.selectedMida, this.shopIndex)
      .subscribe(
        (data: number) => {
          if (data) //added item
            this.selectedMida.TABLEID = data;
           // console.log("this.selectedMida push")
           //this.midaRows[this.shopIndex].push(this.selectedMida);
          this.newMida();
          this.filterItems();
        }, // success path/
        error => this.error = error // error path
      );
  }

}
