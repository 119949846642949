export class Item {
  TABLEID: number;
  SHOP_ID: number;
  IT_CODE: string;
  IT_DESC: string;
  BARCODE: string;
  ITSORT: number;
  CUT: number;
  PRICE1: number;
  PRICE2: number;
  PRICE3: number;
  PRICE4: number;
  PRICE5: number;
  PRICE6: number;
  PRICE7: number;
  PRICE8: number;
  PRICE9: number;
  DISCOUNT: number;
  STANDARTCOST: number;
  LASTCOST: number;
  MAXQTY: number;
  MINIQTY: number;
  SUPPLIER: number;
  
  IT_SALE_BOOL: boolean;
  IT_SALE: string;

  IT_CHPRICE_BOOL: boolean;
  IT_CHPRICE: string;

  SCALE_BOOL: boolean;
  SCALE: string;

  NOVAT_BOOL: boolean;
  NOVAT: string;

  IF_DISCOUNT_BOOL: boolean;
  IF_DISCOUNT: string;

  OTHERS: string;
  SALEREMARK: string;
  SOLDDATE: Date;
  UPDDATE: Date;
  FATHER: string;
  COLOR: number;
  MIDA: number;
  COSTPERC: number;
  IT_S_CODE: string;
  QTYPERUNIT: number;

  SALEREMARK1: string;
  costWithoutVat: number;
  vat: number;
  sapakDiscount: number;
  costWithVat: number;
  costWithoutVatAfterSapakDiscount: number;
  costWithVatAfterSapakDiscount: number;
  buttomProfit: number;
  topProfit: number;
  profit: number;
  priceWithoutVat: number


}


  /*
  COIN: string;
  SMELL: number;
  IT_DESC2: string;
  QTYPERUNIT: number;
  NOVAT: string;
  IF_DISCOUNT: string;*/

  /*
  ALTKEY: null
  AVARAGECOST: null
  DIFDATE: null
  DIFQTY: null
  ENTRYDATE: null
  ENTRYQTY: null
  ICOM: null
  ITSORT: 18
  IT_CHPRICE: "F"

  IT_DESC2: null
  IT_S_CODE: null


  ORDDATE: null
  ORDQTY: null

  PRICE4: null
  PRICE5: null
  PRICE6: null
  PRICE7: null
  PRICE8: null
  PRICE9: null
  PROFITPERC: null
  QTY: -2
  ROLLER: null
  RUNKEY: null
  SALEREMARK: null
  SOLDDATE: "2020-06-08T00:00:00"
  SOLDQTY: 2
  TRNQTY: null
  UPDDATE: "2020-01-23T00:00:00"*/

