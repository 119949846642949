import { Component, OnInit, Inject, Input } from '@angular/core';
import { Item } from '../Item';
import { ItemsManagmentService } from '../items-managment.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbCalendar, NgbDate, NgbDateStructAdapter } from '@ng-bootstrap/ng-bootstrap';
//import { CustomAdapterService } from '.../services/custom-adapter.service';
import ItemCompare from 'src/interfaces/ItemCompare';
import { MatTableDataSource } from '@angular/material/table';
import { ItemsCompare } from './ItemsCompare';

@Component({
  selector: 'ItemsCompareComponent',
  templateUrl: './ItemsCompare.component.html',
  styleUrls: ['./ItemsCompare.component.css'],
})

export class ItemsCompareComponent implements OnInit {

  public selectedItem: Item = new Item();
  public specialItemsCompareRows: ItemsCompare[] = [];

  public dataSource = new MatTableDataSource<ItemsCompare>();

  error: any;

  initialDisplayedColumns: string[] =
    [
      'SHOPNAME',
      'IT_CODE',
      'IT_DESC',
      'BARCODE',
      'ITSORT',
      'CUT',
      'PRICE1',
      'PRICE2',
      'PRICE3',
      'DISCOUNT',
      'STANDARTCOST',
      'MINIQTY',
      'MAXQTY',
      'SUPPLIER',
      'IT_SALE',
      'IT_CHPRICE',
      'SCALE',
      'OTHERS',
      'NOVAT',
      'IF_DISCOUNT',
      'SALEREMARK',
      'SOLDDATE',
      'UPDDATE'
    ];


  displayedColumns: string[] =
    [
      'SHOPNAME',
      'IT_CODE',
      'IT_DESC',
      'BARCODE',
      'ITSORT',
      'SUPPLIER',
//      'CUT',
      'PRICE1',
      'PRICE2',
      'PRICE3',
      'PRICE4',
      'PRICE5',
      'PRICE6',
      'PRICE7',
      'PRICE8',
      'PRICE9',
//    'DISCOUNT',
      'STANDARTCOST',
//      'MINIQTY',
//      'MAXQTY',
//      'SUPPLIER',
//      'IT_SALE',
//      'IT_CHPRICE',
//      'SCALE',
//      'OTHERS',
//      'NOVAT',
//      'IF_DISCOUNT',
//      'SALEREMARK',
//      'SOLDDATE',
      'UPDDATE'
    ];

  displayedColumnForMobilesPhones: string[] =
    [
      'SHOPNAME',
      'IT_CODE',
      'IT_DESC',
      'BARCODE',
      'ITSORT',
      'CUT',
      'PRICE1',
      'PRICE2',
      'PRICE3',
      'DISCOUNT',
      'STANDARTCOST',
      'MINIQTY',
      'MAXQTY',
      'SUPPLIER',
      'IT_SALE',
      'IT_CHPRICE',
      'SCALE',
      'OTHERS',
      'NOVAT',
      'IF_DISCOUNT',
      'SALEREMARK',
      'SOLDDATE',
      'UPDDATE'
    ];

  displayedColumnForDesk1024: string[] =
    [
      'SHOPNAME',
      'IT_CODE',
      'IT_DESC',
      'BARCODE',
      'ITSORT',
      'CUT',
      'PRICE1',
      'PRICE2',
      'PRICE3',
      'DISCOUNT',
      'STANDARTCOST',
      'MINIQTY',
      'MAXQTY',
      'SUPPLIER',
      'IT_SALE',
      'IT_CHPRICE',
      'SCALE',
      'OTHERS',
      'NOVAT',
      'IF_DISCOUNT',
      'SALEREMARK',
      'SOLDDATE',
      'UPDDATE'

    ];

 
  displayedColumnsBarcode: string[] =
    [
      'IT_CODE',
      'IT_DESC',
      'BARCODE',
      'ITSORT',
      'CUT',
      'PRICE1',
      'PRICE2',
      'PRICE3',
      'DISCOUNT',
      'STANDARTCOST',
      'MINIQTY',
      'MAXQTY',
      'SUPPLIER',
      'IT_SALE',
      'IT_CHPRICE',
      'SCALE',
      'OTHERS',
      'NOVAT',
      'IF_DISCOUNT',
      'SALEREMARK',
      'SOLDDATE',
      'UPDDATE'

    ];





  constructor(@Inject(MAT_DIALOG_DATA) public data: ItemCompare, public _ItemsManagmentService: ItemsManagmentService) {
    this.selectedItem = data.CompareItemRow;
  }



  ngOnInit(): void {
    this.getItems();
  }

  getItems() {
//    alert(this.selectedItem.IT_CODE);
    this._ItemsManagmentService.getItemsCompare(this.selectedItem.IT_CODE)
  .subscribe(
    (data: any) => {

      let ItemsCompareRows = data.itemsCOMPARERows;
//      this.specialItemsCompareRows = data.itemsCOMPARERows; 
      this.dataSource = new MatTableDataSource<ItemsCompare>(ItemsCompareRows);

      return;
    }, // success path/
    error => this.error = error // error path
  );

  }


}
