<!--                *ngIf="shopsFilterd.length>8"
-->

<div style="clear:both;">
  <!--
      <mat-form-field style="margin-top:5rem;" floatLabel="never" class="full-width" *ngIf="shopsFilterd.length>8"
                      name="filterShops"
                      ngDefaultControl [(ngModel)]="filterShops"
                      (ngModelChange)="onShopFilterChange()">
        <mat-label>חפש חנות</mat-label>
        <input matInput placeholder="חפש חנות" #input>
      </mat-form-field>
  -->
  <div class="form-floating customized-input flex-item" *ngIf="shopsFilterd.length>8">
    <input type="text" class="form-control" id="floatingInput"
           *ngIf="shopsFilterd.length>8"
           name="filterShops"
           ngDefaultControl [(ngModel)]="filterShops"
           (ngModelChange)="onShopFilterChange()">
    placeholder="חיפוש" autocomplete="off" (keyup)="applyFilter($event)" [(ngModel)]="filterText" #input>
    <label for="floatingInput" class="label-color">חיפוש</label>
  </div>

  <div style="margin-right:0.3rem;" class="form-floating customized-input flex-item">
    <button style="margin-right:0.3rem;" mat-raised-button color="primary" (click)="selectAllShops()">בחר הכל</button>
    <button style="margin-right:0.3rem;" mat-raised-button color="primary" (click)="clearAllShops()">נקה הכל</button>
  </div>
  <div style="margin-right:0.5rem;" class="form-floating customized-input flex-item">
      <mat-selection-list style="margin-top:00px" [(ngModel)]="selectedShops" (ngModelChange)="onShopsChange($event)">
        <mat-list-option style="margin-top:0rem" *ngFor="let shop of shopsFilterd" [value]=shop>
          {{shop.SHORT_NAME}}
        </mat-list-option>
      </mat-selection-list>
  </div>


  <!--  <div class="form-floating customized-input flex-item">
  <mat-selection-list [(ngModel)]="selectedShops" (ngModelChange)="onShopsChange($event)">
    <button mat-raised-button color="primary" (click)="selectAllShops()">בחר הכל</button>
    <button mat-raised-button color="primary" (click)="clearAllShops()">נקה הכל</button>
    <mat-list-option *ngFor="let shop of shopsFilterd" [value]=shop>
      {{shop.SHORT_NAME}}
    </mat-list-option>

  </mat-selection-list> -->

</div>
