  <ng-container>
    <div class="flex-row filter-row-container">
      <p class="reportDate">{{salesReportByGroup[salesReportByGroup.length-1].Name}}</p>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="IT_CODE">
        <th mat-header-cell *matHeaderCellDef class="small">קוד פריט</th>
        <td mat-cell *matCellDef="let element"> {{element.IT_CODE}} </td>
      </ng-container>

      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef>תאור פריט</th>
        <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
      </ng-container>


      <ng-container matColumnDef="PRICE1" class="small">
        <th mat-header-cell *matHeaderCellDef>מ. מכירה</th>
        <td mat-cell *matCellDef="let element"> {{element.PRICE1 | number:'1.2-2'}} </td>
      </ng-container>

      <ng-container matColumnDef="Tot_Qty" class="small">
        <th mat-header-cell *matHeaderCellDef class="small">כמות</th>
        <td mat-cell *matCellDef="let element"> {{element.Tot_Qty | number:'1.0-2'}} </td>
      </ng-container>


      <ng-container matColumnDef="Tot_Total" class="small">
        <th mat-header-cell *matHeaderCellDef class="small">סה"כ מכירה </th>
        <td mat-cell *matCellDef="let element"> {{element.Tot_Total | number:'1.2-2'}} </td>
      </ng-container>

      <ng-container matColumnDef="discount" class="small">
        <th mat-header-cell *matHeaderCellDef class="small">סה"כ הנחה </th>
        <td mat-cell *matCellDef="let element"> {{element.discount | number:'1.2-2'}} </td>
      </ng-container>


      <ng-container matColumnDef="Tot_WitOutVat" class="small">
        <th mat-header-cell *matHeaderCellDef>סה"כ לפני מע"מ</th>
        <td mat-cell *matCellDef="let element"> {{element.Tot_WitOutVat | number:'1.2-2'}} </td>
      </ng-container>


      <!--
  <ng-container matColumnDef="Total_Percentage">
    <th mat-header-cell *matHeaderCellDef class="small">% מכספים</th>
    <td mat-cell *matCellDef="let element"> {{element.Total_Percentage | number:'1.2-2'}}<ng-container *ngIf="element.Total_Percentage">%</ng-container> </td>
  </ng-container>

  <ng-container matColumnDef="Qty_Percentage">
    <th mat-header-cell *matHeaderCellDef class="small">% מכמויות</th>
    <td mat-cell *matCellDef="let element"> {{element.Qty_Percentage | number:'1.2-2'}}<ng-container *ngIf="element.Qty_Percentage">%</ng-container> </td>
  </ng-container>
  -->

      <ng-container matColumnDef="Tot_Cost">
        <th mat-header-cell *matHeaderCellDef class="small">סה"כ עלות</th>
        <td mat-cell *matCellDef="let element"> {{element.Tot_Cost | number:'1.2-2'}} </td>
      </ng-container>

      <ng-container matColumnDef="Profit">
        <th mat-header-cell *matHeaderCellDef class="small">רווח</th>
        <td mat-cell *matCellDef="let element"> {{element.Profit | number:'1.2-2'}} </td>
      </ng-container>

      <ng-container matColumnDef="DProfit">
        <th mat-header-cell *matHeaderCellDef class="small"> %רווח מלמטה</th>
        <td mat-cell *matCellDef="let element"> {{element.DProfit | number:'1.2-2'}}% </td>
      </ng-container>

      <ng-container matColumnDef="AProfit" class="small">
        <th mat-header-cell *matHeaderCellDef>%רווח מלמעלה</th>
        <td mat-cell *matCellDef="let element"> {{element.AProfit | number:'1.2-2'}}% </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{input.value}}"</td>
      </tr>
    </table>

  </ng-container>
