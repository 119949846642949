import { Component, OnInit, ViewChild } from '@angular/core';
import { itSortRow } from '../items-managment/itSortRow';
import { MatTableDataSource } from '@angular/material/table';
import { Shop } from '../Shop';
import { ItemsManagmentService } from '../items-managment/items-managment.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { supplierRow } from '../items-managment/supplierRow';
import { interval } from 'rxjs/internal/observable/interval';
import { Subscription } from 'rxjs';

@Component({
  selector: 'suppliers-managment',
  templateUrl: './suppliers-managment.component.html',
  styleUrls: ['./suppliers-managment.component.css']
})

export class SuppliersManagmentComponent implements OnInit {

  public shops: Shop[] = [];
  public supplierRows: supplierRow[][] = [];
  public lastUpdated: Date;
  public supplierRowsFilterd: supplierRow[] = [];
  public selectedShop: Shop = null;


  public shopIndex = 0;
  public filterValue = "";
  public selectedSupplier: supplierRow;
  error: any;

  displayedColumns: string[] =
    [
      'CLNUM',
      'CLNAME',
    ];

  @ViewChild(MatPaginator) paginator: MatPaginator;



  public newSupplier(): void {
    this.selectedSupplier = {
      CLNAME: null,
      CLNUM: null,
      SHOP_ID: this.selectedShop.SHOP_ID,
      TABLEID: 0,
    }
  }

  constructor(public _ItemsManagmentService: ItemsManagmentService) {
  }

  ngOnInit(): void {
    this.getItems(null);
    this.dataSource.paginator = this.paginator;
  }

  public dataSource = new MatTableDataSource<supplierRow>(this.supplierRowsFilterd);

  filterItems() {
    this.supplierRowsFilterd = this.supplierRows[this.shopIndex].filter(x => x);
    this.supplierRowsFilterd.sort(function (a, b) {
      return a.CLNUM - b.CLNUM
    })

    this.dataSource = new MatTableDataSource<supplierRow>(this.supplierRowsFilterd);
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
  }

  getItems(lastUpdated: Date) {
    this._ItemsManagmentService.getItems(lastUpdated)
      .subscribe(
        (data: any) => {
          this.shops = data.shops;
          this.supplierRows = data.supplierRows;
          this.selectedShop = this.shops[0];
          this.supplierRowsFilterd = [];
          this.dataSource = new MatTableDataSource<supplierRow>(this.supplierRowsFilterd)
          this.dataSource.paginator = this.paginator;
          this.startDelayedFilter();
          this.newSupplier();
          return;
        }, // success path/
        error => this.error = error // error path
      );
  }

  subscription: Subscription;

  ngOnDestroy() {
    //this.subscription.unsubscribe();
  }

  startDelayedFilter(): void {
    const source = interval(1000);
    this.subscription = source.subscribe(val => {
      if (this.supplierRowsFilterd.length === 0) {
        this.filterItems()
        this.subscription.unsubscribe();
      }
      return 1;
    }
    );
  }

  selectionChangeShop(selectedshop: Shop) {
    this.selectedShop = selectedshop;
    this.shopIndex = this.shops.findIndex(x => x.SHOP_ID === this.selectedShop.SHOP_ID)
  }

  public selectRow(supplierRow: supplierRow) {
    this.selectedSupplier = supplierRow;
  }


  save() {
    this.selectedSupplier.CLNUM = this.selectedSupplier.CLNUM ? parseInt(this.selectedSupplier.CLNUM.toString()) : null;

    this._ItemsManagmentService.postselectedSupplier(this.selectedSupplier, this.shopIndex)
      .subscribe(
        (data: number) => {
          if (data) //added item
          {
            this.selectedSupplier.TABLEID = data;
            this.supplierRows[this.shopIndex].push(this.selectedSupplier);
          }
          this.newSupplier();
          this.filterItems();
        }, // success path/
        error => this.error = error // error path
      );
  }

}
