
<mat-label class="Lfirst">מתאריך</mat-label>
<mat-label class="Lsecond">עד תאריך</mat-label>
<br />
<div class="row ">


      <form class="row-cols-sm-auto">
      
          <div class="input-group">
            <input id="DatePic1" class="form-control"
                   locale="he"
                   name="d1"
                   #c1="ngModel"
                   placeholder="{{startReportPicker | date: 'dd/MM/yyyy'}}"
                   [(ngModel)]="startReportPicker"
                   [firstDayOfWeek]="0"
                   (ngModelChange)="changeReportStartDate($event)"
                   ngbDatepicker
                   #d1="ngbDatepicker" />

            <button id="btn1" class="btn btn-outline-secondary bi bi-calendar3" (click)="d1.toggle()" type="button"></button>
          </div>

      </form>

      <form class="row-cols-sm-auto">
          <div class="input-group">
            <input id="DatePic2" class="form-control"
                   placeholder="{{endReportPicker | date:'dd/MM/yyyy'}}"
                   locale="he"
                   name="d2"
                   #c2="ngModel"
                   [(ngModel)]="endReportPicker"
                   (ngModelChange)="changeReportEndDate($event)"
                   [firstDayOfWeek]="0"
                   ngbDatepicker
                   #d2="ngbDatepicker" />
            <button id="btn2" class="btn btn-outline-secondary bi bi-calendar3" (click)="d2.toggle()" type="button"></button>
          </div>
      </form>
</div>


<!--</div>-->



<!--

<mat-form-field>
  <input matInput [matDatepicker]="startReportPicker" [max]="maxDate" (dateChange)="changeReportStartDate($event)" [value]="maxDate" disabled>
  <mat-datepicker-toggle matSuffix [for]="startReportPicker" style="margin-right:5rem;"></mat-datepicker-toggle>
  <mat-datepicker #startReportPicker disabled="false"></mat-datepicker>
</mat-form-field>
  <mat-form-field appearance="fill" class="second">
    <input matInput [matDatepicker]="endReportPicker" [max]="maxDate" (dateChange)="changeReportEndDate($event)" [value]="maxDate" disabled>
    <mat-datepicker-toggle matSuffix [for]="endReportPicker" style="margin-right:5rem; "></mat-datepicker-toggle>
    <mat-datepicker #endReportPicker disabled="false"></mat-datepicker>
  </mat-form-field>
 -->
