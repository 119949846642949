import { NgModule, LOCALE_ID, NO_ERRORS_SCHEMA, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { NavMenuNewComponent } from './nav-menu-new/nav-menu-new.component';

import { HomeComponent } from './home/home.component';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';
import { ManagmentComponent } from './managment/managment.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule, MatTableDataSource, MatFooterRowDef } from '@angular/material/table';
import { MatButtonToggleGroup, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MDCFormField } from '@material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { UsersShopsComponent } from './users-shops/users-shops.component';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { ZReportComponent } from './z-report/z-report.component';
import { ZReportsComponent } from './z-report/z-reports.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { ItemsManagmentComponent } from './items-managment/items-managment.component';
import { ItemsCompareComponent } from './items-managment/ItemsCompare/ItemsCompare.component';
import { CustomMatPaginatorIntl } from './shared/CustomMatPaginatorIntl';
import { UsersRolesComponent } from './users-roles/users-roles.component';
import { FastPriceUpdateComponent } from './items-managment/fast-price-update/fast-price-update.component';
import { iteminsertComponent } from './items-managment/item-insert/item-insert.component';
import { specialSalesHeaderComponent } from './specialSalesHeader/specialSalesHeader.component';
import { specialSalesCompareComponent } from './specialSalesCompare/specialSalesCompare.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SalesReportByGroupComponent } from './reports/sales-report-by-group/sales-report-by-group.component';
import { ItSortPipe } from './shared/pipes/it-sort.pipe';
import { SupplierPipe } from './shared/pipes/supplier.pipe';
import { SalesReportDatailedByGroupsComponent } from './reports/sales-report-datailed-by-groups/sales-report-datailed-by-groups.component';
import { SalesReportDatailedByGroupComponent } from './reports/sales-report-datailed-by-group/sales-report-datailed-by-group.component';
import { MyShopsSelectionComponent } from './reports/my-shops-selection/my-shops-selection.component';
import { DateRangeSelectionComponent } from './reports/date-range-selection/date-range-selection.component';
import { PerHourSalesComponent } from './reports/per-hour-sales/per-hour-sales.component';
import { BarChartComponent } from './shared/bar-chart/bar-chart.component';
import { MatRowKeyboardSelectionDirective } from './shared/mat-row-keyboard-selection.directive';
import { SuppliersManagmentComponent } from './suppliers-managment/suppliers-managment.component';
import { ItSortManagmentComponent } from './it-sort-managment/it-sort-managment.component';
import { ColorManagmentComponent } from './color-managment/color-managment.component';
import { MidaManagmentComponent } from './mida-managment/mida-managment.component';
import { BarChartHorizontalComponent } from './shared/bar-chart-horizontal/bar-chart-horizontal.component';
import { InvoicingReportComponent } from './reports/invoicing-report/invoicing-report.component';
import { TimeRangeSelectionComponent } from './reports/time-range-selection/time-range-selection.component';
import { MyShopsSingleSelectionComponent } from './reports/my-shops-single-selection/my-shops-single-selection.component';
import { TelegramSingleSelectionComponent } from './reports/telegtam-single-selection/telegtam-single-selection.component';
import { InvoicingReportSingleComponent } from './reports/invoicing-report/invoicing-report-single.component';
import { InvoiceHeaderComponent } from './reports/invoice-header/invoice-header.component';
import { PayListComponent } from './reports/pay-list/pay-list.component';
import { TelegramPipe } from './shared/pipes/telegram.pipe';
import { ExportAsModule } from 'ngx-export-as';
import { MainEntryComponent } from './inventory/main-entry/main-entry.component';
import { ItemSearchComponent } from './inventory/item-search/item-search.component';
import { InventoryItemListComponent } from './inventory/inventory-item-list/inventory-item-list.component';
import { InventoryItemEditComponent } from './inventory/inventory-item-edit/inventory-item-edit.component';
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { SelectedItemReducer } from './reducers/selected-item.reducer';
import { SpecialSalesManagmentComponent } from './specialSales-managment/specialSales-managment.component';
import { specialSalesBarcodeManagmentComponent } from './specialSalesBarcode-managment/specialSalesBarcode-managment.component';
import { ItemNamePipe } from './shared/pipes/it-code.pipe';
import { EmvserialManagmentComponent } from './emvserial-managment/emvserial-managment.component';
import { ConfirmDeleteItemComponent } from './items-managment/confirm-delete-item/confirm-delete-item.component';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { UpdateItemItcodeComponent } from './items-managment/update-item-itcode/update-item-itcode.component';
import { UpdateDeleteFailedComponent } from './items-managment/update-delete-failed/update-delete-failed.component';
import { ItemSupComponent } from './item-sup/item-sup.component';
import { TransactionsReportComponent } from './reports/transactions-report/transactions-report.component';
import { CustomerlistComponent } from './reports/customer-list/customer-list.component';
import { DocTypePipe } from './shared/pipes/doc-type.pipe';
//import { RootStoreModule } from './root-store/root-store.module';
//import { reducers } from './reducers';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { NgbCalendar, NgbCalendarHebrew, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbDatepickerI18nHebrew, NgbModule, } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CustomAdapterService } from './services/custom-adapter.service';
import { CustomDateParserFormatterService } from './services/custom-date-parser-formatter.service';
import { JsonPipe } from '@angular/common';
import { registerLocaleData } from "@angular/common";
import localeHe from "@angular/common/locales/he";
import localeHeExtra from "@angular/common/locales/extra/he";
import { DxDataGridModule } from 'devextreme-angular';
import { DxChartModule } from 'devextreme-angular';
import { DxReportDesignerModule } from 'devexpress-reporting-angular';
import { DxReportViewerModule } from 'devexpress-reporting-angular';
import { DxButtonModule } from 'devextreme-angular';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// import 'devexpress-reporting/dx-richedit';
import { EntryOcrComponent } from './inventory/EntryOcr/EntryOcr.component';
import { licenseKey } from 'DevLiscense';
import config from 'devextreme/core/config';
import { DxLoadIndicatorModule } from 'devextreme-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DxMenuModule } from 'devextreme-angular';
config({ licenseKey });

registerLocaleData(localeHe, "he", localeHeExtra);


export const metaReducers: MetaReducer<any>[] = [debug];

//import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    NavMenuNewComponent,
    HomeComponent,
    ManagmentComponent,
    UsersShopsComponent,
    ZReportComponent,
    ZReportsComponent,
    ItemsManagmentComponent,
    UsersRolesComponent,
    FastPriceUpdateComponent,
    SalesReportByGroupComponent,
    ItSortPipe,
    ItemNamePipe,
    iteminsertComponent,
    SupplierPipe,
    TelegramPipe,
    SalesReportDatailedByGroupsComponent,
    SalesReportDatailedByGroupComponent,
    specialSalesHeaderComponent,
    MyShopsSelectionComponent,
    DateRangeSelectionComponent,
    PerHourSalesComponent,
    BarChartComponent,
    SuppliersManagmentComponent,
    ItSortManagmentComponent,
    ColorManagmentComponent,
    MidaManagmentComponent,
    SpecialSalesManagmentComponent,
    specialSalesCompareComponent,
    ItemsCompareComponent,
    specialSalesBarcodeManagmentComponent,
    BarChartHorizontalComponent,
    InvoicingReportComponent,
    TimeRangeSelectionComponent,
    MyShopsSingleSelectionComponent,
    TelegramSingleSelectionComponent,
    InvoicingReportSingleComponent,
    InvoiceHeaderComponent,
    PayListComponent,
    MainEntryComponent,
    ItemSearchComponent,
    InventoryItemListComponent,
    InventoryItemEditComponent,
    EmvserialManagmentComponent,
    ConfirmDeleteItemComponent,
    ConfirmDeleteComponent,
    UpdateItemItcodeComponent,
    UpdateDeleteFailedComponent,
    ItemSupComponent,
    TransactionsReportComponent,
    CustomerlistComponent,
    DocTypePipe,

//    DxButtonModule,
 
   ],
  imports: [
//    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    ExportAsModule,
    FormsModule,
    ApiAuthorizationModule,
    MatTableModule,
    MatProgressSpinnerModule,
    DxChartModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatListModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatSortModule,
    MatMenuModule, MatSidenavModule, MatToolbarModule, MatIconModule, 
    MatSnackBarModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTableModule,
    DxDataGridModule,
    DxLoadIndicatorModule,
    NgxSpinnerModule,
     DxMenuModule,

    RouterModule.forRoot([
      { path: '', pathMatch: 'full', redirectTo:'z-reports'}, 
      /*{ path: 'counter', component: CounterComponent },
      { path: 'fetch-data', component: FetchDataComponent, canActivate: [AuthorizeGuard] },*/
      { path: 'managment', component: ManagmentComponent, canActivate: [AuthorizeGuard] },
      { path: 'UsersShops', component: UsersShopsComponent, canActivate: [AuthorizeGuard] },
      { path: 'UsersRoles', component: UsersRolesComponent, canActivate: [AuthorizeGuard] },
      { path: 'z-reports', component: ZReportsComponent, canActivate: [AuthorizeGuard] },
      { path: 'items-managment', component: ItemsManagmentComponent, canActivate: [AuthorizeGuard] },
      { path: 'salesReportByGroup', component: SalesReportByGroupComponent, canActivate: [AuthorizeGuard] },
      { path: 'SalesReportDatailedByGroups', component: SalesReportDatailedByGroupsComponent, canActivate: [AuthorizeGuard] },
      { path: 'PerHourSales', component: PerHourSalesComponent, canActivate: [AuthorizeGuard] },
      { path: 'itsort-managment', component: ItSortManagmentComponent, canActivate: [AuthorizeGuard] },
      { path: 'suppliers-managment', component: SuppliersManagmentComponent, canActivate: [AuthorizeGuard] },
      { path: 'color-managment', component: ColorManagmentComponent, canActivate: [AuthorizeGuard] },
      { path: 'special-sales-managment', component: SpecialSalesManagmentComponent, canActivate: [AuthorizeGuard] },
      { path: 'mida-managment', component: MidaManagmentComponent, canActivate: [AuthorizeGuard] },
      { path: 'invoicing-report', component: InvoicingReportComponent, canActivate: [AuthorizeGuard] },
      { path: 'inventory-entry', component: MainEntryComponent, canActivate: [AuthorizeGuard] },
      { path: 'emv-serial-managment', component: EmvserialManagmentComponent, canActivate: [AuthorizeGuard] },
      { path: 'EntryOcr', component: EntryOcrComponent, canActivate: [AuthorizeGuard] },
      { path: 'transactions-report', component: TransactionsReportComponent, canActivate: [AuthorizeGuard] },
      { path: 'customer-list', component: CustomerlistComponent, canActivate: [AuthorizeGuard] },
      { path: 'Item-insert', component: iteminsertComponent, canActivate: [AuthorizeGuard] },
    ]),
    BrowserAnimationsModule,
    StoreModule.forRoot(metaReducers),
    StoreModule.forRoot( { SelectedItemReducer: SelectedItemReducer }),
    NgbModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl},
		{ provide: NgbDateAdapter, useClass: CustomAdapterService },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatterService },
    { provide: LOCALE_ID, useValue: "he_IL" },
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
  
})
export class AppModule { }

// console.log all actions
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    console.log('stateBefore', state);
    console.log('action', action);
    let res = reducer(state, action);
    console.log('stateAfter', res);
    return res;

  };
}

