  <div id="contentToConvert" class="flex-row filter-row-container">

  <!--
  <date-range-selection
  (startReportPickerChanged)="startReportPickerChanged($event.value)"
   (endReportPickerChanged)="endReportPickerChanged($event.value)"
   >
  </date-range-selection>
  -->
  <div class="form-floating customized-input flex-item">
    <input type="text" class="form-control" id="floatingInput" placeholder="קוד פריט" autocomplete="off" (keyup)="applyFilter($event)" [(ngModel)]="itCode" #input [disabled]="haveItem">
    <label for="floatingInput" class="label-color">קוד פריט</label>
  </div>

  <div style="width:25rem" class="form-floating customized-input flex-item">
    <input type="text" class="form-control" id="floatingInput" placeholder="תיאור פריט " autocomplete="off" (ngModelChange)="getTransactionsReport()" [(ngModel)]="itDesc" #input disabled>
    <label for="floatingInput" class="label-color">תיאור פריט </label>
  </div>



<!--  <button mat-raised-button color="primary" style="margin-right:0.4rem" (click)="itemsManagment()" *ngIf="haveItem">חזרה לניהול פריטים</button>-->
  <mat-dialog-actions>
    <button mat-button style="margin-top:-3.5rem"  mat-dialog-close mat-raised-button color="primary" *ngIf="haveItem">חזרה לניהול פריטים</button>
  </mat-dialog-actions>

    <div style="height:6rem">
      <my-shops-selection (shopsChanged)="shopsChanged($event)"></my-shops-selection>

    </div>
    <div align="left" class="form-floating customized-input flex-item">
      <img align="left" src="../../assets/images/pdf.png" (click)="exportPDF()" style="width:40px; height:40px" />
    </div>

    <ng-container>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="SHORT_NAME">
          <th mat-header-cell *matHeaderCellDef>חנות</th>
          <td mat-cell *matCellDef="let element"> {{element.SHORT_NAME}} </td>
        </ng-container>

        <ng-container matColumnDef="DOC_TYPE">
          <th mat-header-cell *matHeaderCellDef>סוג תעודה</th>
          <td mat-cell *matCellDef="let element"> {{element.DOC_TYPE | docType}} </td>
        </ng-container>

        <ng-container matColumnDef="AC_NBR">
          <th mat-header-cell *matHeaderCellDef>מחסן/ ספק/ לקוח</th>
          <td mat-cell *matCellDef="let element"> {{element.AC_NBR }} </td><!--| supplier:supplierRows-->
        </ng-container>

        <ng-container matColumnDef="DOC_NUMBER">
          <th mat-header-cell *matHeaderCellDef>מספר תעודה</th>
          <td mat-cell *matCellDef="let element"> {{element.DOC_NUMBER}} </td>
        </ng-container>

        <ng-container matColumnDef="DOC_TIME">
          <th mat-header-cell *matHeaderCellDef>תאריך</th>
          <td mat-cell *matCellDef="let element"> {{element.DOC_TIME | date: 'HH:mm dd/MM/yy '}} </td>
        </ng-container>

        <ng-container matColumnDef="NAME">
          <th mat-header-cell *matHeaderCellDef>מוכרן</th>
          <td mat-cell *matCellDef="let element"> {{element.NAME}}</td>
        </ng-container>

        <ng-container matColumnDef="QTY">
          <th mat-header-cell *matHeaderCellDef>כמות</th>
          <td mat-cell *matCellDef="let element"> {{element.QTY | number:'1.0'}} </td>
        </ng-container>

        <ng-container matColumnDef="PRICE">
          <th mat-header-cell *matHeaderCellDef>מחיר/ עלות </th>
          <td mat-cell *matCellDef="let element"> {{element.PRICE | number:'1.2'}} </td>
        </ng-container>

        <ng-container matColumnDef="DISCOUNT">
          <th mat-header-cell *matHeaderCellDef>%הנחה</th>
          <td mat-cell *matCellDef="let element"> {{element.DISCOUNT | number:'1.2'}} </td>
        </ng-container>


        <ng-container matColumnDef="TOTAL">
          <th mat-header-cell *matHeaderCellDef>סה"כ שורה</th>
          <td mat-cell *matCellDef="let element"> {{element.TOTAL | number:'1.2'}} </td>
        </ng-container>

        <ng-container matColumnDef="accumulate">
          <th mat-header-cell *matHeaderCellDef>מצטבר</th>
          <td mat-cell *matCellDef="let element" style="direction: ltr !important;"> {{element.accumulate | number:'1.0'}} </td>
        </ng-container>




        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">{{input.value}}"</td>
        </tr>
      </table>

    </ng-container>
</div>
