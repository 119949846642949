<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 clearBoth">
  <ng-container matColumnDef="IT_CODE">
    <th mat-header-cell *matHeaderCellDef class="small"> קוד פריט </th>
    <td mat-cell *matCellDef="let element"> {{element.IT_CODE}} </td>
  </ng-container>
  <ng-container matColumnDef="IT_DESC">
    <th mat-header-cell *matHeaderCellDef> תאור פריט </th>
    <td mat-cell *matCellDef="let element"> {{element.IT_DESC}} </td>
  </ng-container>
  
  <ng-container matColumnDef="IT_QTY">
    <th mat-header-cell *matHeaderCellDef class="small"> כמות </th>
    <td mat-cell *matCellDef="let element"> {{element.IT_QTY}} </td>
  </ng-container>
  <ng-container matColumnDef="BONUS">
    <th mat-header-cell *matHeaderCellDef> בונוס </th>
    <td mat-cell *matCellDef="let element"> {{element.BONUS}} </td>
  </ng-container>
  <ng-container matColumnDef="IT_COST">
    <th mat-header-cell *matHeaderCellDef> עלות </th>
    <td mat-cell *matCellDef="let element"> {{element.IT_COST}} </td>
  </ng-container>
  <ng-container matColumnDef="IT_PRICE">
    <th mat-header-cell *matHeaderCellDef> לצרכן </th>
    <td mat-cell *matCellDef="let element"> {{element.IT_PRICE}} </td>
  </ng-container>
  <ng-container matColumnDef="IT_DISC">
    <th mat-header-cell *matHeaderCellDef> % הנחה </th>
    <td mat-cell *matCellDef="let element"> {{element.IT_DISC}} </td>
  </ng-container>

  <ng-container matColumnDef="IT_TOTAL">
    <th mat-header-cell *matHeaderCellDef> סה"כ </th>
    <td mat-cell *matCellDef="let element"> {{element.IT_TOTAL}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectRow(row)"></tr>
</table>
