import { Component, Inject, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { ViewEncapsulation, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
//import { ItemSearchComponent } from '../item-search/item-search.component';
import { ItemsManagmentService } from '../../items-managment/items-managment.service';
import { Shop } from '../../Shop';
import { MyShopsService } from '../../reports/my-shops.service';
import { itSortRow } from '../../items-managment/itSortRow';
import { supplierRow } from '../../items-managment/supplierRow';
import { colorRow } from '../../items-managment/colorRow';
import { midaRow } from '../../items-managment/midaRow';
import { Item } from '../../items-managment/Item';
import { Ent_Head, Ent_Item } from '../ent-item';
import { SaveItem } from '../Saveitem';
import { interval, Observable } from 'rxjs';


import { InventoryItemEditComponent } from '../inventory-item-edit/inventory-item-edit.component';
import { Control } from '../control';
import { AsyncValidatorFn } from '@angular/forms';
//import { ActionId } from 'devexpress-reporting/dx-webdocumentviewer'
import { DxReportViewerComponent } from 'devexpress-reporting-angular';


@Component({
  selector: 'inventory-entry',
  templateUrl: './main-entry.component.html',
  encapsulation: ViewEncapsulation.None,  
  styleUrls: ['./main-entry.component.css']
})


export class MainEntryComponent implements OnInit {
  @ViewChild(DxReportViewerComponent, { static: false }) viewer: DxReportViewerComponent;
  reportUrl: string = "../../items-managment/Item'";
  invokeAction: string = '/DXXRDV';
  getDesignerModelAction = "DXXRD/GetDesignerModel";
  hostUrl = 'https://localhost:5001/';


  // http://localhost:53847

  public shops: Shop[] = [];
  public control: Control;
  public itSortRows: itSortRow[][] = [];
  public supplierRows: supplierRow[] = [];
  public colorRows: colorRow[][] = [];
  public midaRows: midaRow[][] = [];
  public itemsRows: Item[][] = [];
  public selectedItemsRows: Item[] = [];
  public OselectedItem: Observable<Item>;
  public selectedItem: Item;
  public SaveItem: SaveItem;
  public selectedEnt_ItemRows: Ent_Item[] = [];
  public selectedEnt_Head: Ent_Head;
  public selectedEnt_ItemRow: Ent_Item;
  public reservedEnt_ItemRow = new Ent_Item();
  REnt_ItemRow = new Ent_Item();
  public lastUpdated: Date;
  public itemsRowFilterd: Item;
  public selectedShop: Shop = null;
  public selectedSupplier: supplierRow = null;
  public RegDate: Date = new Date();
  public PayDate: Date = new Date();
  public SupplierCode: number = null;
  public dataSource = new MatTableDataSource<Ent_Item>(this.selectedEnt_ItemRows);

  public shopIndex = -1;
  public filterValue = "";
  public selectedNumber: number;
  public isColumnsMobile: boolean;
  HighlightRow: Number;
  Total_F: Number;
  Total_B: Number;
  Disc_P: Number;
  Disc_T: Number;
  Vat_Perc: Number = 17;
  Vat: Number;
  Pikadon: Number;
  Employee: any;
  lwidth: string;
  lheight: string;
  ltop: string;
  rowClicked: number;
  Action: number;
  finishload: number;
  public getScreenWidth: any;
  public getScreenHeight: any;

  error: any;

  displayedColumns: string[] =
    [
      'IT_LINE',
      'IT_CODE',
      'IT_DESC',
      'IT_QTY',
      'BONUS',
      'IT_COST',
      'IT_PRICE',
      'IT_DISC',
      'IT_TOTAL',
      //      'EDITBARCODES' 
    ];


  //constructor(@Inject(' http://localhost:53847'), public hostUrl: string, public _myShopsService: MyShopsService, public _ItemsManagmentService: ItemsManagmentService, public dialog: MatDialog, private el: ElementRef, private renderer: Renderer2) {
  constructor(public _myShopsService: MyShopsService, public _ItemsManagmentService: ItemsManagmentService, public dialog: MatDialog, private el: ElementRef, private renderer: Renderer2) {
  }


  CustomizeMenuActions(event) {
// Hide the "Print" and "PrintPage" actions.
/*
    var printAction = event.args.GetById(ActionId.Print);
    if (printAction)
      printAction.visible = false;
    var printPageAction = event.args.GetById(ActionId.PrintPage);
    if (printPageAction)
      printPageAction.visible = false;
*/
  }

  
  print() {
//    this.viewer.bindingSender.Print(0);
    this.viewer.bindingSender.OpenReport("Entry");
  }  


  ngOnInit(): void {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth <= 400 && !this.isColumnsMobile) {

      this.isColumnsMobile = true;

    }

    this.getShops();
    this.shopChanged(this.shops[0]);
  }

  ClickedRow(index: number, ent_Item: Ent_Item) {
    this.HighlightRow = index;
  }

  changeTableRowColor(idx: any, row) {

    //    this.selectedSpecialSales = row;
    this.rowClicked = this.rowClicked === idx ? -1 : idx;
    this.selectedEnt_ItemRow = this.selectedEnt_ItemRows.find(x => x.IT_LINE === row.IT_LINE);
    this.reservedEnt_ItemRow = this.selectedEnt_ItemRow;
    //    alert(this.selectedEnt_ItemRow.TABLEID);
  }



  //selectionChangeShop(selectedshop: Shop) {
  //  this.selectedShop = selectedshop;
  //  this.shopIndex = this.shops.findIndex(x => x.SHOP_ID === this.selectedShop.SHOP_ID)
  //this.selectionChangeCategory(this.selectAllCategories);
  // }

  shopChanged(selectedshop: Shop) {
    //    alert(selectedshop.SHOP_ID + ' ' + selectedshop.SHOP_NAME);
    this.selectedShop = selectedshop;
    this.getnewNumber();
  }


  changeInputSUPPLIER(code: any) {
    this.selectedSupplier = this.supplierRows.find(x => x.CLNUM == code);
    this.selectedEnt_Head.SUPNUM = this.selectedSupplier.CLNUM;
    this.saveEntHead();
  }

  SUPPLIERhandleKeyboardEvent(event) {
    if (event.keyCode === 13) {
      this.selectedEnt_Head.SUPNUM = event.target.value;
      this.changeInputSUPPLIER(this.selectedEnt_Head.SUPNUM);
      this.moveFocusToInputField('REF2');
    }
  }
  REF2handleKeyboardEvent(event) {
    if (event.keyCode === 13) {
      this.selectedEnt_Head.REF2 = event.target.value;
      this.saveEntHead();
      //      alert('');
      this.moveFocusToInputField('Reg_Date');
    }
  }

  public changeRegDate(event) {
    this.RegDate = event.target.value;
    this.selectedEnt_Head.ENT_DATE = this.RegDate;
    this.selectedEnt_Head.PDATE = this.PayDate;
    this.saveEntHead();

    this.moveFocusToInputField('Pay_Date');
  }
  public changeFDate(event) {
    this.PayDate = event.target.value;
    this.selectedEnt_Head.ENT_DATE = this.RegDate;
    this.selectedEnt_Head.PDATE = this.PayDate;
    this.saveEntHead();

  }
  moveFocusToInputField(elementname: string) {
    const inputElement = this.el.nativeElement.querySelector('#' + elementname);
    if (inputElement)
      this.renderer.selectRootElement(inputElement).focus();
  }

  selectionChangeselectedSUPPLIER(selectedSupplier: supplierRow) {
    this.selectedSupplier = selectedSupplier;
    this.SupplierCode = this.selectedSupplier.CLNUM;
    this.saveEntHead();

  }

  //selectionChangeselectedSUPPLIER() {
  //    this.selectedItem.SUPPLIER = selectedSupplier.CLNUM;
  //    this.selectedItem.SUPPLIER = this.selectedSUPPLIER.CLNUM;
  //  }
  public onShopsChange(code: number) {
    this.selectedShop = this.shops.find(({ SHOP_ID }) => SHOP_ID == code);
  }

  public getnewNumber() {
    this._ItemsManagmentService.getnewEntryNumber(this.selectedShop.SHOP_ID)
      .subscribe(
        (data: any) => {
          this.control = data;
          this.selectedNumber = this.control.LAST_ENTRY + 1;
          this.shopIndex = this.selectedShop.SHOP_ID;
          this.getEntry(this.selectedShop, this.selectedNumber);

          return;
        }, // success path/
        error => this.error = error // error path
      );
  }

  Calc_Total() {
    this.Total_B = 0;
    var total = 0;
    var Vat_Perc = Number(this.Vat_Perc);

    for (let i = 0; i < this.selectedEnt_ItemRows.length; i++) {
      total = total + this.selectedEnt_ItemRows[i].IT_TOTAL;
    }
    this.Total_B = total;
    this.Total_F = total * (1 + Vat_Perc / 100);
    this.Vat = Number(this.Total_F) - Number(this.Total_B);
    this.selectedEnt_Head.VAT = Number(this.Vat);
    this.selectedEnt_Head.TOTAL_B = Number(this.Total_B);
    this.selectedEnt_Head.TOTAL_F = Number(this.Total_F);
    this.selectedEnt_Head.VAT_PERC = Number(this.Vat_Perc);
  }

  getShops() {
    this._myShopsService.getMyShops()
      .subscribe(
        (data: Shop[]) => {
          this.shops = data;
        }, // success path/
        error => this.error = error // error path
      );
  }

  getItem(SearchField: string): Observable<any> {
    this._ItemsManagmentService.getItemSearch(this.selectedShop.ITEMS_FROM_SHOP_ID, -1, -1, null, SearchField)
      .subscribe(
        (data: any) => {
          this.selectedItem = data.itemsSEARCHRows[0];
          if (this.selectedItem.IT_CODE != SearchField) {
            this.selectedItem.IT_CODE = SearchField;
            this.selectedItem.TABLEID = 0;
            this.selectedItem.SHOP_ID = this.selectedShop.ITEMS_FROM_SHOP_ID;
            this.selectedItem.BARCODE = '';
            this.selectedItem.CUT = null;
            this.selectedItem.DISCOUNT = null;
            this.selectedItem.STANDARTCOST = null;
            this.selectedItem.LASTCOST = null;
            this.selectedItem.IF_DISCOUNT = 'Fa';
            this.selectedItem.ITSORT = null;
            this.selectedItem.IT_CHPRICE = 'F';
            this.selectedItem.IT_DESC = this.selectedEnt_ItemRow.IT_DESC;
            this.selectedItem.IT_SALE = 'F';
            this.selectedItem.FATHER = 'T';
            this.selectedItem.MAXQTY = null;
            this.selectedItem.MINIQTY = null;
            this.selectedItem.NOVAT = 'F';
            this.selectedItem.PRICE1 = null;
            this.selectedItem.PRICE2 = null;
            this.selectedItem.PRICE3 = null;
            this.selectedItem.PRICE4 = null;
            this.selectedItem.PRICE5 = null;
            this.selectedItem.PRICE6 = null;
            this.selectedItem.PRICE7 = null;
            this.selectedItem.PRICE8 = null;
            this.selectedItem.PRICE9 = null;
            this.selectedItem.COSTPERC = null;
            this.selectedItem.SALEREMARK = '';
            this.selectedItem.SCALE = 'F';
            this.selectedItem.SUPPLIER = this.selectedEnt_Head.SUPNUM;
            this.selectedItem.MIDA = null;
            this.selectedItem.COLOR = null;
            this.selectedItem.IT_S_CODE = null;
            //              this.selectedItem.QTYPERUNIT = null;
            this.selectedItem.UPDDATE = new Date();
            //              this.SaveItem = this.selectedItem;
          }
          this.openDialogEntItem1(false);
        }, // success path/
        error => this.error = error // error path
      );
    return;
  }

  getEntry(selectedShop: Shop, number1: Number) {

    this._ItemsManagmentService.getEntry(selectedShop, number1)
      .subscribe(
        (data: any) => {
          this.selectedEnt_Head = data.ent_Head;
          this.supplierRows = data.suppliers;
          this.selectedSupplier = this.supplierRows.find(x => x.CLNUM == this.selectedEnt_Head.SUPNUM);
          this.RegDate = this.selectedEnt_Head.ENT_DATE;
          this.PayDate = this.selectedEnt_Head.PDATE;
          this.selectedEnt_ItemRows = data.ent_Item;
          this.selectedEnt_ItemRow = this.selectedEnt_ItemRows[0];
          this.itemsRows = data.items;
          this.itSortRows = data.it_sort;
          this.dataSource = new MatTableDataSource<Ent_Item>(this.selectedEnt_ItemRows);
          this.Calc_Total();
          return;
        }, // success path/
        error => this.error = error // error path
      );

  }

  openDialogEntItem1(Code) {
    const dialogRefp = this.dialog.open(InventoryItemEditComponent
      , {
        disableClose: true,
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: this.lheight,
        width: this.lwidth,
        data: {
          selectedShop: this.selectedShop,
          supplierRows: this.supplierRows,
          itSortRows: this.itSortRows,
          itemsRows: this.itemsRows,
          SelectedItem: this.selectedItem,
          Ent_Head: this.selectedEnt_Head,
          Ent_Item: this.selectedEnt_ItemRow,
          //          reservedEnt_ItemRow: this.selectedEnt_ItemRow,

          Code: Code,
          id: 'dialog1'
          //          dialog: this.dialog,
        }
      }
    );



    dialogRefp.afterClosed().subscribe(result => {
      if (result.data === true) {
        this.selectedEnt_ItemRow = result.selectedEntItem;
        this.itemsRowFilterd = result.selectedItemsRow;
        this.selectedItem = JSON.parse(JSON.stringify(this.itemsRowFilterd));
        this.selectedEnt_ItemRow = JSON.parse(JSON.stringify(this.selectedEnt_ItemRow));

        this.Calc_Total();
        this.saveEntItem();


      }
      else
        this.selectedEnt_ItemRow = this.reservedEnt_ItemRow;
    });



  }

  openDialogEntItem(Code) {
    this.REnt_ItemRow.BONUS = this.reservedEnt_ItemRow.BONUS;
    this.REnt_ItemRow.IT_QTY = this.reservedEnt_ItemRow.IT_QTY;
    this.REnt_ItemRow.CASHIER = this.reservedEnt_ItemRow.CASHIER;
    this.REnt_ItemRow.IT_CODE = this.reservedEnt_ItemRow.IT_CODE;
    this.REnt_ItemRow.IT_DESC = this.reservedEnt_ItemRow.IT_DESC;
    this.REnt_ItemRow.NUMBER = this.reservedEnt_ItemRow.NUMBER;
    this.REnt_ItemRow.IT_LINE = this.reservedEnt_ItemRow.IT_LINE;
    this.REnt_ItemRow.TABLEID = this.reservedEnt_ItemRow.TABLEID;
    this.REnt_ItemRow.IT_CHPRICE = this.selectedEnt_ItemRow.IT_CHPRICE;
    this.REnt_ItemRow.IT_COST = this.selectedEnt_ItemRow.IT_COST;
    this.REnt_ItemRow.IT_DISC = this.selectedEnt_ItemRow.IT_DISC;
    this.REnt_ItemRow.IT_PRICE = this.selectedEnt_ItemRow.IT_PRICE;
    this.REnt_ItemRow.IT_TOTAL = this.selectedEnt_ItemRow.IT_TOTAL;
    this.REnt_ItemRow.PRICE2 = this.selectedEnt_ItemRow.PRICE2;
    this.REnt_ItemRow.PRICE3 = this.selectedEnt_ItemRow.PRICE3;
    this.REnt_ItemRow.VAT = this.selectedEnt_ItemRow.VAT;

    if (this.shopIndex === -1)
      this.shopIndex = this.shops.findIndex(x => x.SHOP_ID === this.selectedShop.ITEMS_FROM_SHOP_ID);
    this.lwidth = '50%';
    this.lheight = '75%';
    this.ltop = '6.5rem';
    let iline;
     if (Code == true) {
       for (let i = 0; i < this.selectedEnt_ItemRows.length; i++) {
         iline = this.selectedEnt_ItemRows[i].IT_LINE;
       }
       let Ent_ItemRow = new Ent_Item();
       Ent_ItemRow.CASHIER = this.selectedEnt_Head.CASHIER;
       Ent_ItemRow.NUMBER = this.selectedEnt_Head.NUMBER;
       Ent_ItemRow.SHOP_ID = this.selectedEnt_Head.SHOP_ID;
       Ent_ItemRow.IT_LINE = iline + 1;
       Ent_ItemRow.BONUS = null;
       Ent_ItemRow.IT_CHPRICE = null;
       Ent_ItemRow.IT_CODE = null;
       Ent_ItemRow.IT_COST = null;
       Ent_ItemRow.IT_DESC = null;
       Ent_ItemRow.IT_DISC = null;
       Ent_ItemRow.IT_PRICE = null;
       Ent_ItemRow.IT_PRICE_CUR = null;
       Ent_ItemRow.PRICE2 = null;
       Ent_ItemRow.PRICE3 = null;
       Ent_ItemRow.VAT = null;
       Ent_ItemRow.TABLEID = 0;
       Ent_ItemRow.IT_QTY = null;
       Ent_ItemRow.IT_TOTAL = null;
       //      this.selectedEnt_ItemRows.push(Ent_ItemRow);
       //      this.dataSource = new MatTableDataSource<Ent_Item>(this.selectedEnt_ItemRows);
       this.selectedEnt_ItemRow = Ent_ItemRow;
       let item = new Item();
       item.TABLEID = 0;
       item.SHOP_ID = this.selectedShop.ITEMS_FROM_SHOP_ID;
       item.BARCODE = '';
       item.CUT = null;
       item.DISCOUNT = null;
       item.STANDARTCOST = null;
       item.LASTCOST = null;
       item.IF_DISCOUNT = 'Fa';
       item.ITSORT = null;
       item.IT_CHPRICE = 'F';
       item.IT_CODE = '';
       item.IT_DESC = '';
       item.IT_SALE = 'F';
       item.MAXQTY = null;
       item.MINIQTY = null;
       item.NOVAT = 'F';
       item.PRICE1 = null;
       item.PRICE2 = null;
       item.PRICE3 = null;
       item.PRICE4 = null;
       item.PRICE5 = null;
       item.PRICE6 = null;
       item.PRICE7 = null;
       item.PRICE8 = null;
       item.PRICE9 = null;
       item.COSTPERC = null;
       item.SALEREMARK = '';
       item.SCALE = 'F';
       item.SUPPLIER = this.selectedEnt_Head.SUPNUM;
       item.MIDA = null;
       item.COLOR = null;
       item.IT_S_CODE = null;
       item.QTYPERUNIT = null;
       item.UPDDATE = new Date();
       this.selectedItem = item;
       this.openDialogEntItem1(true);
     }
     else {
       this.getItem(this.selectedEnt_ItemRow.IT_CODE);
     }

  }

  openDialogBarcode() {
    
  }
  convertToNumber(str: any): number {
    return +str;
  }

  saveEntHead() {
    this.selectedEnt_ItemRow = null;
    this.itemsRowFilterd = null;
    this.selectedEnt_Head.REF2 = this.convertToNumber(this.selectedEnt_Head.REF2);
    this._ItemsManagmentService.postselectedEnt_Item(this.selectedEnt_Head, null, null,0)
      .subscribe(
        (data: number) => {
        }, // success path/
        error => this.error = error // error path
      );

  }

  saveEntItem() {
    //    let TABLEIDtmp = this.itemsRowFilterd.TABLEID;
    this.selectedEnt_ItemRow.IT_QTY = this.convertToNumber(this.selectedEnt_ItemRow.IT_QTY);
    this.selectedEnt_ItemRow.BONUS = this.convertToNumber(this.selectedEnt_ItemRow.BONUS);
    this.selectedEnt_ItemRow.IT_COST = this.convertToNumber(this.selectedEnt_ItemRow.IT_COST);
    this.selectedEnt_ItemRow.IT_DISC = this.convertToNumber(this.selectedEnt_ItemRow.IT_DISC);
    this.selectedEnt_ItemRow.IT_PRICE = this.convertToNumber(this.selectedEnt_ItemRow.IT_PRICE);
    this.selectedEnt_ItemRow.PRICE2 = this.convertToNumber(this.selectedEnt_ItemRow.PRICE2);
    this.selectedItem.PRICE2 = this.convertToNumber(this.selectedItem.PRICE2);
    this.selectedEnt_ItemRow.PRICE3 = this.convertToNumber(this.selectedEnt_ItemRow.PRICE3);
    this.selectedItem.PRICE3 = this.convertToNumber(this.selectedItem.PRICE3);
    this.selectedItem.CUT = this.convertToNumber(this.selectedItem.CUT);
    this.selectedItem.MINIQTY = this.convertToNumber(this.selectedItem.MINIQTY);
    this.selectedItem.MAXQTY = this.convertToNumber(this.selectedItem.MAXQTY);
    this.selectedItem.ITSORT = this.convertToNumber(this.selectedItem.ITSORT);
    this.selectedEnt_ItemRow.IT_TOTAL = this.convertToNumber(this.selectedEnt_ItemRow.IT_TOTAL);
//    this.SaveItem = this.selectedItem;
    let item = new SaveItem();
    item.TABLEID = this.selectedItem.TABLEID;
    item.SHOP_ID = this.selectedShop.ITEMS_FROM_SHOP_ID;
    item.BARCODE = this.selectedItem.BARCODE;
    item.CUT = this.selectedItem.CUT;
    item.DISCOUNT = this.selectedItem.DISCOUNT;
    item.STANDARTCOST = this.selectedItem.STANDARTCOST;
    item.LASTCOST = this.selectedItem.LASTCOST;
    item.ITSORT = this.selectedItem.ITSORT;
    item.IT_CODE = this.selectedItem.IT_CODE;
    item.IT_DESC = this.selectedItem.IT_DESC;
    item.IT_SALE = this.selectedItem.IT_SALE;
    item.FATHER = this.selectedItem.FATHER;
    item.MAXQTY = this.selectedItem.MAXQTY;
    item.MINIQTY = this.selectedItem.MINIQTY;
    item.NOVAT = this.selectedItem.NOVAT;
    item.PRICE1 = this.selectedEnt_ItemRow.IT_PRICE;
    item.PRICE2 = this.selectedItem.PRICE2;
    item.PRICE3 = this.selectedItem.PRICE3;
    item.PRICE4 = this.selectedItem.PRICE4;
    item.PRICE5 = this.selectedItem.PRICE5;
    item.PRICE6 = this.selectedItem.PRICE6;
    item.PRICE7 = this.selectedItem.PRICE7;
    item.PRICE8 = this.selectedItem.PRICE8;
    item.PRICE9 = this.selectedItem.PRICE9;
//    this.SaveItem.COSTPERC = this.selectedItem.COSTPERC;
    item.SALEREMARK = this.selectedItem.SALEREMARK;
    item.SCALE = this.selectedItem.SCALE;
    item.SUPPLIER = this.selectedItem.SUPPLIER;
    item.MIDA = this.selectedItem.MIDA;
    item.COLOR = this.selectedItem.COLOR;
    item.IT_S_CODE = this.selectedItem.IT_S_CODE;
    item.UPDDATE = new Date();
    item.IT_SALE = 'F'
    if (this.selectedItem.IT_SALE_BOOL) item.IT_SALE = 'T';
    item.IT_CHPRICE = 'F'
    if (this.selectedItem.IT_CHPRICE_BOOL) item.IT_CHPRICE = 'T';
    item.SCALE = 'F'
    if (this.selectedItem.SCALE_BOOL) item.SCALE = 'T';
    item.NOVAT = 'F'
    if (this.selectedItem.NOVAT_BOOL) item.NOVAT = 'T';
    item.IF_DISCOUNT = 'F'; 
    if (this.selectedItem.IF_DISCOUNT_BOOL) item.IF_DISCOUNT = 'T'; 


    this._ItemsManagmentService.postselectedEnt_Item(this.selectedEnt_Head, this.selectedEnt_ItemRow, item, 0)
//    this._ItemsManagmentService.postselectedEnt_Item(this.selectedEnt_Head, this.selectedEnt_ItemRow, this.selectedItem, 0)
      .subscribe(
        (data: number) => {
          let itemIndex = this.selectedEnt_ItemRows.findIndex(item => item.IT_LINE == this.reservedEnt_ItemRow.IT_LINE);
          this.selectedEnt_ItemRows[itemIndex] = this.selectedEnt_ItemRow;
          if (itemIndex = null) {
            this.selectedEnt_ItemRows.push(this.selectedEnt_ItemRow);
          }
          else
            this.selectedEnt_ItemRows[itemIndex] = this.selectedEnt_ItemRow;
          this.dataSource = new MatTableDataSource<Ent_Item>(this.selectedEnt_ItemRows);
        }, // success path/
        error => {
          this.selectedEnt_ItemRow = this.reservedEnt_ItemRow;
          this.selectedEnt_ItemRow.BONUS = this.REnt_ItemRow.BONUS;
          this.selectedEnt_ItemRow.IT_QTY = this.REnt_ItemRow.IT_QTY;
          this.selectedEnt_ItemRow.CASHIER = this.REnt_ItemRow.CASHIER;
          this.selectedEnt_ItemRow.IT_CODE = this.REnt_ItemRow.IT_CODE;
          this.selectedEnt_ItemRow.IT_DESC = this.REnt_ItemRow.IT_DESC;
          this.selectedEnt_ItemRow.NUMBER = this.REnt_ItemRow.NUMBER;
          this.selectedEnt_ItemRow.IT_LINE = this.REnt_ItemRow.IT_LINE;
          this.selectedEnt_ItemRow.TABLEID = this.REnt_ItemRow.TABLEID;
          this.selectedEnt_ItemRow.IT_CHPRICE = this.REnt_ItemRow.IT_CHPRICE;
          this.selectedEnt_ItemRow.IT_COST = this.REnt_ItemRow.IT_COST;
          this.selectedEnt_ItemRow.IT_DISC = this.REnt_ItemRow.IT_DISC;
          this.selectedEnt_ItemRow.IT_PRICE = this.REnt_ItemRow.IT_PRICE;
          this.selectedEnt_ItemRow.IT_TOTAL = this.REnt_ItemRow.IT_TOTAL;
          this.selectedEnt_ItemRow.PRICE2 = this.REnt_ItemRow.PRICE2;
          this.selectedEnt_ItemRow.PRICE3 = this.REnt_ItemRow.PRICE3;
          this.selectedEnt_ItemRow.VAT = this.REnt_ItemRow.VAT;

          
          let itemIndex = this.selectedEnt_ItemRows.findIndex(item => item.IT_LINE == this.reservedEnt_ItemRow.IT_LINE);
          this.selectedEnt_ItemRows[itemIndex] = this.selectedEnt_ItemRow;
          this._ItemsManagmentService.openSnackBar('שמירה נכשלה');
          this.dataSource = new MatTableDataSource<Ent_Item>(this.selectedEnt_ItemRows);
          this.error = error;

        }
    );
  }

  removeEntItem(SelectedEntlRow: Ent_Item) {
    this._ItemsManagmentService.postselectedEnt_Item(this.selectedEnt_Head, this.selectedEnt_ItemRow, this.SaveItem, 1)
      .subscribe(
        (data: number) => {
          const index = this.selectedEnt_ItemRows.findIndex(x => x.TABLEID === this.selectedEnt_ItemRow.TABLEID);
          this.selectedEnt_ItemRows.splice(index, 1);
          this.dataSource = new MatTableDataSource<Ent_Item>(this.selectedEnt_ItemRows);
        }, // success path/
        error => this.error = error // error path
    );

  }
     
}
