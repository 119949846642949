import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Customerlist } from './customerlist';
import { Shop } from '../../Shop';


@Injectable({
  providedIn: 'root'
})


export class CustomerlistService {
  configUrl = 'api/CUSTOMER_LIST';
  httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json',
    })
  };

  constructor(private http: HttpClient) {

  }

  
  getCustomerList(selectedShops: Shop[]){
    let shopIds: number[] = [];

    if (selectedShops && selectedShops.length > 0)
      shopIds = selectedShops.map(x => x.SHOP_ID);
       

    return this.http.post<Customerlist[]>(this.configUrl,
      {
        "shopIds": shopIds,
      }, this.httpOptions)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later ${error.status}, ' +
        'body was: ${error.error}');
  };
}
