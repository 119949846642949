import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'invoice-header',
  templateUrl: './invoice-header.component.html',
  styleUrls: ['./invoice-header.component.css']
})
export class InvoiceHeaderComponent implements OnInit {

  @Input('invoice') invoice: any = null;
  public displayedColumns: string[] = ['invoiceNumber', 'clientName', 'clientNumber', 'clientAddress', 'dateTime'];
  public dataSource = new MatTableDataSource([]);

  constructor() {

  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource([
      {
        invoiceNumber: this.invoice.invoiceNumber,
        clientName: this.invoice.clientName,
        clientNumber: this.invoice.clientNumber,
        clientAddress: this.invoice.clientAddress,
        dateTime: this.invoice.dateTime,
      }]);
  }

}
