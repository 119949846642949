import { Component, OnInit } from '@angular/core';
import { AuthorizeService } from '../authorize.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';


@Component({
  selector: 'app-login-menu',
  templateUrl: './login-menu.component.html',
  styleUrls: ['./login-menu.component.css']
})
export class LoginMenuComponent implements OnInit {
  public isAuthenticated: Observable<boolean>;
  public userName: Observable<string>;
  public userNameFirstLetter: Observable<string>;
 // public familyName: Observable<string>;
 // public givenName: Observable<string>;
 // public gender: Observable<string>;

  constructor(private authorizeService: AuthorizeService) { }

  ngOnInit() {
    this.isAuthenticated = this.authorizeService.isAuthenticated();
    this.userName = this.authorizeService.getUser().pipe(map(u => u && u.name));
    this.userNameFirstLetter = this.authorizeService.getUser().pipe(map(u => u && u.name[0].toUpperCase()));


   // this.familyName = this.authorizeService.getUser().pipe(map(u => u && u.family_name));
   // this.givenName = this.authorizeService.getUser().pipe(map(u => u && u.given_name));
   // this.gender = this.authorizeService.getUser().pipe(map(u => u && u.gender));

  }


}
