
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

export class UsersShopsService {
  configUrl = 'api/USERS_TO_SHOPS';
  httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json',
    })
  };

  constructor(private http: HttpClient) {
  }

  posttUsersShops(usersShops: any) { 
    return this.http.post<any>(this.configUrl, usersShops, this.httpOptions)
      .pipe(
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  getUsersShops() {
    return this.http.get<any>(this.configUrl)//Shop[]
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  /*


  deleteUsersShops(Id:number) {
    return this.http.delete<any>(this.configUrl + "/" + Id)//Shop[]
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }
  */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}
