
<header>
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-black border-bottom box-shadow">
    <div class="container">
      <button class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target=".navbar-collapse"
              aria-label="Toggle navigation"
              [attr.aria-expanded]="isExpanded"
              (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse"
           [ngClass]="{ show: isExpanded }">
        <ul class="navbar-nav flex-grow" style="width:100%;direction:rtl;text-align:right">
          <li class="onlyCellular" [ngClass]="{'visible': menuVisible}" (mouseover)="toggleMenu()" (mouseout)="toggleMenu()">
            <img src="../../assets/images/omega-logo.png" />
            <a class="navbar-brand text-white" href="tel:054-7757997">054-775-7997</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isAdmin" (click)="hideReportsMenuClick(); closeMenu()">
            <a class="nav-link text-white" [routerLink]="['/managment']">ניהול</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isAdmin" (click)="hideReportsMenuClick(); closeMenu()">
            <a class="nav-link text-white" [routerLink]="['/UsersShops']">שיוך חנויות</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isAdmin" (click)="hideReportsMenuClick(); closeMenu()">
            <a class="nav-link text-white" [routerLink]="['/UsersRoles']">הרשאות</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isStore" (click)="hideReportsMenuClick(); closeMenu()">
            <a class="nav-link text-white" [routerLink]="['/z-reports']">דו"ח Z</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isStore" (click)="hideReportsMenuClick(); closeMenu()">
            <a class="nav-link text-white" [routerLink]="['/items-managment']">ניהול פריטים</a>
          </li>
          <li class="nav-item haveSubMenu nav-table" style="width: 4rem;" [routerLinkActive]="['link-active']" *ngIf="isStore" (click)="showTableMenuClick(); closeMenu()">
            <a class="nav-link text-white" [routerLink]="[]">טבלאות </a>
            <div *ngIf="showTableMenu" style="width: 200px; height: 90px">
              <ul style="list-style: none; padding:0px">
                <li style="height: 16px" class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isStore" (click)="hideReportsMenuClick(); closeMenu()">
                  <a class="nav-link text-white" [routerLink]="['/itsort-managment']">ניהול קבוצות</a>
                </li>
                <li style="height: 16px" class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isStore" (click)="hideReportsMenuClick(); closeMenu()">
                  <a class="nav-link text-white" [routerLink]="['/suppliers-managment']">ניהול ספקים</a>
                </li>
                <li style="height: 16px" class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isStore" (click)="hideReportsMenuClick(); closeMenu()">
                  <a class="nav-link text-white" [routerLink]="['/mida-managment']">ניהול מידות</a>
                </li>
                <li style="height: 16px" class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isStore" (click)="hideReportsMenuClick(); closeMenu()">
                  <a class="nav-link text-white" [routerLink]="['/color-managment']">ניהול צבעים</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isStore" (click)="hideReportsMenuClick(); closeMenu()">
            <a class="nav-link text-white" [routerLink]="['/special-sales-managment']">מבצעים מיוחדים</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isAdmin" (click)="hideReportsMenuClick(); closeMenu()">
            <a class="nav-link text-white" [routerLink]="['/inventory-entry']">תעודת כניסה</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isAdmin" (click)="hideReportsMenuClick(); closeMenu()">
            <a class="nav-link text-white" [routerLink]="['/emv-serial-managment']">סידורי</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isAdmin" (click)="hideReportsMenuClick(); closeMenu()">
            <a class="nav-link text-white" [routerLink]="['/EntryOcr']">Ocr</a>
          </li>
          <li class="nav-item haveSubMenu" [routerLinkActive]="['link-active']" *ngIf="isStore" (click)="showReportsMenuClick(); closeMenu()">
            <a class="nav-link text-white" [routerLink]="[]">דו"חות</a>
            <div *ngIf="showReportsMenu" style="width: 300px; height: 130px">
              <ul style="list-style: none; padding:0px">
                <li style="height: 16px" class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isStore" (click)="closeMenu()">
                  <a class="nav-link text-white" [routerLink]="['/salesReportByGroup']">ד"וח מכירות מרוכז</a>
                </li>
                <li style="height: 16px" class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isStore" (click)="closeMenu()">
                  <a class="nav-link text-white" [routerLink]="['/SalesReportDatailedByGroups']">ד"וח מכירות מפורט</a>
                </li>
                <li style="height: 16px" class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isStore" (click)="closeMenu()">
                  <a class="nav-link text-white" [routerLink]="['/PerHourSales']">ד"וח מכירות שעתי</a>
                </li>
                <li style="height: 16px" class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isStore" (click)="closeMenu()">
                  <a class="nav-link text-white" [routerLink]="['/invoicing-report']">ד"וח חשבוניות</a>
                </li>
                <li style="height: 16px" class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isStore" (click)="closeMenu()">
                  <a class="nav-link text-white" [routerLink]="['/transactions-report']">דו"ח תנועות</a>
                </li>
                <li style="height: 2px" class="nav-item" [routerLinkActive]="['link-active']" *ngIf="isStore" (click)="closeMenu()">
                  <a class="nav-link text-white" [routerLink]="['/customer-list']">אלפון לקוחות</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="onlyDesktop" style="margin-top:-0.5rem">
            <img src="../../assets/images/omega-logo.png" />
          </li>
          <li id="version" style="margin-right: 0.5rem; text-align: center">
            <a class="navbar-brand omegaphone text-white" href="tel:054-7757997">054-775-7997</a>
            <br />
            <label>    Version 1.6.9.1</label>
          </li>
          <li>
            <app-login-menu></app-login-menu>
          </li>
        </ul>
      </div>
    </div>
  </nav>
 
</header>
