import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Item } from '../../items-managment/Item';
import { Shop } from '../../Shop';
import { ItemSearchComponent } from '../item-search/item-search.component';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs/internal/Subscription';
import { interval, Observable, of } from 'rxjs';
import { itSortRow } from '../../items-managment/itSortRow';
import { supplierRow } from '../../items-managment/supplierRow';
import { Ent_Head, Ent_Item } from '../ent-item';
import { ItemsManagmentService } from '../../items-managment/items-managment.service';
//import { FastPriceUpdateComponent } from '../../items-managment/fast-price-update/fast-price-update.component';
// import { select } from 'd3';



@Component({
  selector: 'inventory-item-edit',
  templateUrl: './inventory-item-edit.component.html',
  styleUrls: ['./inventory-item-edit.component.css']
})
export class InventoryItemEditComponent implements OnInit {

  public selectedEntItem: Ent_Item = null;
  public reservedEnt_ItemRow: Ent_Item = null;
  public itemsRows: Item[] = [];
  public supplierRows: supplierRow[] = [];
  public selectedItemsRow: Item;
  public itSortRows: itSortRow[] = [];
  public selecteditSortRow: itSortRow;
  public selectedEnt_Head: Ent_Head;
  public selectedShop: Shop = null;
 
  ifdisable: boolean;
  Code: any;
  VatPerc: number;
  lwidth: string;
  lheight: string;
  ltop: string;

  //public selectedEntfullRows: FullEnvItem[];

  public filterValue = "";

  constructor(public _ItemsManagmentService: ItemsManagmentService, private el: ElementRef, private renderer: Renderer2, public dialog1: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<InventoryItemEditComponent>) {

    this.selectedShop = data.selectedShop;
    this.supplierRows = data.supplierRows;
    this.itSortRows = data.itSortRows;
    this.itemsRows = data.itemsRows;
    this.selectedItemsRow = data.SelectedItem;
    this.selectedEnt_Head = data.Ent_Head;
    this.selectedEntItem = data.Ent_Item;
//    this.reservedEnt_ItemRow = data.reservedEnt_ItemRow;
//    var vat = this.selectedEnt_Head.VAT_PERC;
    if (this.selectedEnt_Head == null) this.VatPerc = 0;
    else
      this.VatPerc = 1 + Number(this.selectedEnt_Head.VAT_PERC)/ 100;
    this.Code = data.Code;
    if (this.Code == false) {
      if (this.selectedItemsRow != null) {
        this.selecteditSortRow = this.itSortRows.find(x => x.CODE == this.selectedItemsRow.ITSORT);
        this.ifdisable = true;
        this.stringsToBoolean();
        this.setCost();
        this.SetCostAfterSapakDiscount();
        this.setPriceWithoutVat();
        this.setProfit()
        this.setButtomProfit();
        this.setTopProfit();
      }
      else {
        this.implamentnewitem();
        this.setCost();
        this.SetCostAfterSapakDiscount();
        this.setPriceWithoutVat();
        this.setProfit()
        this.setButtomProfit();
        this.setTopProfit();
      }
    }
    else {
      this.ifdisable = false;
//      this.implamentnewitem();
      this.moveFocusToInputField('IT_CODE');
    }
  }

  subscription: Subscription;

  error: any;

  moveFocusToInputField(elementname: string) {
    const inputElement = this.el.nativeElement.querySelector('#'+elementname);
    if (inputElement) 
      this.renderer.selectRootElement(inputElement).focus();
  }

  implamentnewitem(){
    let item = new Item();
    item.PRICE1 = this.selectedEntItem.IT_PRICE;
    item.IT_CODE = this.selectedEntItem.IT_CODE;
    item.LASTCOST = this.selectedEntItem.IT_COST;
    item.IT_DESC = this.selectedEntItem.IT_DESC;
    item.BARCODE = null;
    item.IT_S_CODE = null;
    item.PRICE2 = null;
    item.PRICE3 = null;
    item.MAXQTY = null;
    item.MINIQTY = null;
    item.CUT = null;
    item.SALEREMARK = null;
    item.IF_DISCOUNT = 'Tr';
    item.IF_DISCOUNT_BOOL = true;
    item.TABLEID = 0;
    item.SHOP_ID = this.selectedEntItem.SHOP_ID;


    this.selectedItemsRow = item;
  }
  ngOnInit(): void {
    if (this.Code == false) {
      this.moveFocusToInputField('IT_QTY');
    }
    else {
      this.moveFocusToInputField('IT_CODE');
    }

  }

  ngAfterViewInit() {

    if (this.Code == false) {
      this.moveFocusToInputField('IT_QTY');
    }
    else {
      this.moveFocusToInputField('IT_CODE');
    }
  }

  closeComponent() {
    this.dialogRef.close();
  }

  saveComponent() {
//    alert(this.selectedEntItem.IT_CODE);
    this.data.selectedEntItem = this.selectedEntItem;
    this.data.itemsRows = this.selectedItemsRow;
    this.dialogRef.close();
    //    this.dialog.closeAll();
  }

  openDialogBarcode() {
    this.lwidth = '95%';

    this.lheight = '60%';
    this.ltop = '6.5rem';

    let dialogRef1 = this.dialog1.open(ItemSearchComponent
      , {
        disableClose: true,
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: this.lheight,
        width: this.lwidth,
        data: {
          itSortRows: this.itSortRows,
          supplierRows: this.supplierRows,
          selectedShop: this.selectedShop,
          itemsRows: this.itemsRows,

          
//          dialog: this.dialog,
        }
      }
    );


    dialogRef1.afterClosed().subscribe(result => {
      if (result) {
        this.selectedEntItem.IT_CODE = result.IT_CODE;
        this.selectedEntItem.IT_DESC = result.IT_DESC;
        this.selectedEntItem.IT_PRICE = result.PRICE1;
        this.selectedEntItem.PRICE2 = result.PRICE2;
        this.selectedEntItem.PRICE3 = result.PRICE3;
        this.selectedEntItem.IT_CHPRICE = result.IT_CHPRICE;
        this.selectedEntItem.VAT = result.VAT;
        this.selectedItemsRow = result;
        this.changeInputITSORT();
        this.stringsToBoolean();
        this.moveFocusToInputField("IT_S_CODE");
      }
      else {
      }
    });


  }

  stringsToBoolean() {
    this.selectedItemsRow.IT_SALE_BOOL = this.selectedItemsRow.IT_SALE.includes("T");
    this.selectedItemsRow.IT_CHPRICE_BOOL = this.selectedItemsRow.IT_CHPRICE.includes("T");
    this.selectedItemsRow.SCALE_BOOL = this.selectedItemsRow.SCALE.includes("T");
    this.selectedItemsRow.NOVAT_BOOL = this.selectedItemsRow.NOVAT.includes("T");
    this.selectedItemsRow.IF_DISCOUNT_BOOL = this.selectedItemsRow.IF_DISCOUNT.includes("T");
  }

  selectionChangeselectedITSORT(selectedCategory: itSortRow) {
    this.selectedItemsRow.ITSORT = selectedCategory.CODE;
    this.selecteditSortRow = selectedCategory;
    this.moveFocusToInputField("IT_S_CODE");

  }

  focusNext(nextInput: ElementRef) {
    if (nextInput) {
      nextInput.nativeElement.focus();
    }
  }

  IT_CODEonFocusOutEvent(event: any) {
    this.selectedItemsRow.IT_CODE = event.target.value;
    this.selectedEntItem.IT_CODE = event.target.value;
    this.getItem(this.selectedItemsRow.IT_CODE);


//    let selected = this.itemsRows.filter(x => x.IT_CODE == event.target.value);
  }

  getItem(SearchField: string) {
    let itemcode = this.selectedEntItem.IT_CODE;
    let itemdesc = this.selectedEntItem.IT_DESC;
    this._ItemsManagmentService.getItemSearch(this.selectedShop.ITEMS_FROM_SHOP_ID, -1, -1, null, SearchField)
      .subscribe(
        (data: any) => {
          if (data.itemsSEARCHRows[0].IT_CODE != null) {
            this.selectedItemsRow = data.itemsSEARCHRows[0];
            this.selectedEntItem.IT_CODE = this.selectedItemsRow.IT_CODE;
            this.selectedEntItem.IT_DESC = this.selectedItemsRow.IT_DESC;
            this.selectedEntItem.IT_PRICE = this.selectedItemsRow.PRICE1;
            this.selectedEntItem.PRICE2 = this.selectedItemsRow.PRICE2;
            this.selectedEntItem.PRICE3 = this.selectedItemsRow.PRICE3;
            this.selectedEntItem.IT_CHPRICE = this.selectedItemsRow.IT_CHPRICE;
            this.stringsToBoolean();
            this.changeInputITSORT();
          }
          else {
            this.selectedEntItem.IT_CODE = itemcode;
            this.selectedItemsRow.IT_CODE = itemcode;
            this.selectedItemsRow.IT_DESC = itemdesc;
            this.selectedEntItem.IT_DESC = itemdesc;
            this.selectedItemsRow.TABLEID = 0;
          }
          return;
        }, // success path/
        error => this.error = error // error path
    );
  }

  IT_CODEhandleKeyboardEvent(event) {
    this.selectedItemsRow.IT_CODE = event.target.value;
    if (event.keyCode === 13) {
      this.IT_CODEonFocusOutEvent(event);
      this.moveFocusToInputField("IT_DESC");
    }
  }

  IT_DESConFocusOutEvent(event) {
    this.selectedItemsRow.IT_DESC = event.target.value;
    this.selectedEntItem.IT_DESC = event.target.value;
  }

  IT_DESChandleKeyboardEvent(event) {
    this.selectedItemsRow.IT_DESC = event.target.value;
    if (event.keyCode === 13) {
      this.IT_DESConFocusOutEvent(event);
      this.moveFocusToInputField("BARCODE");
    }
  }


  ITSORTonFocusOutEvent(event: any) {
    this.selectedItemsRow.ITSORT = event.target.value;
  }

  IT_SORThandleKeyboardEvent(event) {
    this.selectedItemsRow.ITSORT = event.target.value;
    if (event.keyCode === 13) {
      this.ITSORTonFocusOutEvent(event);
      this.moveFocusToInputField("ITSORTNAME");
    }
  }

  ITSORTNAMEonFocusOutEvent(event: any) {
    this.selectedItemsRow.ITSORT = event.target.value;
  }

  ITSORTNAMEhandleKeyboardEvent(event) {
    this.selectedItemsRow.ITSORT = event.target.value;
    if (event.keyCode === 13) {
      this.ITSORTNAMEonFocusOutEvent(event);
      this.moveFocusToInputField("IT_S_CODE");
    }
  }

  IT_S_CODEonFocusOutEvent(event: any) {
    this.selectedItemsRow.IT_S_CODE = event.target.value;
  }

  IT_S_CODEhandleKeyboardEvent(event) {
    this.selectedItemsRow.IT_S_CODE = event.target.value;
    if (event.keyCode === 13) {
      this.IT_S_CODEonFocusOutEvent(event);
      this.moveFocusToInputField("IT_QTY");
    }
  }
    
  BONUSonFocusOutEvent(event: any) {
    this.selectedEntItem.BONUS = event.target.value;
  }

  BONUShandleKeyboardEvent(event) {
    this.selectedEntItem.BONUS = event.target.value;
    if (event.keyCode === 13) {
      this.BONUSonFocusOutEvent(event);
      this.moveFocusToInputField("IT_COST");
    }
  }

  IT_QTYFocusOutEvent(event: any) {
    this.selectedEntItem.IT_QTY = event.target.value;
    this.calculateIT_TOTAL();
  }

  IT_QTYhandleKeyboardEvent(event) {
    this.selectedEntItem.IT_QTY = event.target.value;
    if (event.keyCode === 13) {
      this.IT_QTYFocusOutEvent(event);
      this.moveFocusToInputField("BONUS");
    }
  }

  IT_COSTFocusOutEvent(event: any) {
    this.selectedEntItem.IT_COST = event.target.value;
    this.calculateIT_TOTAL();
    this.SetCostAfterSapakDiscount();
  }

  IT_COSThandleKeyboardEvent(event) {
    this.selectedEntItem.IT_COST = event.target.value;
    if (event.keyCode === 13) {
      this.IT_COSTFocusOutEvent(event);
      this.moveFocusToInputField("IT_DISC");
    }
  }

  IT_DISCfocusOutEvent(event: any) {
    this.selectedEntItem.IT_DISC = event.target.value;
    this.calculateIT_TOTAL();
  }

  IT_DISChandleKeyboardEvent(event) {
    this.selectedEntItem.IT_DISC = event.target.value;
    if (event.keyCode === 13) {
      this.IT_DISCfocusOutEvent(event);
      this.moveFocusToInputField("CostWithoutVatA");
    }
  }

  CostWithoutVatAonFocusOutEvent(event: any) {
//    this.selectedEntItem.co IT_DISC = event.target.value;
    this.calculateIT_TOTAL();
  }

  CostWithoutVatAhandleKeyboardEvent(event) {
// daniel    this.selectedEntItem.IT_DISC = event.target.value;
    if (event.keyCode === 13) {
      this.CostWithoutVatAonFocusOutEvent(event);
      this.moveFocusToInputField("IT_TOTAL");
    }
  }

  IT_TOTALonFocusOutEvent(event: any) {
    this.selectedEntItem.IT_TOTAL = event.target.value;
    this.calculateIT_TOTAL();
  }

  IT_TOTALhandleKeyboardEvent(event) {
    this.selectedEntItem.IT_TOTAL = event.target.value;
    if (event.keyCode === 13) {
      this.IT_TOTALonFocusOutEvent(event);
      this.moveFocusToInputField("IT_PRICE");
    }
  }

  IT_PRICEonFocusOutEvent(event: any) {
    this.selectedEntItem.IT_PRICE = event.target.value;
    this.selectedItemsRow.PRICE1 = event.target.value;
    this.calculateIT_TOTAL();
  }

  IT_PRICEhandleKeyboardEvent(event) {
    this.selectedEntItem.IT_PRICE = event.target.value;
    this.selectedItemsRow.PRICE1 = event.target.value;
    if (event.keyCode === 13) {
      this.IT_PRICEonFocusOutEvent(event);
      this.moveFocusToInputField("DISC_P");
    }
  }

  DISC_PonFocusOutEvent(event: any) {
    this.selectedEnt_Head.DISCT_P = event.target.value;
    this.calculateIT_TOTAL();
  }

  DISC_PhandleKeyboardEvent(event) {
    this.selectedEnt_Head.DISCT_P = event.target.value;
    if (event.keyCode === 13) {
      this.DISC_PonFocusOutEvent(event);
      this.moveFocusToInputField("PROFITD");
    }
  }

  
  PROFITDonFocusOutEvent(event: any) {
    this.selectedEnt_Head.DISCT_T = event.target.value;
    this.calculateIT_TOTAL();
  }

  PROFITDhandleKeyboardEvent(event) {
    this.selectedEnt_Head.DISCT_T = event.target.value;
    if (event.keyCode === 13) {
      this.PROFITDonFocusOutEvent(event);
      this.moveFocusToInputField("PROFITA");
    }
  }

  PROFITAonFocusOutEvent(event: any) {
    this.selectedEnt_Head.DISCT_T = event.target.value;
    this.calculateIT_TOTAL();
  }

  PROFITAhandleKeyboardEvent(event) {
    this.selectedEnt_Head.DISCT_T = event.target.value;
    if (event.keyCode === 13) {
      this.PROFITAonFocusOutEvent(event);
      this.moveFocusToInputField("PRICE2");
    }
  }

  PRICE2onFocusOutEvent(event: any) {
    this.selectedItemsRow.PRICE2 = event.target.value;
    this.calculateIT_TOTAL();
  }

  PRICE2handleKeyboardEvent(event) {
    this.selectedItemsRow.PRICE2 = event.target.value;
    if (event.keyCode === 13) {
      this.PROFITAonFocusOutEvent(event);
      this.moveFocusToInputField("PRICE3");
    }
  }

  PRICE3onFocusOutEvent(event: any) {
    this.selectedItemsRow.PRICE3 = event.target.value;
    this.calculateIT_TOTAL();
  }

  PRICE3handleKeyboardEvent(event) {
    this.selectedItemsRow.PRICE3 = event.target.value;
    if (event.keyCode === 13) {
      this.PROFITAonFocusOutEvent(event);
      this.moveFocusToInputField("MINQTY");
    }
  }

  MINQTYonFocusOutEvent(event: any) {
    this.selectedItemsRow.MINIQTY = event.target.value;
    this.calculateIT_TOTAL();
  }

  MINQTYhandleKeyboardEvent(event) {
    this.selectedItemsRow.MINIQTY = event.target.value;
    if (event.keyCode === 13) {
      this.PROFITAonFocusOutEvent(event);
      this.moveFocusToInputField("MAXQTY");
    }
  }

  BARCODEonFocusOutEvent(event: any) {
    this.selectedItemsRow.BARCODE = event.target.value;
    this.calculateIT_TOTAL();
  }

  BARCODEhandleKeyboardEvent(event) {
    this.selectedItemsRow.BARCODE = event.target.value;
    if (event.keyCode === 13) {
      this.PROFITAonFocusOutEvent(event);
      this.moveFocusToInputField("ITSORT");
    }
  }

  CUTonFocusOutEvent(event: any) {
    this.selectedItemsRow.CUT = event.target.value;
    this.calculateIT_TOTAL();
  }

  CUThandleKeyboardEvent(event) {
    this.selectedItemsRow.CUT = event.target.value;
    if (event.keyCode === 13) {
      this.PROFITAonFocusOutEvent(event);
      this.moveFocusToInputField("ITSORT");
    }
  }

  MAXQTYonFocusOutEvent(event: any) {
    this.selectedItemsRow.MAXQTY = event.target.value;
    this.calculateIT_TOTAL();
  }

  MAXQTYhandleKeyboardEvent(event) {
    this.selectedItemsRow.MAXQTY = event.target.value;
    if (event.keyCode === 13) {
      this.PROFITAonFocusOutEvent(event);
      this.moveFocusToInputField("CUT");
    }
  }
 
  BAR_DISConFocusOutEvent(event: any) {
    this.selectedItemsRow.MAXQTY = event.target.value;
    this.calculateIT_TOTAL();
  }

  BAR_DISChandleKeyboardEvent(event) {
    this.selectedItemsRow.MAXQTY = event.target.value;
    if (event.keyCode === 13) {
      this.PROFITAonFocusOutEvent(event);
      this.moveFocusToInputField("CUT");
    }
  }

  private calculateIT_TOTAL(): void {
    this.selectedEntItem.IT_TOTAL = this.selectedEntItem.IT_QTY * this.selectedEntItem.IT_COST * (100 - this.selectedEntItem.IT_DISC) / 100;
    this.SetCostAfterSapakDiscount();
    this.setProfit()
    this.setButtomProfit();
    this.setTopProfit();
  }


  changeInputITSORT() {
    this.selecteditSortRow = this.itSortRows.find(x => x.CODE == this.selectedItemsRow.ITSORT);
  }

  SetCostAfterSapakDiscount() {

    if (this.selectedEntItem.IT_COST > 0) this.selectedItemsRow.costWithoutVat = this.selectedEntItem.IT_COST
    else {
      this.selectedItemsRow.costWithoutVat = this.selectedItemsRow.LASTCOST;
      if (this.selectedItemsRow.costWithoutVat == null || this.selectedItemsRow.costWithoutVat == 0)
        if (this.selectedItemsRow.STANDARTCOST >= 0) this.selectedItemsRow.costWithoutVat = this.selectedItemsRow.STANDARTCOST;
    }
    if (this.selectedEntItem.IT_DISC >= 0) this.selectedItemsRow.COSTPERC = this.selectedEntItem.IT_DISC;

    if (this.selectedEntItem.IT_COST == 0) this.selectedItemsRow.costWithoutVatAfterSapakDiscount = 0
    else
      this.selectedItemsRow.costWithoutVatAfterSapakDiscount = this.selectedEntItem.IT_COST * (100 - this.selectedItemsRow.COSTPERC) / 100;
  }

/*
  selectionChangeselectedSUPPLIER(selectedSupplier: supplierRow) {
    this.selectedSupplier = selectedSupplier;
    this.filterItems();
  }*/



  //applyFilter(event: Event) {
  //  this.filterValue = (event.target as HTMLInputElement).value;
  //  this.dataSource.filter = this.filterValue.trim().toLowerCase();
  // }


  


  private setCost(): void {
    this.selectedItemsRow.costWithoutVat = this.selectedItemsRow.STANDARTCOST;
    this.selectedItemsRow.costWithVat = this.selectedItemsRow.NOVAT === "T" ? this.selectedItemsRow.STANDARTCOST : this.selectedItemsRow.STANDARTCOST * this.VatPerc;
    this.selectedItemsRow.costWithVat = Math.round((this.selectedItemsRow.costWithVat + Number.EPSILON) * 100) / 100
  }

 
  private setPriceWithoutVat(): void {
    this.selectedItemsRow.priceWithoutVat = this.selectedItemsRow.NOVAT !== "T" ? this.selectedItemsRow.PRICE1 / this.VatPerc : this.selectedItemsRow.PRICE1;
    this.selectedItemsRow.priceWithoutVat = Math.round(this.selectedItemsRow.priceWithoutVat * 100) / 100

    
  }


  public topProfitChange(topProfit: number): void {
    this.selectedItemsRow.topProfit = topProfit;

    this.selectedItemsRow.PRICE1 = (100 * this.selectedItemsRow.costWithVatAfterSapakDiscount) / (100 - topProfit)
    this.selectedItemsRow.PRICE1 = Math.round((this.selectedItemsRow.PRICE1 + Number.EPSILON) * 100) / 100

    this.setPriceWithoutVat();
    this.setProfit()
    this.setButtomProfit();
  }


  public buttomProfitChange(buttomProfit: number): void {
    this.selectedItemsRow.buttomProfit = buttomProfit;
    this.selectedItemsRow.profit = (buttomProfit / 100) * this.selectedItemsRow.costWithoutVatAfterSapakDiscount;
    this.selectedItemsRow.priceWithoutVat = this.selectedItemsRow.profit + this.selectedItemsRow.costWithoutVatAfterSapakDiscount;

    this.selectedItemsRow.PRICE1 = this.selectedItemsRow.NOVAT !== "T" ? this.selectedItemsRow.priceWithoutVat * this.VatPerc : this.selectedItemsRow.priceWithoutVat;
    this.selectedItemsRow.PRICE1 = Math.round((this.selectedItemsRow.PRICE1 + Number.EPSILON) * 100) / 100

    this.setTopProfit();
  }

  public NOVATChange() {
    console.log('NOVATChange');
    this.setCost();
    this.SetCostAfterSapakDiscount();
    this.setPriceWithoutVat();
    this.setProfit()
    this.setButtomProfit();
    this.setTopProfit();
  }

  public COSTPERCChange(costperc: number): void {
    console.log('COSTPERCChange', costperc);
    this.data.editItem.COSTPERC = costperc;
    this.SetCostAfterSapakDiscount();
    this.setPriceWithoutVat();
    this.setProfit()
    this.setButtomProfit();
    this.setTopProfit();
  }

  public costWithVatChange(costWithVat: number) {
    console.log('costWithVatChange'), costWithVat;
    this.selectedItemsRow.costWithVat = costWithVat;
    this.selectedItemsRow.STANDARTCOST = (this.selectedItemsRow.NOVAT !== "T") ? costWithVat / this.VatPerc : costWithVat;

    this.selectedItemsRow.STANDARTCOST = Math.round((this.selectedItemsRow.STANDARTCOST + Number.EPSILON) * 100) / 100
    this.selectedItemsRow.costWithoutVat = this.selectedItemsRow.STANDARTCOST;

    this.SetCostAfterSapakDiscount();
    this.setPriceWithoutVat();
    this.setProfit()
    this.setButtomProfit();
    this.setTopProfit();
  }

  public costWithoutVatChange(costWithoutVat: number): void {
    this.selectedItemsRow.costWithoutVat = costWithoutVat;
    this.selectedItemsRow.STANDARTCOST = costWithoutVat
    this.selectedItemsRow.costWithVat = this.data.editItem.NOVAT === "T" ? this.selectedItemsRow.STANDARTCOST : this.selectedItemsRow.STANDARTCOST * this.VatPerc;
    this.selectedItemsRow.costWithVat = Math.round((this.selectedItemsRow.costWithVat + Number.EPSILON) * 100) / 100

    this.SetCostAfterSapakDiscount();
    this.setPriceWithoutVat();
    this.setProfit()
    this.setButtomProfit();
    this.setTopProfit();
  }

  public priceChange(price: number): void {
    console.log('priceChange', price)
    this.selectedItemsRow.PRICE1 = price;
    this.setPriceWithoutVat();
    this.setProfit()
    this.setButtomProfit();
    this.setTopProfit();
  }

  setProfit()  {
//    alert(this.selectedItemsRow.costWithoutVatAfterSapakDiscount);

    this.selectedItemsRow.profit = (this.selectedEntItem.IT_PRICE / this.VatPerc - this.selectedItemsRow.costWithoutVatAfterSapakDiscount);
//    console.log('setProfit', this.data.editItem.profit)

  }


  private setButtomProfit(): void {
    this.selectedItemsRow.buttomProfit = (this.selectedItemsRow.profit / this.selectedItemsRow.costWithoutVatAfterSapakDiscount) * 100;
    this.selectedItemsRow.buttomProfit = Math.round((this.selectedItemsRow.buttomProfit + Number.EPSILON) * 100) / 100;
  }

  private setTopProfit(): void {
    this.selectedItemsRow.topProfit = (this.selectedItemsRow.profit / (this.selectedEntItem.IT_PRICE / this.VatPerc)) * 100
    this.selectedItemsRow.topProfit = Math.round((this.selectedItemsRow.topProfit + Number.EPSILON) * 100) / 100;
  }
/*
  private SetCostAfterSapakDiscount(): void {
    if (!this.data.editItem.COSTPERC)
      this.data.editItem.COSTPERC = 0;

    this.data.editItem.costWithVatAfterSapakDiscount = this.data.editItem.costWithVat * (100 - this.data.editItem.COSTPERC) / 100
    this.data.editItem.costWithoutVatAfterSapakDiscount = this.data.editItem.costWithoutVat * (100 - this.data.editItem.COSTPERC) / 100
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) {
    console.log('data', data);
    // this.supplierRows = data.supplierRows;
    //this.itSortRows = data.itSortRows;
    this.changeInputITSORT();
    this.changeInputSUPPLIER();

    
    this.setCost();
    this.SetCostAfterSapakDiscount();
    this.setPriceWithoutVat();
    this.setProfit()
    this.setButtomProfit();
    this.setTopProfit();
    
    //this.data.editInv.IT_QTY;
    // this.data.editItem.STANDARTCOST = this.data.editInv.IT_COST;

    //console.log("this.data.selectedEntfullRows", this.data.selectedEntfullRows)
    // let tt = this.data.selectedEntfullRows.filter(x => x.IT_CODE == this.data.editItem.IT_CODE);
    // this.selectedItem = (tt.length > 0) ? tt[0] : {};

    //console.log('this.itSortRows', this.itSortRows);
    // this.itemsRowsFilterd = [];
    //this.dataSource = new MatTableDataSource<Item>(this.itemsRowsFilterd)
    //this.dataSource.paginator = this.paginator;
    //this.startDelayedFilter();
  }

  subscription: Subscription;
  startDelayedFilter(): void {
    const source = interval(1000);
    this.subscription = source.subscribe(val => {
      if (this.itemsRowsFilterd.length === 0) {
        //this.selectedCategory = this.selectAllCategories;
        //this.selectedSupplier = this.selectAllSuppliers;
        //this.filterItems()
        this.subscription.unsubscribe();
      }
      return 1;
    }
    );
  }



 

  public selectedITSORT: itSortRow;
  changeInputITSORT() {
    this.selectedITSORT = this.data.itSortRows.find(x => x.CODE == this.data.editItem.ITSORT);
  }

  selectionChangeselectedITSORT(selectedCategory: itSortRow) {
    this.data.editItem.ITSORT = selectedCategory.CODE;
  }

  public selectedSUPPLIER: supplierRow;
  changeInputSUPPLIER() {
    this.selectedSUPPLIER = this.data.supplierRows.find(x => x.CLNUM == this.data.editItem.SUPPLIER);
  }

  selectionChangeselectedSUPPLIER(selectedSupplier: supplierRow) {
    this.data.editItem.SUPPLIER = selectedSupplier.CLNUM;
  }
  
  filterItems() {
    this.itemsRowsFilterd = this.data.itemsRows.
      filter(x =>
        (x.ITSORT === this.selectedCategory.CODE || this.selectedCategory.DESCR === "כל הקטגוריות") &&
        (x.SUPPLIER === this.selectedSupplier.CLNUM || this.selectedSupplier.CLNUM === 999999999));
    this.dataSource = new MatTableDataSource<Item>(this.itemsRowsFilterd);
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    this.dataSource.paginator = this.paginator;
  }

  public selectRow(row: Item) {
    let fullEnvItem = new FullEnvItem();
    fullEnvItem.IT_CODE = row.IT_CODE;
    fullEnvItem.IT_DESC = row.IT_DESC;
    this.data.selectedEntfullRows.push(fullEnvItem);
    console.log('selectRow this.data.selectedEntfullRows', this.data.selectedEntfullRows)
    //this.data.selectedEntfullRows = this.data.selectedEntfullRows.filter(x => x)
  }clostDialog

  openDialog(selectedItem: Item) {
    const backupItem: Item = JSON.parse(JSON.stringify(selectedItem));
    const dialogRef = this.dialog.open(FastPriceUpdateComponent
      , {
        disableClose: true,
        data: {
          selectedItem: selectedItem
        }
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.calculateIT_TOTAL();
        //this.save();
      }
      else {
        this.data.editItem.PRICE1 = backupItem.PRICE1;
        this.data.editItem.COSTPERC = backupItem.COSTPERC;
        this.data.editItem.NOVAT = backupItem.NOVAT;
        this.data.editItem.STANDARTCOST = backupItem.STANDARTCOST;
      }

    });
  }

  public qtyChange() {
    this.calculateIT_TOTAL();
  }

  public STANDARTCOSTChange() {
    this.calculateIT_TOTAL();
  }

  private calculateIT_TOTAL(): void {
    this.data.editInv.IT_TOTAL = this.data.editInv.IT_QTY * this.data.editItem.STANDARTCOST
  }


  public clostDialog() {
    this.data.dialog.closeAll();
  }
  */
}

