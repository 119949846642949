import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { Item } from '../Item';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'fast-price-update',
  templateUrl: './fast-price-update.component.html',
  styleUrls: ['./fast-price-update.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class FastPriceUpdateComponent implements OnInit {

  public selectedItem: Item;

  public vat = 1.17;
  public sapakDiscount: number;

  public costWithoutVat: number;
  public costWithVat: number;

  public costWithoutVatAfterSapakDiscount: number;
  public costWithVatAfterSapakDiscount: number;

  public buttomProfit: number;
  public topProfit: number;

  public profit: number;
  public priceWithoutVat: number


  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedItem = data.selectedItem;

    this.setCost();
    this.SetCostAfterSapakDiscount();
    this.setPriceWithoutVat();
    this.setProfit()
    this.setButtomProfit();
    this.setTopProfit();
  }


  public topProfitChange(topProfit: number): void {
    this.topProfit = topProfit;

    this.selectedItem.PRICE1 = (100 * this.costWithVatAfterSapakDiscount) / (100 - topProfit)
    this.selectedItem.PRICE1 = Math.round((this.selectedItem.PRICE1 + Number.EPSILON) * 100) / 100

    this.setPriceWithoutVat();
    this.setProfit()
    this.setButtomProfit();
  }


  public buttomProfitChange(buttomProfit: number): void {
    console.log('buttomProfitChange', buttomProfit)
    this.buttomProfit = buttomProfit; 
    this.profit = (buttomProfit / 100) * this.costWithoutVatAfterSapakDiscount;
    this.priceWithoutVat = this.profit + this.costWithoutVatAfterSapakDiscount;

    this.selectedItem.PRICE1 = this.selectedItem.NOVAT !== "T" ? this.priceWithoutVat * this.vat : this.priceWithoutVat;
    this.selectedItem.PRICE1 = Math.round((this.selectedItem.PRICE1 + Number.EPSILON) * 100) / 100

    this.setTopProfit();
  }

  public NOVATChange() {
    console.log('NOVATChange');
    this.setCost();
    this.SetCostAfterSapakDiscount();
    this.setPriceWithoutVat();
    this.setProfit()
    this.setButtomProfit();
    this.setTopProfit();
  }

  public COSTPERCChange(costperc: number): void{
    console.log('COSTPERCChange', costperc);
    this.selectedItem.COSTPERC = costperc;
    this.SetCostAfterSapakDiscount();
    this.setPriceWithoutVat();
    this.setProfit()
    this.setButtomProfit();
    this.setTopProfit();
  }

  public costWithVatChange(costWithVat: number) {
    console.log('costWithVatChange'), costWithVat;
    this.costWithVat = costWithVat;
    this.selectedItem.STANDARTCOST = (this.selectedItem.NOVAT !== "T") ? costWithVat / this.vat : costWithVat;

    this.selectedItem.STANDARTCOST = Math.round((this.selectedItem.STANDARTCOST + Number.EPSILON) * 100) / 100
    this.costWithoutVat = this.selectedItem.STANDARTCOST;

    this.SetCostAfterSapakDiscount();
    this.setPriceWithoutVat();
    this.setProfit()
    this.setButtomProfit();
    this.setTopProfit();
  }

  public costWithoutVatChange(costWithoutVat: number): void{
    console.log('costWithoutVatChange', costWithoutVat)
    this.costWithoutVat = costWithoutVat;
    this.selectedItem.STANDARTCOST = costWithoutVat
    this.costWithVat = this.selectedItem.NOVAT === "T" ? this.selectedItem.STANDARTCOST : this.selectedItem.STANDARTCOST * this.vat;
    this.costWithVat = Math.round((this.costWithVat + Number.EPSILON) * 100) / 100

    this.SetCostAfterSapakDiscount();
    this.setPriceWithoutVat();
    this.setProfit()
    this.setButtomProfit();
    this.setTopProfit();
  }

  public priceChange(price: number): void {
    console.log('priceChange', price)
    this.selectedItem.PRICE1 = price;
    this.setPriceWithoutVat();
    this.setProfit()
    this.setButtomProfit();
    this.setTopProfit();
  }

  private setProfit(): void {
    this.profit = (this.priceWithoutVat - this.costWithoutVatAfterSapakDiscount);
    console.log('setProfit', this.profit)

  }


  private setButtomProfit(): void {
    this.buttomProfit = (this.profit / this.costWithoutVatAfterSapakDiscount) * 100;
    this.buttomProfit = Math.round((this.buttomProfit + Number.EPSILON) * 100) / 100;
  }

  private setTopProfit(): void {
    this.topProfit = (this.profit / this.priceWithoutVat) * 100
    this.topProfit = Math.round((this.topProfit + Number.EPSILON) * 100) / 100;
  }


  private setCost(): void {
    this.costWithoutVat = this.selectedItem.STANDARTCOST;
    this.costWithVat = this.selectedItem.NOVAT === "T" ? this.selectedItem.STANDARTCOST : this.selectedItem.STANDARTCOST * this.vat;
    this.costWithVat = Math.round((this.costWithVat + Number.EPSILON) * 100) / 100
  }

  private setPriceWithoutVat(): void {
    this.priceWithoutVat = this.selectedItem.NOVAT !== "T" ? this.selectedItem.PRICE1 / this.vat : this.selectedItem.PRICE1;
    console.log('setPriceWithoutVat', this.priceWithoutVat)
    this.priceWithoutVat = Math.round(this.priceWithoutVat * 100) / 100
    console.log('setPriceWithoutVat', this.priceWithoutVat)

}
  private SetCostAfterSapakDiscount(): void {
    if (!this.selectedItem.COSTPERC)
      this.selectedItem.COSTPERC = 0;

    this.costWithVatAfterSapakDiscount = this.costWithVat * (100 - this.selectedItem.COSTPERC) / 100
    this.costWithoutVatAfterSapakDiscount = this.costWithoutVat * (100 - this.selectedItem.COSTPERC) / 100
  }


  ngOnInit(): void {
  }

}
