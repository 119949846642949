import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'pay-list',
  templateUrl: './pay-list.component.html',
  styleUrls: ['./pay-list.component.css']
})
export class PayListComponent implements OnInit {

  @Input('report') report: any = null;
  public displayedColumns: string[] = ['cash', 'checks', 'creditCard', 'hakafa','netPayment', 'sum'];
  public dataSource = new MatTableDataSource([]);

  constructor() {

  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource([this.report.payListRows[this.report.payListRows.length-1]]);
  }



}

