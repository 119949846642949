import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, timer, observable, Observable } from 'rxjs';
import { catchError, retry, delay, delayWhen, retryWhen, take } from 'rxjs/operators';
import { AuthorizeService } from 'src/api-authorization/authorize.service';

@Injectable({
  providedIn: 'root'
})
export class NavMenuService {
  configUrl = 'api/ROLES_BY_USER';
  httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json',
    })
  };

  constructor(private http: HttpClient, private authorizeService: AuthorizeService) {
  }

  public getRoles() {
    return this.http.get<string[]>(this.configUrl, this.httpOptions)
      .pipe(
        catchError(this.handleError), // then handle the error
        retryWhen(errors => errors.pipe(delay(1000), take(10)))
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } 
    else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log( `Backend returned code ${error.status}, body was: ${error.error}` );

      switch (error.status) {
        case 401: {
          // Unauthorized response
          this.authorizeService.signOut( null );
        }
      }
    }
    
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  };
}

