import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MyShopsService } from '../my-shops.service';
import { Shop } from '../../Shop';

@Component({
  selector: 'my-shops-selection',
  templateUrl: './my-shops-selection.component.html',
  styleUrls: ['./my-shops-selection.component.css']
})
export class MyShopsSelectionComponent implements OnInit {

  @Output() shopsChanged = new EventEmitter<Shop[]>();

  public shops: Shop[] = [];
  public shopsFilterd: Shop[] = [];
  public filterShops = "";
  public selectedShops: Shop[];

  error: any;

  constructor(public _myShopsService: MyShopsService) { }

  ngOnInit(): void {
    this.getShops();
  }

  getShops() {
    this._myShopsService.getMyShops()
      .subscribe(
        (data: Shop[]) => {
          this.shops = data;
          this.onShopFilterChange();
          this.selectAllShops();
        }, // success path/
        error => this.error = error // error path
      );
  }

  public onShopFilterChange() {
    this.filterShops = this.filterShops.trim();
    if (this.filterShops.length > 0)
      this.shopsFilterd = this.shops.filter(x => x.SHOP_NAME.indexOf(this.filterShops) > -1)
    else
      this.shopsFilterd = this.shops.filter(x => x)
  }

  public clearAllShops(): void {
    this.selectedShops = [];
    this.onShopsChange(this.selectedShops);
  }

  public selectAllShops(): void {
    this.selectedShops = this.shops.filter(x => x);
    this.onShopsChange(this.selectedShops);
  }

  public onShopsChange(selectedShops: Shop[]) {
    this.selectedShops = selectedShops;
    this.shopsChanged.emit(this.selectedShops);
  }

}
