<div class="my-shops-selection">
  <my-shops-selection (shopsChanged)="shopsChanged($event)"></my-shops-selection>
</div>
<br />

<table mat-table [dataSource]="this.dataSource" class="mat-elevation-z8" matSort #CustomerSort="matSort">

  <ng-container matColumnDef="SHORT_NAME">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>חנות</th>
    <td mat-cell *matCellDef="let element"> {{element.SHORT_NAME}} </td>
  </ng-container>

  <ng-container matColumnDef="CLNUM">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>מספר</th>
    <td mat-cell *matCellDef="let element"> {{element.CLNUM}} </td>
  </ng-container>

  <ng-container matColumnDef="CLNAME">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>שם לקוח</th>
    <td mat-cell *matCellDef="let element"> {{element.CLNAME}} </td>
  </ng-container>
  <ng-container matColumnDef="ADDRESS">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>כתובת</th>
    <td mat-cell *matCellDef="let element"> {{element.ADDRESS}} </td>
  </ng-container>
  <ng-container matColumnDef="CITY">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>עיר</th>
    <td mat-cell *matCellDef="let element"> {{element.CITY}} </td>
  </ng-container>

  <ng-container matColumnDef="HPHONE">
    <th mat-header-cell *matHeaderCellDef>טלפון בית</th>
    <td mat-cell *matCellDef="let element"> {{element.HPHONE}} </td>
  </ng-container>
  <ng-container matColumnDef="SPHONE">
    <th mat-header-cell *matHeaderCellDef>נייד</th>
    <td mat-cell *matCellDef="let element"> {{element.SPHONE}} </td>
  </ng-container>
  <ng-container matColumnDef="LAST_UPDATE">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>עדכון אחרון</th>
    <td mat-cell *matCellDef="let element"> {{element.LAST_UPDATE | date: 'dd/MM/yyyy'}} </td>
  </ng-container>



  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">{{input && input.value}}"</td>
  </tr>
</table>



