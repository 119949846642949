<div>
  <mat-form-field appearance="fill" class="filterFields">
    <mat-label>בחר חנות</mat-label>
    <mat-select [(ngModel)]="selectedShop" (selectionChange)="selectionChangeShop($event.value)">
      <mat-option *ngFor="let shop of shops" [value]="shop">{{ shop.SHOP_NAME }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="filterFields">
    <mat-label>חיפוש</mat-label>
    <input autocomplete="off" matInput (keyup)="applyFilter($event)" placeholder="חיפוש" #input>
  </mat-form-field>

  <div class="inputDropDown">
    <button mat-raised-button color="primary" (click)="newEmvSerial()">חדש</button>
    <button mat-raised-button color="primary" (click)="save()">שמור</button>
  </div>


</div>

<div style="clear:both" *ngIf="selectedEmvSerialRow">


  <mat-form-field class="edit-item medium">
    <mat-label>שם חנות </mat-label>
    <input autocomplete="off" matInput [(ngModel)]="selectedEmvSerialRow.SHOPNAME" placeholder="שם חנות" #input maxlength="30">
  </mat-form-field>

  <mat-form-field class="edit-item smallNumericField">
    <mat-label>ת.ז / ח.פ </mat-label>
    <input autocomplete="off" matInput [(ngModel)]="selectedEmvSerialRow.ID" placeholder="קופה" #input  maxlength="9">
  </mat-form-field>

  <mat-form-field class="edit-item smallNumericField">
    <mat-label>סידורי </mat-label>
    <input autocomplete="off" matInput [(ngModel)]="selectedEmvSerialRow.SERIAL" placeholder="קופה" #input  maxlength="10">
  </mat-form-field>

  

  <mat-form-field appearance="fill">
    <mat-label>תאריך התחלה</mat-label>
    <input matInput [matDatepicker]="FIRSTDATEPicker" (dateChange)="changeFIRSTDATE($event.value)" [value]="selectedEmvSerialRow.FIRSTDATE" disabled>
    <mat-datepicker-toggle matSuffix [for]="FIRSTDATEPicker"></mat-datepicker-toggle>
    <mat-datepicker #FIRSTDATEPicker disabled="false"></mat-datepicker>
  </mat-form-field>


  <mat-form-field appearance="fill">
    <mat-label>תאריך עדכון</mat-label>
    <input matInput [matDatepicker]="LASTDATEPicker" (dateChange)="changeLASTDATE($event.value)" [value]="selectedEmvSerialRow.LASTDATE" disabled>
    <mat-datepicker-toggle matSuffix [for]="LASTDATEPicker"></mat-datepicker-toggle>
    <mat-datepicker #LASTDATEPicker disabled="false"></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="edit-item medium">
    <mat-label>כתובת </mat-label>
    <input autocomplete="off" matInput [(ngModel)]="selectedEmvSerialRow.ADDRESS" placeholder="כתובת" #input maxlength="30">
  </mat-form-field>

  <mat-form-field class="edit-item smallNumericField">
    <mat-label>קופה </mat-label>
    <input autocomplete="off" matInput [(ngModel)]="selectedEmvSerialRow.STATION" placeholder="קופה" #input>
  </mat-form-field>

  <mat-form-field class="edit-item medium">
    <mat-label>גרסה </mat-label>
    <input autocomplete="off" matInput [(ngModel)]="selectedEmvSerialRow.VERNBR" placeholder="גרסה" #input maxlength="30">
  </mat-form-field>


  <mat-form-field appearance="fill" class="edit-item yesNoDropdown">
    <mat-label> תשלום כ/ל </mat-label>
    <mat-select [(ngModel)]="ispayingstring">
      <mat-option value=true>כן</mat-option>
      <mat-option value=false>לא</mat-option>
    </mat-select>
  </mat-form-field>


</div>


<!--
  'ENDDATE',
  -->

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 clearBoth">

  <ng-container matColumnDef="SHOPNAME">
    <th mat-header-cell *matHeaderCellDef class="medium"> שם חנות  </th>
    <td mat-cell *matCellDef="let element"> {{element.SHOPNAME}} </td>
  </ng-container>

  <ng-container matColumnDef="ID">
    <th mat-header-cell *matHeaderCellDef class="small"> ת.ז / ח.פ  </th>
    <td mat-cell *matCellDef="let element"> {{element.ID}} </td>
  </ng-container>

  <ng-container matColumnDef="SERIAL">
    <th mat-header-cell *matHeaderCellDef class="small"> סידורי  </th>
    <td mat-cell *matCellDef="let element"> {{element.SERIAL}} </td>
  </ng-container>

  <ng-container matColumnDef="FIRSTDATE">
    <th mat-header-cell *matHeaderCellDef class="small"> התחלה  </th>
    <td mat-cell *matCellDef="let element"> {{element.FIRSTDATE | date: 'dd/MM/yyyy'}} </td>
  </ng-container>

  <ng-container matColumnDef="LASTDATE">
    <th mat-header-cell *matHeaderCellDef class="small"> תאריך אחרון  </th>
    <td mat-cell *matCellDef="let element"> {{element.LASTDATE | date: 'dd/MM/yyyy'}} </td>
  </ng-container>

  <ng-container matColumnDef="ADDRESS">
    <th mat-header-cell *matHeaderCellDef class="medium"> כתובת  </th>
    <td mat-cell *matCellDef="let element"> {{element.ADDRESS}} </td>
  </ng-container>

  <ng-container matColumnDef="STATION">
    <th mat-header-cell *matHeaderCellDef class="small"> קופה </th>
    <td mat-cell *matCellDef="let element"> {{element.STATION}} </td>
  </ng-container>

  <ng-container matColumnDef="VERNBR">
    <th mat-header-cell *matHeaderCellDef class="medium"> גרסה  </th>
    <td mat-cell *matCellDef="let element"> {{element.VERNBR}} </td>
  </ng-container>

  <ng-container matColumnDef="ISPAYING">
    <th mat-header-cell *matHeaderCellDef> תשלום כ/ל </th>
    <td mat-cell *matCellDef="let element"> {{element.ISPAYING}} </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectRow(row)"></tr>

</table>

<mat-paginator [length]="emvserialRowsFilterd.length"
               [pageSize]="10">
</mat-paginator>
