import { Component, OnInit, Inject } from '@angular/core';
import { Item } from '../Item';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-update-item-itcode',
  templateUrl: './update-item-itcode.component.html',
  styleUrls: ['./update-item-itcode.component.css']
})

export class UpdateItemItcodeComponent implements OnInit {

  public selectedItem: Item;
  public IT_CODE_NEW = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedItem = data.selectedItem;

  }

  ngOnInit(): void {
  }

}
