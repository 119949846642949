<div class="flex-row filter-row-container">

  <!--<input type="text" class="form-control" bsDatepicker [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD HH:mm' }">-->
  <!--  <div style="width:9rem; height:2rem; margin-right:0.5rem" class="input-group">
      <input class="form-control"
             name="dp"
             type="date"
             [value]="theDate1 | date:'yyyy-MM-dd'"
             (change)="changeReportDate()" />
    </div>
    -->
  <div class="input-group">
    <input id="DatePic1" class="form-control"
           locale="he"
           name="d1"
           #c1="ngModel"
           placeholder="{{reportPicker | date: 'dd/MM/yyyy'}}"
           [(ngModel)]="reportPicker"
           [firstDayOfWeek]="0"
           (ngModelChange)="changeReportStartDate($event)"
           ngbDatepicker
           #d1="ngbDatepicker" />

    <button id="btn1" class="btn btn-outline-secondary bi bi-calendar3" (click)="d1.toggle()" type="button"></button>
  </div>

  <div style="width:6.5rem; height:2rem; margin-right:0.5rem" class="input-group">
    <input class="form-control"
           name="dp"
           type="time"
           [(ngModel)]="fHour"
           (change)="timeChange()" />
    <!--<button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>-->
  </div>
  <div style="width:6.5rem; height:2rem; margin-right:0.5rem" class="input-group">
    <input class="form-control"
           name="dp"
           type="time"
           [(ngModel)]="tHour"
           (change)="timeChange()" />

    <!--<button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>-->
  </div>




  <!--

  <div style="margin-right: 0.51rem; font-size: 1rem; height: 4.2rem; ">
    <mat-form-field floatLabel="never" style="width:70px; text-align:center">
      <mat-select [(ngModel)]="fromMinute" (selectionChange)="timeChange()">
        <mat-option *ngFor="let minute of minutes" [value]=minute>{{minute | number:'2.0' }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field floatLabel="never" style="width:70px;margin-right:0.1rem;">
      <mat-select [(ngModel)]="fromHour" (selectionChange)="timeChange()">
        <mat-option *ngFor="let hour of hours" [value]=hour>{{hour | number:'2.0' }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div style="margin-right: 0.3rem; display: inline-block; clear: both">
    <mat-form-field floatLabel="never" style="width:70px">
      <mat-select [(ngModel)]="toMinute" (selectionChange)="timeChange()">
        <mat-option *ngFor="let minute of minutes" [value]=minute>{{minute | number:'2.0' }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field floatLabel="never" style="width: 70px; margin-right: 0.1rem;">
      <mat-select [(ngModel)]="toHour" (selectionChange)="timeChange()">
        <mat-option *ngFor="let hour of hours" [value]=hour>{{hour | number:'2.0' }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>  -->
</div>




