<h4 mat-dialog-title>עדכון קוד פריט</h4>
<mat-dialog-content class="mat-typography">
  <mat-dialog-content class="mat-typography">
    <!-- <h5>
     האם אתה בטוח שברצונך למחוק פריט:
   </h5>-->

    <mat-form-field class="edit-item mediumNumericField">
      <mat-label>קוד פריט </mat-label>
      <input autocomplete="off" matInput [(ngModel)]="selectedItem.IT_CODE" placeholder="קוד פריט" #input readonly>
    </mat-form-field>


    <mat-form-field class="edit-item longTextField">
      <mat-label>תאור פריט </mat-label>
      <input autocomplete="off" matInput [(ngModel)]="selectedItem.IT_DESC" placeholder="תאור פריט" #input readonly>
    </mat-form-field>

    <mat-form-field class="edit-item mediumNumericField">
      <mat-label>קוד פריט חדש </mat-label>
      <input autocomplete="off" matInput [(ngModel)]="IT_CODE_NEW" placeholder="קוד פריט" #input>
    </mat-form-field>

    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="false" mat-raised-button color="basic">ביטול</button>
      <button mat-button [mat-dialog-close]="IT_CODE_NEW" cdkFocusInitial mat-raised-button color="primary" [disabled]="!IT_CODE_NEW || !selectedItem.IT_CODE || (IT_CODE_NEW == selectedItem.IT_CODE)">עדכן קוד פריט</button>
      <button mat-button [mat-dialog-close]="999999999" cdkFocusInitial mat-raised-button color="primary">עדכן קוד פריט ל 999999999</button>
    </mat-dialog-actions>

  </mat-dialog-content>
</mat-dialog-content>
