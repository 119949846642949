import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { TelegramChat } from './telegram-chat';
import { TelegramChatsService } from './telegram-chat.service';

@Component({
  selector: 'telegtam-single-selection',
  templateUrl: './telegtam-single-selection.component.html',
  styleUrls: ['./telegtam-single-selection.component.css']
})

export class TelegramSingleSelectionComponent implements OnInit {

  @Output() telegramChatChanged = new EventEmitter<TelegramChat>();
  @Input() selectedTelegramId: number;

  public telegramChats: TelegramChat[] = [];
  public selectedTelegramChat: TelegramChat;
  public noTelegram: TelegramChat = {
    id: 0,
    first_name: "ללא טלגרם",
    last_name: "",
    title: "",
    type: ""
  }

  error: any;

  public selectedChat = this.noTelegram;
  constructor(public _telegramChatsService: TelegramChatsService) { }

  ngOnInit(): void {
    this.getShops();
  }

  getShops() {
    this._telegramChatsService.getTelegramChats()
      .subscribe(
        (data: TelegramChat[]) => {
          this.telegramChats = data;
          this.telegramChats.push(this.noTelegram);
        }, // success path/
        error => this.error = error // error path
      );
  }
  
  public onChatChange(selectedTelegramChat: TelegramChat) {
    this.selectedTelegramChat = selectedTelegramChat;
    this.telegramChatChanged.emit(this.selectedTelegramChat);
  }

  ngOnChanges() {
    if (!this.selectedTelegramId)
      this.selectedTelegramId = 0;

    let selectedInput = this.telegramChats.find(x => x.id == this.selectedTelegramId)
    this.selectedTelegramId = selectedInput.id;
    this.selectedTelegramChat = selectedInput;
    this.selectedChat = selectedInput;
    this.onChatChange(selectedInput)
  }

}
