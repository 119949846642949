import { Pipe, PipeTransform } from '@angular/core';
import { TelegramChat } from '../../reports/telegtam-single-selection/telegram-chat';

@Pipe({
  name: 'telegram',
  pure: false
})
export class TelegramPipe implements PipeTransform {

  transform(value: number, TelegramChats: TelegramChat[]): unknown {
    if (value == null || value ==0)
      return 'ללא טלגרם'

    try {
      return value;
      let chat = TelegramChats.find(x => x.id == value);
      return chat.first_name + ' ' + chat.last_name;
    }
    catch{
      return "";
    }
  }
}
