

<div>
  <mat-form-field floatLabel="never" class="full-width filterUsers" name="filterUsers" ngDefaultControl [(ngModel)]="filterUsers" (ngModelChange)="onUserFilterChange()">
    <label>חפש משתמש</label>
    <input matInput placeholder="חפש משתמש" #input>
  </mat-form-field>

  <!--<mat-selection-list #userslist [multiple]="false">-->
  <mat-selection-list #userslist>
    <mat-list-option *ngFor="let user of usersFilterd" (click)="selectUser(user)">
      {{user.NormalizedEmail}}
    </mat-list-option>
  </mat-selection-list>
</div>

<div style="margin-left:0.5rem"  *ngIf="selectedUser">
    <button mat-raised-button color="primary" (click)="selectAllShops()">בחר הכל</button>
    <button mat-raised-button color="primary" (click)="clearAllShops()">נקה הכל</button>

    <mat-form-field floatLabel="never" class="full-width filterUsers" name="filterShops" ngDefaultControl [(ngModel)]="filterShops" (ngModelChange)="onShopFilterChange()">
      <mat-label>חפש חנות</mat-label>
      <input matInput placeholder="חפש חנות" #input>
    </mat-form-field>

    <mat-selection-list #pizzaList [(ngModel)]="selectedShops" (ngModelChange)="onShopsChange($event)">
      <mat-list-option *ngFor="let shop of shopsFilterd" [value]=shop>
        {{shop.SHOP_NAME}}
      </mat-list-option>
    </mat-selection-list>
  </div>





