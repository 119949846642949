import { Component, OnInit,Inject, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef,   } from '@angular/material/dialog';
import { Item } from '../../items-managment/Item';
import { ItemsManagmentService } from '../../items-managment/items-managment.service';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs/internal/Subscription';
import { interval, Observable, of } from 'rxjs';
import { itSortRow } from '../../items-managment/itSortRow';
import { supplierRow } from '../../items-managment/supplierRow';
import { Shop } from '../../Shop';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
  selector: 'app-item-search',
  templateUrl: './item-search.component.html',
  styleUrls: ['./item-search.component.css']
})
export class ItemSearchComponent implements OnInit {

  public selectedItem: Item;
  public selectedShop: Shop = null;
  public itemsRowsFilterd: Item[] = [];

  public filterValue = "";

  public selectedCategory: itSortRow = null;
  public selectAllCategories: itSortRow = { CODE: 999999999, DESCR: "כל הקטגוריות", TABLEID: 0, SHOP_ID: 0, ITSORT: null, DISDCOUNT:null };
  public selectedSupplier: supplierRow = null;
  public selectAllSuppliers: supplierRow = { CLNUM: 999999999, CLNAME: "כל הספקים", TABLEID: 0, SHOP_ID: 0 };
  public getScreenWidth: any;
  public getScreenHeight: any;
  public selection: SelectionModel<Item>;

  isColumnsMobile: boolean;
  rowClicked: number =-2;
         
  //public selectedITSORT: itSortRow;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;



  error: any;

  initialDisplayedColumns: string[] =
  [
    'IT_CODE',
    'IT_DESC',
    'ITSORT',
    'SUPPLIER',
    'PRICE1',
    'BARCODE'
  ];

  displayedColumnForDesk1024: string[] =
    [
      'IT_CODE',
      'IT_DESC',
      'ITSORT',
      'SUPPLIER',
      'PRICE1',
      'BARCODE'
    ];



  displayedColumns: string[] =
    [
      'IT_CODE',
      'IT_DESC',
      'ITSORT',
      'SUPPLIER',
      'PRICE1',
      'BARCODE'
    ];

  displayedColumnForMobilesPhones
    : string[] =
    [
      'IT_CODE',
      'IT_DESC',
      'PRICE1',
    ];

  public dataSource = new MatTableDataSource<Item>(this.itemsRowsFilterd);
  
  selectionChangeCategory(selectedCategory: itSortRow) {
    this.selectedCategory = selectedCategory;
    let supplier = -1;
    this.selectedCategory = selectedCategory;
    if (this.selectedCategory.CODE == null) this.selectedCategory.CODE = -1;
    if (this.selectedSupplier != null && this.selectedSupplier.CLNAME != "כל הספקים")
      if (this.selectedSupplier.CLNUM != null) supplier = this.selectedSupplier.CLNUM;

    this.getItemSearch(this.selectedShop.ITEMS_FROM_SHOP_ID, this.selectedCategory.CODE, supplier, this.filterValue,null);
//    this.filterItems();
  }

  selectionChangeselectedSUPPLIER(selectedSupplier: supplierRow) {
    this.selectedSupplier = selectedSupplier;
    let sortcode = -1;
    let supplier = -1;
    if (this.selectedCategory != null)
      if (this.selectedCategory.DESCR != "כל הקטגוריות")
        if (this.selectedCategory.CODE != null) sortcode = this.selectedCategory.CODE;

    //    if (this.selectedSupplier != null)
    if (this.selectedSupplier.CLNUM != null && this.selectedSupplier.CLNAME != "כל הספקים") supplier = this.selectedSupplier.CLNUM;

    this.getItemSearch(this.selectedShop.ITEMS_FROM_SHOP_ID, sortcode, supplier, this.filterValue,null);

//    this.filterItems();
  }

  SearchFocusOutEvent(event: any) {
    if (this.rowClicked == -2) {
      this.filterValue = event.target.value;
      let supplier = -1;
      let sortcode = -1;

      if (this.selectedCategory != null)
      if (this.selectedCategory.DESCR != "כל הקטגוריות")
        if (this.selectedCategory.CODE != null) sortcode = this.selectedCategory.CODE;
      if (this.selectedSupplier != null)
      if (this.selectedSupplier.CLNUM != null && this.selectedSupplier.CLNAME != "כל הספקים") supplier = this.selectedSupplier.CLNUM;

      if (this.filterValue.length > 3 || event.keyCode === 13)
        this.getItemSearch(this.selectedShop.ITEMS_FROM_SHOP_ID, sortcode, supplier, this.filterValue,null);
      this.rowClicked = -3;
    }
    if (this.rowClicked == -2)
    {
      alert(this.itemsRowsFilterd.length );
    }
  }

  sortItems() {
    this.dataSource.sort = this.sort;
    const sortState: Sort = { active: 'IT_CODE', direction: 'asc' };
//    this.sort.active = sortState.active;
//    this.sort.direction = sortState.direction;
//    this.sort.sortChange.emit(sortState);
  }


  getItemSearch(selectedShop: number, Sort: Number, Supplier: Number, SearchField: string, It_Code: string) {
    //    alert(selectedShop);
    this._ItemsManagmentService.getItemSearch(selectedShop, Sort, Supplier, SearchField,null)
      .subscribe(
        (data: any) => {
          data.itemsSEARCHRows.sort((a, b) => (a.IT_CODE > b.IT_CODE) ? 1 : ((b.IT_CODE > a.IT_CODE) ? -1 : 0));
          this.data.itemsRows = data.itemsSEARCHRows;

          this.dataSource = new MatTableDataSource<Item>(data.itemsSEARCHRows)
          this.dataSource.paginator = this.paginator;
//          this.filterItems();
//          this.sortItems();

          return;
        }, // success path/
        error => this.error = error // error path
      );
  }

  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ItemSearchComponent>, public _ItemsManagmentService: ItemsManagmentService) {
    console.log('data', data);
    this.selection = new SelectionModel<Item>(true);
    //    this.data.selectedItem = null;
    this.selectedShop = data.selectedShop;
    this.itemsRowsFilterd = data.itemsRows;
    this.dataSource = new MatTableDataSource<Item>(this.itemsRowsFilterd);
    this.dataSource.paginator = this.paginator;
    //    this.selectedShop = data.selectedShop;

    //    this.filterItems();
    //    this.sortItems();

  }

  subscription: Subscription;

  SerchhandleKeyboardEvent(event) {
   this.filterValue = event.target.value;
    if (event.keyCode === 13) {
      this.rowClicked = -2; 
      this.SearchFocusOutEvent(event);
    }
  }

  applyFilter(event: any) {
    this.filterValue = (event.target as HTMLInputElement).value;
    let supplier = -1;
    let sortcode = -1;

    if (this.selectedCategory != null)
      if (this.selectedCategory.DESCR != "כל הקטגוריות")
        if (this.selectedCategory.CODE != null) sortcode = this.selectedCategory.CODE;
    if (this.selectedSupplier != null)
      if (this.selectedSupplier.CLNUM != null && this.selectedSupplier.CLNAME != "כל הספקים") supplier = this.selectedSupplier.CLNUM;

    if (this.filterValue.length > 3 || event.keyCode === 13)
      this.getItemSearch(this.selectedShop.ITEMS_FROM_SHOP_ID, sortcode, supplier, this.filterValue,null);

/*

    this.filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
*/

  }
  startDelayedFilter(): void {
    const source = interval(1000);

    this.subscription = source.subscribe(val => {
      if (this.itemsRowsFilterd.length === 0) {
        this.selectedCategory = this.selectAllCategories;
        this.selectedSupplier = this.selectAllSuppliers;
        this.filterItems()
        this.subscription.unsubscribe();
      }
      return 1;
    }
    );
  }

  ngOnInit(): void {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth <= 400 && !this.isColumnsMobile) {

      this.displayedColumns = this.displayedColumnForMobilesPhones;
      this.isColumnsMobile = true;

    } else
      if (this.getScreenWidth <= 1024 && !this.isColumnsMobile) {

        this.displayedColumns = this.displayedColumnForDesk1024;
        this.isColumnsMobile = true;
      }
      else
        if (this.getScreenWidth >= 1024 && this.isColumnsMobile) {

          this.displayedColumns = this.initialDisplayedColumns;
          this.isColumnsMobile = false;

        }
  }


  filterItems() {
//    this.itemsRowsFilterd.sort((a, b) => (a.IT_CODE > b.IT_CODE) ? 1 : ((b.IT_CODE > a.IT_CODE) ? -1 : 0));
    this.dataSource = new MatTableDataSource<Item>(this.itemsRowsFilterd);
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    this.dataSource.paginator = this.paginator;

  }

//  public selectRow(item: Item) {
  public selectRow(idx: any, item: Item) {

    if (this.rowClicked === idx) {
      this.rowClicked = -1;
      this.selectedItem = item;
      this.dialogRef.close(item);
    }
    else {
      this.rowClicked = idx;
      if (this.data.itemsRows.length == 1) {
        this.selectedItem = item;
        this.dialogRef.close(item);

      }

    }




//      this.dialogRef.close(this.data.itemsRows.filter(x => x.TABLEID == row.TABLEID)[0]);

    //this.data.dialog.close();
    //console.log('selectRow this.data.selectedEntfullRows', this.data.selectedEntfullRows)
  }
}
