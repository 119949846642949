
<mat-dialog-content id="content2">

  <p class="h4 dialog-header">השוואת מבצעים</p>



  <table id="table1" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="SHOPNAME">
      <th mat-header-cell *matHeaderCellDef> שם חנות  </th>
      <td mat-cell *matCellDef="let element"> {{element.SHOPNAME}} </td>
      <!-- [class.selected]="element.selected" (click)="element.selected= !element.selected" -->
    </ng-container>

    <ng-container matColumnDef="CODE">
      <th mat-header-cell *matHeaderCellDef> קוד מבצע  </th>
      <td mat-cell *matCellDef="let element"> {{element.CODE}} </td>
      <!-- [class.selected]="element.selected" (click)="element.selected= !element.selected" -->
    </ng-container>

    <ng-container matColumnDef="NAME">
      <th mat-header-cell *matHeaderCellDef> שם מבצע  </th>
      <td mat-cell *matCellDef="let element"> {{element.NAME}} </td>
    </ng-container>

    <ng-container matColumnDef="QTY">
      <th mat-header-cell *matHeaderCellDef> כמות </th>
      <td mat-cell *matCellDef="let element"> {{element.QTY}} </td>
    </ng-container>

    <ng-container matColumnDef="PRICE">
      <th mat-header-cell *matHeaderCellDef> מחיר </th>
      <td mat-cell *matCellDef="let element"> {{element.PRICE | number:'1.2'}} </td>
    </ng-container>

    <ng-container matColumnDef="AMOUNT">
      <th mat-header-cell *matHeaderCellDef> מ-סכום </th>
      <td mat-cell *matCellDef="let element"> {{element.AMOUNT | number:'1.2'}} </td>
    </ng-container>

    <ng-container matColumnDef="IFCONTINUE">
      <th mat-header-cell *matHeaderCellDef> המשך מבצע </th>
      <td mat-cell *matCellDef="let element"> {{element.IFCONTINUE}} </td>
    </ng-container>

    <ng-container matColumnDef="MAXQTY">
      <th mat-header-cell *matHeaderCellDef> כמות MAX </th>
      <td mat-cell *matCellDef="let element"> {{element.MAXQTY}} </td>
    </ng-container>

    <ng-container matColumnDef="ENDDATE">
      <th mat-header-cell *matHeaderCellDef> סיום  </th>
      <td mat-cell *matCellDef="let element"> {{element.ENDDATE | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="REMARKS">
      <th mat-header-cell *matHeaderCellDef> הודעת מבצע  </th>
      <td mat-cell *matCellDef="let element"> {{element.REMARKS}} </td>
    </ng-container>

    <ng-container matColumnDef="QTY2">
      <th mat-header-cell *matHeaderCellDef>כמות 2</th>
      <td mat-cell *matCellDef="let element"> {{element.QTY2}} </td>
    </ng-container>

    <ng-container matColumnDef="PRICE2">
      <th mat-header-cell *matHeaderCellDef> מחיר 2</th>
      <td mat-cell *matCellDef="let element"> {{element.PRICE2 | number:'1.2'}} </td>
    </ng-container>

    <ng-container matColumnDef="QTY3">
      <th mat-header-cell *matHeaderCellDef>כמות 3</th>
      <td mat-cell *matCellDef="let element"> {{element.QTY3}} </td>
    </ng-container>

    <ng-container matColumnDef="PRICE3">
      <th mat-header-cell *matHeaderCellDef> מחיר 3</th>
      <td mat-cell *matCellDef="let element"> {{element.PRICE3 | number:'1.2'}} </td>
    </ng-container>

    <ng-container matColumnDef="QTY4">
      <th mat-header-cell *matHeaderCellDef>כמות 4</th>
      <td mat-cell *matCellDef="let element"> {{element.QTY4}} </td>
    </ng-container>

    <ng-container matColumnDef="PRICE4">
      <th mat-header-cell *matHeaderCellDef>מחיר 4</th>
      <td mat-cell *matCellDef="let element"> {{element.PRICE5 | number:'1.2'}} </td>
    </ng-container>

    <ng-container matColumnDef="QTY5">
      <th mat-header-cell *matHeaderCellDef>כמות 5</th>
      <td mat-cell *matCellDef="let element"> {{element.QTY5}} </td>
    </ng-container>

    <ng-container matColumnDef="PRICE5">
      <th mat-header-cell *matHeaderCellDef> מחיר 5</th>
      <td mat-cell *matCellDef="let element"> {{element.PRICE3 | number:'1.2'}} </td>
    </ng-container>

    <ng-container matColumnDef="QTY6">
      <th mat-header-cell *matHeaderCellDef>כמות 6</th>
      <td mat-cell *matCellDef="let element"> {{element.QTY6}} </td>
    </ng-container>

    <ng-container matColumnDef="PRICE6">
      <th mat-header-cell *matHeaderCellDef>מחיר 6</th>
      <td mat-cell *matCellDef="let element"> {{element.PRICE6 | number:'1.2'}} </td>
    </ng-container>

    <ng-container matColumnDef="QTY7">
      <th mat-header-cell *matHeaderCellDef>כמות 7</th>
      <td mat-cell *matCellDef="let element"> {{element.QTY7}} </td>
    </ng-container>

    <ng-container matColumnDef="PRICE7">
      <th mat-header-cell *matHeaderCellDef>מחיר 7</th>
      <td mat-cell *matCellDef="let element"> {{element.PRICE7 | number:'1.2'}} </td>
    </ng-container>
    <ng-container matColumnDef="UPDDATE">
      <th mat-header-cell *matHeaderCellDef>עדכון אחרון</th>
      <td mat-cell *matCellDef="let element"> {{element.UPDDATE | date: 'dd/MM/yyyy HH:mm' }}</td>
    </ng-container>
    <!--
        <ng-container matColumnDef="EDITBARCODES">
          <th mat-header-cell *matHeaderCellDef class="">  </th>
          <td mat-cell *matCellDef="let element">
            <button mat-raised-button color="primary" (click)="openSpecialSalesBarcodeDialog(element)">בחירת פריטים</button>
            <button mat-raised-button color="primary" (click)="deleteSpecialSales(element)">מחיקת מבצע</button>
          </td>

        </ng-container>
        -->


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let idx=index; let even=even; let row; columns: displayedColumns;"
        (click)="changeTableRowColor(idx,row)" [style.background-color]="rowClicked == idx ? 'yellow' : (even ? 'white' : 'lightgray')">
    </tr>

  </table>
  <br />
  <div style="text-align: left;">
    <button class="btn btn-primary flex-item customized-button" [mat-dialog-close]="false">חזרה</button>
  </div>
  <br />

  </mat-dialog-content>
