import { createAction, props } from '@ngrx/store';
import { Item } from '../items-managment/Item';

export const setItem = createAction('setItem', props<{ item: Item }>());
export const setItemCost = createAction('setItemCost');
export const SetItemCostAfterSapakDiscount = createAction('SetItemCostAfterSapakDiscount');
export const setItemPriceWithoutVat = createAction('setItemPriceWithoutVat');
export const setItemProfit = createAction('setItemProfit');
export const setItemButtomProfit = createAction('setItemButtomProfit');
export const setItemTopProfit = createAction('setItemTopProfit');

//export const selectAllBooks = (state: AppState) => state.allBooks;

/*
export const increment = createAction('[Counter Component] Increment');
export const decrement = createAction('[Counter Component] Decrement');
export const reset = createAction('[Counter Component] Reset');
*/
