import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, retry, tap, map } from 'rxjs/operators';
import { Shop } from '../../users-shops/users-shops.component';
import { SalesReportByGroupDetailed } from './sales-report-datailed-by-group';
import { throwError, Observable, of } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';


@Injectable({
  providedIn: 'root'
})
export class SalesReportDatailedByGroupsService {
  configUrl = 'api/SALESREPORTBYGROUPDETAILED';

  httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json',

    })
  };


  constructor(private http: HttpClient) { }

  private addLeadingZero(numnerAsString: number): string {
    console.log('numnerAsString', numnerAsString);
    return ((numnerAsString.toString().length === 1) ? "0" + numnerAsString.toString() : numnerAsString.toString());

  }

    
  getSalesReportByGroup(reportFrom: Date, reportTo: Date, selectedShops: Shop[], catorgiesBy: string, itCode: string, itSortFrom: number, itSortTo: number, supplierFrom: number, supplierTo: number)
  {

    this.configUrl = 'api/SALESREPORTBYGROUPDETAILED';
    let shopIds: number[] = [];

    if (selectedShops && selectedShops.length > 0)
      shopIds = selectedShops.map(x => x.SHOP_ID);
//    alert('shopIds=' + shopIds + ' catorgiesBy=' + catorgiesBy + ' itCode=' + itCode + ' itSortFrom=' + itSortFrom + ' itSortTo=' + itSortTo + ' supplierFrom=' + supplierFrom + ' supplierTo=' + supplierTo);

    let reportFromString: string = reportFrom.getFullYear() + "-" + this.addLeadingZero((reportFrom.getMonth() + 1)) + "-" + this.addLeadingZero(reportFrom.getDate()) + 'T00:00:00';
    let reporToString: string = reportTo.getFullYear() + "-" + this.addLeadingZero((reportTo.getMonth() + 1)) + "-" + this.addLeadingZero(reportTo.getDate()) + 'T00:00:00';
        
    return this.http.post<SalesReportByGroupDetailed[]>(this.configUrl, {
      "reportStart": reportFromString,
      "reportEnd": reporToString,
      "shopIds": shopIds,
      "catorgiesBy": catorgiesBy,
      "itCode": itCode,
//      "itSortFrom": parseInt(itSortFrom?.toString()),
//      "itSortTo": parseInt(itSortTo?.toString()),
      "itSortFrom": itSortFrom,
      "itSortTo": itSortTo,

//      "supplierFrom": parseInt(supplierFrom?.toString()),
//      "supplierTo": parseInt(supplierTo?.toString())
      "supplierFrom": supplierFrom,
      "supplierTo": supplierTo

    }
      , this.httpOptions)
      .pipe(
        map(res => {
          //console.log('res', res);
          return res;
        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );


  }

  
  /*

  getSalesReportByGroup(reportFrom: Date, reportTo: Date, selectedShops: Shop[], catorgiesBy: number, itCode: string, itSortFrom: number, itSortTo: number, supplierFrom: number, supplierTo: number) {
    let shopIds: number[] = [];

    if (selectedShops && selectedShops.length > 0)
      shopIds = selectedShops.map(x => x.SHOP_ID);

    let reportFromString: string = reportFrom.getFullYear() + "-" + this.addLeadingZero((reportFrom.getMonth() + 1)) + "-" + this.addLeadingZero(reportFrom.getDate()) + 'T00:00:00';
    let reporToString: string = reportTo.getFullYear() + "-" + this.addLeadingZero((reportTo.getMonth() + 1)) + "-" + this.addLeadingZero(reportTo.getDate()) + 'T00:00:00';



    return this.http.post<Observable<any>>(this.configUrl,
//    return this.http.post<SalesReportByGroupDetailed[]>(this.configUrl,
//    return this.http.post<any>(this.configUrl,
  {
    "reportStart": reportFromString,
    "reportEnd": reporToString,
    "sortStart": 1,
    "sortEnd": 2,
    "shopIds": shopIds,
    "catorgiesBy": catorgiesBy,
    "itCode": itCode,
    "itSortFrom": parseInt(itSortFrom?.toString()),
    "itSortTo": parseInt(itSortTo?.toString()),
    "supplierFrom": parseInt(supplierFrom?.toString()),
    "supplierTo": parseInt(supplierTo?.toString())
  }, this.httpOptions)
      .pipe(map(res => {
        //console.log('res', res);
        return res;
      }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );



  }
  */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later Status=' + error.status + ' Error=' + error.error);
  };

}
