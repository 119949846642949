import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Item } from '../Item';
import { Shop } from '../../Shop';
import { itSortRow } from '../itSortRow';
import { supplierRow } from '../supplierRow';
import { FastPriceUpdateComponent } from '../fast-price-update/fast-price-update.component';
import { ItemsManagmentService } from '../items-managment.service';
import { midaRow } from '../midaRow';

@Component({
  selector: 'item-new',
  templateUrl: './item-insert.component.html',
  styleUrls: ['./item-insert.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class iteminsertComponent implements OnInit {

  isupdate: boolean;
  public selectedItem: Item;
  public selectedITSORT: itSortRow;
  public selectedSupplier: supplierRow = null;
  public selectedSUPPLIER: supplierRow;
  public shops: Shop[] = [];
  public itSortRows: itSortRow[][] = [];
  public supplierRows: supplierRow[][] = [];
  public selectedShop: Shop = null;
  public shopIndex = 0;
  public dialog: MatDialog;
  public selectedIT_CODE: string;
  public _ItemsManagmentService: ItemsManagmentService;
  public itemsRowsFilterd: Item[] = [];
  public itemsRows: Item[][] = [];
  public selectedCategory: itSortRow = null;
  public dataSource = new MatTableDataSource<Item>(this.itemsRowsFilterd);
  public filterValue = "";
  public selectedMida: midaRow = null;
  public selectedMIDA: midaRow;
  lwidth: string;
  lheight: string;
  ltop: string;

  
  error: any;
  local_data: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<iteminsertComponent> ) {
//    this.selectedItem = data.items;
    this.isupdate = data.isinsert;
    this.shops = data.shops;
    this.itSortRows = data.itSortRows;
    this.supplierRows = data.supplierRows;
//    this.itemsRows = data.itemsRows;
    this.selectedShop = this.shops[0];
    this.selectedItem = data.selectedItem;

    if (!this.isupdate) this.setCode();
    /*    this.setCost();
    this.SetCostAfterSapakDiscount();
    this.setPriceWithoutVat();
    this.setProfit()
    this.setButtomProfit();
    this.setTopProfit();*/
  }

  private setCode(): void {

//    this.code = this.selectedspecialSales.CODE;
  }

  ngOnInit(): void {
  }

  changeInputITSORT() {
    this.selectedITSORT = this.itSortRows[this.shopIndex].find(x => x.CODE == this.selectedItem.ITSORT);
  }

  selectionChangeselectedITSORT(selectedCategory: itSortRow) {
    this.selectedItem.ITSORT = selectedCategory.CODE;
  }

  changeInputSUPPLIER() {
    this.selectedSUPPLIER = this.supplierRows[this.shopIndex].find(x => x.CLNUM == this.selectedItem.SUPPLIER);
  }

  selectionChangeselectedSUPPLIER(selectedSupplier: supplierRow) {
    this.selectedItem.SUPPLIER = selectedSupplier.CLNUM;
  }

 
 
  openDialogT(selectedItem: Item) {
//    const backupItem: Item = JSON.parse(JSON.stringify(selectedItem));
    this.lwidth = '80%';
    this.lheight = '83%';
    this.ltop = '5.0rem';

    const dialogRef = this.dialog.open(FastPriceUpdateComponent
      , {
        disableClose: true,
        maxWidth: '100vw',
        maxHeight: '100vh',
        position: {
          top: this.ltop
        },
        height: this.lheight,
        width: this.lwidth,

        data: {
          selectedItem: selectedItem
        }
      }
    );
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
