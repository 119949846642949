<div class="form-floating customized-select flex-item">
  <select class="form-select" id="floatingSelect" #code (change)="onShopsChange(code.value)">
    <option *ngFor="let shop of shops" [value]="shop.SHOP_ID">{{ shop.SHOP_NAME }}</option>
  </select>
  <label for="floatingSelect" class="label-color">בחר חנות</label>
</div>

<!--

<mat-form-field floatLabel="never" style="margin-left:0.3rem" appearance="fill" class="filterFields">
  <mat-select [(ngModel)]="selectedShop" (selectionChange)="onShopsChange($event.value)">
    <mat-label>בחר חנות</mat-label>
    <mat-option *ngFor="let shop of shops" [value]="shop">{{ shop.SHOP_NAME }}</mat-option>
  </mat-select>
</mat-form-field>
 -->
