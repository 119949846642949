<body id="main">

  <div class="flex-row filter-row-container">
    <label class="label-IT_CODE">קוד פריט </label>
    <div id="rightdiv" class="flex-row filter-row-container">
      <div id="DOC_IT_CODE" class="edit-item">
        <input type="text" [value]="selectedEntItem.IT_CODE" [disabled]="ifdisable" (focusout)="IT_CODEonFocusOutEvent($event)" (keyup)="IT_CODEhandleKeyboardEvent($event)" class="form-control" placeholder="קוד פריט" id="floatingInput" />
      </div>
      <button mat-button mat-raised-button id="BF9" color="primary" *ngIf="this.Code === true" (click)="openDialogBarcode()">חיפוש פריט</button>
    </div>

    <label id="label-Price2">מחיר מועדון  </label>
    <div id="DOC_IT_QTY" class="flex-item">
      <input type="text" class="form-control" [value]="selectedItemsRow.PRICE2" placeholder="מחיר מועדון " id="PRICE2" (focusout)="PRICE2onFocusOutEvent($event)" (keyup)="PRICE2handleKeyboardEvent($event)" />
    </div>
  </div>  


    <div class="flex-row filter-row-container">
      <div class="Main1 flex-row filter-row-container">
        <label class="label-color">תאור פריט </label>
        <div id="DOC_it_DESC" class="flex-item">
          <input type="text" [value]="selectedEntItem.IT_DESC" [disabled]="ifdisable" class="form-control" placeholder="תאור פריט" id="IT_DESC" (focusout)="IT_DESConFocusOutEvent($event)" (keyup)="IT_DESChandleKeyboardEvent($event)" />
        </div>
      </div>


         <label id="label-Price2">מחיר 3 </label>
         <div id="DOC_IT_QTY" class="flex-item">
           <input type="text" class="form-control" [value]="selectedItemsRow.PRICE3" placeholder="מחיר 3" id="PRICE3" (focusout)="PRICE3onFocusOutEvent($event)" (keyup)="PRICE3handleKeyboardEvent($event)" />
         </div>

    </div>



       <div class="flex-row filter-row-container">
         <div id="rightdiv" class="Main1 flex-row filter-row-container">
           <label class="label-color">ברקוד  </label>
           <div id="DOC_IT_CODE" class="flex-item">
             <input type="text" class="form-control" [value]="selectedItemsRow.BARCODE" placeholder="ברקוד " id="BARCODE" (focusout)="BARCODEonFocusOutEvent($event)" (keyup)="BARCODEhandleKeyboardEvent($event)" />
           </div>
         </div>

         <label id="label-Min"> כמות מינימום</label>
         <div id="DOC_IT_QTY" class="flex-item">
           <input type="text" class="form-control" [value]="selectedItemsRow.MINIQTY" placeholder="  כמות מינימום" id="MINQTY" (focusout)="MINQTYonFocusOutEvent($event)" (keyup)="MINQTYhandleKeyboardEvent($event)" />
         </div>
       </div>

       <div class="flex-row filter-row-container">
         <div class="Main1 flex-row filter-row-container">
           <label class="label-color">מיון/קבוצה</label>
           <div id="DOC_IT_SORT" class="flex-item">
             <input type="text" class="form-control" id="ITSORT" autocomplete="off" placeholder="קבוצה" [(ngModel)]="selectedItemsRow.ITSORT" #IT_SORT (keyup)="changeInputITSORT()">
           </div>

           <div class="form-floating customized-select flex-item">
             <select class="form-select" [(ngModel)]="selecteditSortRow" id="ITSORTNAME" (ngModelChange)="selectionChangeselectedITSORT($event)" required>
               <option *ngFor="let itSortRow of itSortRows" [ngValue]="itSortRow">{{ itSortRow.DESCR }}</option>
             </select>
           </div>

         </div>
         <label id="label-Max"> כמות MAX</label>
         <div id="DOC_IT_QTY" class="flex-item">
           <input type="text" class="form-control" [value]="selectedItemsRow.MAXQTY" placeholder="כמות MAX" id="MAXQTY" (focusout)="MAXQTYonFocusOutEvent($event)" (keyup)="MAXQTYhandleKeyboardEvent($event)" />
         </div>
       </div>

       <div class="flex-row filter-row-container">
         <div class="Main1 flex-row filter-row-container">
           <label class="label-color">קוד ספק</label>
           <div id="DOC_IT_CODE" class="flex-item">
             <input type="text" class="form-control" [value]="selectedItemsRow.IT_S_CODE" placeholder="קוד ספק" id="IT_S_CODE" (focusout)="IT_S_CODEonFocusOutEvent($event)" (keyup)="IT_S_CODEhandleKeyboardEvent($event)" />
           </div>
         </div>
         <label id="label-Cut"> חתך/עונה</label>
         <div id="DOC_IT_QTY" class="flex-item">
           <input type="text" class="form-control" [value]="selectedItemsRow.CUT" placeholder="חתך/עונה" id="CUT" (focusout)="CUTonFocusOutEvent($event)" (keyup)="CUThandleKeyboardEvent($event)" />
         </div>

       </div>
       <div class="Main1 flex-row filter-row-container">
         <label class="label-color">כמות </label>
         <div id="DOC_IT_QTY" class="flex-item">
           <input type="text" class="form-control" [value]="selectedEntItem.IT_QTY | number:'1.0-3'" placeholder="כמות " id="IT_QTY" (focusout)="IT_QTYFocusOutEvent($event)" (keyup)="IT_QTYhandleKeyboardEvent($event)" />
         </div>
         <label class="form-check-label" for="flexCheckDefaultDiscount">בר הנחה</label>
         <div>
           <input class="form-check-input" type="checkbox" id="flexCheckDefaultDiscount" [(ngModel)]="selectedItemsRow.IF_DISCOUNT_BOOL" />
         </div>
       </div>


       <div class="Main1 flex-row filter-row-container">
         <label class="label-color">בונוס </label>
         <div id="DOC_IT_QTY" class="flex-item">
           <input type="text" class="form-control" [value]="selectedEntItem.BONUS | number:'1.0-3'" placeholder="בונוס " id="BONUS" (focusout)="BONUSonFocusOutEvent($event)" (keyup)="BONUShandleKeyboardEvent($event)" />
         </div>
         <label class="form-check-label" for="flexCheckDefaultDiscount">מבצע</label>
         <div>
           <input class="form-check-input" type="checkbox" id="flexCheckDefaultSale" [(ngModel)]="selectedItemsRow.IT_SALE_BOOL" />
         </div>
       </div>


       <div class="Main1 flex-row filter-row-container">
         <label class="label-color">עלות  </label>
         <div id="DOC_IT_QTY" class="flex-item">
           <input type="number" class="form-control" [value]="selectedEntItem.IT_COST" step="0.1" min="0" pattern="[0-9]+([,\.][0-9]+)?" placeholder="עלות  " id="IT_COST" (focusout)="IT_COSTFocusOutEvent($event)" (keyup)="IT_COSThandleKeyboardEvent($event)" />
         </div>
         <label class="form-check-label" for="flexCheckDefaultDiscount">ללא מע"מ</label>
         <div>
           <input class="form-check-input" type="checkbox" id="flexCheckDefaultNOVAT" [(ngModel)]="selectedItemsRow.NOVAT_BOOL" />
         </div>
       </div>


       <div class="Main1 flex-row filter-row-container">
         <label class="label-color">אחוז הנחה </label>
         <div id="DOC_IT_QTY" class="flex-item">
           <input type="text" class="form-control" [value]="selectedEntItem.IT_DISC | number:'1.0-2'" placeholder="אחוז הנחה " id="IT_DISC" (focusout)="IT_DISCfocusOutEvent($event)" (keyup)="IT_DISChandleKeyboardEvent($event)" />
         </div>
         <label class="form-check-label" for="flexCheckDefaultDiscount">משקל</label>
         <div>
           <input class="form-check-input" type="checkbox" id="flexCheckDefaultScale" [(ngModel)]="selectedItemsRow.SCALE_BOOL" />
         </div>
       </div>

       <div class="Main1 flex-row filter-row-container">
         <label class="label-color">עלות לאחר הנחה </label>
         <div id="DOC_IT_QTY" class="flex-item">
           <input type="text" class="form-control" [value]="selectedItemsRow.costWithoutVatAfterSapakDiscount" placeholder="עלות לאחר הנחה " id="CostWithoutVatA" (focusout)="CostWithoutVatAonFocusOutEvent($event)" (keyup)="CostWithoutVatAhandleKeyboardEvent($event)" />
         </div>
       </div>

       <div class="Main1 flex-row filter-row-container">
         <label class="label-color">סה"כ  </label>
         <div id="DOC_IT_QTY" class="flex-item">
           <input type="text" class="form-control" [value]="selectedEntItem.IT_TOTAL" placeholder='סה"כ ' id="IT_TOTAL" (focusout)="IT_TOTALonFocusOutEvent($event)" (keyup)="IT_TOTALhandleKeyboardEvent($event)" />
         </div>
       </div>



       <div class="Main1 flex-row filter-row-container">
         <label class="label-color">מחיר מכירה </label>
         <div id="DOC_IT_QTY" class="flex-item">
           <input type="text" class="form-control" [value]="selectedEntItem.IT_PRICE" placeholder="מחיר מכירה " id="IT_PRICE" (focusout)="IT_PRICEonFocusOutEvent($event)" (keyup)="IT_PRICEhandleKeyboardEvent($event)" />
         </div>
       </div>

       <div class="Main1 flex-row filter-row-container">
         <label class="label-color">%הנחת תעודה </label>
         <div id="DOC_IT_QTY" class="flex-item">
           <input type="text" class="form-control" placeholder="%הנחת ספק" id="DISC_P" (focusout)="DISC_PonFocusOutEvent($event)" (keyup)="DISC_PhandleKeyboardEvent($event)" />
         </div>
       </div>

       <div class="Main1 flex-row filter-row-container">
         <label class="label-color">%רווח מלמטה   </label>
         <div id="DOC_IT_QTY" class="flex-item">
           <input type="text" [value]="selectedItemsRow.buttomProfit" class="form-control" placeholder="%רווח מלמטה " id="PROFITD" (focusout)="PROFITDonFocusOutEvent($event)" (keyup)="PROFITDhandleKeyboardEvent($event)" />
         </div>
       </div>

       <div class="Main1 flex-row filter-row-container">
         <label class="label-color">%רווח מלמעלה </label>
         <div id="DOC_IT_QTY" class="flex-item">
           <input type="text" [value]="selectedItemsRow.topProfit" class="form-control" placeholder="%רווח מלמעלה" id="PROFITA" (focusout)="PROFITAonFocusOutEvent($event)" (keyup)="PROFITAhandleKeyboardEvent($event)" />
         </div>

         <mat-dialog-actions align="left">
           <button mat-button mat-dialog-close mat-raised-button color="basic" (click)="closeComponent()">ביטול</button>
           <button mat-button [mat-dialog-close]="{data: true,selectedEntItem,selectedItemsRow}" cdkFocusInitial mat-raised-button color="primary">שמור</button>
         </mat-dialog-actions>

  </div>

</body>


