import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable, of } from 'rxjs';
import { catchError, retry, tap, map } from 'rxjs/operators';
import { Item } from './Item';
import { SaveItem } from '../inventory/Saveitem';
import { itSortRow } from './itSortRow';
import { supplierRow } from './supplierRow';
import { midaRow } from './midaRow';
import { Ent_Head, Ent_Item } from '../inventory/ent-item';
import { Color } from 'd3';
import { colorRow } from './colorRow';
import { SpecialSalesRow } from './specialSalesRow';
import { specialSalesBarcodeRow } from './specialSalesBarcodeRow';
import { emvSerialRow } from './emvSerialRow';
import { Shop } from '../Shop';
import { Control } from '../inventory/control';
import { ItemSup } from '../item-sup/item-sup';
import { Transaction } from '../reports/transactions-report/transaction';
import { Customerlist } from '../reports/customer-list/customerlist';
import { SalesReportByGroupDetailed } from '../reports/sales-report-datailed-by-groups/sales-report-datailed-by-group';
import { FilterItems } from './filter-items';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})


export class ItemsManagmentService {

  private _data: any = null;
  private _currentItemRow: any = null;
  private _currentItemManagmentFilter: FilterItems = null;
  ifsel: boolean = true;

  configUrl = 'api/USERS_SHOPS_ITEMS';
  httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json',
    })
  };

 

  public today: Date;
  constructor(private http: HttpClient, private _snackBar: MatSnackBar) {
    this.today = new Date();
  }

  openSnackBar(message: string) {
    return this._snackBar.open(message, 'אישור', {
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: ['blue-snackbar'],
      
    });
  }

  private addLeadingZero(numnerAsString: number): string {
    return ((numnerAsString.toString().length === 1) ? "0" + numnerAsString.toString() : numnerAsString.toString());
  }


  public setCurrentItemManagmentFilter(filter: FilterItems) {
    this._currentItemManagmentFilter = filter;
    //console.log('setCurrentItemManagmentFilter',this._currentItemManagmentFilter)

  }

  public getCurrentItemManagmentFilter():FilterItems{
   // console.log('getCurrentItemManagmentFilter',this._currentItemManagmentFilter)

    return this._currentItemManagmentFilter;
  }


  public setCurrentItemRow(item: any) {
    this._currentItemRow = item;
  }

  public getCurrentItemRow(): any {
    return this._currentItemRow;
  }


  public setItems(data: any): void {
    this._data = data;
  }

  returnStringIfNotTrue(str: string, falseStr: string) {
    return !str || (str.length === 0) || (str !== "T" && str !== "Tr") ? falseStr : str;
  }

  public getItems(lastUpdated: Date, forceUpdate = false): Observable<any> {

    if (this._data && !forceUpdate)
      return of(this._data);

    let lastUpdatedString = "";

    if (lastUpdated)
      lastUpdatedString = lastUpdated.getFullYear() + "-" + this.addLeadingZero((lastUpdated.getMonth() + 1)) + "-" + this.addLeadingZero(lastUpdated.getDate()) + 'T00:00:00';
    this.configUrl = 'api/USERS_SHOPS_ITEMS';

    const url = lastUpdated ? this.configUrl + "?lastUpdated=" + lastUpdatedString : this.configUrl;
    //console.log(Date.now())
    return this.http.get<Observable<any>>(url, this.httpOptions)
      .pipe(
        map(data =>
        {
         // console.log(Date.now());
          let shops = data["item_managment_data"].map(x => x.shop);
          let itSortRows = data["item_managment_data"].map(x => x.itSortRows);
          let supplierRows = data["item_managment_data"].map(x => x.supplierRows);
          let colorRows = data["item_managment_data"].map(x => x.colorRows);
          let midaRows = data["item_managment_data"].map(x => x.midaRows);
          let itemsRows = data["item_managment_data"].map(x => x.itemsRows);
//          let emvSerialRows = data["item_managment_data"].map(x => x.emvSerialRows);

          //console.log(Date.now())

          for (let shopIndex = 0; shopIndex < shops.length; shopIndex++) {
            for (let i = 0; i < itemsRows[shopIndex].length; i++) {
              itemsRows[shopIndex][i].IT_SALE = this.returnStringIfNotTrue(itemsRows[shopIndex][i].IT_SALE, "F");
              itemsRows[shopIndex][i].IT_CHPRICE = this.returnStringIfNotTrue(itemsRows[shopIndex][i].IT_CHPRICE, "F");
              itemsRows[shopIndex][i].NOVAT = this.returnStringIfNotTrue(itemsRows[shopIndex][i].NOVAT, "F");
              itemsRows[shopIndex][i].IF_DISCOUNT = this.returnStringIfNotTrue(itemsRows[shopIndex][i].IF_DISCOUNT, "Fa");
              itemsRows[shopIndex][i].SCALE = this.returnStringIfNotTrue(itemsRows[shopIndex][i].SCALE, "F");
              itemsRows[shopIndex][i].FATHER = this.returnStringIfNotTrue(itemsRows[shopIndex][i].FATHER, "F");
              //itemsRows[shopIndex][i]["COSTPERC"] = itemsRows[shopIndex][i].COSTPERC ? itemsRows[shopIndex][i].COSTPERC : 0;


            } 
          }

          this.setItems(
            {
              shops: shops,
              itSortRows: itSortRows,
              itemsRows: itemsRows,
              supplierRows: supplierRows,
              colorRows: colorRows,
              midaRows: midaRows,
//              entfullRows: entfullRows,
//              specialSalesRows: specialSalesRows,
//              specialSalesBarcodeRows: specialSalesBarcodeRows,

              skip: true
            }


          );

         // console.log(Date.now())
          console.log({
            shops: shops,
            itSortRows: itSortRows,
            supplierRows: supplierRows,
            colorRows: colorRows,
            midaRows: midaRows,
            skip: true
          })
          return {
            shops: shops,
            itSortRows: itSortRows,
            itemsRows: itemsRows,
            supplierRows: supplierRows,
            colorRows: colorRows,
            midaRows: midaRows,
            skip: true
          }
        }
        ),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error


      );
  }

  public getItemSearch(selectedShop: number, Sort: Number, Supplier: Number, SearchField:string, It_Code:string): Observable<any> {

    this.configUrl = 'api/USERS_SHOPS_ITEMSSearch';

    return this.http.get<Observable <any>>(this.configUrl + "/?Shop=" + selectedShop + "&Sort=" + Sort + "&Supplier=" + Supplier + "&SearchField=" + SearchField + "&It_Code=" + It_Code, this.httpOptions)
      .pipe(
        map(data => {
          return data;
        }),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );

  }

  getItemsCompare(CODE: string) {
    this.configUrl = 'api/USERS_SHOPS_ITEMSCompare';

    return this.http.get<any>(this.configUrl + "/?code=" + CODE, this.httpOptions)
      .pipe(
        map(data => {
          return data;
        }),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );

  }

  public postselectedItem(item: Item, shopIndex:number) {
    console.log('post Item', item);
    return this.http.post<number>(this.configUrl, item, this.httpOptions)
      .pipe(
        map(res => {
          console.log('res', res)
          console.log('item', item)
          if (item.TABLEID > 0 && res) //added item
          {
            if (res == 999999999) {
              // alert('פריט נשמר בהצלחה');
              this.openSnackBar('פריט נשמר בהצלחה');
            }
            else {
              //console.log('res 2', res)
              this.openSnackBar('שמירה נכשלה');
            }
          }

          if (item.TABLEID === 0 && res) //added item
          {
            item.TABLEID = res;
            if (res > 1) {
              this._data.itemsRows[shopIndex].push(item);
              this.openSnackBar('פריט נשמר בהצלחה');
            }
            else {
              //console.log('res 1',res)
              this.openSnackBar('שמירה נכשלה');
            }
          }

          return res;
        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  public UpdateItemItCode(item: Item, shop: Shop, shopIndex: number, ITCODENEW: string) {
    this.configUrl = 'api/UpdateItemItCode';
    return this.http.post<boolean>(this.configUrl, {
      "SHOP_ID": shop.SHOP_ID,
      "IT_CODE": item.IT_CODE,
      "IT_CODE_NEW": ""+ITCODENEW
    }
      , this.httpOptions)
      .pipe(
        map(res => {
          if (res) //added item
          {
            const isItemExists = this._data.itemsRows[shopIndex].filter(x => x.IT_CODE == ITCODENEW).length > 0;

            if (isItemExists) { //delete item
              const index = this._data.itemsRows[shopIndex].indexOf(item);
              if (index > -1)
                this._data.itemsRows[shopIndex].splice(index, 1);
            }
            else {
              item.IT_CODE = ITCODENEW;
            }

            this.setItems(this._data);
          }
          return res;
        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }


  public deleteselectedItem(item: Item, shop: Shop, shopIndex:number) {
    this.configUrl = 'api/DELETEITEM';
    return this.http.post<boolean>(this.configUrl, {
      "SHOP_ID": shop.SHOP_ID,
      "IT_CODE": item.IT_CODE
    }
     , this.httpOptions)
      .pipe(
        map(res => {
          //console.log('res', res);
          if (res) //added item
          {
            const index = this._data.itemsRows[shopIndex].indexOf(item);
            if (index > -1)
              this._data.itemsRows[shopIndex].splice(index, 1);
            this.setItems(this._data);
          }
          return res;
        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }


  public postselectedITSORT(itSort: itSortRow, shopIndex: number) {
    this.configUrl = 'api/USERS_SHOPS_ITSORT';
    return this.http.post<number>(this.configUrl, itSort, this.httpOptions)
      .pipe(
        map(res => {
          if (itSort.TABLEID === 0 && res) //added item
          {
            itSort.TABLEID = res;
            this._data.itemsRows[shopIndex].push(itSort);
          }
          return res;
        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  public deleteselectedIt_sort(itSort : itSortRow) {
    this.configUrl = 'api/DELETEIT_SORT';
//    alert(itSort.CODE);
    return this.http.post<number>(this.configUrl, itSort, this.httpOptions)
/*      .pipe(
    return this.http.post<boolean>(this.configUrl, {
      deleteIt_SortRequest: itSort
    }
      , this.httpOptions) */
      .pipe(
        map(res => {
          //console.log('res', res);
          if (res) //delete item
          {
            
          }
          return res;
        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }


  public postselectedSupplier(supplierRow: supplierRow, shopIndex: number) {
    this.configUrl = 'api/USERS_SHOPS_SUPPLIERS';
    return this.http.post<number>(this.configUrl, supplierRow, this.httpOptions)
      .pipe(
        map(res => {
          if (supplierRow.TABLEID === 0 && res) //added item
          {
            supplierRow.TABLEID = res;
            this._data.itemsRows[shopIndex].push(supplierRow);
          }
          return res;
        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  public postselectedMida(midaRow: midaRow, shopIndex: number) {
    this.configUrl = 'api/USERS_SHOPS_MIDA';
    return this.http.post<number>(this.configUrl, midaRow, this.httpOptions)
      .pipe(
        map(res => {
          if (midaRow.TABLEID === 0 && res) //added item
          {
            midaRow.TABLEID = res;
            this._data.midaRows[shopIndex].push(midaRow);
          }
          return res;
        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  public postselectedColor(colorRow: colorRow, shopIndex: number) {
    this.configUrl = 'api/USERS_SHOPS_COLOR';
    return this.http.post<number>(this.configUrl, colorRow, this.httpOptions)
      .pipe(
        map(res => {
          if (colorRow.TABLEID === 0 && res) //added item
          {
            colorRow.TABLEID = res;
            this._data.colorRows[shopIndex].push(colorRow);
          }
          return res;
        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  public postselectedEmvSerialRows(emvSerialRow: emvSerialRow, shopIndex: number) {
    this.configUrl = 'api/USERS_SHOPS_EMVSERIAL';
    return this.http.post<number>(this.configUrl, emvSerialRow, this.httpOptions)
      .pipe(
        map(res => {
          if (emvSerialRow.TABLEID === 0 && res) //added item
          {
            emvSerialRow.TABLEID = res;
            this._data.emvSerialRows[shopIndex].push(emvSerialRow);
          }
          return res;
        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }



  public postselectedSpecialSales(specialSalesRow: SpecialSalesRow, shopIndex: number) {
    this.configUrl = 'api/USERS_SHOPS_SPECIALSALES';
    return this.http.post<number>(this.configUrl, specialSalesRow, this.httpOptions);
  }

  public deleteSpecialSales(specialSalesRow: SpecialSalesRow) {
    this.configUrl = 'api/USERS_SHOPS_SPECIALSALES';
    return this.http.delete<number>(this.configUrl + '/' + specialSalesRow.TABLEID, this.httpOptions)
      .pipe(
        map(res => {
          location.reload();
          return res;
        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
    );

  }



  public postselectedSpecialSalesBarcode(code: string, specialSalesBarcodeRows: specialSalesBarcodeRow[], shopIndex: number) {

    this.configUrl = 'api/USERS_SHOPS_SPECIALSALESBARCODE';
    return this.http.post<number>(
      this.configUrl,
      {
        "CODE": code,
        "SPECIALSALESBARCODELIST": specialSalesBarcodeRows,
        "SHOP": shopIndex
      },
      this.httpOptions)
      .pipe(
        map(res => {
          if (res) //added item
          {
            let specialSalesBarcodeRows = res;
           // this._data.specialSalesBarcodeRows[shopIndex].push(specialSalesBarcodeRows);
          }
          return res;
        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  public postselectedEnt_Item(ent_head: Ent_Head, ent_item: Ent_Item, saveItem: SaveItem, Action: number) {

    this.configUrl = 'api/USERS_SHOPS_ENTRY';
    return this.http.post<number>(
      this.configUrl,
      {
        Ent_Head: ent_head,
        Ent_Item: ent_item,
        SaveItem: saveItem,
        Action: Action,
      },
      this.httpOptions)
      .pipe(
        map(res => {
          if (res) //added item
          {
//            let specialSalesBarcodeRows = res;
            // this._data.specialSalesBarcodeRows[shopIndex].push(specialSalesBarcodeRows);
          }
          return res;
        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  public getnewEntryNumber(selectedShop: any) {
    this.configUrl = 'api/USERS_SHOPS_Ent_Number';
    const url = selectedShop ? this.configUrl + "?selectedShop=" + selectedShop : this.configUrl;
//    alert(url);
    return this.http.get<Control>(url, this.httpOptions)
      .pipe(
        map(res => {
          
          return res;

        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }


  public getEntry(selectedShop: Shop, number1: Number): Observable<any> {
    
    this.configUrl = 'api/USERS_SHOPS_ENTRY';
    const url = selectedShop ? + this.configUrl + "?number=" + number1 : this.configUrl;
    //    const url1 = lastUpdated ? this.configUrl + "?lastUpdated=" + lastUpdatedString : this.configUrl;
//    this.httpOptions.headers.append('selectedShop', "1");

//    alert(this.configUrl + "?selectedShop=" + selectedShop.ITEMS_FROM_SHOP_ID + "&number=" + number1);
    //console.log(Date.now())
//    return this.http.get<Observable<any>>(this.configUrl,this.httpOptions)
    return this.http.get<any>(this.configUrl + "/?selectedShop=" + selectedShop.SHOP_ID + "&number=" + number1,this.httpOptions)
      .pipe(
        map(data => {
          let ent_head = data.ent_Head;
          let ent_item = data.ent_item;
          let items = data.items;
          let it_sort = data.it_sort;
          let suppliers = data.suppliers;
      
//          let ent_item = data["USERS_SHOPS_ENTRY_ROW"].map(x => x.ent_item);
          return data;
        }),
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  
  }

  public getItemsSupList(item: Item, shop: Shop) {
    this.configUrl = 'api/USERS_SHOPS_ITEMSSUP';
    return this.http.post<ItemSup[]>(this.configUrl, {
      "SHOP_ID": shop.SHOP_ID,
      "IT_CODE": item.IT_CODE
    }
      , this.httpOptions)
      .pipe(
        map(res => {
          //console.log('res', res);
          return res;
        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  /*
  public getCustomerList(selectedShops: Shop[]) {
    let shopIds: number[] = [];

    if (selectedShops && selectedShops.length > 0)
      shopIds = selectedShops.map(x => x.SHOP_ID);

    this.configUrl = 'api/CUSTOMER_LIST';
    return this.http.post <Customerlist[]>(this.configUrl, {
      "shopIds": shopIds
    }
      , this.httpOptions)
      .pipe(
        map(res => {
          //console.log('res', res);
          return res;
        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );

  }
  */

  getSalesReportByGroup(reportFrom: Date, reportTo: Date, selectedShops: Shop[], catorgiesBy: number, itCode: string, itSortFrom: number, itSortTo: number, supplierFrom: number, supplierTo: number) {
    this.configUrl = 'api/SALESREPORTBYGROUPDETAILED';

    let shopIds: number[] = [];

    if (selectedShops && selectedShops.length > 0)
      shopIds = selectedShops.map(x => x.SHOP_ID);

    let reportFromString: string = reportFrom.getFullYear() + "-" + this.addLeadingZero((reportFrom.getMonth() + 1)) + "-" + this.addLeadingZero(reportFrom.getDate()) + 'T00:00:00';
    let reporToString: string = reportTo.getFullYear() + "-" + this.addLeadingZero((reportTo.getMonth() + 1)) + "-" + this.addLeadingZero(reportTo.getDate()) + 'T00:00:00';
    return this.http.post<SalesReportByGroupDetailed[]>(this.configUrl, {
      "reportStart": reportFromString,
      "reportEnd": reporToString,
      "sortStart": 1,
      "sortEnd": 2,
      "shopIds": shopIds,
      "catorgiesBy": catorgiesBy,
      "itCode": itCode,
      "itSortFrom": parseInt(itSortFrom?.toString()),
      "itSortTo": parseInt(itSortTo?.toString()),
      "supplierFrom": parseInt(supplierFrom?.toString()),
      "supplierTo": parseInt(supplierTo?.toString())

    }
      , this.httpOptions)
      .pipe(
        map(res => {
          //console.log('res', res);
          return res;
        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );


  }


  public getTransactionsReport(it_code:string,reportFrom: Date, reportTo: Date, selectedShops: Shop[]) {
    let shopIds: number[] = [];

    if (selectedShops && selectedShops.length > 0)
      shopIds = selectedShops.map(x => x.SHOP_ID);


    let reportFromString: string = reportFrom.getFullYear() + "-" + this.addLeadingZero((reportFrom.getMonth() + 1)) + "-" + this.addLeadingZero(reportFrom.getDate()) + 'T00:00:00';
    let reporToString: string = reportTo.getFullYear() + "-" + this.addLeadingZero((reportTo.getMonth() + 1)) + "-" + this.addLeadingZero(reportTo.getDate()) + 'T00:00:00';
    this.configUrl = 'api/TRANSACTIONS_REPORT';
    return this.http.post<Transaction[]>(this.configUrl, {
     // "reportStart": reportFromString,
     // "reportEnd": reporToString,
      "reportStart": "1900-04-28T00:00:00",
      "reportEnd": "2030-04-28T00:00:00",
      "shopIds": shopIds,
      "IT_CODE": it_code
      
    }
      , this.httpOptions)
      .pipe(
        map(res => {
          //console.log('res', res);
          return res;
        }),
        retry(0), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

}

