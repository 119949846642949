<div class="flex-row filter-row-container">

  <div class="form-floating customized-select flex-item">
    <select class="form-select" id="floatingSelect" [(ngModel)]="selectedShop" (ngModelChange)="selectionChangeShop($event.value)" required>
      <option *ngFor="let shop of shops" [ngValue]="shop">{{ shop.SHOP_NAME }}</option>
    </select>
    <label for="floatingSelect" class="label-color">בחר חנות</label>
  </div>

  <div class="form-floating customized-input flex-item">
    <input type="text" class="form-control" id="floatingInput" placeholder="חיפוש" autocomplete="off" (keyup)="applyFilter($event)" #input>
    <label for="floatingInput" class="label-color">חיפוש</label>
  </div>

  <button class="btn btn-primary flex-item customized-button" (click)="newItSort()">קבוצה חדשה</button>
  <button class="btn btn-primary flex-item customized-button" (click)="save()">שמור</button>
  <!--<button class="btn btn-primary flex-item customized-button" (click)="confirmDelete(selectedITSORT)" *ngIf="selectedITSORT.TABLEID">מחק קבוצה</button>-->
  <button class="btn btn-primary flex-item customized-button" (click)="openConfirmDialog()" *ngIf="selectedITSORT.TABLEID">מחק קבוצה</button>
</div>

<div class="flex-row" *ngIf="selectedITSORT">

  <div class="form-floating customized-input flex-item">
    <input type="number" class="form-control" id="floatingInput" autocomplete="off" [(ngModel)]="selectedITSORT.CODE" placeholder="קוד קבוצה" #input>
    <label for="floatingInput" class="label-color">קוד קבוצה</label>
  </div>

  <div class="form-floating customized-input flex-item" id="desc1">
    <input type="text" class="form-control" id="floatingInput" autocomplete="off" [(ngModel)]="selectedITSORT.DESCR" placeholder="תאור קבוצה" #input>
    <label for="floatingInput" class="label-color">תאור קבוצה</label>
  </div>

</div>

  <div class="bordered-div" *ngIf="ifdelete">
    <h1 mat-dialog-title style="text-align:center">ביטול קבוצה</h1>
    <div mat-dialog-content style="text-align: center">
      <p style="margin-top:-1rem">האם אתה בטוח שברצונך לבטל?</p>

      <button mat-button style="background-color:yellow;margin-right:10%" (click)="onNoClick()">לא</button>
      <button mat-button style="background-color:yellow" (click)="onYesClick()">כן</button>
      <!--   </div> -->
    </div>
  </div>




<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 clearBoth">
  <ng-container matColumnDef="CODE">
    <th mat-header-cell *matHeaderCellDef class="small"> קוד קבוצה  </th>
    <td mat-cell *matCellDef="let element"> {{element.CODE}} </td>
  </ng-container>
  <ng-container matColumnDef="DESCR">
    <th mat-header-cell *matHeaderCellDef> תאור קבוצה </th>
    <td mat-cell *matCellDef="let element"> {{element.DESCR}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let idx=index; let even=even; let row;columns: displayedColumns;"
      [style.background-color]="rowClicked == idx ? 'yellow' : (even ? 'white' : 'lightgray')"
      (click)="selectRow(idx, row)"></tr>


</table>

<mat-paginator [length]="itSortRowsFilterd.length" [pageSize]="25">
</mat-paginator>
