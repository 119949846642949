import { Component, OnInit, ViewChild, OnDestroy, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Shop } from '../Shop';
import { ItemsManagmentService } from '../items-managment/items-managment.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { specialSalesBarcodeRow } from '../items-managment/specialSalesBarcodeRow';
import { interval } from 'rxjs/internal/observable/interval';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Item } from '../items-managment/Item';
import { SpecialSalesRow } from '../items-managment/specialSalesRow';
import { ItemSearchComponent } from '../inventory/item-search/item-search.component';
import { itSortRow } from '../items-managment/itSortRow';
import { supplierRow } from '../items-managment/supplierRow';
import { colorRow } from '../items-managment/colorRow';
import { midaRow } from '../items-managment/midaRow';

@Component({
  selector: 'specialSalesBarcode-managment',
  templateUrl: './specialSalesBarcode-managment.component.html',
  styleUrls: ['./specialSalesBarcode-managment.component.css']
})

export class specialSalesBarcodeManagmentComponent implements OnInit, OnDestroy {

  public shops: Shop[] = [];
  public specialSalesBarcodeRows: specialSalesBarcodeRow[][] = [];
  public itemsRows: Item[][] = [];
  public lastUpdated: Date;
  public specialSalesBarcodeRowsFilterd: specialSalesBarcodeRow[] = [];
  public selectedShop: Shop = null;
  public itSortRows: itSortRow[][] = [];
  public supplierRows: supplierRow[][] = [];
  public colorRows: colorRow[][] = [];
  public midaRows: midaRow[][] = [];
  public selectedItem: Item = null;

  public shopIndex = 0;
  public filterValue = "";
  public selectedspecialSalesBarcode: specialSalesBarcodeRow;
  error: any;

  public reportPicker: Date = new Date();


  displayedColumns: string[] =
    [
      'ITEMCODE',
      'IT_DESC',
      'PRICE',
//      'removeitem'
    ];

  @ViewChild(MatPaginator) paginator: MatPaginator;



  public newspecialSalesBarcode(): void {
    this.selectedspecialSalesBarcode = {
      SHOP_ID: this.selectedShop.SHOP_ID,
      TABLEID: 0,
      CODE: null,
      ITEMCODE: null,
      IT_DESC: null,
      PRICE: null,
//      UPDDATE: null,
    }

    //this.openDialog();
  }
  public specialSalesRow: SpecialSalesRow ;
  constructor(public _ItemsManagmentService: ItemsManagmentService, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) {
    this.specialSalesRow = data.selectedItem;
  }

  ngOnInit(): void {
    this.getItems(null);
    this.dataSource.paginator = this.paginator;
  }

  public dataSource = new MatTableDataSource<specialSalesBarcodeRow>(this.specialSalesBarcodeRowsFilterd);

  filterItems() {
    this.specialSalesBarcodeRowsFilterd = this.specialSalesBarcodeRows[this.shopIndex].filter(x => x.CODE == this.specialSalesRow.CODE);
    //this.specialSalesBarcodeRowsFilterd.sort(function (a, b) {
    // return a.CODE - b.CODE
    //

    this.dataSource = new MatTableDataSource<specialSalesBarcodeRow>(this.specialSalesBarcodeRowsFilterd);
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
  }

  getItems(lastUpdated: Date) {
    this._ItemsManagmentService.getItems(lastUpdated)
      .subscribe(
        (data: any) => {
          this.shops = data.shops;
          this.specialSalesBarcodeRows = data.specialSalesBarcodeRows;
          this.selectedShop = this.shops[0];
          this.specialSalesBarcodeRowsFilterd = [];
          this.itemsRows = data.itemsRows;
          this.dataSource = new MatTableDataSource<specialSalesBarcodeRow>(this.specialSalesBarcodeRowsFilterd)
          this.dataSource.paginator = this.paginator;
          this.startDelayedFilter();
          this.newspecialSalesBarcode();
          return;
        }, // success path/
        error => this.error = error // error path
      );
  }

  subscription: Subscription;

  ngOnDestroy() {
    //this.subscription.unsubscribe();
  }


  startDelayedFilter(): void {
    const source = interval(1000);
    this.subscription = source.subscribe(val => {
      if (this.specialSalesBarcodeRowsFilterd.length === 0) {
        this.filterItems()
        this.subscription.unsubscribe();
      }
      return 1;
    }
    );
  }

  selectionChangeShop(selectedshop: Shop) {
    this.selectedShop = selectedshop;
    this.shopIndex = this.shops.findIndex(x => x.SHOP_ID === this.selectedShop.SHOP_ID)
    this.filterItems();
  }

  public selectRow(specialSalesBarcodeRow: specialSalesBarcodeRow) {
    this.selectedspecialSalesBarcode = specialSalesBarcodeRow;
  }

  public removeItemBarcode(specialSalesBarcodeRow: specialSalesBarcodeRow) {
    this.specialSalesBarcodeRowsFilterd = this.specialSalesBarcodeRowsFilterd.filter(x => x.ITEMCODE != specialSalesBarcodeRow.ITEMCODE);
    this.save(false);
  }

  save(newItem = false) {
    for (let i = 0; i < this.specialSalesBarcodeRowsFilterd.length; i++) {
      this.specialSalesBarcodeRowsFilterd[i].PRICE = this.specialSalesBarcodeRowsFilterd[i].PRICE ? parseFloat(this.specialSalesBarcodeRowsFilterd[i].PRICE.toString()) : null;
    }

    const isItemExsits = this.specialSalesBarcodeRowsFilterd.findIndex(x => x.ITEMCODE === this.selectedspecialSalesBarcode.ITEMCODE) > -1;
    if (newItem && !isItemExsits)
      this.specialSalesBarcodeRowsFilterd.push(this.selectedspecialSalesBarcode);

    this._ItemsManagmentService.postselectedSpecialSalesBarcode(this.specialSalesRow.CODE, this.specialSalesBarcodeRowsFilterd, this.shopIndex, this.specialSalesRow)
      .subscribe(
        (data: number) => {
          if (data) //added item
            //console.log("data1",data)
            //window.location.reload()
          this.newspecialSalesBarcode();
          this.filterItems();
        }, // success path/
        error => this.error = error // error path
      );
  }

  openDialog() {
    this.newspecialSalesBarcode();
    if (this.shopIndex === -1)
      this.shopIndex = this.shops.findIndex(x => x.SHOP_ID === this.selectedShop.ITEMS_FROM_SHOP_ID);

    const dialogRef = this.dialog.open(ItemSearchComponent
      , {
        disableClose: true,
        data: {
          shop: this.shops[this.shopIndex],
          itSortRows: this.itSortRows[this.shopIndex],
          supplierRows: this.supplierRows[this.shopIndex],
          colorRows: this.colorRows[this.shopIndex],
          midaRows: this.midaRows[this.shopIndex],
          itemsRows: this.itemsRows[this.shopIndex],
          dialog: this.dialog,
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedItem = this.data.selectedItem;
        this.selectedspecialSalesBarcode.ITEMCODE = result.IT_CODE;
        this.selectedspecialSalesBarcode.PRICE = result.PRICE1;
        this.selectedspecialSalesBarcode.CODE = this.specialSalesRow.CODE;
        this.selectedspecialSalesBarcode.SHOP_ID = this.specialSalesRow.SHOP_ID;
        this.save(true);
      }
      else {
        /*this.selectedItem.PRICE1 = backupItem.PRICE1;
        this.selectedItem.COSTPERC = backupItem.COSTPERC;
        this.selectedItem.NOVAT = backupItem.NOVAT;
        this.selectedItem.STANDARTCOST = backupItem.STANDARTCOST;*/
      }
    });
  }


}
