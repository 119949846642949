
<mat-dialog-content id="content2">

  <p class="h4 dialog-header">השוואת פריטים</p>



  <table id="table1" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="SHOPNAME">
      <th mat-header-cell *matHeaderCellDef> שם חנות  </th>
      <td mat-cell *matCellDef="let element"> {{element.SHOPNAME}} </td>
      <!-- [class.selected]="element.selected" (click)="element.selected= !element.selected" -->
    </ng-container>

    <ng-container matColumnDef="IT_CODE">
      <th mat-header-cell *matHeaderCellDef> קוד פריט  </th>
      <td mat-cell *matCellDef="let element"> {{element.IT_CODE}} </td>
      <!-- [class.selected]="element.selected" (click)="element.selected= !element.selected" -->
    </ng-container>

    <ng-container matColumnDef="IT_DESC">
      <th mat-header-cell *matHeaderCellDef> תאור פריט  </th>
      <td mat-cell *matCellDef="let element"> {{element.IT_DESC}} </td>
    </ng-container>

    <ng-container matColumnDef="BARCODE">
      <th mat-header-cell *matHeaderCellDef> ברקוד </th>
      <td mat-cell *matCellDef="let element"> {{element.BARCODE}} </td>
    </ng-container>

    <ng-container matColumnDef="ITSORT">
      <th mat-header-cell *matHeaderCellDef> קוד מיון </th>
      <td mat-cell *matCellDef="let element"> {{element.ITSORT}} </td>
    </ng-container>

    <ng-container matColumnDef="SUPPLIER">
      <th mat-header-cell *matHeaderCellDef> מספר ספק </th>
      <td mat-cell *matCellDef="let element"> {{element.SUPPLIER}} </td>
    </ng-container>

    <ng-container matColumnDef="PRICE1">
      <th mat-header-cell *matHeaderCellDef> מחיר 1 </th>
      <td mat-cell *matCellDef="let element"> {{element.PRICE1 | number:'1.2'}} </td>
    </ng-container>

    <ng-container matColumnDef="PRICE2">
      <th mat-header-cell *matHeaderCellDef>  מחיר 2 </th>
      <td mat-cell *matCellDef="let element"> {{element.PRICE2}} </td>
    </ng-container>

    <ng-container matColumnDef="PRICE3">
      <th mat-header-cell *matHeaderCellDef>  מחיר 3 </th>
      <td mat-cell *matCellDef="let element"> {{element.PRICE3}} </td>
    </ng-container>
    <ng-container matColumnDef="PRICE4">
      <th mat-header-cell *matHeaderCellDef>  מחיר 4 </th>
      <td mat-cell *matCellDef="let element"> {{element.PRICE4}} </td>
    </ng-container>
    <ng-container matColumnDef="PRICE5">
      <th mat-header-cell *matHeaderCellDef>  מחיר 5 </th>
      <td mat-cell *matCellDef="let element"> {{element.PRICE5}} </td>
    </ng-container>
    <ng-container matColumnDef="PRICE6">
      <th mat-header-cell *matHeaderCellDef>  מחיר 6 </th>
      <td mat-cell *matCellDef="let element"> {{element.PRICE6}} </td>
    </ng-container>
    <ng-container matColumnDef="PRICE7">
      <th mat-header-cell *matHeaderCellDef>  מחיר 7 </th>
      <td mat-cell *matCellDef="let element"> {{element.PRICE7}} </td>
    </ng-container>
    <ng-container matColumnDef="PRICE8">
      <th mat-header-cell *matHeaderCellDef>  מחיר 8 </th>
      <td mat-cell *matCellDef="let element"> {{element.PRICE8}} </td>
    </ng-container>
    <ng-container matColumnDef="PRICE9">
      <th mat-header-cell *matHeaderCellDef>  מחיר 9 </th>
      <td mat-cell *matCellDef="let element"> {{element.PRICE9}} </td>
    </ng-container>

    <ng-container matColumnDef="STANDARTCOST">
      <th mat-header-cell *matHeaderCellDef>  עלות תקן </th>
      <td mat-cell *matCellDef="let element"> {{element.STANDARTCOST}} </td>
    </ng-container>


    <ng-container matColumnDef="UPDDATE">
      <th mat-header-cell *matHeaderCellDef>עדכון אחרון</th>
      <td mat-cell *matCellDef="let element"> {{element.UPDDATE | date: 'dd/MM/yyyy HH:mm' }}</td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let idx=index; let even=even; let row; columns: displayedColumns;"
        (click)="changeTableRowColor(idx,row)" [style.background-color]="rowClicked == idx ? 'yellow' : (even ? 'white' : 'lightgray')">
    </tr>

  </table>
  <br />
  <div style="text-align: left;">
    <button class="btn btn-primary flex-item customized-button" [mat-dialog-close]="false">חזרה</button>
  </div>
  <br />

