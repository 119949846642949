<head>
  <title>Document</title>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js"></script>
  <script src="Tahoma-normal.js"></script>
  <script src="Arial-normal.js"></script>
</head>

<div class="flex-row filter-row-container">
  <date-range-selection (startReportPickerChanged)="startReportPickerChanged($event)" (endReportPickerChanged)="endReportPickerChanged($event)"></date-range-selection>
  <button mat-button style="margin-right: 1.5rem; margin-top:1.5rem" (click)=geSalesReport() cdkFocusInitial mat-raised-button color="primary">בצע</button>

  <div style="margin-right: 1.5rem; ">
    <label style="font-weight:bold;" class="label-color">מיון לפי</label>
    <!--    <select class="form-select" id="floatingSelect" [(ngModel)]="catorgiesBy" #code1 (change)="catorgiesByChange(code1.value)" required>-->
    <select class="form-select" [(ngModel)]="catorgiesBy" (ngModelChange)="catorgiesByChange($event)" required>
      <option [value]=3>קוד פריט</option>
      <option [value]=1>קבוצה</option>
      <option [value]=2>ספק</option>
    </select>
  </div>
  <my-shops-selection (shopsChanged)="shopsChanged($event)"></my-shops-selection>
</div>


<div id="Div1" class="flex-row filter-row-container">
  <div class="form-floating customized-input flex-item" *ngIf="catorgiesBy=='3'">
    <input type="text" class="form-control" id="floatingInput" placeholder="קוד פריט" autocomplete="off" [(ngModel)]="itCode" (ngModelChange)="CahngeItemSalesReport($event)">
    <label for="floatingInput" class="label-color">קוד פריט</label>
  </div>

  <div id="Grp" class="form-floating customized-input flex-item">
    <input type="text" class="form-control" id="floatingInput" placeholder="מקבוצה " autocomplete="off" [(ngModel)]="itSortFrom" (ngModelChange)="CahngeSortSalesReport($event)">
    <label for="floatingInput" class="label-color">מקבוצה </label>
  </div>



  <div id="Grp" class="form-floating customized-input flex-item">
    <input type="text" class="form-control" id="floatingInput" placeholder="עד קבוצה " autocomplete="off" [(ngModel)]="itSortTo" (ngModelChange)="CahngeSortSalesReport($event)">
    <label for="floatingInput" class="label-color">עד קבוצה </label>
  </div>

  <div id="Grp" class="form-floating customized-input flex-item">
    <input type="text" class="form-control" id="floatingInput" placeholder="מספק " autocomplete="off" [(ngModel)]="supplierFrom" (ngModelChange)="CahngeSortSalesReport($event)">
    <label for="floatingInput" class="label-color">מספק </label>
  </div>

  <div id="Grp" class="form-floating customized-input flex-item">
    <input type="text" class="form-control" id="floatingInput" placeholder="עד ספק " autocomplete="off" [(ngModel)]="supplierTo" (ngModelChange)="CahngeSortSalesReport($event)">
    <label for="floatingInput" class="label-color">עד ספק </label>
  </div>

  <button *ngIf="isLoading" style="margin-top:3.5rem;" class="btn btn-primary" type="button" disabled>
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span class="sr-only">  מטעין... </span>
  </button>
 


  <!--
    <div *ngIf="isLoading" class="spinner-border" style="width: 6rem; height: 6rem;" role="status">
      <span class="sr-only">Loading...</span>
    </div>
   -->

</div>

<div style="height:1rem"></div>
<dx-data-grid id="gridContainer"
              [dataSource]="dataSourse1"
              [rowAlternationEnabled]="false"
              keyExpr="CODE"
              [rtlEnabled]="rtlEnabled"
              [repaintChangesOnly]="true"
              (onCellPrepared)="onCellPrepared($event)"
              [columnAutoWidth]="true"
              [wordWrapEnabled]="true"
              (onExporting)="onExporting($event)"
              height="100%"
              [showBorders]="true">

  <div id="exportContainer">
    <dx-button text="Export multiple grids"
               icon="xlsxfile"
               (onClick)="exportGrids($event)"></dx-button>
  </div>

  <dxo-selection mode="single"></dxo-selection>
  <dx-button (onClick)="onExporting($event)">
  </dx-button>



  <dxi-column dataField="IT_CODE" [width]="80" caption="קוד פריט"></dxi-column>
  <dxi-column dataField="Name" [width]="300" caption="תאור פריט"></dxi-column>
  <dxi-column dataField="CODE" [width]="55" [(visible)]="isPdfNoVisible" caption=" קבוצה"></dxi-column>
  <dxi-column *ngIf="catorgiesBy != '1'" dataField="SortName" [width]="150" caption=" שם קבוצה"></dxi-column>
  <dxi-column *ngIf="catorgiesBy == '1'" dataField="SortName" [groupIndex]="0" [width]="180" caption=" שם קבוצה"></dxi-column>
  <dxi-column dataField="SUPPLIER" [(visible)]="isPdfNoVisible" [width]="85" caption=" ספק"></dxi-column>
  <dxi-column *ngIf="catorgiesBy != '2'" dataField="SuppName" [width]="150" caption=" שם ספק"></dxi-column>
  <dxi-column *ngIf="catorgiesBy == '2'" dataField="SuppName" [groupIndex]="0" [width]="180" caption=" שם ספק"></dxi-column>
  <dxi-column dataField="PRICE1" [width]="80" caption=" מחיר " [format]="{ type: 'fixedPoint', precision: 2 }"></dxi-column>
  <dxi-column dataField="Tot_Qty" [width]="80" caption=" כמות" [format]="{ type: 'fixedPoint', precision: 2 }"></dxi-column>
  <dxi-column dataField="Discount" [width]="80" caption=" סה&quot;כ הנחה " [format]="{ type: 'fixedPoint', precision: 2 }"></dxi-column>
  <dxi-column dataField="Tot_Total" [width]="100" caption=" סה&quot;כ מכירה " [format]="{ type: 'fixedPoint', precision: 2 }"></dxi-column>
  <dxi-column dataField="Tot_WitOutVat" [width]="80" caption=" ללא מע&quot;מ " [format]="{ type: 'fixedPoint', precision: 2 }"></dxi-column>
  <dxi-column dataField="Tot_Cost" [width]="80" caption="סה&quot;כ עלות" [format]="{ type: 'fixedPoint', precision: 2 }"></dxi-column>
  <dxi-column dataField="Profit" [width]="80" caption=" רווח " [format]="{ type: 'fixedPoint', precision: 2 }"></dxi-column>
  <dxi-column dataField="DProfit" [width]="64" caption=" %רווח מלמטה " [format]="{ type: 'fixedPoint', precision: 2 }"></dxi-column>
  <dxi-column dataField="AProfit" [width]="68" caption=" %רווח מלמעלה " [format]="{ type: 'fixedPoint', precision: 2 }"></dxi-column>




  <dxo-summary *ngIf="catorgiesBy != '3'">
    <dxi-group-item *ngIf="catorgiesBy == 1" column="SortName"
                    [showInGroupFooter]="true"
                    [alignByColumn]="true"
                    [rtlEnabled]="rtlEnabled"
                    [autoExpandAll]="true">

    </dxi-group-item>

    <dxi-group-item *ngIf="catorgiesBy == '2'" column="SuppName"
                    [showInGroupFooter]="true"
                    [alignByColumn]="true"
                    [rtlEnabled]="rtlEnabled"
                    [autoExpandAll]="true">

    </dxi-group-item>

    <dxi-group-item column="Tot_Total"
                    summaryType="sum"
                    [valueFormat]="{type:'fixedPoint',precision:2}"
                    displayFormat="{0}"
                    [showInGroupFooter]="true">
    </dxi-group-item>


    <dxi-group-item column="Tot_Qty"
                    summaryType="sum"
                    [valueFormat]="{type:'fixedPoint',precision:2}"
                    displayFormat="{0}"
                    [showInGroupFooter]="true">
    </dxi-group-item>

    <dxi-group-item column="Discount"
                    summaryType="sum"
                    [showInGroupFooter]="true"
                    [valueFormat]="{type:'fixedPoint',precision:2}"
                    displayFormat="{0}"
                    [alignByColumn]="true">
    </dxi-group-item>

    <dxi-group-item column="Tot_WitOutVat"
                    summaryType="sum"
                    [valueFormat]="{type:'fixedPoint',precision:2}"
                    displayFormat="{0}"
                    [showInGroupFooter]="true">
    </dxi-group-item>
    <dxi-group-item column="Tot_Cost"
                    summaryType="sum"
                    [valueFormat]="{type:'fixedPoint',precision:2}"
                    [showInGroupFooter]="true"
                    displayFormat="{0}"
                    [alignByColumn]="true">
    </dxi-group-item>
    <dxi-group-item column="Profit"
                    summaryType="sum"
                    [showInGroupFooter]="true"
                    [valueFormat]="{type:'fixedPoint',precision:2}"
                    displayFormat="{0}"
                    [alignByColumn]="true">
    </dxi-group-item>

    <dxi-group-item column="DProfit"
                    summaryType="avg"
                    [showInGroupFooter]="true"
                    displayFormat="{0}"
                    [valueFormat]="{type:'fixedPoint',precision:2}"
                    [alignByColumn]="true">

    </dxi-group-item>

    <dxi-group-item column="AProfit"
                    summaryType="avg"
                    [showInGroupFooter]="true"
                    [valueFormat]="{type:'fixedPoint',precision:2}"
                    displayFormat="{0}"
                    [alignByColumn]="true">
    </dxi-group-item>




  </dxo-summary>





  <dxi-sort-by-group-summary-info summaryItem="count"></dxi-sort-by-group-summary-info>

  <dxo-toolbar>

  </dxo-toolbar>

  <dxo-export [enabled]="true"
              [formats]="['pdf','excel']"
              [allowExportSelectedData]="true"></dxo-export>

</dx-data-grid>

