import { Component } from '@angular/core';
import { UsersRolesService } from './users-roles.service';

@Component({
  selector: 'users-roles',
  templateUrl: './users-roles.component.html',
  styleUrls: ['./users-roles.component.css']
})

export class UsersRolesComponent {

  error: any;

  public roles: Role[] = [];
  public connections: any = [];
  public users: any = [];
  public usersFilterd: any = [];
  public rolesFilterd: Role[] = [];


  public filterUsers = "";
  public filterRoles = "";


  public userroles: Role[] = [];
  public usersroles: any;
  public availableroles: Role[] = [];

  public selectedUser: any = {};
  public selectedRoles: Role[];


  constructor(public _UsersRolesService: UsersRolesService) {
    this.getUsersroles();
  }

  public onUserFilterChange() {
    this.filterUsers = this.filterUsers.trim().toUpperCase();
    if (this.filterUsers.length > 0)
      this.usersFilterd = this.users.filter(x => x.NormalizedEmail.indexOf(this.filterUsers) > -1)
    else
      this.usersFilterd = this.users.filter(x => x)

    if (this.usersFilterd.length === 1)
      this.selectUser(this.usersFilterd[0]);
  }

  public onRolesChange(selectedRoles: Role[]) {
    const dataToSend = [];

    dataToSend.push({ "UserId": this.selectedUser.Id, "RoleId": "123" })
    for (let i = 0; i < selectedRoles.length; i++) {
      dataToSend.push({ "UserId": this.selectedUser.Id, "RoleId": selectedRoles[i].Id })
    }

    this._UsersRolesService.posttUsersRoles(dataToSend)
        .subscribe(
          (data: any) => this.gotData(data), // success path/
          error => this.error = error // error path
        );
  }

  public clearAllRoles(): void {
    this.selectedRoles = [];
    this.onRolesChange(this.selectedRoles);
  }

  public selectAllRoles(): void {
    this.selectedRoles = this.roles.filter(x => x);
    this.onRolesChange(this.selectedRoles);
  }

  selectUser(user: any) {
    this.selectedUser = user;
    this.onRoleFilterChange();
    let usersRolesIds = this.connections.filter(x => x.UserId === this.selectedUser.Id).map(x => x.RoleId);
    this.selectedRoles = this.rolesFilterd.filter(x => usersRolesIds.includes(x.Id));
  }

  public onRoleFilterChange() {
    this.filterRoles = this.filterRoles.trim();
    if (this.filterRoles.length > 0)
      this.rolesFilterd = this.roles.filter(x => x.Id.indexOf(this.filterRoles) > -1)
    else
      this.rolesFilterd = this.roles.filter(x => x)
  }

  filterUserroles() {
    if (!this.selectedUser.roles)
      this.selectedUser.roles = [];

    this.usersroles = this.selectedUser ?
      this.connections
        .filter(x => x.UserId === this.selectedUser.Id)
       // .map(x => x.RoleId)
        .sort((a, b) => (a.RoleId > b.RoleId) ? 1 : -1) : [];

    let usersrolesIds = this.selectedUser ? this.usersroles.map(x => x.RoleId) : [];

    this.availableroles = this.selectedUser.Id ?
      this.roles
        .filter(x => !usersrolesIds.includes(x.Id)).sort((a, b) => (a.Id > b.Id) ? 1 : -1)
      : [];
    
  }

  getUsersroles() {
    this._UsersRolesService.getUsersRoles()
      .subscribe(
        (data: any) => this.gotData(data), // success path/
        error => this.error = error // error path
      );
  }
  gotData(data: any) {
    this.roles = data.ROLES;
    if (this.users.length === 0)
      this.users = data.USERS.sort((a, b) => (a.UserName > b.UserName) ? 1 : -1);
    this.connections = data.CONNECTIONS;
    this.onUserFilterChange();
    this.filterUserroles();
  }



  addUsersRoles(role: Role) {
    this._UsersRolesService.posttUsersRoles({ "UserId": this.selectedUser.Id, "RoleId": role.Id })
      .subscribe(
        (data: any) => this.gotData(data), // success path/
        error => this.error = error // error path
      );
  }

  deleteUsersRoles(RoleId: string) {
    this._UsersRolesService.deleteUsersRoles({ "UserId": this.selectedUser.Id, "RoleId": RoleId })
      .subscribe(
        (data: any) => this.gotData(data), // success path/
        error => this.error = error // error path
      );
  }
}

export class Role {
  ConcurrencyStamp: string;
  Id: string;
  Name: string;
  NormalizedName: string;
}


