import { Component, OnInit, Inject } from '@angular/core';
import { Item } from '../Item';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-update-delete-failed',
  templateUrl: './update-delete-failed.component.html',
  styleUrls: ['./update-delete-failed.component.css']
})

export class UpdateDeleteFailedComponent implements OnInit {
  public selectedItem: Item;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedItem = data.selectedItem;

  }


  ngOnInit(): void {
  }

}
