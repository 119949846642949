import { Component, OnInit, Inject } from '@angular/core';
import { Item } from '../Item';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete-item',
  templateUrl: './confirm-delete-item.component.html',
  styleUrls: ['./confirm-delete-item.component.css']
})

export class ConfirmDeleteItemComponent implements OnInit {

  public selectedItem: Item;

 
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedItem = data.selectedItem;

   }


  ngOnInit(): void {
  }

}
