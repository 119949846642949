import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ZReportService {

  configUrl = 'api/ZREPORT';
  httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json',

    })
  };
  public today: Date;
  constructor(private http: HttpClient) {
    this.today = new Date();
  }

  public getZReport(reportFrom: Date, reportTo: Date) {
    /*let dayString: string = reportFrom.getDate().toString();
    if (dayString.length === 1)
      dayString = "0" + dayString;*/
    /*
    let monthString: string = (reportFrom.getMonth()+1).toString();
    if (monthString.length === 1)
      monthString = "0" + monthString;
      */

    let reportFromString: string = reportFrom.getFullYear() + "-" + this.addLeadingZero((reportFrom.getMonth() + 1)) + "-" + this.addLeadingZero(reportFrom.getDate()) + 'T00:00:00';
    let reporToString: string = reportTo.getFullYear() + "-" + this.addLeadingZero((reportTo.getMonth() + 1)) + "-" + this.addLeadingZero(reportTo.getDate())  + 'T00:00:00'; 

    return this.http.get<any>(this.configUrl + "?reportStart=" + reportFromString + "&reportEnd=" + reporToString, this.httpOptions)//Shop[]
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  private addLeadingZero(numnerAsString):string {
    return ((numnerAsString.length === 1) ? "0" + numnerAsString : numnerAsString);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

}
