<!--<p class="reportDate">{{invoice.invoiceNumber}}</p>-->

<!--<ng-container *ngIf="invoice.length>0">-->


<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <ng-container matColumnDef="IT_DESC">
    <th mat-header-cell style="background-color:lightyellow" *matHeaderCellDef [ngClass]="'custom-cell'">תיאור פריט</th>
    <td mat-cell *matCellDef="let element"> {{element.IT_DESC}} </td>
  </ng-container>

  <ng-container matColumnDef="IT_CODE">
    <th mat-header-cell  style="background-color: lightyellow" *matHeaderCellDef [ngClass]="'custom-cell'">קוד פריט</th>
    <td mat-cell *matCellDef="let element"> {{element.IT_CODE}} </td>
  </ng-container>


  <ng-container matColumnDef="IT_QTY">
    <th mat-header-cell style="background-color: lightyellow" *matHeaderCellDef [ngClass]="custom-cell">כמות</th>
    <td mat-cell *matCellDef="let element"> {{element.IT_QTY}} </td>
  </ng-container>

  <ng-container matColumnDef="IT_PRICE">
    <th mat-header-cell style="background-color: lightyellow" *matHeaderCellDef [ngClass]="'custom-cell'">מחיר</th>
    <td mat-cell *matCellDef="let element"> {{element.IT_PRICE | number:'1.2'}} </td>
  </ng-container>

  <ng-container matColumnDef="IT_DISC">
    <th mat-header-cell style="background-color: lightyellow" *matHeaderCellDef  [ngClass]="'custom-cell'">הנחה</th>
    <td mat-cell *matCellDef="let element"> {{element.IT_DISC | number:'1.2'}} </td>
  </ng-container>

  <ng-container matColumnDef="IT_TOTAL">
    <th mat-header-cell style="background-color: lightyellow" *matHeaderCellDef  [ngClass]="'custom-cell'">סה"כ</th>
    <td mat-cell *matCellDef="let element"> {{element.IT_TOTAL | number:'1.2'}} </td>
  </ng-container>



  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">{{input.value}}"</td>
  </tr>
</table>
 <!--</ng-container>-->


