import { Component, Directive, EventEmitter, Input, Output, OnInit, QueryList, ViewChild } from '@angular/core';
import { DecimalPipe, formatDate, NgFor } from '@angular/common';
import { MatSort, Sort } from '@angular/material/sort';
import { Shop } from '../../Shop';
import { MyShopsService } from '../my-shops.service';
import { SalesReportDatailedByGroupsService } from './sales-report-datailed-by-groups.service';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { DxDataGridComponent } from 'devextreme-angular';
import { locale, loadMessages } from "devextreme/localization";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { saveAs } from 'file-saver';
import { SalesReportByGroupDetailed } from './sales-report-datailed-by-group';
import { LoadingService } from '../../services/loading.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'sales-report-datailed-by-groups',
  templateUrl: './sales-report-datailed-by-groups.component.html',
  styleUrls: ['./sales-report-datailed-by-groups.component.css'],

})


export class SalesReportDatailedByGroupsComponent implements OnInit {

  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  public startReportPicker: Date = new Date();
  public endReportPicker: Date = new Date();
  public itCode: string = null;
  public itSortFrom: number = null;
  public itSortTo: number = null;
  dataSourse1: DataSource;
  gridFilter: Array<any> = null;
  catorgiesBylast = 3;
  isLoading = false;

  public supplierFrom: number = null;
  public supplierTo: number = null;

  public shops: Shop[] = [];
  error: any;
  rtlEnabled = true;
   
  public shopsFilterd: Shop[] = [];
  public filterShops = "";
  public selectedShops: Shop[];
  public catorgiesBy: string = '3';
  catorgiesByStr: string; 
 
  public salesReportdatailedByGroup: SalesReportByGroupDetailed[] = [];
  salesReportdatailedByGroupFilterd: SalesReportByGroupDetailed;
  SalesReportByGroupDetailed1: any;
  public static counter: number = 0;

  isPdfNoVisible: boolean = true;
  isFirst: boolean = true;

  

  constructor(public _salesReportDatailedByGroupsService: SalesReportDatailedByGroupsService, public _myShopsService: MyShopsService, private spinner: NgxSpinnerService) {
    locale(navigator.language);
  }

  
  shopsChanged(shops: Shop[]) {
    this.selectedShops = shops;
    if (this.isFirst) {
      this.isFirst = false;
    }
    else
    this.geSalesReport();
  }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {

  }


  exportGrids(e) {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('CountriesPopulation');
    e.numberFormat = "$#,##0.00";
    e.horizontalAlignment = "right";

    exportDataGrid({
      component: e.component,
      worksheet,
      topLeftCell: { row: 4, column: 1 },
    }).then((cellRange) => {
      // header
      const headerRow = worksheet.getRow(2);
      headerRow.height = 30;
      worksheet.mergeCells(2, 1, 2, 8);
      let ReDate: Date = new Date();
      var d = '0' + (new Date().getMonth() + 1).toString();
      var d1 = '0' + (this.startReportPicker.getMonth() + 1).toString();
      var d2 = '0' + (this.endReportPicker.getMonth() + 1).toString();

      let date_String: string;
      if (this.catorgiesBy == '1') date_String = "דוח מכירות פריטים מפורט לפי קבוצה";
      if (this.catorgiesBy == '2') date_String = "דוח מכירות פריטים מפורט לפי ספק ";
      if (this.catorgiesBy == '3') date_String = " דוח מכירות פריטים מפורט לפי פריט";
      date_String = date_String + "  מתאריך " + (this.startReportPicker.getDate() < 10 ? '0' : '') + this.startReportPicker.getDate() + "/" + d1 + "/" + this.startReportPicker.getFullYear() + "  עד תאריך  " + (this.endReportPicker.getDate() < 10 ? '0' : '') + this.endReportPicker.getDate() + "/" + d2 + "/" + this.endReportPicker.getFullYear() + "                                                      ";
//      date_String = date_String + " תאריך הדפסה:  " + (ReDate.getDate() < 10 ? '0' : '') + ReDate.getDate() + "/" + d + "/" + ReDate.getFullYear() + " " + ReDate.getHours() + ":" + (ReDate.getMinutes() < 10 ? '0' : '') + ReDate.getMinutes();


      headerRow.getCell(1).value = date_String;
      headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 15 };
      headerRow.getCell(1).alignment = { horizontal: 'center' };
     

      // footer
      //const footerRowIndex = cellRange.to.row + 2;
      //const footerRow = worksheet.getRow(footerRowIndex);
      //worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

      //footerRow.getCell(1).value = 'www.wikipedia.org';
      //footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true };
      //footerRow.getCell(1).alignment = { horizontal: 'right' };
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'SaleReportDetail.xlsx');
      });
    });
    e.cancel = true;
  }


  onExporting(e) 
  {
//    alert(e.format);
    if (e.format == 'excel') {
      this.exportGrids(e);
    }
    else {
      const doc = new jsPDF('l', 'pt', 'a4');
      doc.addFont("../assets/David.ttf", "David", "normal");
      doc.addFont("../assets/davidbd.ttf", "David", "bold");

      doc.setFont("David", "normal");
      doc.setFontSize(10);

      let ReDate: Date = new Date();
      var d = '0' + (new Date().getMonth() + 1).toString();
      var d1 = '0' + (this.startReportPicker.getMonth() + 1).toString();
      var d2 = '0' + (this.endReportPicker.getMonth() + 1).toString();

      let date_String: string;
      if (this.catorgiesBy == '1') date_String = "דוח מכירות פריטים מפורט לפי קבוצה";
      if (this.catorgiesBy == '2') date_String = "דוח מכירות פריטים מפורט לפי ספק ";
      if (this.catorgiesBy == '3') date_String = " דוח מכירות פריטים מפורט לפי פריט";
      date_String = date_String + "  מתאריך " + (this.startReportPicker.getDate() < 10 ? '0' : '') + this.startReportPicker.getDate() + "/" + d1 + "/" + this.startReportPicker.getFullYear() + "  עד תאריך  " + (this.endReportPicker.getDate() < 10 ? '0' : '') + this.endReportPicker.getDate() + "/" + d2 + "/" + this.endReportPicker.getFullYear() + "                                                      ";
      date_String = date_String + " תאריך הדפסה:  " + (ReDate.getDate() < 10 ? '0' : '') + ReDate.getDate() + "/" + d + "/" + ReDate.getFullYear() + " " + ReDate.getHours() + ":" + (ReDate.getMinutes() < 10 ? '0' : '') + ReDate.getMinutes();
      let reverseString: string;
      for (let char of date_String) {
        reverseString = char + reverseString;
      }

      reverseString = reverseString.slice(0, date_String.length);

      //    doc.text(new Date().toJSON().slice(0, 15).replace(/-/g, '/') + 'ךיראת : ', 10, 20);
      //    doc.text(date_String + , 50, 20);
      doc.text(reverseString, 50, 20);
      this.isPdfNoVisible = true;

      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: e.component,
        indent: 0,
        customizeCell({ gridCell, pdfCell }) {

          if (gridCell.rowType === 'header') {
            pdfCell.backgroundColor = '#D8FEFF';
            pdfCell.font.style = 'bold';
            pdfCell.textColor = '#010101';
            pdfCell.font.size = 8;

          } else if (gridCell.rowType === 'group') {
            pdfCell.backgroundColor = '#BEDFE6';
          } else if (gridCell.rowType === 'totalFooter') {
            pdfCell.font.style = 'italic';
          }
        },

      }).then(() => {
        //      const header = date_String;
        //      const pageWidth = doc.internal.pageSize.getWidth();
        //      doc.setFontSize(15);
        //      const headerWidth = doc.getTextDimensions(header).w;
        //      doc.text(header, (pageWidth - headerWidth) / 2, 20);

        doc.save(date_String);
      });
    }
  }

  phoneNumberFormat(value) {
      const USNumber = value.match(/(\d{3})(\d{3})(\d{4})/);

      return `(${USNumber[1]}) ${USNumber[2]}-${USNumber[3]}`;
  }
  
  onCellPrepared(e) {

    if (e.rowType === 'header') {
      e.cellElement.style.backgroundColor = "lightgray";
      e.cellElement.style.color = 'black';

    }
    else
      if (e.rowType === 'data') {
      if (e.rowIndex % 2 === 0) {
        // Even row index
        e.cellElement.style.height = "0.35rem";
        e.cellElement.style.backgroundColor = '#c0f0f0'; // Set the background color for even rows
        e.rowElement.css({ height: 10 });
      } else {
        // Odd row index
        e.cellElement.style.height = "0.5rem";
        e.cellElement.style.backgroundColor = '#ffffff'; // Set the background color for odd rows
        e.rowElement.css({ height: 10 });
      }
    }
  }

  fillDatabase(SalesReportByGroupDetailed1: any) {
    this.dataSourse1 = new DataSource({
      store: SalesReportByGroupDetailed1,

      select: [
        "IT_CODE",
        "CODE",
        "Name",
        "SUPPLIER",
        "Tot_Qty",
        "Tot_Total",
        "Tot_WitOutVat",
        "Tot_Cost",
        "Profit",
        "DProfit",
        "AProfit",
        "Discount",
        "Qty_Percentage",
        "Total_Percentage",
        "PRICE1",
        "SortName",
        "SuppName",
      ]
    });

  }

  private addLeadingZero(numnerAsString: number): string {
    console.log('numnerAsString', numnerAsString);
    return ((numnerAsString.toString().length === 1) ? "0" + numnerAsString.toString() : numnerAsString.toString());

  }



  
  private handleError(error: any) {
//    this.loadingService.hide();
    this.isLoading = false;
    console.error('Error fetching sales report:', error);
    this.error = error;
    // Optionally show a user-friendly error message
  }

  geSalesReport() {
    if (this.startReportPicker > this.endReportPicker) {
      this.salesReportdatailedByGroup = [];
      return;
    }

    if (!this.selectedShops || this.selectedShops.length < 1) {
      this.salesReportdatailedByGroup = [];
      return;
    }

//    this.loadingService.show();
    this.isLoading = true;
//    this.spinner.show();

    this._salesReportDatailedByGroupsService.getSalesReportByGroup(this.startReportPicker, this.endReportPicker, this.selectedShops, this.catorgiesBy, this.itCode, this.itSortFrom, this.itSortTo, this.supplierFrom, this.supplierTo)
      .subscribe(
        (data: SalesReportByGroupDetailed[]) => {
          this.salesReportdatailedByGroup = data;
          this.SalesReportByGroupDetailed1 = data[0];
          this.fillDatabase(this.SalesReportByGroupDetailed1);
//          this.loadingService.hide();
          this.isLoading = false;

        }, // success path/
        error => this.error = error // error path
      );
  }

  convertToDate(date: string) {
    if (date.indexOf('T') != -1) {
      date = date.split('T')[0];
    }

    let arr = date.split('-');
    return new Date(`${arr[2]}-${arr[1]}-${arr[0]}`);
  }

  startReportPickerChanged(startReportPicker: string) {
    this.startReportPicker = this.convertToDate(startReportPicker);
//    this.geSalesReport();
  }

  endReportPickerChanged(endReportPicker: string) {
    this.endReportPicker = this.convertToDate(endReportPicker);
//    this.geSalesReport();

  }

  catorgiesByChange(selectedCategory: number) {
//    this.geSalesReport();
//    this.catorgiesBy = selectedCategory;
/*    if (this.catorgiesBy == 3)
      this.SalesReportByGroupDetailed1.sort((a, b) => (a.IT_CODE > b.IT_CODE) ? 1 : ((b.IT_CODE > a.IT_CODE) ? -1 : 0));
    else
    if (this.catorgiesBy == 2)
      this.SalesReportByGroupDetailed1.sort((a, b) => (a.SUPPLIER > b.SUPPLIER) ? 1 : ((b.SUPPLIER > a.SUPPLIER) ? -1 : 0));
    if (this.catorgiesBy == 1)
      this.SalesReportByGroupDetailed1.sort((a, b) => (a.CODE > b.CODE) ? 1 : ((b.CODE > a.CODE) ? -1 : 0));


    this.fillDatabase(this.SalesReportByGroupDetailed1);
    if (this.catorgiesBy == 3 && this.catorgiesBylast != 3) window.location.reload();
    this.catorgiesBylast = this.catorgiesBy; */
  }

  CahngeItemSalesReport(e) {
    this.salesReportdatailedByGroupFilterd = this.SalesReportByGroupDetailed1.filter(x => x.IT_CODE == this.itCode);
    this.fillDatabase(this.salesReportdatailedByGroupFilterd);
  }
  

  CahngeSortSalesReport(e) {
    if (this.itSortTo < this.itSortFrom) this.itSortTo = this.itSortFrom;
    if (this.supplierTo == null) this.supplierTo = this.supplierFrom;
    if (this.supplierFrom == null && this.supplierTo == null) this.salesReportdatailedByGroupFilterd = this.SalesReportByGroupDetailed1.filter(x => x.CODE >= this.itSortFrom && x.CODE <= this.itSortTo);
    else
    if (this.itSortFrom == null && this.itSortTo == null) this.salesReportdatailedByGroupFilterd = this.SalesReportByGroupDetailed1.filter(x => x.SUPPLIER >= this.supplierFrom && x.SUPPLIER <= this.supplierTo);
    else
      if (this.itSortFrom != null && this.supplierFrom != null) this.salesReportdatailedByGroupFilterd = this.SalesReportByGroupDetailed1.filter(x => x.CODE >= this.itSortFrom && x.CODE <= this.itSortTo && x.SUPPLIER >= this.supplierFrom && x.SUPPLIER <= this.supplierTo);


    this.fillDatabase(this.salesReportdatailedByGroupFilterd);
  }


}
