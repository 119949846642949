<div class="flex-row filter-row-container">

  <div style="width:10rem" class="form-floating customized-select flex-item">
    <!--    <select class="form-select" id="floatingSelect" [(ngModel)]="selectedShop" #code1 (ngModelChange)="selectionChangeShop($event)" required>-->
    <select class="form-select" id="floatingSelect" [(ngModel)]="selectedShop" (ngModelChange)="selectionChangeShop($event) ">
      <option *ngFor="let shop1 of shops" [ngValue]="shop1">{{ shop1.SHORT_NAME }}</option>
    </select>
    <label for="floatingSelect" class="label-color">{{shops.length}} בחר חנות </label>
  </div>

  <div class="form-floating customized-input flex-item">
    <input type="text" class="form-control" id="floatingInput" placeholder="חיפוש" autocomplete="off" (keyup)="applyFilter($event)" #input>
    <label for="floatingInput" class="label-color">חיפוש</label>
  </div>
</div>

<div class="flex-row">
  <button class="btn btn-primary flex-item customized-button" (click)="openDialogHeader(true)">מבצע חדש</button>
  <button class="btn btn-primary flex-item customized-button" (click)="openDialogHeader(false)">עדכון מבצע</button>
  <button *ngIf="rowClicked >=0" class="btn btn-primary flex-item customized-button" (click)="deleteSpecialSales(element)">מחיקת מבצע</button>
  <button *ngIf="rowClicked >=0" class="btn btn-primary flex-item customized-button" (click)="openDialogCompare()">השוואת מבצעים</button>

  <button *ngIf="isLoading" style="margin-top:0.8”rem;margin-right:1.0rem" class="btn btn-primary" type="button" disabled>
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span class="sr-only">  מטעין ...</span>
  </button>

</div>

”
<table id="table1" mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="CODE">
    <th mat-header-cell *matHeaderCellDef> קוד מבצע  </th>
    <td mat-cell *matCellDef="let element"> {{element.CODE}} </td>
    <!-- [class.selected]="element.selected" (click)="element.selected= !element.selected" -->
  </ng-container>

  <ng-container matColumnDef="NAME">
    <th mat-header-cell *matHeaderCellDef> שם מבצע  </th>
    <td mat-cell *matCellDef="let element"> {{element.NAME}} </td>
  </ng-container>

  <ng-container matColumnDef="QTY" class="QTY">
    <th mat-header-cell *matHeaderCellDef> כמות </th>
    <td mat-cell *matCellDef="let element"> {{element.QTY}} </td>
  </ng-container>

  <ng-container matColumnDef="PRICE" class="PRICE">
    <th mat-header-cell *matHeaderCellDef> מחיר </th>
    <td mat-cell *matCellDef="let element"> {{element.PRICE | number:'1.2'}} </td>
  </ng-container>

  <ng-container matColumnDef="AMOUNT">
    <th mat-header-cell *matHeaderCellDef> מ-סכום </th>
    <td mat-cell *matCellDef="let element"> {{element.AMOUNT | number:'1.2'}} </td>
  </ng-container>

  <ng-container matColumnDef="IFCONTINUE">
    <th mat-header-cell *matHeaderCellDef> המשך מבצע </th>
    <td mat-cell *matCellDef="let element"> {{element.IFCONTINUE}} </td>
  </ng-container>

  <ng-container matColumnDef="MAXQTY">
    <th mat-header-cell *matHeaderCellDef> כמות MAX </th>
    <td mat-cell *matCellDef="let element"> {{element.MAXQTY}} </td>
  </ng-container>

  <ng-container matColumnDef="ENDDATE">
    <th mat-header-cell *matHeaderCellDef> סיום  </th>
    <td mat-cell *matCellDef="let element"> {{element.ENDDATE | date: 'dd/MM/yyyy'}} </td>
  </ng-container>

  <ng-container matColumnDef="REMARKS">
    <th mat-header-cell *matHeaderCellDef> הודעת מבצע  </th>
    <td mat-cell *matCellDef="let element"> {{element.REMARKS}} </td>
  </ng-container>

  <ng-container matColumnDef="QTY2">
    <th mat-header-cell *matHeaderCellDef>כמות 2</th>
    <td mat-cell *matCellDef="let element"> {{element.QTY2}} </td>
  </ng-container>

  <ng-container matColumnDef="PRICE2">
    <th mat-header-cell *matHeaderCellDef> מחיר 2</th>
    <td mat-cell *matCellDef="let element"> {{element.PRICE2 | number:'1.2'}} </td>
  </ng-container>

  <ng-container matColumnDef="QTY3">
    <th mat-header-cell *matHeaderCellDef>כמות 3</th>
    <td mat-cell *matCellDef="let element"> {{element.QTY3}} </td>
  </ng-container>

  <ng-container matColumnDef="PRICE3">
    <th mat-header-cell *matHeaderCellDef> מחיר 3</th>
    <td mat-cell *matCellDef="let element"> {{element.PRICE3 | number:'1.2'}} </td>
  </ng-container>

  <ng-container matColumnDef="QTY4">
    <th mat-header-cell *matHeaderCellDef>כמות 4</th>
    <td mat-cell *matCellDef="let element"> {{element.QTY4}} </td>
  </ng-container>

  <ng-container matColumnDef="PRICE4">
    <th mat-header-cell *matHeaderCellDef>מחיר 4</th>
    <td mat-cell *matCellDef="let element"> {{element.PRICE5 | number:'1.2'}} </td>
  </ng-container>

  <ng-container matColumnDef="QTY5">
    <th mat-header-cell *matHeaderCellDef>כמות 5</th>
    <td mat-cell *matCellDef="let element"> {{element.QTY5}} </td>
  </ng-container>

  <ng-container matColumnDef="PRICE5">
    <th mat-header-cell *matHeaderCellDef> מחיר 5</th>
    <td mat-cell *matCellDef="let element"> {{element.PRICE3 | number:'1.2'}} </td>
  </ng-container>

  <ng-container matColumnDef="QTY6">
    <th mat-header-cell *matHeaderCellDef>כמות 6</th>
    <td mat-cell *matCellDef="let element"> {{element.QTY6}} </td>
  </ng-container>

  <ng-container matColumnDef="PRICE6">
    <th mat-header-cell *matHeaderCellDef>מחיר 6</th>
    <td mat-cell *matCellDef="let element"> {{element.PRICE6 | number:'1.2'}} </td>
  </ng-container>

  <ng-container matColumnDef="QTY7">
    <th mat-header-cell *matHeaderCellDef>כמות 7</th>
    <td mat-cell *matCellDef="let element"> {{element.QTY7}} </td>
  </ng-container>

  <ng-container matColumnDef="PRICE7">
    <th mat-header-cell *matHeaderCellDef>מחיר 7</th>
    <td mat-cell *matCellDef="let element"> {{element.PRICE7 | number:'1.2'}} </td>
  </ng-container>
  <ng-container matColumnDef="UPDDATE">
    <th mat-header-cell *matHeaderCellDef>עדכון אחרון</th>
    <td mat-cell *matCellDef="let element"> {{element.UPDDATE | date: 'dd/MM/yyyy HH:mm' }}</td>
  </ng-container>

  <ng-container matColumnDef="EDITBARCODES">
    <th mat-header-cell *matHeaderCellDef class="">  </th>
    <td mat-cell *matCellDef="let element">
      <button mat-raised-button color="primary" (click)="openSpecialSalesBarcodeDialog(element)">בחירת פריטים</button>
      <button mat-raised-button color="primary" (click)="deleteSpecialSales(element)">מחיקת מבצע</button>
    </td>

  </ng-container>


  <!--
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectRow(row)"></tr>
    -->
  <tr mat-row *matRowDef="let idx=index; let even=even; let row; columns: displayedColumns;"
      (click)="changeTableRowColor(idx,row)" [style.background-color]="rowClicked == idx ? 'yellow' : (even ? 'white' : 'lightgray')">
  </tr>



</table>

<mat-paginator style="height:2rem" [length]="specialSalesRowsFilterd.length" [pageSize]="10"></mat-paginator>

<div *ngIf="rowClicked >=0" style="margin-top:0.3rem" class="tablebarcode" text-align="center">
  <div style="height:2.5rem; background-color:lightgray">
    <button mat-raised-button color="primary" (click)="openDialogBarcode()">הוספת פריט</button>
    <button mat-raised-button color="primary" (click)="removeItemBarcode(this.selectedSpecialSalesBarcode)">מחק</button>
  </div>
  <table mat-table [dataSource]="dataSourceBarcode" class="mat-elevation-z8 clearBoth">

    <ng-container matColumnDef="ITEMCODE">
      <th mat-header-cell *matHeaderCellDef class="small"> קוד פריט </th>
      <td mat-cell *matCellDef="let element"> {{element.ITEMCODE}} </td>
    </ng-container>
    <ng-container matColumnDef="IT_DESC">
      <th mat-header-cell *matHeaderCellDef> שם פריט </th>

      <td mat-cell *matCellDef="let element"> {{element.IT_DESC}} </td>
    </ng-container>
    <ng-container matColumnDef="PRICE">
      <th mat-header-cell *matHeaderCellDef> מחיר </th>
      <td mat-cell *matCellDef="let element"> {{element.PRICE  | number:'1.2'}} </td>
    </ng-container>
    <!--
        <ng-container matColumnDef="removeitem">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element">
          <button mat-raised-button color="primary" (click)="removeItem(element)">הסר פריט</button>
      </td>
    </ng-container>
    -->
    <tr mat-header-row *matHeaderRowDef="displayedColumnsBarcode;"></tr>
    <tr mat-row *matRowDef="let idx1=index; let even=even; let row1; columns: displayedColumnsBarcode"
        [style.background-color]="rowBarcodeClicked == idx1 ? 'yellow' : (even ? 'white' : 'lightgray')"
        (click)="changeBarcodeTableRowColor(idx1,row1)">
      (click)="selectRow1(row)">
    </tr>

  </table>

  <!--
  <mat-paginator [length]="specialSalesBarcodeRowsFilterd.length"
                 [pageSize]="10">
  </mat-paginator>
    -->
</div>
