import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ZReport, ZReports } from './z-report';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'z-report',
  templateUrl: './z-report.component.html',
  styleUrls: ['./z-report.component.css']
})
export class ZReportComponent implements OnInit {

  @Input('ZReport') ZReport: ZReports = null;
  public displayedColumns: string[] = ['shopName', 'cash', 'checks', 'creditCard', 'hakafa','netPayment', 'sum'];
  public dataSource = new MatTableDataSource([]);

  constructor() {
   
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.displayedColumns = [];
   

    if (this.ZReport.zreports.length > 2)
      this.displayedColumns.push('shopName')

    if (this.ZReport.zreports[this.ZReport.zreports.length - 1]?.cash > 0)
      this.displayedColumns.push('cash')

    if (this.ZReport.zreports[this.ZReport.zreports.length - 1]?.checks > 0)
      this.displayedColumns.push('checks')

    if (this.ZReport.zreports[this.ZReport.zreports.length - 1]?.creditCard > 0)
      this.displayedColumns.push('creditCard')

    if (this.ZReport.zreports[this.ZReport.zreports.length - 1]?.hakafa > 0)
      this.displayedColumns.push('hakafa')
   
    if (this.ZReport.zreports[this.ZReport.zreports.length - 1]?.netPayment > 0)
      this.displayedColumns.push('netPayment')

    if (this.ZReport.zreports[this.ZReport.zreports.length - 1]?.sum > 0)
      this.displayedColumns.push('sum')
    
    this.dataSource = new MatTableDataSource(this.ZReport.zreports);
  }



}
