import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs/internal/observable/interval';
import IPrice from 'src/interfaces/IPrice';
import { Shop } from '../Shop';
import { colorRow } from './colorRow';
import { ConfirmDeleteItemComponent } from './confirm-delete-item/confirm-delete-item.component';
import { FastPriceUpdateComponent } from './fast-price-update/fast-price-update.component';
import { FilterItems } from './filter-items';
import { Item } from './Item';
import { iteminsertComponent } from './item-insert/item-insert.component';
import { ItemsManagmentService } from './items-managment.service';
import { ItemsCompareComponent } from './ItemsCompare/ItemsCompare.component';
import { itSortRow } from './itSortRow';
import { midaRow } from './midaRow';
import { supplierRow } from './supplierRow';
import { UpdateDeleteFailedComponent } from './update-delete-failed/update-delete-failed.component';
import { UpdateItemItcodeComponent } from './update-item-itcode/update-item-itcode.component';
import { ChangeDetectorRef } from '@angular/core';


export interface PeriodicElement {
  IT_CODE: string;
  IT_DESC: string;
  ITSORT: number;
  CUT: number;
  PRICE1: number;
  PRICE2: number;
  SUPPLIER: number;
  SOLDDATE: Date;
  UPDDATE: Date;
}

@Component({
  selector: 'items-managment',
  templateUrl: './items-managment.component.html',
  styleUrls: ['./items-managment.component.css'],
//  encapsulation: ViewEncapsulation.None
})


export class ItemsManagmentComponent implements OnInit, OnDestroy {

  public selected = new FormControl('valid', [Validators.required, Validators.pattern('valid')]);
  public shops: Shop[] = [];
  public itSortRows: itSortRow[] = [];
  public supplierRows: supplierRow[][] = [];
  public colorRows: colorRow[][] = [];
  public midaRows: midaRow[][] = [];
  public itemsRows: Item[] = [];
  public lastUpdated: Date;

  public itemsRowsFilterd: Item[] = [];

  public selectedShop: Shop = null;

  public selectedCategory: itSortRow = null;
  public selectAllCategories: itSortRow = { CODE: 999999999, DESCR: "כל הקטגוריות", TABLEID: 0, SHOP_ID: 0, ITSORT: null, DISDCOUNT: null };

  public selectedSupplier: supplierRow = null;
  public selectAllSuppliers: supplierRow = { CLNUM: 999999999, CLNAME: "כל הספקים", TABLEID: 0, SHOP_ID: 0 };//new supplierRow(999999999, "בחר ספק");
  public selectedSUPPLIER: supplierRow;

  public selectedColor: colorRow = null;
  public selectAllColors: colorRow = { CODE: 999999999, DESC1: "בחר צבע", TABLEID: 0, SHOP_ID: 0 }; //new colorRow(999999999, "בחר צבע");
  public selectedCOLOR: colorRow;

  public selectedMida: midaRow = null;
  public selectAllMida: midaRow = { CODE: 999999999, DESC1: "בחר מידה", TABLEID: 0, SHOP_ID: 0 };//new midaRow(999999999, "בחר מידה");
  public selectedMIDA: midaRow;

  public shopIndex = 0;
  public filterValue = null;
  isLoading = false;
  public selectedItem: Item;
  public selectedITSORT: itSortRow;
  public selectedIT_CODE: string;
  public selectedIT_DESC: string;
  public getScreenWidth: any;
  public getScreenHeight: any;
  public isColumnsMobile: boolean = false;

  private priceLabels = [ 'PRICE1', 'PRICE2', 'PRICE3', 'PRICE4', 'PRICE5', 'PRICE6', 'PRICE7', 'PRICE8', 'PRICE9' ];
  public prices: IPrice[];
  public currentPriceItem: IPrice;

  // TODO: let's see.. set initial values.. and then create a function that sets the current item
  populatePricesToArray() {
    this.prices = [];
    this.priceLabels.forEach(( prop, idx ) => {
      if (this.selectedItem[prop] != null) this.prices.push({ name: `מחיר ${idx+1}`, value: this.selectedItem[prop] });
    })
    this.currentPriceItem = this.prices[0];
  }

  setSelectedItemPriceToCurrentPrice(e) {
    const idx = this.prices.findIndex((priceItem) => {return this.currentPriceItem.name === priceItem.name});
    this.selectedItem[this.priceLabels[idx]] = this.currentPriceItem.value;
  }

  setCurrentPriceItem = (i: number) => {
    this.currentPriceItem = this.prices[i];
  }

  addPriceItem() {
    const idx = this.prices.length + 1;
    this.prices.push({ name: `מחיר ${idx}`, value: null });
    this.currentPriceItem = this.prices[this.prices.length - 1];
  }

  initPrices() {
    this.prices = [];
    this.prices.push( { name: 'מחיר 1', value: null } );
    this.currentPriceItem = this.prices[0];
  }
  // TODO: end - let's see.. set initial values.. and then create a function that sets the current item

  stringsToBoolean() {
    this.selectedItem.IT_SALE_BOOL = this.selectedItem.IT_SALE.includes("T");
    this.selectedItem.IT_CHPRICE_BOOL = this.selectedItem.IT_CHPRICE.includes("T");
    this.selectedItem.SCALE_BOOL = this.selectedItem.SCALE.includes("T");
    this.selectedItem.NOVAT_BOOL = this.selectedItem.NOVAT.includes("T");
    this.selectedItem.IF_DISCOUNT_BOOL = this.selectedItem.IF_DISCOUNT.includes("T");
  }

  
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  

  subscription: Subscription;
  rowClicked: number;
  iFirst: boolean;
  lwidth: string;
  lheight: string;
  ltop: string;


  error: any;

  displayedColumnForMobilesPhones: string[] =
    [
      'IT_CODE',
      'IT_DESC',
      'PRICE1',
    ];

  displayedColumnForDesk1024: string[] =
    [
      'IT_CODE',
      'IT_DESC',
      'BARCODE',
      'ITSORT',
      //'CUT',
      'PRICE1',
      'PRICE2',
      //'PRICE3',
      //'DISCOUNT',
      //'STANDARTCOST',
      //'MINIQTY',
      //'MAXQTY',
      'SUPPLIER',
      //'IT_SALE',
      //'IT_CHPRICE',
      //'SCALE',
      //'OTHERS',
      //'NOVAT',
      //'IF_DISCOUNT',
      //'SALEREMARK'
      'SOLDDATE',
      'UPDDATE'
    ];


  initialDisplayedColumns: string[] =
    [
      'IT_CODE',
      'IT_DESC',
      'BARCODE',
      'ITSORT',
      //'CUT',
      'PRICE1',
      'PRICE2',
      //'PRICE3',
      //'DISCOUNT',
      //'STANDARTCOST',
      //'MINIQTY',
      //'MAXQTY',
      'SUPPLIER',
      //'IT_SALE',
      //'IT_CHPRICE',
      //'SCALE',
      //'OTHERS',
      //'NOVAT',
      //'IF_DISCOUNT',
      'SOLDDATE',
      'UPDDATE',
      'SALEREMARK1'
    ];

  displayedColumns: string[] =
    [
      'IT_CODE',
      'IT_DESC',
      'BARCODE',
      'ITSORT',
      //'CUT',
      'PRICE1',
      'PRICE2',
      //'PRICE3',
      //'DISCOUNT',
      //'STANDARTCOST',
      //'MINIQTY',
      //'MAXQTY',
      'SUPPLIER',
      //'IT_SALE',
      //'IT_CHPRICE',
      //'SCALE',
      //'OTHERS',
      //'NOVAT',
      //'IF_DISCOUNT',
      //'SALEREMARK'
      'SOLDDATE',
      'UPDDATE',
      'SALEREMARK1'
    ];
  public dataSource = new MatTableDataSource<Item>(this.itemsRowsFilterd);
  public filterText: string = null;
  public selection: SelectionModel<PeriodicElement>;
  

  constructor(
    private cdr: ChangeDetectorRef,
    public _ItemsManagmentService: ItemsManagmentService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router) {
    this.selection = new SelectionModel<PeriodicElement>(true);  }


  @ViewChild(MatSort) sort: MatSort;
  ngOnDestroy() {
    this.subscription.unsubscribe();

  }

  openDialog(selectedItem: Item) {
    const backupItem: Item = JSON.parse(JSON.stringify(selectedItem));
    const dialogRef = this.dialog.open(FastPriceUpdateComponent
      , {
        disableClose: true,
        data: {
          selectedItem: selectedItem
        }
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result === true)
        this.save();
      else {
        this.selectedItem.PRICE1 = backupItem.PRICE1;
        this.selectedItem.COSTPERC = backupItem.COSTPERC;
        this.selectedItem.NOVAT = backupItem.NOVAT;
        this.selectedItem.STANDARTCOST = backupItem.STANDARTCOST;
      }
    });
  }

  confirmDelete(selectedItem: Item) {

    if (this.isColumnsMobile == true) {
      this.lwidth = '80%';
      this.lheight = '30%';
      this.ltop = '5.0rem';
    }


    const dialogRef = this.dialog.open(
      ConfirmDeleteItemComponent
      , {
        disableClose: true,
        maxWidth: '100vw',
        maxHeight: '100vh',
        position: {
          top: this.ltop
        },
        height: this.lheight,
        width: this.lwidth,
        data: {

          selectedItem: selectedItem
        }
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      if (result === true) {
        this.selectedItem = selectedItem;
        this.deleteItem(selectedItem);
      }
      else {
        //console.log(result);
      }
    });
  }

  updateItCodeWindow(selectedItem: Item) {
    const dialogRef = this.dialog.open(
      UpdateItemItcodeComponent
      , {
        disableClose: true,
        data: {
          selectedItem: selectedItem
        }
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      console.log("result",result)
      if (result)
      {
        console.log("start update");
        this.updateItCode(result);

      }
      else {
        console.log("dont update");
      }
    });
  }

  updateDeleteFailed(selectedItem: Item) {
    const dialogRef = this.dialog.open(
      UpdateDeleteFailedComponent
      , {
        disableClose: true,
        data: {
          selectedItem: selectedItem
        }
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result === true)
        this.updateItCodeWindow(selectedItem);
      else {

      }
    });
  }

  sortItems(sortState: Sort){
  this.dataSource.sort = this.sort;
//  const sortState: Sort;
  // = {active: 'IT_CODE', direction: 'asc'};
  this.sort.active = sortState.active;
  this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
    this.cdr.detectChanges();  // Force change detection
  }

ngOnInit(): void {

    this.initPrices();

    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth <= 400 && !this.isColumnsMobile) {

      this.displayedColumns = this.displayedColumnForMobilesPhones;
      this.isColumnsMobile = true;

    } else
      if (this.getScreenWidth <= 1024 && !this.isColumnsMobile) {

        this.displayedColumns = this.displayedColumnForDesk1024;
        this.isColumnsMobile = true;
      }
      else
        if (this.getScreenWidth >= 1024 && this.isColumnsMobile) {

          this.displayedColumns = this.initialDisplayedColumns;
          this.isColumnsMobile = false;
        }
  this.newItem();

  this.iFirst = true;
    this.getItems(null);
//    if (this.isColumnsMobile) this.paginator.pageSize = 20;
//  this.dataSource.paginator = this.paginator;
  

}

  public openDialogCompare() {
    this.lwidth = '95%';
    this.lheight = '50%';
    this.ltop = '6.5rem';
    const dialogRef = this.dialog.open(
      ItemsCompareComponent,
      {
        disableClose: true,
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: this.lheight,
        width: this.lwidth,        
        data: {
          CompareItemRow: this.selectedItem,
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      //      if (result) {
      //        this.save(result);
      //      }
    });

  }


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.sort.sortChange.subscribe((sortState: Sort) => {
      console.log('MatSort event triggered:', sortState); // Debug message
      this.sortItems(sortState);
    });
  }
  public getItemSearch(selectedShop: number, Sort: Number, Supplier: Number, SearchField: string) {
    //    alert(selectedShop);
    this.isLoading = true;
    this._ItemsManagmentService.getItemSearch(selectedShop, Sort, Supplier, SearchField,null)
      .subscribe(
        (data: any) => {

          this.itemsRows = data.itemsSEARCHRows;
          this.isLoading = false;
          this.filterItems();
          
//          this.dataSource = new MatTableDataSource<Item>(data.itemsSEARCHRows)
//          this.dataSource.paginator = this.paginator;

          return;
        }, // success path/
        error => { this.isLoading = false; this.error = error } // error path
      );
  }

  selectionChangeShop(selectedshop: Shop) {
    this.selectedShop = selectedshop;
    this.shopIndex = this.shops.findIndex(x => x.SHOP_ID === this.selectedShop.SHOP_ID)
//    this.selectionChangeCategory(this.selectAllCategories);
//    this.selectionChangeSupplier(this.selectAllSuppliers);
  }

  selectionChangeCategory(selectedCategory: itSortRow) {
    let supplier = -1;
    this.selectedCategory = selectedCategory;
    if (this.selectedCategory.CODE == null) this.selectedCategory.CODE = -1;
    if (this.selectedSupplier != null && this.selectedSupplier.CLNAME != "כל הספקים") 
      if (this.selectedSupplier.CLNUM != null) supplier = this.selectedSupplier.CLNUM;

    this.getItemSearch(this.selectedShop.ITEMS_FROM_SHOP_ID, this.selectedCategory.CODE, supplier, this.filterText);
//    this.filterItems();
//    this.saveFilters();
  }

  SearchFocusOutEvent(event) {
    this.filterText = event.target.value;
    let supplier = -1;
    let sortcode = -1;

    if (this.selectedCategory != null)
      if (this.selectedCategory.DESCR != "כל הקטגוריות")
        if (this.selectedCategory.CODE != null) sortcode = this.selectedCategory.CODE;
    if (this.selectedSupplier != null)
      if (this.selectedSupplier.CLNUM != null && this.selectedSupplier.CLNAME != "כל הספקים") supplier = this.selectedSupplier.CLNUM;

    if (this.filterText.length > 3 || event.keyCode === 13)
      this.getItemSearch(this.selectedShop.ITEMS_FROM_SHOP_ID, sortcode, supplier, this.filterText);

  }

  SerchhandleKeyboardEvent(event) {
    this.filterText = event.target.value;
    if (event.keyCode === 13) {
//      event.keyCode = 0;
      this.SearchFocusOutEvent(event);
    }
  }

  selectionChangeSupplier(selectedSupplier: supplierRow) {
    this.selectedSupplier = selectedSupplier;
    let sortcode = -1;
    let supplier = -1;
    if (this.selectedCategory != null)
      if (this.selectedCategory.DESCR != "כל הקטגוריות" )
    if (this.selectedCategory.CODE != null) sortcode = this.selectedCategory.CODE;

    //    if (this.selectedSupplier != null)
    if (this.selectedSupplier.CLNUM != null && this.selectedSupplier.CLNAME != "כל הספקים" ) supplier = this.selectedSupplier.CLNUM;

    this.getItemSearch(this.selectedShop.ITEMS_FROM_SHOP_ID, sortcode, supplier, this.filterText);
//    this.filterItems();
//    this.saveFilters();
  }


  changeInputITSORT() {
    this.selectedITSORT = this.itSortRows.find(x => x.CODE == this.selectedItem.ITSORT);
  }

  selectionChangeselectedITSORT(selectedCategory: itSortRow) {
    this.selectedItem.ITSORT = this.selectedITSORT.CODE;
  }

  changeInputSUPPLIER() {
    this.selectedSUPPLIER = this.supplierRows[this.shopIndex].find(x => x.CLNUM == this.selectedItem.SUPPLIER);
  }

  selectionChangeselectedSUPPLIER() {
    // this.selectedItem.SUPPLIER = selectedSupplier.CLNUM;
    this.selectedItem.SUPPLIER = this.selectedSUPPLIER.CLNUM;
  }

  changeInputCOLOR() {
    this.selectedCOLOR = this.colorRows[this.shopIndex].find(x => x.CODE == this.selectedItem.COLOR);
  }

  selectionChangeselectedCOLOR(selectedColor: colorRow) {
    this.selectedItem.COLOR = this.selectedCOLOR.CODE;
  }

  changeInputMIDA() {
    this.selectedMIDA = this.midaRows[this.shopIndex].find(x => x.CODE == this.selectedItem.MIDA);
  }

  selectionChangeselectedMIDA() {
    this.selectedItem.MIDA = this.selectedMIDA.CODE;
  }

  filterItems() {
    this.itemsRowsFilterd = this.itemsRows; // .filt nd(x => x.SHOP_ID === this.shopIndex);
//     (x.ITSORT === this.selectedCategory.CODE || this.selectedCategory.DESCR === "כל הקטגוריות") &&
//      (!this.selectedSupplier || x.SUPPLIER === this.selectedSupplier.CLNUM || this.selectedSupplier.CLNAME === "כל הספקים"));
    this.dataSource = new MatTableDataSource<Item>(this.itemsRowsFilterd);
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
//    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  IT_CODEonFocusOutEvent(event) {
    this.selectedIT_CODE = event.target.value;
    let selected = this.itemsRowsFilterd.filter(x => x.IT_CODE == event.target.value);
    if (selected.length === 1)
      this.selectRow(0,selected[0]);
    else {
      this.newItem();
      this.selectedItem.IT_CODE = event.target.value;
      this.SearchFocusOutEvent(event);
      this.stringsToBoolean();
      this.populatePricesToArray();
      this.changeInputITSORT();
      this.changeInputSUPPLIER();
      this.changeInputCOLOR();
      this.changeInputMIDA();
    }
  }

  IT_CODEhandleKeyboardEvent(event) {
    this.selectedIT_CODE = event.target.value;
    if (event.keyCode === 13) {
      this.IT_CODEonFocusOutEvent(event);
    }
  }

  IT_DESChandleKeyboardEvent(event) {
    if (event.keyCode === 113) {
      this.selectedItem.IT_DESC = this.selectedIT_DESC;
    }
    this.selectedIT_DESC = event.target.value;
  }
  /*
   returnStringIfNotTrue(str: string, falseStr: string) {
    return !str || (str.length === 0) || (str !== "T" && str !== "Tr") ? falseStr : str;
  }
  */

  checkValue(value: boolean) {
    return value ? 'T' : 'F';
  }


  getItems(lastUpdated: Date) {
    this._ItemsManagmentService.getItems(lastUpdated)
      .subscribe(
        (data: any) => {
          this.shops = data.shops;
          this.itSortRows = data.itSortRows;
          this.supplierRows = data.supplierRows;
          this.colorRows = data.colorRows;
          this.midaRows = data.midaRows;
          this.itemsRows = data.itemsRows[this.shopIndex];
          this.selectedShop = this.shops[0];
          this.selectedCategory = this.selectAllCategories;
          this.selectedSupplier = this.selectAllSuppliers;
          this.itemsRowsFilterd = [];
          this.dataSource = new MatTableDataSource<Item>(this.itemsRowsFilterd)
//          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.sortItems(this.sort);
//          this.startDelayedFilter();
          this.filterItems();
          return;
        }, // success path/
        error => this.error = error // error path
      );
  }
  public pageIndex:number = 0;
  startDelayedFilter(): void {
    const source = interval(1000);
    this.subscription = source.subscribe(val => {
      if (this.itemsRowsFilterd.length === 0) {
        let currentFilter:FilterItems = this._ItemsManagmentService.getCurrentItemManagmentFilter();
        if (currentFilter)
          {
            if (currentFilter.filterText)
            {
            this.filterText = currentFilter.filterText;
            this.filterValue = currentFilter.filterText;
            this.dataSource.filter = this.filterValue ? this.filterValue.trim().toLowerCase() :'';
            }
            this.selectionChangeCategory(currentFilter.selectedCategory);
            this.selectionChangeSupplier(currentFilter.selectedSupplier);
          }

        this.filterItems();
        this.sortItems(this.sort);
//        console.log(currentFilter)
        if(currentFilter){

          setTimeout((idx) => {
//            this.paginator.pageIndex = idx;
//            if (this.isColumnsMobile) this.paginator.pageSize = 20;
//            this.paginator._changePageSize(this.paginator.pageSize);
          }, 0, currentFilter.pageIndex);

        }


        this.saveFilters();
        this.subscription.unsubscribe();
        let selectedItemTemp = this._ItemsManagmentService.getCurrentItemRow();
        if (selectedItemTemp)
        {
          this._ItemsManagmentService.setCurrentItemRow(null);
          this.selectedItem = selectedItemTemp;
        }
      }
      return 1;
    }
    );
  }

  // MatPaginator Inputs
  length = 100;
  pageSize = 30;
  pageSizeOptions: number[] = [30];


  // MatPaginator Output
  pageEvent: PageEvent;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  publicshiftFocusDown(idx: any) {
    idx = idx + 1; 
  }

  public selectRow(idx: any, item: Item) {
    if (this.rowClicked === idx) 
    {
      this.rowClicked = -1;
    }
    else 
    { 
      this.rowClicked = idx;
    }
    
    this.selectedItem = item;
    
    // TODO: Apply strings vars to matching boolean vars
    this.stringsToBoolean();
    this.populatePricesToArray();
    this.changeInputITSORT();
    this.changeInputSUPPLIER();
    this.changeInputCOLOR();
    this.changeInputMIDA();
    //this.store.dispatch(increment());

  }

  public newItem(): void
  {
    this.selectedItem = new Item();
    this.selectedItem.TABLEID = 0;
    this.selectedItem.IT_CODE = null;
    this.selectedItem.IT_DESC = null;
    this.selectedItem.BARCODE = null;
    this.selectedItem.ITSORT = null;
    this.selectedItem.CUT = null;
    this.selectedItem.PRICE1 = null;
    this.selectedItem.PRICE2 = null;
    this.selectedItem.PRICE3 = null;
    this.selectedItem.PRICE4 = null;
    this.selectedItem.PRICE5 = null;
    this.selectedItem.PRICE6 = null;
    this.selectedItem.PRICE7 = null;
    this.selectedItem.PRICE8 = null;
    this.selectedItem.PRICE9 = null;
    this.selectedItem.COSTPERC = null;
    this.selectedItem.DISCOUNT = null;
    this.selectedItem.STANDARTCOST = null;
    this.selectedItem.MAXQTY = null;
    this.selectedItem.MINIQTY = null;
    this.selectedItem.SUPPLIER = null;
    this.selectedItem.COLOR = null;
    this.selectedItem.MIDA = null;
    
    this.selectedItem.IT_SALE_BOOL = false;
    this.selectedItem.IT_CHPRICE_BOOL = false;
    this.selectedItem.NOVAT_BOOL = false;
    this.selectedItem.IF_DISCOUNT_BOOL = true;
    this.selectedItem.SCALE_BOOL = false;

    this.selectedItem.OTHERS = null;
    this.selectedItem.SALEREMARK = null;
    this.selectedItem.FATHER = "T";
    this.selectedItem.QTYPERUNIT = null;
    this.selectedSupplier = null;
    this.selectedSUPPLIER = null;
    this.selectedMida = null;
    this.selectedMIDA = null;
    this.selectedITSORT = null;
    this.selectedColor = null;
    this.selectedCOLOR = null;
    this.selectedItem.SOLDDATE = null;
    this.selectedItem.UPDDATE = null;

    if (this.isColumnsMobile == true) {
      this.lwidth = '99%';
      this.lheight = '90%';
      this.ltop = '4.0rem';
    }

//    this.isColumnsMobile = true;

    if (this.iFirst && this.isColumnsMobile)
    {
      const dialogRef = this.dialog.open(iteminsertComponent,
      {
         disableClose: true,
         maxWidth: '100vw',
         maxHeight: '100vh',
         position: {
         top: this.ltop },
         height: this.lheight,
         width: this.lwidth,
         data:{
             selectedItem: this.selectedItem,
             shops: this.shops,
             itSortRows: this.itSortRows,
            supplierRows: this.supplierRows,
         }
      });
      dialogRef.afterClosed().subscribe((result: Item) => {
        if (result.IT_CODE.length > 0) {
          this.selectedItem = result;
          this.selectedIT_CODE = this.selectedItem.IT_CODE;
          this.save();
        }
      });
    }
  }

  updateItem(): void {
    if (this.isColumnsMobile == true) {
      this.lwidth = '100%';
      this.lheight = '100%';
      this.ltop = '3.5rem';
    }

    if (this.iFirst && this.isColumnsMobile) {
      const dialogRef = this.dialog.open(iteminsertComponent,
        {
          disableClose: true,
          maxWidth: '100%',
          maxHeight: '200%',
          height: this.lheight,
          width: this.lwidth,
          data: {
            selectedItem: this.selectedItem,
            shops: this.shops,
            itSortRows: this.itSortRows,
            supplierRows: this.supplierRows,
          }
        });
      dialogRef.afterClosed().subscribe((result: Item) => {
        if (result.IT_CODE.length > 0) {
          this.selectedItem = result;
          this.selectedIT_CODE = this.selectedItem.IT_CODE;
          this.save();
        }
      });
    }
  }

  save() {

    if (this.selectedItem.TABLEID === 0) {
      this.selectedItem.IT_CODE = this.selectedIT_CODE;
    }

    this.selectedItem.SHOP_ID = this.selectedShop.SHOP_ID;
    this.selectedItem.ITSORT = this.selectedItem.ITSORT ? parseInt(this.selectedItem.ITSORT.toString()) : null;
    this.selectedItem.CUT = this.selectedItem.CUT ? parseInt(this.selectedItem.CUT.toString()) : null;
    this.selectedItem.PRICE1 = this.selectedItem.PRICE1 ? parseFloat(this.selectedItem.PRICE1.toString()) : null;
    this.selectedItem.PRICE2 = this.selectedItem.PRICE2 ? parseFloat(this.selectedItem.PRICE2.toString()) : null;
    this.selectedItem.PRICE3 = this.selectedItem.PRICE3 ? parseFloat(this.selectedItem.PRICE3.toString()) : null;

    this.selectedItem.PRICE4 = this.selectedItem.PRICE4 ? parseFloat(this.selectedItem.PRICE4.toString()) : null;
    this.selectedItem.PRICE5 = this.selectedItem.PRICE5 ? parseFloat(this.selectedItem.PRICE5.toString()) : null;
    this.selectedItem.PRICE6 = this.selectedItem.PRICE6 ? parseFloat(this.selectedItem.PRICE6.toString()) : null;

    this.selectedItem.PRICE7 = this.selectedItem.PRICE7 ? parseFloat(this.selectedItem.PRICE7.toString()) : null;
    this.selectedItem.PRICE8 = this.selectedItem.PRICE8 ? parseFloat(this.selectedItem.PRICE8.toString()) : null;
    this.selectedItem.PRICE9 = this.selectedItem.PRICE9 ? parseFloat(this.selectedItem.PRICE9.toString()) : null;

    this.selectedItem.DISCOUNT = this.selectedItem.DISCOUNT ? parseFloat(this.selectedItem.DISCOUNT.toString()) : null
    this.selectedItem.STANDARTCOST = this.selectedItem.STANDARTCOST ? parseFloat(this.selectedItem.STANDARTCOST.toString()) : null;
    this.selectedItem.COSTPERC = this.selectedItem.COSTPERC ? parseFloat(this.selectedItem.COSTPERC.toString()) : null;
    this.selectedItem.QTYPERUNIT = this.selectedItem.QTYPERUNIT ? parseFloat(this.selectedItem.QTYPERUNIT.toString()) : null;

    
    this.selectedItem.MAXQTY = this.selectedItem.MAXQTY ? parseInt(this.selectedItem.MAXQTY.toString()) : null;
    this.selectedItem.MINIQTY = this.selectedItem.MINIQTY ? parseInt(this.selectedItem.MINIQTY.toString()) : null;
    this.selectedItem.SUPPLIER = this.selectedItem.SUPPLIER ? parseInt(this.selectedItem.SUPPLIER.toString()) : null;
    this.selectedItem.COLOR = this.selectedItem.COLOR ? parseInt(this.selectedItem.COLOR.toString()) : null;
    this.selectedItem.MIDA = this.selectedItem.MIDA ? parseInt(this.selectedItem.MIDA.toString()) : null;

    // TODO: Add booleans
    this.selectedItem.IT_SALE = this.selectedItem.IT_SALE_BOOL ? 'T' : 'F';
    this.selectedItem.IT_CHPRICE = this.selectedItem.IT_CHPRICE_BOOL ? 'T' : 'F';
    this.selectedItem.SCALE = this.selectedItem.SCALE_BOOL ? 'T' : 'F';
    this.selectedItem.NOVAT = this.selectedItem.NOVAT_BOOL ? 'T' : 'F';
    this.selectedItem.IF_DISCOUNT = this.selectedItem.IF_DISCOUNT_BOOL ? 'Tr' : 'Fa';

    let TABLEIDtmp = this.selectedItem.TABLEID;
    this._ItemsManagmentService.postselectedItem(this.selectedItem, this.shopIndex)
      .subscribe(
        (data: number) => {
          if (TABLEIDtmp === 0 && data) //added item
          {
            //this.selectedItem.TABLEID = data;
            //this.itemsRows[this.shopIndex].push(this.selectedItem);

            this.itemsRows.push(this.selectedItem);
//            this.itemsRowsFilterd.push(this.selectedItem);
//            this.dataSource = new MatTableDataSource<Item>(this.itemsRowsFilterd);
            this.newItem();
          }
          this.filterItems();
        }, // success path/
        error => this.error = error // error path
      );
  }


  deleteItem(selectedItem : Item) {
    this._ItemsManagmentService.deleteselectedItem(this.selectedItem, this.selectedShop, this.shopIndex)
      .subscribe(
        (data: boolean) => {
          if (data) //added item
          {
            if (!this.isColumnsMobile) this.newItem();

            this.itemsRows = this.itemsRows.filter(item => item.IT_CODE !== selectedItem.IT_CODE);
            this.itemsRowsFilterd = this.itemsRowsFilterd.filter(item => item.IT_CODE !== selectedItem.IT_CODE);

/*
            for (let i = 0; i < this.itemsRows.length; i++) {
              if (this.itemsRows[i].IT_CODE == this.selectedItem.IT_CODE)
                this.itemsRows.reduce(this.selectedItem);
            }

            for (let i = 0; i < this.itemsRowsFilterd.length; i++) {
              if (this.itemsRowsFilterd[i] == this.selectedItem) this.itemsRowsFilterd.splice(i, 1);
            }
*/
//            this.dataSource = new MatTableDataSource<Item>(this.itemsRowsFilterd)

            this.filterItems();
//             this.sortItems();
            // sortItems(){
            //  this.dataSource.sort = this.sort;
            //  const sortState: Sort = {active: 'IT_CODE', direction: 'asc'};
            //  this.sort.active = sortState.active;
            //  this.sort.direction = sortState.direction;
            //  this.sort.sortChange.emit();
           // }
          }
          else {
            this.updateDeleteFailed(this.selectedItem);
          }
        }, // success path/
        error => this.error = error // error path
      );
  }

  updateItCode(itcodenew: string) {
    this._ItemsManagmentService.UpdateItemItCode(this.selectedItem, this.selectedShop, this.shopIndex,itcodenew)
      .subscribe(
        (data: boolean) => {
          if (data) //updated item
          {
            let supplier = -1;
            let sortcode = -1;
            this.filterText = this.selectedItem.IT_CODE;
            this.getItemSearch(this.selectedShop.ITEMS_FROM_SHOP_ID, sortcode, supplier, this.selectedItem.IT_CODE);            
           // this.newItem();
//            this.filterItems();
//            this.sortItems();

          }
          else {
            //this.updateDeleteFailed(this.selectedItem);
          }
        }, // success path/
        error => this.error = error // error path
      );
  }



  public togglePageSize() {
//    this.paginator.pageSize = this.paginator.pageSize === 5 ? 10 : 5;
//    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
//    this.pageSize = this.paginator.pageSize;
  }

  public transactionsReport():void{
    this._ItemsManagmentService.setCurrentItemRow(this.selectedItem);
    this.router.navigate(['transactions-report', {  }]);
  }

  onPaginateChange(pageIndex){
    this.saveFilters();
  }

  /**
   * saveFilters
   */
  public saveFilters() {
    let currentFilter = new FilterItems(this.selectedCategory,this.selectedShop,this.filterText,this.dataSource.paginator.pageIndex,this.selectedSupplier);
    this._ItemsManagmentService.setCurrentItemManagmentFilter(currentFilter);
  }

}


