<!--<mat-selection-list #userslist [multiple]="false">
  <mat-list-option *ngFor="let user of users" (click)="selectUser(user)">
    {{user.NormalizedEmail}}
  </mat-list-option>
</mat-selection-list>


<mat-selection-list #shopslist [multiple]="false" *ngIf="selectedUser">
  <mat-list-option *ngFor="let role of availableroles" (click)="addUsersRoles(role)" [selected]="true">
    {{role.Id}}
  </mat-list-option>
</mat-selection-list>

<mat-selection-list #usersShopsList [multiple]="false" *ngIf="selectedUser && usersroles">
  <mat-list-option *ngFor="let role of usersroles" (click)="deleteUsersRoles(role.RoleId)">
    {{role.RoleId}}
  </mat-list-option>
</mat-selection-list>-->

<div>
  <mat-form-field floatLabel="never" class="full-width filterUsers" name="filterUsers" ngDefaultControl [(ngModel)]="filterUsers" (ngModelChange)="onUserFilterChange()">
    <mat-label>חפש משתמש</mat-label>
    <input matInput placeholder="חפש משתמש" #input>
  </mat-form-field>

  <mat-selection-list #userslist [multiple]="false">
    <mat-list-option *ngFor="let user of usersFilterd" (click)="selectUser(user)">
      {{user.NormalizedEmail}}
    </mat-list-option>
  </mat-selection-list>
</div>

<div style="margin-left:0.5rem" *ngIf="selectedUser">
  <button mat-raised-button color="primary" (click)="selectAllRoles()">בחר הכל</button>
  <button mat-raised-button color="primary" (click)="clearAllRoles()">נקה הכל</button>

  <mat-form-field floatLabel="never" class="full-width filterUsers" name="filterRoles" ngDefaultControl [(ngModel)]="filterRoles" (ngModelChange)="onRoleFilterChange()">
    <mat-label>חפש חנות</mat-label>
    <input matInput placeholder="חפש הרשאה" #input>
  </mat-form-field>

  <mat-selection-list #pizzaList [(ngModel)]="selectedRoles" (ngModelChange)="onRolesChange($event)">
    <mat-list-option *ngFor="let role of rolesFilterd" [value]=role>
      {{role.Id}}
    </mat-list-option>
  </mat-selection-list>
</div>



