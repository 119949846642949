import { Component, OnInit } from '@angular/core';
import { ReportDateRange } from '../time-range-selection/time-range-selection.component';
import { Shop } from '../../Shop';
import { InvoicingReportService } from './invoicing-report.service';


@Component({
  selector: 'app-invoicing-report',
  templateUrl: './invoicing-report.component.html',
  styleUrls: ['./invoicing-report.component.css']
})
export class InvoicingReportComponent {
  selectedShop: Shop = null;
  selectedDateRange: ReportDateRange = null;
  error: any;
  public invoices: any[];
  isLoading = false;

  constructor(public _invoicingReportService: InvoicingReportService) {

  
}

  public getReport(): void {
//    alert("getReport" + this.selectedDateRange.reportEnd + " " + this.selectedShop.SHOP_ID)
    this.isLoading = true;
    this._invoicingReportService.getInvoicing(this.selectedDateRange.reportStart, this.selectedDateRange.reportEnd, this.selectedShop.SHOP_ID)
      .subscribe(
        (data: any[]) => {
          console.log(data);
          this.invoices = data;
          this.isLoading = false;
          return;
        }, // success path/
        error => { this.isLoading = false; this.error = error } // error path
      );
  }

  public dateChanged(daterange: ReportDateRange) {
//    alert("dateChanged " +daterange.reportEnd);
    this.selectedDateRange = daterange;
//    this.getReport();
  }

  public shopChanged(shop: Shop) {
//    alert(shop.SHOP_NAME);
    this.selectedShop = shop;
//    this.getReport();
  }

  
}
