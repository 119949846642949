import { Pipe, PipeTransform } from '@angular/core';
import { Item } from '../../items-managment/Item';

@Pipe({
  name: 'itemName',
  pure: true

})

export class ItemNamePipe implements PipeTransform {

  transform(value: string, items: Item[]): unknown {
    try {
      return items.find(x => x.IT_CODE === value).IT_DESC;
   }
    catch{
      return value;
    }
  }

}
