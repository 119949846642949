import { Component, Input } from '@angular/core';
import * as d3 from 'd3';
import { PerHourSales } from '../../reports/per-hour-sales/per-hour-sales';

@Component({
  selector: 'bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent {
  @Input('PerHourSales') perHourSales: PerHourSales[] = null;
  @Input('Title') title = '';
  @Input('YaxisMax') yaxisMax = 0;
  @Input('YaxisMin') yaxisMin = 0;


  constructor() { }
  
  ngOnChanges() {
    d3.select("figure#bar").select("svg").remove();
    this.createSvg();
    this.drawBars(this.perHourSales);
  }
 
  private svg;
  private margin = 50;
  private width = 1400 - (this.margin * 2);
  private height = 450 - (this.margin * 2);

  private createSvg(): void {
    this.svg = d3.select("figure#bar")
      .append("svg")
      .attr("width", this.width + (this.margin * 2))
      .attr("height", this.height + (this.margin * 2))
      .append("g")
      .attr("transform", "translate(" + this.margin + "," + this.margin + ")");
  }

  private drawBars(data: PerHourSales[]): void {
    // Create the X-axis band scale
    const x = d3.scaleBand()
      .range([0, this.width])
      .domain(data.map(d => d.hour.toString()))
      .padding(0.2);


    // Draw the X-axis on the DOM
    this.svg.append("g")
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3.axisBottom(x))
      .selectAll("text")


          //.attr("transform", "translate(-50,0)")

      //.attr("transform", "translate(-10,0)rotate(-45)")
      //.style("text-anchor", "end");

    // Create the Y-axis band scale
    const y = d3.scaleLinear()
      .domain([this.yaxisMin, this.yaxisMax])
      .range([this.height, 0]);

    // Draw the Y-axis on the DOM
    this.svg.append("g")
      .call(d3.axisLeft(y));

    //20 + 45 * index
    // Create and fill the bars
    this.svg.selectAll("bars")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", d => x(d.hour))
     // .attr("x", d => data.findIndex(x => x.hour === d.hour) * 45)
      .attr("y", d => y(d.totalPerDay))
      .attr("width", x.bandwidth())
     // .attr("width", 40)
      .attr("height", (d) => this.height - y(d.totalPerDay))
      .attr("fill", "#0000FF")
     // .attr("aaa", d => data.findIndex(x => x.hour === d.hour))
  }
  
}
