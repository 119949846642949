import { createReducer, on } from '@ngrx/store';
import { Item } from '../items-managment/Item';
import {
  setItem,
  setItemCost,
  SetItemCostAfterSapakDiscount,
  setItemPriceWithoutVat,
  setItemProfit,
  setItemButtomProfit,
  setItemTopProfit
} from '../actions/selected-item.actions';

export const initialState: Item = null;


const _SelectedItemReducer = createReducer(
  initialState,
  on(setItem, (state, { item }) => {
  /*  setItemCost();
    SetItemCostAfterSapakDiscount();
    setItemPriceWithoutVat();
    setItemProfit()
    setItemButtomProfit();
    setItemTopProfit();
    console.log('newState', newState)*/
    let newState = JSON.parse(JSON.stringify(item));
    return newState;
  }),

  on(setItemCost, (state) => {
    let newState: Item = JSON.parse(JSON.stringify(state));
    newState.vat = 17;
    newState.costWithoutVat = newState.STANDARTCOST;
    newState.costWithVat = newState.NOVAT === "T" ? newState.STANDARTCOST : newState.STANDARTCOST * newState.vat;
    newState.costWithVat = Math.round((newState.costWithVat + Number.EPSILON) * 100) / 100;
    console.log('setItemCost', newState)
    return newState;
  }),

  on(SetItemCostAfterSapakDiscount, (state) => {
    let newState: Item = JSON.parse(JSON.stringify(state));
    if (!newState.COSTPERC)
        newState.COSTPERC = 0;

    newState.costWithVatAfterSapakDiscount = newState.costWithVat * (100 - newState.COSTPERC) / 100
    newState.costWithoutVatAfterSapakDiscount = newState.costWithoutVat * (100 - newState.COSTPERC) / 100
    return newState;
  }),

  on(setItemPriceWithoutVat, (state) => {
    let newState: Item = JSON.parse(JSON.stringify(state));
    newState.priceWithoutVat = newState.NOVAT !== "T" ? newState.PRICE1 / newState.vat : newState.PRICE1;
    newState.priceWithoutVat = Math.round(newState.priceWithoutVat * 100) / 100
    return newState;
  }),

  on(setItemProfit, (state) => {
    let newState: Item = JSON.parse(JSON.stringify(state));
    newState.profit = (newState.priceWithoutVat - newState.costWithoutVatAfterSapakDiscount);
    return newState;
  }),

  on(setItemButtomProfit, (state) => {
    let newState: Item = JSON.parse(JSON.stringify(state));
    newState.buttomProfit = (newState.profit / newState.costWithoutVatAfterSapakDiscount) * 100;
    newState.buttomProfit = Math.round((newState.buttomProfit + Number.EPSILON) * 100) / 100;
    return newState;
  }),

  on(setItemTopProfit, (state) => {
    let newState: Item = JSON.parse(JSON.stringify(state));
    newState.topProfit = (newState.profit / newState.priceWithoutVat) * 100
    newState.topProfit = Math.round((newState.topProfit + Number.EPSILON) * 100) / 100;
    return newState;
  }),
)

export const costWithoutVat = (state: Item) => state.costWithoutVat;





export function SelectedItemReducer(state, action) {
  return _SelectedItemReducer(state, action);
}


