
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <ng-container matColumnDef="invoiceNumber">
    <th mat-header-cell *matHeaderCellDef>מספר חשבונית</th>
    <td mat-cell style="background-color: yellow; font-weight: bold;" *matCellDef="let element"> {{element.invoiceNumber}} </td>
  </ng-container>

  <ng-container matColumnDef="clientName">
    <th mat-header-cell *matHeaderCellDef>שם לקוח</th>
    <td mat-cell *matCellDef="let element"> {{element.clientName}} </td>
  </ng-container>

  <ng-container matColumnDef="clientNumber">
    <th mat-header-cell *matHeaderCellDef>מספר לקוח</th>
    <td mat-cell *matCellDef="let element"> {{element.clientNumber}} </td>
  </ng-container>
  <!--  -->
  <ng-container matColumnDef="clientAddress">
    <th mat-header-cell *matHeaderCellDef>כתובת לקוח</th>
    <td mat-cell *matCellDef="let element"> {{element.clientAddress}} </td>
  </ng-container>


  <ng-container matColumnDef="dateTime">
    <th mat-header-cell *matHeaderCellDef>זמן עסקה</th>
    <td mat-cell *matCellDef="let element" style="direction:ltr !important"> {{element.dateTime | date:'dd/MM/yyyy, hh:mm a'}} </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


  <!-- Row shown when there is no matching data.  -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">{{input.value}}"</td>
  </tr>
</table>
