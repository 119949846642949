import { Component, OnInit, ViewChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import { itSortRow } from '../items-managment/itSortRow';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Shop } from '../Shop';
import { ItemsManagmentService } from '../items-managment/items-managment.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { interval } from 'rxjs/internal/observable/interval';
import { Subscription } from 'rxjs';
import { MDCFormField } from '@material/form-field';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';


@Component({
  selector: 'it-sort-managment',
  templateUrl: './it-sort-managment.component.html',
  styleUrls: ['./it-sort-managment.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ItSortManagmentComponent implements OnInit, OnDestroy {

  public shops: Shop[] = [];
  public itSortRows: itSortRow[][] = [];
  public lastUpdated: Date;
  public itSortRowsFilterd: itSortRow[] = [];
  public selectedShop: Shop = null;
  public dialog: MatDialog;
  public selectedItem: any;

  public shopIndex = 0;
  public filterValue = "";
  public selectedITSORT: itSortRow;
  rowClicked: number;
  error: any;
  lwidth: string;
  lheight: string;
  ltop: string;
  ifdelete: boolean; 


  displayedColumns: string[] =
    [
      'CODE',
      'DESCR',
    ];

  @ViewChild(MatPaginator) paginator: MatPaginator;



  public newItSort(): void {
    this.selectedITSORT = {
      SHOP_ID: this.selectedShop.SHOP_ID,
      TABLEID: 0,
      CODE: null,
      DESCR: null,
      ITSORT: null,
      DISDCOUNT: null
    }
  }

  constructor(public _ItemsManagmentService: ItemsManagmentService) {
  }

  ngOnInit(): void {
    this.getItems(null);
    this.dataSource.paginator = this.paginator;
  }

  public dataSource = new MatTableDataSource<itSortRow>(this.itSortRowsFilterd);

  filterItems() {
    this.itSortRowsFilterd = this.itSortRows[this.shopIndex].filter(x => x);
    this.itSortRowsFilterd.sort(function (a, b) {
      return a.CODE - b.CODE
    })

    this.dataSource = new MatTableDataSource<itSortRow>(this.itSortRowsFilterd);
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    this.dataSource.paginator = this.paginator;
  }



  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
  }

  getItems(lastUpdated: Date) {
    this._ItemsManagmentService.getItems(lastUpdated)
      .subscribe(
        (data: any) => {
          this.shops = data.shops;
          this.itSortRows = data.itSortRows;
          this.selectedShop = this.shops[0];
          this.itSortRowsFilterd = [];
          this.dataSource = new MatTableDataSource<itSortRow>(this.itSortRowsFilterd)
          this.dataSource.paginator = this.paginator;
          this.startDelayedFilter();
          this.newItSort();
          return;
        }, // success path/
        error => this.error = error // error path
      );
  }
  subscription: Subscription;

  ngOnDestroy() {
    //this.subscription.unsubscribe();
  }


  startDelayedFilter(): void {
    const source = interval(1000);
    this.subscription = source.subscribe(val => {
      if (this.itSortRowsFilterd.length === 0) {
        this.filterItems()
        this.subscription.unsubscribe();
      }
      return 1;
    }
    );
  }

  selectionChangeShop(selectedshop: Shop) {
    this.selectedShop = selectedshop;
    this.shopIndex = this.shops.findIndex(x => x.SHOP_ID === this.selectedShop.SHOP_ID)
    this.filterItems();
  }

  public selectRow(idx: any, itSortRow: itSortRow) {
    if (this.rowClicked === idx) {
      this.rowClicked = -1;
    }
    else {
      this.rowClicked = idx;
    }

    this.selectedITSORT = itSortRow;
  }

  onNoClick(): void {
    this.ifdelete = false;
  }

  onYesClick(): void {
    this.ifdelete = false;

    this.selectedITSORT.SHOP_ID = this.selectedShop.SHOP_ID;
        this._ItemsManagmentService.deleteselectedIt_sort(this.selectedITSORT)
      .subscribe(
        (data: any) => {
          for (let i = 0; i < this.itSortRows[this.shopIndex].length; i++) {
            let itSortRow = this.itSortRows[this.shopIndex][i];
            if (itSortRow == this.selectedITSORT) this.itSortRows[this.shopIndex].splice(i, 1); 
          }
          this.itSortRowsFilterd = [];
          this.dataSource = new MatTableDataSource<itSortRow>(this.itSortRowsFilterd)
          this.startDelayedFilter();

          this.filterItems();
          this.rowClicked = -1;

          return;
        }, // success path/
        error => this.error = error // error path
      );


  }

  openConfirmDialog(): void {
    this.ifdelete=true;
/*
    this.lwidth = '95%';
    this.lheight = '50%';
    this.ltop = '6.5rem';

    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
    disableClose: true,
    maxWidth: '100vw',
    maxHeight: '100vh',
    position: {
         top: this.ltop
    },
    height: this.lheight,
    width: this.lwidth,
    data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Yes clicked');
        // Perform delete action here
      } else {
        console.log('No clicked');
      }
    });
*/
  }
     
  
  save() {
    this.selectedITSORT.CODE = this.selectedITSORT.CODE ? parseInt(this.selectedITSORT.CODE.toString()) : null;

    this._ItemsManagmentService.postselectedITSORT(this.selectedITSORT, this.shopIndex)
      .subscribe(
        (data: number) => {
          //console.log('this.selectedITSORT.TABLEID 1', this.selectedITSORT.TABLEID)
         // if (this.selectedITSORT.TABLEID === 0 && data) //added item
          if(data)
          {
            this.selectedITSORT.TABLEID = data;
            this.itSortRows[this.shopIndex].push(this.selectedITSORT);
          }
          this.newItSort();
          this.filterItems();
        }, // success path/
        error => this.error = error // error path
      );
  }

}
