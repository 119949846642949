<div class="bordered-div">
  <h1 mat-dialog-title>Confirm</h1>
  <div mat-dialog-content>
    <p>Are you sure you want to delete?</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No</button>
    <button mat-button (click)="onYesClick()">Yes</button>
  </div>
</div>
