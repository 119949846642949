import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'docType',
  pure: true

})
export class DocTypePipe implements PipeTransform {

  transform(value: number): string {
    switch(value) {
      case 1: {  return 'ספירת מלאי'; }
      case 2: {  return 'הפרשי ספירה'; }
      case 10: {  return 'כניסת ספק'; }
      case 11: {  return 'כניסת ספק בן'; }
      case 12: {  return 'כניסה מחנות'; }
      case 30: {  return 'חשבוניות'; }

      case 31: {  return 'חשבוניות'; }
      case 32: {  return 'תעודות משלוח'; }
      case 33: {  return 'העברה לחנות'; }
      case 34: {  return 'חשבונית חו"ל'; }
      case 35: {  return 'ת.משלוח חו"ל'; }

      case 36: {  return 'תעודת בלאי'; }
      case 37: {  return 'תעודת ביטול'; }

      case 40: {  return 'חשבוניות'; }
      case 41: {  return 'חשבוניות'; }
      case 42: {  return 'תעודות משלוח'; }
      case 43: {  return 'העברה לחנות'; }
      case 44: {  return 'חשבונית חו"ל'; }
      case 45: {  return 'ת.משלוח חו"ל'; }
      case 46: {  return 'תעודת בלאי'; }
      case 47: {  return 'חשבונית עסקה'; }

      case 50: {  return 'הזמנות'; }




   }
   return null
  }

}
