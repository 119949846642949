<div id="content1">
  <h1 class="hh4" style="text-align: center; font-weight: bold; background-color: yellow; color: blue;"> הקמה ו/או עדכון פריט </h1>

  <div id="IT_CODE_Div" class="form-floating customized-input flex-item">
    <input type="text" class="form-control" id="IT_CODE" autocomplete="off" placeholder="קוד פריט" [(ngModel)]="selectedItem.IT_CODE" #input>
    <label for="IT_CODE" class="label-color">קוד פריט</label>
  </div>

  <div id="IT_DESC_Div" class="form-floating customized-input flex-item">
    <input type="text" class="form-control" id="IT_DESC" autocomplete="off" placeholder="תיאור פריט" [(ngModel)]="selectedItem.IT_DESC" #input maxlength="40" />
    <label for="IT_DESC" class="label-color">תאור פריט </label>
  </div>

  <div class="flex-row">
    <div id="IT_CODE_Div" class="form-floating customized-input flex-item">
      <input type="text" class="form-control" autocomplete="off" placeholder="ברקוד" [(ngModel)]="selectedItem.BARCODE" #input>
      <label for="BARCODE" class="label-color">ברקוד</label>
    </div>
    <div id="IT_CODE_Div" class="form-floating customized-input flex-item">
      <input type="text" inputmode="numeric" class="form-control" placeholder="קוד ספק" autocomplete="off" [(ngModel)]="selectedItem.IT_S_CODE" #input>
      <label class="label-color">קוד ספק</label>
    </div>
  </div>

  <div class="flex-row">
    <div id="CODE_Div" class="form-floating customized-input flex-item">
      <input type="text" class="form-control" autocomplete="off" placeholder="קבוצה" [(ngModel)]="selectedItem.ITSORT" #input (keyup)="changeInputITSORT()">
      <label class="label-color">קבוצה</label>
    </div>

    <div id="ITSORT_Div" class="form-floating customized-select flex-item">
      <select class="form-select" [(ngModel)]="selectedITSORT" (ngModelChange)="selectionChangeselectedITSORT($event.value)" required>
        <option *ngFor="let itSortRow of itSortRows[shopIndex]" [ngValue]="itSortRow">{{ itSortRow.DESCR }}</option>
      </select>
      <label class="label-color">בחר קבוצה</label>
    </div>
  </div>
  <div class="flex-row">
    <div id="SUP_Div" class="form-floating customized-input flex-item">
      <input type="text" inputmode="numeric" class="form-control" placeholder="ספק" autocomplete="off" (keyup)="changeInputSUPPLIER()" [(ngModel)]="selectedItem.SUPPLIER" #input>
      <label class="label-color">מס' ספק</label>
    </div>

    <div id="ITSORT_Div" class="form-floating customized-select flex-item">
      <select class="form-select" [(ngModel)]="selectedSUPPLIER" (ngModelChange)="selectionChangeselectedSUPPLIER($event.value)" required>
        <option *ngFor="let supplierRow of supplierRows[shopIndex]" [ngValue]="supplierRow">{{ supplierRow.CLNAME }}</option>
      </select>
      <label class="label-color">בחר ספק</label>
    </div>
  </div>


  <div class="flex-row">
    <div id="PRICE_Div" class="form-floating customized-input flex-item">
      <input type="text" inputmode="numeric" style="padding-right:0" class="form-control" placeholder="הנחת ספק" autocomplete="off" [(ngModel)]="selectedItem.COSTPERC" #input>
      <label style="padding-right:0" class="label-color">הנחת ספק</label>
    </div>

    <div id="PRICE_Div" class="form-floating customized-input flex-item">
      <input type="text" inputmode="numeric" class="form-control" placeholder="עלות תקן" autocomplete="off" [(ngModel)]="selectedItem.STANDARTCOST" #input>
      <label class="label-color">עלות תקן</label>
    </div>

    <div id="PRICE_Div" class="form-floating customized-input flex-item">
      <input type="text" class="form-control" placeholder="ע. אחרונה" autocomplete="off" [(ngModel)]="this.selectedItem.LASTCOST" #input disabled>
      <label class="label-color">ע. אחרונה</label>
    </div>
  </div>
  <div class="flex-row">
    <div id="PRICE_Div" class="form-floating customized-input flex-item">
      <input type="text" inputmode="numeric" class="form-control" placeholder="מחיר 1" autocomplete="off" [(ngModel)]="this.selectedItem.PRICE1" #input>
      <label class="label-color">מחיר 1 </label>
    </div>

    <div id="PRICE_Div" class="form-floating customized-input flex-item">
      <input type="text" inputmode="numeric" class="form-control" placeholder="מחיר 2" autocomplete="off" [(ngModel)]="this.selectedItem.PRICE2" #input>
      <label class="label-color">מחיר 2</label>
    </div>

    <div id="PRICE_Div" class="form-floating customized-input flex-item">
      <input type="text" inputmode="numeric" class="form-control" placeholder="מחיר 3" autocomplete="off" [(ngModel)]="this.selectedItem.PRICE3" #input>
      <label class="label-color">מחיר 3 </label>
    </div>
  </div>
  <div class="flex-row">
    <div id="PRICE_Div" class="form-floating customized-input flex-item">
      <input type="text" inputmode="numeric" class="form-control" placeholder="מחיר 4" autocomplete="off" [(ngModel)]="this.selectedItem.PRICE4" #input>
      <label class="label-color">מחיר 4 </label>
    </div>

    <div id="PRICE_Div" class="form-floating customized-input flex-item">
      <input type="text" inputmode="numeric" class="form-control" placeholder="מחיר 5" autocomplete="off" [(ngModel)]="this.selectedItem.PRICE5" #input>
      <label class="label-color">מחיר 5 </label>
    </div>

    <div id="PRICE_Div" class="form-floating customized-input flex-item">
      <input type="text" inputmode="numeric" class="form-control" placeholder="מחיר 6" autocomplete="off" [(ngModel)]="this.selectedItem.PRICE6" #input>
      <label class="label-color">מחיר 6 </label>
    </div>
  </div>
  <div class="flex-row">
    <div id="PRICE_Div" class="form-floating customized-input flex-item">
      <input type="text" inputmode="numeric" class="form-control" placeholder="מחיר 7" autocomplete="off" [(ngModel)]="this.selectedItem.PRICE7" #input>
      <label class="label-color">מחיר 7 </label>
    </div>

    <div id="PRICE_Div" class="form-floating customized-input flex-item">
      <input type="text" inputmode="numeric" class="form-control" placeholder="מחיר 8" autocomplete="off" [(ngModel)]="this.selectedItem.PRICE8" #input>
      <label class="label-color">מחיר 8 </label>
    </div>

    <div id="PRICE_Div" class="form-floating customized-input flex-item">
      <input type="text" inputmode="numeric" class="form-control" placeholder="מחיר 9 " autocomplete="off" [(ngModel)]="this.selectedItem.PRICE9" #input>
      <label class="label-color">מחיר 9 </label>
    </div>
  </div>
  <div class="flex-row">
    <div id="SHORT_Div" class="form-floating customized-input flex-item">
      <input type="text" inputmode="numeric" class="form-control" placeholder="חתך" autocomplete="off" [(ngModel)]="selectedItem.CUT" #input>
      <label class="label-color">חתך</label>
    </div>

    <div id="PRICE_Div" class="form-floating customized-input flex-item">
      <input type="text" inputmode="numeric" class="form-control" placeholder="כ. מינימום" autocomplete="off" [(ngModel)]="selectedItem.MINIQTY" #input>
      <label class="label-color">כ. מינימום</label>
    </div>

    <div id="PRICE_Div" class="form-floating customized-input flex-item">
      <input type="text" inputmode="numeric" class="form-control" placeholder="כ. מקסימום" autocomplete="off" [(ngModel)]="selectedItem.MAXQTY" #input>
      <label class="label-color">כ. מקסימום</label>
    </div>
  </div>
  <div id="REMARK_Div" class="form-floating customized-input flex-item">
    <input type="text" class="form-control" placeholder="הערת מכירה" autocomplete="off" [(ngModel)]="selectedItem.SALEREMARK" #input>
    <label class="label-color">הערת מכירה</label>
  </div>
  <div class="flex-row">
    <div id="PERCENT_Div" class="form-floating customized-input flex-item">
      <input type="text" class="form-control" placeholder="כ. ליחידה" autocomplete="off" [(ngModel)]="selectedItem.QTYPERUNIT" #input>
      <label class="label-color">כ.יחידה</label>
    </div>

    <div id="PERCENT_Div" class="form-floating customized-input flex-item">
      <input type="text" inputmode="numeric" class="form-control" placeholder="_" autocomplete="off" [(ngModel)]="selectedItem.DISCOUNT" #input>
      <label class="label-color">% הנחה</label>
    </div>
  </div>

  <mat-dialog-actions align="left" id="EndButton">
    <button mat-button mat-dialog-close mat-raised-button color="basic">ביטול</button>
    <button mat-button [mat-dialog-close]=selectedItem cdkFocusInitial mat-raised-button color="primary">שמור</button>
  </mat-dialog-actions>
</div>
