<ng-container *ngIf="ZReport.zreports.length>0">

  <p class="reportDate">
    {{ZReport.date | date: 'dd/MM/yyyy'}}
    <span *ngIf="ZReport.zreports.length == 2"> - {{ZReport.zreports[0].shopName}} </span>
  </p>

  <div style="display: flex; justify-content: center;" *ngIf="isLoading" class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
  <div class="table-container">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="shopName">
        <th mat-header-cell *matHeaderCellDef>שם סניף</th>
        <td mat-cell *matCellDef="let element"> {{element.shopName}} </td>
      </ng-container>

      <ng-container matColumnDef="cash">
        <th mat-header-cell *matHeaderCellDef>מזומן</th>
        <td mat-cell *matCellDef="let element"> {{element.cash | number:'1.2'}} </td>
      </ng-container>

      <ng-container matColumnDef="checks">
        <th mat-header-cell *matHeaderCellDef>צ'קים</th>
        <td mat-cell *matCellDef="let element"> {{element.checks | number:'1.2'}} </td>
      </ng-container>

      <ng-container matColumnDef="creditCard">
        <th mat-header-cell *matHeaderCellDef>כרטיסי אשראי </th>
        <td mat-cell *matCellDef="let element"> {{element.creditCard | number:'1.2'}} </td>
      </ng-container>

      <ng-container matColumnDef="hakafa">
        <th mat-header-cell *matHeaderCellDef>הקפה</th>
        <td mat-cell *matCellDef="let element"> {{element.hakafa | number:'1.2'}} </td>
      </ng-container>

      <ng-container matColumnDef="netPayment">
        <th mat-header-cell *matHeaderCellDef>תשלום באתר</th>
        <td mat-cell *matCellDef="let element"> {{element.netPayment | number:'1.2'}} </td>
      </ng-container>



      <ng-container matColumnDef="sum">
        <th mat-header-cell *matHeaderCellDef>סה"כ</th>
        <td mat-cell *matCellDef="let element"> {{element.sum | number:'1.2'}} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>



      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{input.value}}"</td>
      </tr>
    </table>

    <!--
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="shopName">
              <th mat-header-cell *matHeaderCellDef>שם סניף</th>
              <td mat-cell *matCellDef="let element"> {{element.shopName}} </td>
            </ng-container>

            <ng-container matColumnDef="cash">
              <th mat-header-cell *matHeaderCellDef>מזומן</th>
              <td mat-cell *matCellDef="let element"> {{element.cash | number:'1.2'}} </td>
            </ng-container>

            <ng-container matColumnDef="checks">
              <th mat-header-cell *matHeaderCellDef>צ'קים</th>
              <td mat-cell *matCellDef="let element"> {{element.checks | number:'1.2'}} </td>
            </ng-container>

            <ng-container matColumnDef="creditCard">
              <th mat-header-cell *matHeaderCellDef>כרטיסי אשראי </th>
              <td mat-cell *matCellDef="let element"> {{element.creditCard | number:'1.2'}} </td>
            </ng-container>

            <ng-container matColumnDef="hakafa">
              <th mat-header-cell *matHeaderCellDef>הקפה</th>
              <td mat-cell *matCellDef="let element"> {{element.hakafa | number:'1.2'}} </td>
            </ng-container>

            <ng-container matColumnDef="netPayment">
              <th mat-header-cell *matHeaderCellDef>תשלום באתר</th>
              <td mat-cell *matCellDef="let element"> {{element.netPayment | number:'1.2'}} </td>
            </ng-container>



            <ng-container matColumnDef="sum">
              <th mat-header-cell *matHeaderCellDef>סה"כ</th>
              <td mat-cell *matCellDef="let element"> {{element.sum | number:'1.2'}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>



  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">{{input.value}}"</td>
  </tr>
  </table>-->

  </div>

</ng-container>
