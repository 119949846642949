<div class="flex-row filter-row-container">
  <date-range-selection (startReportPickerChanged)="startReportPickerChanged($event)" (endReportPickerChanged)="endReportPickerChanged($event)"></date-range-selection>
  <span style="font-weight:bold; margin-right:1rem; display:block;clear:both">פער ימים {{Difference_In_Days}}</span>

  <div class="flex-row filter-row-container">
    <button mat-button style="margin-right: -4.5rem; margin-top: 1.5rem" (click)=geSalesReport() cdkFocusInitial mat-raised-button color="primary">בצע</button>
  </div>

  <div class="my-shops-selection">
    <my-shops-selection (shopsChanged)="shopsChanged($event)"></my-shops-selection>

  </div>

  <button *ngIf="isLoading" style="margin-top:0rem;height:3rem;" class="btn btn-primary" type="button" disabled>
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span class="sr-only">מטעין...</span>
  </button>
</div>

<ng-container *ngIf="perHourSales && perHourSales.length>0">

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="hour">
      <th mat-header-cell *matHeaderCellDef>שעה </th>
      <td mat-cell *matCellDef="let element"> {{element.hour | number:'1.0-2'}} </td>
    </ng-container>


    <ng-container matColumnDef="qty">
      <th mat-header-cell *matHeaderCellDef>כמות </th>
      <td mat-cell *matCellDef="let element"> {{element.qty | number:'1.0-2'}} </td>

    </ng-container>

    <ng-container matColumnDef="qtyPerDay">
      <th mat-header-cell *matHeaderCellDef>כמות מחולק בימים</th>
      <td mat-cell *matCellDef="let element"> {{element.qtyPerDay | number:'1.2-2'}} </td>
    </ng-container>


    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef>סה"כ כספי </th>
      <td mat-cell *matCellDef="let element"> {{element.total | number:'1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="totalPerDay">
      <th mat-header-cell *matHeaderCellDef>כספי מחולק לימים </th>
      <td mat-cell *matCellDef="let element"> {{element.totalPerDay | number:'1.2-2'}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">{{input.value}}"</td>
    </tr>
  </table>

</ng-container>

<div allign="center" style="background-color:white; width:100rem;" *ngIf="perHourSales && perHourSales.length>0">

  <dx-chart [dataSource]="perHourSales"
 [argumentAxis]="{tickInterval: 1, label: {visible: true } }"
            >
    <dxo-size [height]="420"></dxo-size>
    <dxi-series argumentField="hour"
                valueField="total"
                type="bar">
      <dxo-label [visible]="true" [format]="{type: 'fixedPoint', precision: '0'}">  </dxo-label>
    </dxi-series>

    <dxo-legend [visible]="false"></dxo-legend>
    <dxo-loading-indicator [enabled]="true"></dxo-loading-indicator>
  </dx-chart>

  <!--


  <dx-chart id="chart" [dataSource]="perHourSales" palette="soft">

    <dxo-common-series-settings argumentField="hour"
                                type="bar"
                                valueField="total"
                                [tickInterval]="1"
                                [ignoreEmptyPoints]="true">
    </dxo-common-series-settings>
    <dxo-series-template nameField="hour"></dxo-series-template>
  </dx-chart>  -->
</div>
<!--
  <div allign="center">
    <bar-chart allign="center" style="font-size:1rem; height:20rem; width:20rem" class="onlyDesktop"
               *ngIf="perHourSales && perHourSales.length>0"
               [PerHourSales]="perHourSales"
               [Title]=""
               [YaxisMax]=yaxisMax
               [YaxisMin]=0></bar-chart>

  </div>
 -->
<!--class="onlyDesktop"-->
<!--
<bar-chart-horizontal *ngIf="perHourSales && perHourSales.length>0"
           [PerHourSales]="perHourSales"
           [Title]=""
           [YaxisMax]=yaxisMax
           [YaxisMin]=0></bar-chart-horizontal>-->
