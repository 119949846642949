import { Component } from '@angular/core';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { NavMenuService } from '../nav-menu/nav-menu.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-nav-menu-new',
  templateUrl: './nav-menu-new.component.html',
  styleUrls: ['./nav-menu-new.component.css']
})
export class NavMenuNewComponent {

  isExpanded = false;
  public isAuthenticated: Observable<boolean>;
  public userName: Observable<string>;
  public roles: string[];
  public isAdmin = false;
  public isStore = false;
  error: any;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
//    this.isExpanded = !this.isExpanded;
    let bool = this.isExpanded;
    this.isExpanded = bool === false ? true : false;
  }
  

  constructor(private authorizeService: AuthorizeService, private _NavMenuService: NavMenuService) {  
  }

  subscription: Subscription;

  // startLogoutTimer(): void {
  //   const source = interval(1000);
  //   this.subscription = source.subscribe(val => {
  //     let t = this._AutoLogoutService.logOutCheck();
  //     if (t) {
  //       window.location.href = '/authentication/logout';
  //       this.subscription.unsubscribe();
  //     }
  //   });
  // }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public menuVisible = false;
  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }

  getRoles() {
    this._NavMenuService.getRoles()
      .subscribe(
        (data: string[]) => {
          this.roles = data;
          this.isAdmin = this.roles.indexOf('ADMIN') > -1;
          this.isStore = this.isAdmin || this.roles.indexOf('USER_STORE') > -1;
          
        }, // success path/
        error => this.error = error // error path
      );
  }
  ngAfterViewInit() {
    this.getRoles();
  }
  ngOnInit() {
    this.isAuthenticated = this.authorizeService.isAuthenticated();

    //this.userName = this.authorizeService.getUser().pipe(map(u => u && u.name));
    // this.familyName = this.authorizeService.getUser().pipe(map(u => u && u.family_name));
    // this.givenName = this.authorizeService.getUser().pipe(map(u => u && u.given_name));
    // this.gender = this.authorizeService.getUser().pipe(map(u => u && u.gender));

  }

  public showReportsMenu = false;

  public hideReportsMenuClick(): void {
    this.showReportsMenu = false;
  }
  showReportsMenuClick(): void
  {
    this.showReportsMenu = !this.showReportsMenu
  }  

}
